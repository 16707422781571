import { BusinessFilters } from "@/modules/business/config/BusinessFilters"


export class OrderFilters extends BusinessFilters {
  constructor () {
    super()
    this.availableFields.status_id.apiUrl = 'service-desk/config/status/order'
    this.availableFields.category_id.apiUrl = 'service-desk/config/category/order'

    this.fields = [
      this.availableFields.subHeader1,
      this.availableFields.status_id,
      this.availableFields.category_id,
      this.availableFields.external_code,
      this.availableFields.person_id,
      this.availableFields.collaborator_id,
      this.availableFields.company_id,
      this.availableFields.well_id,
      this.availableFields.subHeader2,
      this.availableFields.dateField,
      this.availableFields.dateStart,
      this.availableFields.dateEnd,
      this.availableFields.subHeader4,
      this.availableFields.state_id,
      this.availableFields.city_id,
      this.availableFields.subHeader3,
      this.availableFields.product_id,
      this.availableFields.service_id
    ]
  }
}

export class OrderCalendarFilters extends BusinessFilters {
  constructor () {
    super()
    this.availableFields.status_id.apiUrl = 'service-desk/config/status/order'
    this.availableFields.category_id.apiUrl = 'service-desk/config/category/order'
    this.fields = [
      this.availableFields.subHeader1,
      this.availableFields.status_id,
      this.availableFields.category_id,
      this.availableFields.external_code,
      this.availableFields.person_id,
      this.availableFields.collaborator_id,
      this.availableFields.company_id,
      this.availableFields.well_id,
      this.availableFields.subHeader4,
      this.availableFields.state_id,
      this.availableFields.city_id,
      this.availableFields.subHeader3,
      this.availableFields.product_id,
      this.availableFields.service_id
    ]
  }
}
