var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.apiParams)?_c('WiView',{attrs:{"index":"finance-dre","apiUrl":'finance/report/dre',"apiParams":_vm.apiParams,"notShowToolbar":true,"notGetID":true},on:{"afterLoad":_vm.setContent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
return [_c('v-container',{staticStyle:{"padding":"10px"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticStyle:{"padding":"20px 5px 10px 5px"},attrs:{"sm12":""}},[_c('v-toolbar',{staticClass:"elevation-10",staticStyle:{"border-radius":"10px"},attrs:{"dense":""}},[_c('v-btn',{attrs:{"icon":"","ripple":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-list-tile',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"hidden-xs-only",attrs:{"avatar":""}},[_c('v-list-tile-avatar',{staticStyle:{"cursor":"pointer"},attrs:{"size":"45"},on:{"click":function($event){return refresh()}}},[_c('img',{attrs:{"src":"static/systems/modules/wises-technology-module-finance.png","alt":"DRE"}})]),_c('v-list-tile-content',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return refresh()}}},[_c('v-list-tile-sub-title',{staticStyle:{"font-size":"12px"}},[_vm._v(" Relatório Gerencial ")]),_c('v-list-tile-title',{staticStyle:{"font-size":"18px"}},[_vm._v(" Demonstrativo de Resultados ")])],1)],1),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"outline":"","small":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("event")]),(_vm.selectedPeriodType === 'custom')?_c('span',{staticClass:"hidden-xs-only"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.dateStart,'DD/MM/YY'))+" - "+_vm._s(_vm._f("moment")(_vm.dateEnd,'DD/MM/YY'))+" ")]):(_vm.selectedPeriodType === 'month')?_c('span',{staticClass:"hidden-xs-only"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.selectedMonth,'MMMM/YYYY'))+" ")]):_c('span',{staticClass:"hidden-xs-only"},[_vm._v(" "+_vm._s(_vm.selectedYear)+" ")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v("Filtrar Período")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.periodTypes,"item-text":"text","item-value":"value","label":"Tipo de Período","outline":"","dense":""},model:{value:(_vm.selectedPeriodType),callback:function ($$v) {_vm.selectedPeriodType=$$v},expression:"selectedPeriodType"}}),(_vm.selectedPeriodType === 'month')?_c('v-menu',{ref:"monthMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Selecione o Mês","persistent-hint":"","readonly":"","outline":"","dense":""},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}},on))]}}],null,true),model:{value:(_vm.monthMenu),callback:function ($$v) {_vm.monthMenu=$$v},expression:"monthMenu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":""},on:{"input":function($event){_vm.monthMenu = false}},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}})],1):_vm._e(),(_vm.selectedPeriodType === 'year')?_c('v-menu',{ref:"yearMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","full-width":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Selecione o Ano","persistent-hint":"","readonly":"","outline":"","dense":""},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}},on))]}}],null,true),model:{value:(_vm.yearMenu),callback:function ($$v) {_vm.yearMenu=$$v},expression:"yearMenu"}},[_c('v-date-picker',{attrs:{"type":"year","no-title":""},on:{"input":function($event){_vm.yearMenu = false}},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1):_vm._e(),(_vm.selectedPeriodType === 'custom')?[_c('v-text-field',{attrs:{"dense":"","outline":"","type":"date","label":"Data Inicial"},model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}}),_c('v-text-field',{attrs:{"dense":"","outline":"","type":"date","label":"Data Final"},model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}})]:_vm._e()],2),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","outline":"","color":"green"},on:{"click":function($event){return _vm.changeFilters()}}},[_c('v-icon',[_vm._v("filter_list")]),_c('span',[_vm._v("Filtrar")])],1)],1)],1)],1),_c('v-btn',{attrs:{"flat":"","icon":"","color":"green"},on:{"click":function($event){return refresh()}}},[_c('v-icon',[_vm._v("refresh")])],1)],1)],1),_c('v-flex',{staticStyle:{"padding":"5px"},attrs:{"sm12":""}},[_c('strong',[_vm._v(" Exibindo dados do período de "+_vm._s(_vm._f("moment")(content.current_period.start,'DD/MM/YY'))+" a "+_vm._s(_vm._f("moment")(content.current_period.end,'DD/MM/YY'))+", comparando com o período anterior de "+_vm._s(_vm._f("moment")(content.previous_period.start,'DD/MM/YY'))+" a "+_vm._s(_vm._f("moment")(content.previous_period.end,'DD/MM/YY'))+". ")])])],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[(
            content.comparison && content.comparison.revenues
          )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md4":""}},[_c('DRECard',{attrs:{"title":"Receitas","icon":"trending_up","color":"green","current_value":content.comparison.revenues.current_value || 0,"previous_value":content.comparison.revenues.previous_value || 0,"absolute_variation":content.comparison.revenues.absolute_variation || 0,"percentage_variation":content.comparison.revenues.percentage_variation || 0}})],1):_vm._e(),(
            content.comparison && content.comparison.expenses
          )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md4":""}},[_c('DRECard',{attrs:{"title":"Despesas","icon":"trending_down","color":"red","current_value":content.comparison.expenses.current_value || 0,"previous_value":content.comparison.expenses.previous_value || 0,"absolute_variation":content.comparison.expenses.absolute_variation || 0,"percentage_variation":content.comparison.expenses.percentage_variation || 0}})],1):_vm._e(),(
            content.comparison && content.comparison.result
          )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md4":""}},[_c('DRECard',{attrs:{"title":"Resultado","icon":"assessment","color":"blue","current_value":content.comparison.result.current_value || 0,"previous_value":content.comparison.result.previous_value || 0,"absolute_variation":content.comparison.result.absolute_variation || 0,"percentage_variation":content.comparison.result.percentage_variation || 0}})],1):_vm._e()],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[(
            content.current_period && content.current_period.revenues &&
            content.previous_period && content.previous_period.revenues &&
            content.current_period.revenues.categories && content.previous_period.revenues.categories
          )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('DRETable',{attrs:{"title":"Receitas","categories":content.current_period.revenues.categories || [],"previousCategories":content.previous_period.revenues.categories || [],"previousTotal":content.previous_period.revenues.total || 0,"currentTotal":content.current_period.revenues.total || 0,"currentPeriodStart":content.current_period.start,"currentPeriodEnd":content.current_period.end,"previousPeriodStart":content.previous_period.start,"previousPeriodEnd":content.previous_period.end}})],1):_vm._e(),(
            content.current_period && content.current_period.expenses &&
            content.previous_period && content.previous_period.expenses &&
            content.current_period.expenses.categories && content.previous_period.expenses.categories
          )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('DRETable',{attrs:{"title":"Despesas","categories":content.current_period.expenses.categories || [],"previousCategories":content.previous_period.expenses.categories || [],"previousTotal":content.previous_period.expenses.total || 0,"currentTotal":content.current_period.expenses.total || 0,"currentPeriodStart":content.current_period.start,"currentPeriodEnd":content.current_period.end,"previousPeriodStart":content.previous_period.start,"previousPeriodEnd":content.previous_period.end}})],1):_vm._e()],1)],1)]}}],null,false,3508838821)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }