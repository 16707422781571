import {Lang} from '@/default/service/Lang'
import { Auth } from '@/default/service/Auth'

export class Model {
  constructor () {
    this.list = true
    this.id = {
      id: null,
      create: null,
      update: null,
      delete: null
    }
    this.title = {
      list: null,
      edit: {
        create: null,
        update: null
      },
      view: null
    }
    this.icon = {
      list: null,
      edit: {
        create: null,
        update: null
      },
      view: null
    }
    this.actions = {
      list: true,
      create: true,
      update: true,
      delete: true
    }
    this.apiUrl = {
      list: null,
      edit: {
        create: null,
        update: null,
        delete: null
      },
      view: null
    }
    this.apiParams = {}
    this.params = {
      limit: 10
    }
    this.search = {
      placeholder: 'Digite sua pesquisa aqui...',
      fields: ['id']
    }
    this.urlDetails = {
      name: null,
      params: {},
      query: {}
    }
    this.fieldsSelected = false
    this.fields = []
    this.components = {
      list: {
        header: null,
        tableRow: null
      }
    }
  }
  fieldList ({ value, text, sortable, align }) {
    return {
      value: value || null,
      text: text || null,
      list: true,
      align: align || 'left',
      sortable: typeof sortable === 'boolean' ? sortable : true
    }
  }
  fieldText ({ value, text, placeholder, required, action, size, showField, mask, rules, disabled }) {
    return {
      value: value || null,
      text: text || null,
      type: 'text',
      required: required || false,
      disabled: disabled || false,
      placeholder: placeholder || 'Insira aqui...',
      edit: action || {
        create: true,
        update: true
      },
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      showField: showField,
      mask: mask || null,
      rules: rules || null
    }
  }
  fieldMacAddress ({ value, text, placeholder, required, action, size, showField, mask, rules }) {
    return {
      value: value || null,
      text: text || null,
      type: 'mac-address',
      required: required || false,
      placeholder: placeholder || '##:##:##:##:##:##',
      edit: action || {
        create: true,
        update: true
      },
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      showField: showField,
      mask: mask || null,
      rules: rules || null
    }
  }
  fieldPassword ({ value, text, placeholder, required, action, size, showField, mask }) {
    return {
      value: value || null,
      text: text || null,
      type: 'password',
      required: required || false,
      placeholder: placeholder || 'Insira aqui...',
      edit: action || {
        create: true,
        update: true
      },
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      showField: showField,
      mask: mask || null
    }
  }
  fieldSubHeader ({text, showField, size = null, style = {}}) {
    return {
      text: text || 'Informe os dados solicitados nos campos abaixo.',
      type: 'subheader',
      edit: true,
      showField: showField,
      size: size,
      style: style,
    }
  }
  fieldSelect ({
    value,
    text,
    required,
    action,
    size,
    placeholder,
    disabled,
    options,
    showField,
    multiple,
    autocomplete,
    defaultOption
  }) {
    return {
      value: value,
      text: text,
      type: 'select',
      disabled: disabled || false,
      placeholder: placeholder || 'Selecione um item..',
      edit: action || {
        create: true,
        update: true
      },
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      options: options || [{ value: 'undefined', text: 'Não definido' }],
      showField: showField,
      multiple: multiple || false,
      autocomplete: autocomplete || false,
      defaultOption: defaultOption || null
    }
  }
  fieldSelectApi ({ value, text, placeholder, required, action, size, disabled, showField, api: {url, params}, show, selectValue, icon, color, change, multiple, clearable }) {
    return {
      value: value,
      text: text,
      autocomplete: true,
      required: required || false,
      placeholder: placeholder || 'Selecione um item..',
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'db-autocomplete',
      disabled: disabled || false,
      multiple: multiple || false,
      edit: action || {
        create: true,
        update: true
      },
      apiUrl: url,
      apiParams: params || {},
      show: show || 'id',
      selectValue: selectValue || 'id',
      showField: showField,
      icon: icon || null,
      color: color || null,
      change: change || null,
      clearable: clearable || false
    }
  }
  fieldWiListDialog ({ value, text, wiConfig, required, action, size, disabled, show, icon, showField, filter, callbackFields, wiEditData, onChange }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'wi-list-dialog',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      wiConfig: wiConfig,
      show: show || 'id',
      icon: icon || 'edit',
      showField: showField,
      filter: filter,
      wiEditData: wiEditData,
      onChange: onChange,
      callbackFields: callbackFields
    }
  }
  fieldWiFileDialog ({
    value,
    text,
    data,
    filter,
    placeholder,
    required,
    action,
    size,
    disabled,
    show,
    showField,
    options = null
  }) {
    return {
      value: value,
      text: text,
      placeholder: placeholder || 'Selecione um arquivo..',
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'wi-file-dialog',
      disabled: disabled || false,
      data: data || {},
      filter: filter || {},
      edit: action || {
        create: true,
        update: true
      },
      show: show || 'id',
      showField: showField,
      options: options
    }
  }
  fieldDate ({ value, text, required, action, size, disabled, showField }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'date',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldDateTime ({ value, text, required, action, size, disabled, showField }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'datetime',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldTime ({ value, text, required, action, size, disabled, showField }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'time',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldNumber ({ value, text, placeholder, required, action, size, disabled, showField, min, max, prefix, suffix, step, rules }) {
    return {
      value: value,
      text: text,
      placeholder: placeholder || text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'int',
      min: min || null,
      max: max || null,
      step: step || null,
      prefix: prefix,
      suffix: suffix,
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField,
      rules: rules
    }
  }
  fieldMoney ({ value, text, required, action, size, disabled, prefix, showField }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'money',
      prefix: prefix || 'R$',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldMargin ({ value, text, required, action, size, disabled, prefix, showField }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'margin',
      prefix: prefix || 'R$',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldSwitch ({ value, text, required, action, size, disabled, showField, defaultValue }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'switch',
      disabled: disabled || false,
      defaultValue: defaultValue || null,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldCheckbox ({ value, text, required, action, size, disabled, showField, trueText, falseText }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'checkbox',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField,
      trueText: trueText || 'Sim',
      falseText: falseText || 'Não',
    }
  }
  fieldEditor ({ value, text, placeholder, required, action, showField }) {
    return {
      value: value || null,
      text: text || null,
      required: required || false,
      placeholder: placeholder || 'Insira aqui...',
      type: 'editor',
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldTinyEditor ({ value, text, placeholder, required, action, showField, variableScope }) {
    return {
      value: value || null,
      text: text || null,
      required: required || false,
      placeholder: placeholder || 'Insira aqui...',
      type: 'tiny-editor',
      edit: action || {
        create: true,
        update: true
      },
      showField: showField,
      variableScope: variableScope
    }
  }
  fieldTextArea ({ value, text, placeholder, required, disabled, action, size, showField, rows }) {
    return {
      value: value || null,
      text: text || null,
      type: 'textarea',
      required: required || false,
      placeholder: placeholder || 'Insira aqui...',
      edit: action || {
        create: true,
        update: true
      },
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      showField: showField,
      disabled: disabled || false,
      rows: rows || 3
    }
  }
  fieldColor ({ value, text, placeholder, required, action, disabled, size, showField }) {
    return {
      value: value || null,
      text: text || null,
      type: 'color',
      required: required || false,
      placeholder: placeholder || 'Insira aqui...',
      edit: action || {
        create: true,
        update: true
      },
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      showField: showField,
      disabled: disabled || false
    }
  }
  fieldMessage ({ value, text, placeholder, required, action, disabled, size, showField, color }) {
    return {
      value: value || null,
      text: text || null,
      color: color || null,
      type: 'message',
      required: required || false,
      placeholder: placeholder || 'Insira aqui...',
      edit: action || {
        create: true,
        update: true
      },
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      showField: showField,
      disabled: disabled || false
    }
  }
  fieldFileDownload ({ fileUrl, text, size, action, showField, background, color }) {
    return {
      fileUrl: fileUrl || null,
      text: text || null,
      color: color || null,
      type: 'file-download',
      edit: action || {
        create: true,
        update: true
      },
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      showField: showField,
      background: background || 'primary'
    }
  }
  fieldWiGoogleIcon ({ value, text, placeholder, required, action, disabled, size, showField }) {
    return {
      value: value || null,
      text: text || null,
      type: 'wi-google-icons',
      required: required || false,
      placeholder: placeholder || 'Insira aqui...',
      edit: action || {
        create: true,
        update: true
      },
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      showField: showField,
      disabled: disabled || false
    }
  }
  fieldShowResult ({ value, text, placeholder, size, showField, action, calculate }) {
    return {
      value,
      text,
      type: 'show-result',
      placeholder,
      size,
      edit: action || {
        create: true,
        update: true
      },
      showField,
      calculate
    }
  }
  fieldAmountMargin ({ value, text, placeholder, size, showField, action, config }) {
    return {
      value,
      text,
      type: 'amount-margin',
      placeholder,
      size,
      edit: action || {
        create: true,
        update: true
      },
      showField,
      config
    }
  }
  setFields (fields) {
    if (Array.isArray(fields)) {
      this.setFieldsArray(fields)
    } else {
      this.setFieldsObject(fields)
    }
  }
  setFieldsArray (arrayFields) {
    arrayFields.filter((field) => {
      this.fields.push(field)
    })
  }
  setFieldsObject (objectFields) {
    this.setFieldsArray(Object.values(objectFields))
  }
  getLangText (textId, text) {
    return Lang.langText(textId, text)
  }
  permissionIsAvaiable (permissionId) {
    return Auth.checkPermission(permissionId)
  }
  getAuth () {
    return Auth
  }
  clearFields () {
    this.fields = []
  }

  rules = {
    required: () => (value => !!value || 'Campo obrigatório!'),
    maxLength: (maxLength) => (value => (!!value && value.length <= maxLength) || `Máximo de ${maxLength} caracteres.`),
    minLength: (minLength) => (value => (!value || value.length >= minLength) || `Mínimo de ${minLength} caracteres.`)
  }
}
