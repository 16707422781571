<template>
  <div style="z-index: 0;">
    <div v-if="contact.type == 2 && contact.observation && contact.observation != ''" style="margin-top: 15px;">
      <v-divider></v-divider>
      <v-expansion-panel v-model="items" flat>
        <v-expansion-panel-content flat lazy>
          <template v-slot:header>
            <div @click="showData = !showData" style="display: flex; align-items: center; gap: 10px;">
              <v-icon>description</v-icon>
              <strong> Descrição do grupo</strong>
            </div>
          </template>
          <v-divider></v-divider>
          <div class="mx-3 my-3">
            <span v-html="contact.observation_html"></span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-divider></v-divider>
    </div>
    <v-divider v-else-if="contact.type == 2"></v-divider>
  </div>
</template>
<script>
  import talkViewMixin from '../../talkViewMixin'
  export default {
    name: 'TalkContactMembers',
    data () {
      return {
        items: [],
        showData: false,
        memberSearch: ''
      }
    },
    computed: {
      members () {
        const members = this.contact?.current_members || []
        if (this.memberSearch) {
          return members.filter(member => {
            return (member?.name && typeof member.name == 'string') &&
              member.name.toLowerCase().includes(
                this.memberSearch.toLowerCase()
              )
          })
        } else {
          return members || []
        }
      }
    },
    methods: {
      startTalkByMember: function (contact) {
        this.$swal({
          title: `Iniciar conversa com ${contact.name}?`,
          text: 'Ao clicar em "Sim, iniciar!", você será redirecionado para a conversa com este contato.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, iniciar!',
          cancelButtonText: 'Cancelar'
        }).then((response) => {
          if (response.value) {
            this.openTalkContact(contact)
          }
        })
      }
    },
    props: ['contact'],
    mixins: [talkViewMixin]
  }
</script>