<template>
	<v-navigation-drawer
    app color="blue-grey darken-4"
    floating temporary right
    v-model="drawerChats.active"
    :touchless="touch"
    width="330"
  >
    <ChatList :drawer="true"></ChatList>
    <v-footer dense absolute height="49" :color="primaryColor" class="pa-3 elevation-24">
      <v-spacer></v-spacer>
      <v-btn outline color="white" small link :to="{name: 'chat-view'}">
        Todas as conversas
      </v-btn>
      <v-spacer></v-spacer>
    </v-footer>
  </v-navigation-drawer>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import ChatList from '@/default/pages/ChatView/ChatList'
  export default {
    name: 'DrawerChats',
    computed: {
      ...mapState('Pages/Main', ['drawerChats']),
      touch () {
        return this.$route.meta.touch || false
      },
      system () {
        return this.$store.state.system || []
      },
      primaryColor () {
        return !this.$wiDark ? 'primary' : 'dark'
      }
    },
    methods: {
      ...mapMutations('Pages/Main', ['TOUGGLE_DRAWER_CHATS_ACTIVE'])
    },
    components: {
      ChatList
    }
  }
</script>