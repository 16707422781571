// Campos do Banco Pan em ordem
// Linha 1 - Header - Consulta Não me perturbe / card de ofertas
// Linha 2 - Promotora, Empregador, Orgão
// Linha 3 - CPF, DT. Nascimento, DDD Telefone, Matrícula
// Linha 4 - Header - Simulação
// Linha 5 - Tipo de Operação
// Linha 6 - Margem Disponível, Renda Líquida, Valor Parcela, Valor Solicitado, Prazo
// Linha 7 - Header - Dados do cliente
// Linha 8 - Nome, Nacionalidade, Naturalidade, Estado Civil, Email
// Linha 9 - Tipo Doc, Nº Documento, Dv, Emissor, Dt.Emissão, Analfabeto
// Linha 10 - CEP, Endereço, número, complemento, Bairro, Cidade, UF
// Linha 11 - Mãe, Pai, PPE
// Linha 12 - Header - Liberação
// Linha 13 - Meio de Liberação, Tp. Conta
// Linha 14 - Banco, Agencia, Dv, Descrição Agência, Cidade Agência, UF, Conta, Dv
// Linha 15 - Header - Dados do Digitador
// Linha 16 - CPF do Vendedor, Nome do Vendedor, Digitador
// Campos do Banco Pan em ordem

export default [
  // Linha 1 - Header - Consulta Não me perturbe / card de ofertas
  [
    {
      key: 'header1',
      label: 'Consulta card de ofertas',
      icon: 'block',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    }
  ],

  // Linha 2 - Promotora, Empregador, Orgão
  [
    {
      key: 'company.name',
      label: 'Promotora',
      icon: 'business',
      size: ['xs12', 'sm4', 'md4', 'lg4']
    },
    {
      key: 'agreement.name',
      label: 'Empregador',
      icon: 'work',
      size: ['xs12', 'sm4', 'md4', 'lg4']
    },
    {
      key: 'agreement.name',
      label: 'Órgão',
      icon: 'account_balance',
      size: ['xs12', 'sm4', 'md4', 'lg4']
    }
  ],

  // Linha 3 - CPF, DT. Nascimento, DDD Telefone, Matrícula
  [
    {
      key: 'person.cpf_cnpj',
      label: 'CPF',
      icon: 'person',
      size: ['xs12', 'sm3', 'md3', 'lg3']
    },
    {
      key: 'person.birthday',
      label: 'Data Nascimento',
      icon: 'cake',
      size: ['xs12', 'sm3', 'md3', 'lg3'],
      type: 'date'
    },
    {
      key: 'talk_contact.number',
      label: 'DDD e Telefone',
      icon: 'phone',
      size: ['xs12', 'sm3', 'md3', 'lg3']
    },
    {
      key: 'integration_code',
      label: 'Matrícula',
      icon: 'badge',
      size: ['xs12', 'sm3', 'md3', 'lg3']
    }
  ],

  // Linha 4 e 5 - Header Simulação e Tipo de Operação
  [
    {
      key: 'header2',
      label: 'Simulação',
      icon: 'calculate',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'category.name',
      label: 'Tipo de Operação',
      icon: 'category',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }
  ],

  // Linha 6 - Margem Disponível, Renda Líquida, Valor Parcela, Valor Solicitado, Prazo
  [
    {
      key: 'total_liquid',
      label: 'Margem Disponível',
      icon: 'attach_money',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      prefix: 'R$',
      type: 'money'
    },
    {
      key: 'person.salary_liquid',
      label: 'Renda Líquida',
      icon: 'payments',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      prefix: 'R$',
      type: 'money'
    },
    {
      key: 'amount_installment',
      label: 'Valor Parcela',
      icon: 'payments',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      prefix: 'R$',
      type: 'money'
    },
    {
      key: 'amount_requested',
      label: 'Valor Solicitado',
      icon: 'attach_money',
      size: ['xs12', 'sm3', 'md3', 'lg3'],
      prefix: 'R$',
      type: 'money'
    },
    {
      key: 'quantity_installment',
      label: 'Prazo',
      icon: 'schedule',
      size: ['xs12', 'sm3', 'md3', 'lg3'],
      suffix: 'meses'
    }
  ],

  // Linha 7 e 8 - Header Dados do cliente e campos pessoais
  [
    {
      key: 'header3',
      label: 'Dados do cliente',
      icon: 'person',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'person.first_name',
      label: 'Nome',
      icon: 'person',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    },
    {
      key: 'person.nationality',
      label: 'Nacionalidade',
      icon: 'public',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'person.naturalness',
      label: 'Naturalidade',
      icon: 'location_city',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'person.marital_status',
      label: 'Estado Civil',
      icon: 'family_restroom',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'person.email',
      label: 'Email',
      icon: 'email',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }
  ],

  // Linha 9 - Documentos
  [
    {
      key: 'person.document_type',
      label: 'Tipo Doc',
      icon: 'description',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      default: 'RG'
    },
    {
      key: 'person.rg',
      label: 'Nº Documento',
      icon: 'badge',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'person.rg_digit',
      label: 'DV',
      icon: 'pin',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'person.rg_issuing_agency',
      label: 'Emissor',
      icon: 'domain',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'person.rg_issue_date',
      label: 'Data Emissão',
      icon: 'date_range',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      type: 'date'
    },
    {
      key: 'person.education',
      label: 'Analfabeto',
      icon: 'school',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      type: 'boolean'
    }
  ],

  // Linha 10 - Endereço
  [
    {
      key: 'address.zip_code',
      label: 'CEP',
      icon: 'location_on',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'address.address',
      label: 'Endereço',
      icon: 'home',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'address.number',
      label: 'Número',
      icon: 'pin',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'address.complement',
      label: 'Complemento',
      icon: 'apartment',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'address.district',
      label: 'Bairro',
      icon: 'location_city',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'address.city.name',
      label: 'Cidade',
      // icon: 'location_city',
      size: ['xs12', 'sm1', 'md1', 'lg1']
    },
    {
      key: 'address.city.state.code',
      label: 'UF',
      // icon: 'place',
      size: ['xs12', 'sm1', 'md1', 'lg1']
    }
  ],

  // Linha 11 - Filiação e PPE
  [
    {
      key: 'person.mothers_name',
      label: 'Mãe',
      icon: 'person',
      size: ['xs12', 'sm4', 'md4', 'lg4']
    },
    {
      key: 'person.fathers_name',
      label: 'Pai',
      icon: 'person',
      size: ['xs12', 'sm4', 'md4', 'lg4']
    },
    {
      key: 'person.ppe',
      label: 'PPE',
      icon: 'security',
      size: ['xs12', 'sm4', 'md4', 'lg4'],
      type: 'boolean'
    }
  ],

  // Linha 12 e 13 - Header Liberação e dados bancários
  [
    {
      key: 'header4',
      label: 'Liberação',
      icon: 'account_balance',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'release_method',
      label: 'Meio de Liberação',
      icon: 'payments',
      size: ['xs12', 'sm6', 'md6', 'lg6']
    },
    {
      key: 'finance_account.type.name',
      label: 'Tipo Conta',
      icon: 'account_balance',
      size: ['xs12', 'sm6', 'md6', 'lg6']
    }
  ],

  // Linha 14 - Dados bancários detalhados
  [
    {
      key: 'finance_account.bank.name',
      label: 'Banco',
      icon: 'account_balance',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'finance_account.agency',
      label: 'Agência',
      icon: 'store',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    // {
    //   key: 'finance_account.agency_digit',
    //   label: 'DV',
    //   // icon: 'pin',
    //   size: ['xs12', 'sm1', 'md1', 'lg1']
    // },
    {
      key: 'finance_account.agency_description',
      label: 'Descrição Agência',
      icon: 'info',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'finance_account.agency_city',
      label: 'Cidade Agência',
      icon: 'location_city',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'finance_account.agency_state',
      label: 'UF',
      // icon: 'place',
      size: ['xs12', 'sm1', 'md1', 'lg1']
    },
    {
      key: 'finance_account.account',
      label: 'Conta',
      icon: 'credit_card',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'finance_account.account_digit',
      label: 'DV',
      // icon: 'pin',
      size: ['xs12', 'sm1', 'md1', 'lg1']
    }
  ],

  // Linha 15 e 16 - Header Dados do Digitador e informações
  [
    {
      key: 'header5',
      label: 'Dados do Digitador',
      icon: 'person',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'collaborator.cpf',
      label: 'CPF do Vendedor',
      icon: 'badge',
      size: ['xs12', 'sm4', 'md4', 'lg4']
    },
    {
      key: 'collaborator.name',
      label: 'Nome do Vendedor',
      icon: 'person',
      size: ['xs12', 'sm4', 'md4', 'lg4']
    },
    {
      key: 'collaborator.name',
      label: 'Digitador',
      icon: 'keyboard',
      size: ['xs12', 'sm4', 'md4', 'lg4']
    }
  ],

  // Documentos
  [
    {
      key: 'header6',
      label: 'Arquivos e documentos anexados',
      icon: 'description',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'documents',
      label: 'Documentos',
      icon: 'description',
      type: 'files',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }
  ]
]
