<template>
  <v-dialog
    v-model="dialog" width="350"
    scrollable persistent lazy
    class="chat-profile__dialog"
  >
    <v-card class="chat-profile__card">
      <v-toolbar
        dense flat color="transparent"
        class="chat-profile__toolbar ml-0 pl-0"
      >
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="chat-profile__card-text">
        <div class="chat-profile__content">
          <div class="chat-profile__avatar">
            <v-avatar size="200" tile>
              <img
                v-if="chat.image && chat.image"
                :src="chat.image.url"
                class="chat-profile__avatar-image"
              >
              <img
                v-else-if="chat.type_id == 1"
                src="static/icons/user_icon_1.png"
                class="chat-profile__avatar-image"
              >
              <img
                v-else-if="[2, 3].includes(chat.type_id)"
                src="static/icons/group_icon_1.jpg"
                class="chat-profile__avatar-image"
              >
            </v-avatar>
          </div>
          <div class="chat-profile__name">
            <span>{{ chat.name }}</span>
          </div>
          <div v-if="chat.description" class="chat-profile__description">
            <span>{{ chat.description }}</span>
          </div>
          <div v-if="[2, 3].includes(chat.type_id)" class="chat-profile__members">
            <div>
              {{ `${chat.persons.length} membro${chat.persons.length > 1 ? 's' : ''}` }}
            </div>
            <div class="chat-profile__members-actions">
              <v-btn outline small color="primary" @click="listMembers()">
                <v-icon>groups</v-icon>
                <span class="ml-1">Ver membros</span>
              </v-btn>
              <v-btn outline small color="primary" @click="editChat()">
                <v-icon>edit</v-icon>
                <span class="ml-1">Editar grupo</span>
              </v-btn>
              <v-btn outline small color="primary" @click="addMember()">
                <v-icon>person_add</v-icon>
                <span class="ml-1">Adicionar membro</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'ChatProfileDialog',
    computed: {
      dialog: {
        get () {
          return this.active
        },
        set (value) {
          this.$emit('onActive', value)
        }
      },
      members () {
        const members = this.contact?.members || []
        if (this.memberSearch) {
          return members.filter(member => {
            return (member?.name && typeof member.name == 'string') &&
              member.name.toLowerCase().includes(
                this.memberSearch.toLowerCase()
              )
          })
        } else {
          return members || []
        }
      },
      personTitle () {
        if (this.contact?.person?.person_title) {
          return this.contact.person.person_title
        }
        return null
      }
    },
    methods: {
      editContact: function () {
        this.$WiEditDialog({
          wiConfig: 'talk-contact',
          data: {
            id: this.contact.id,
            talk_channel_id: this.contact.talk_channel_id,
            name: this.contact.name,
            type: this.contact.type,
            person_type: this.contact.person_type,
            person: this.contact.person,
            observation: this.contact.observation
          },
          onSubmit: () => {
            this.refresh(this.$route.params.id)
          },
          redirectOnSubmit: false
        })
      },
      startTalkByMember: function (contact) {
        this.openTalkContact(contact)
      },
      refreshContact: function () {
        this.$WiApiPost({
          uri: 'talks/contact-refresh',
          data: {
            talk_contact_id: this.contact.id
          },
          config: {
            msgLoad: true,
            msgSucess: true,
            msgError: true,
            msgAsk: {
              title: `Atualizar contato?`
            }
          },
          callback: () => {
            this.refresh(this.$route.params.id)
          }
        })
      },
      listMembers: function () {
        this.$WiListDialog({
          wiConfig: 'chat-members',
          filter: {
            chat_id: this.chat.id
          }
        })
      },
      addMember: function () {
        this.$WiListDialog({
          wiConfig: 'collaborator',
          mode: 'select',
          callback: (collaborator) => {
            this.$WiApiPost({
              uri: 'chats/chat-person',
              data: { person_id: collaborator.id, chat_id: this.chat.id },
              callback: () => {

              },
              config: {
                msgLoad: {
                  title: 'Adicionando membro...',
                  text: 'Aguarde enquanto o membro é adicionado.'
                },
                msgSucess: {
                  title: 'Membro adicionado com sucesso',
                  text: 'O membro foi adicionado com sucesso.'
                },
                msgError: {
                  title: 'Erro ao adicionar membro',
                  text: 'Tente novamente ou contacte o suporte.'
                }
              }
            })
          },
          params: { order: 'created_at,desc'},
          filter: { order: 'name,asc', where: 'collaborator,1' }
        })
      },
      editChat: function () {
        this.$WiEditDialog({
          wiConfig: 'chat-group',
          data: this.chat
        })
      }
    },
    props: ['active', 'chat', 'refresh']
  }
</script>

<style scoped>
  .chat-profile__dialog {
    border-radius: 10px;
  }
  .chat-profile__card {
    border-radius: 10px;
  }
  .chat-profile__toolbar {
    border-radius: 10px;
  }
  .chat-profile__card-text {
    padding: 0px !important;
    border-radius: 10px;
  }
  .chat-profile__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  .chat-profile__avatar {
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #e0e0e0;
  }
  .chat-profile__avatar-image {
    border-radius: 10px;
  }
  .chat-profile__name {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px;
  }
  .chat-profile__description {
    margin: 0 20px 10px 20px;
    font-size: 14px;
    color: #808080;
    text-align: center;
    border-radius: 10px;
  }
  .chat-profile__members {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .chat-profile__members-actions {
    text-align: center;
  }
</style>