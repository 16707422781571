import { BusinessCategory } from '@/modules/business/config/BusinessCategory'

class SalesCategory extends BusinessCategory {
  constructor() {
    super()
    this.id = 'a02e979e'
  }
}

export class SaleCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Venda'
    this.apiUrl = 'sales/config/category/sale'
  }
}

export class BudgetCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Orçamento'
    this.apiUrl = 'sales/config/category/budget'

    if (this.permissionIsAvaiable('185a4bb9')) {
      this.clearFields()
      this.setFields(this.fieldsList)
      this.setFields(this.fieldsEdit)
    }
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, cor e ícone desta categoria.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da Categoria',
      size: ['xs12', 'sm6', 'md3', 'lg3']
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor',
      size: ['xs12', 'sm6', 'md1', 'lg1']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Categoria',
      size: ['xs12', 'sm6', 'md2', 'lg2']
    }),
    form: this.fieldWiListDialog({
      value: 'form_id',
      text: 'Formulário Complementar',
      required: false,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      wiConfig: 'service-desk-config-form',
      show: 'form.name',
      icon: 'list_alt'
    }),
    bank_product_id: this.fieldSelect({
      value: 'bank_product_id',
      text: 'Banco',
      size: ['xs12', 'sm6', 'md3', 'lg3'],
      options: [
        { text: 'Banco Master', value: 1 },
        { text: 'Banco PAN', value: 2 },
        { text: 'Banco BMG', value: 3 }
      ]
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Crie um layout de descrição para esta categoria.'
    }),
    layout: this.fieldTinyEditor({
      value: 'layout',
      text: 'Layout desta categoria',
      placeholder: 'Crie a estrutura da categoria.'
    })
  }
}

export class ContractCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Contrato'
    this.apiUrl = 'sales/config/category/contract'
  }
}

export class GuaranteeCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Termo de Garantia'
    this.apiUrl = 'sales/config/category/guarantee'
  }
}
