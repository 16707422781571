<template>
  <v-dialog 
    v-model="chatDialogActive" 
    scrollable 
    :max-width="topicUuid ? '58%' : '70%'" 
    persistent 
    lazy 
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card :dark="$wiDark" style="min-height: 350px; border-radius: 5px;">
      <ChatMessages
        v-if="chatDialogActive && chatUuid"
        :setChatUuid="chatUuid"
        :setTopicUuid="topicUuid"
        mode="dialog"
        @onClose="closeChatDialog"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import ChatMessages from './ChatMessages.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ChatMessagesDialog',
  components: {
    ChatMessages
  },
  computed: {
    ...mapState('Chat', ['chatDialogActive', 'chatUuid', 'topicUuid'])
  },
  methods: {
    ...mapActions('Chat', ['closeChatDialog'])
  }
}
</script> 