<template>
  <v-img 
    v-if="contact && contact.file"
    :src="contact.file.url"
    lazy-src="static/icons/not-found.png"
  />
  <v-img 
    v-else-if="contact.type == 1" 
    src="static/icons/user_icon_1.png"
  />
  <v-img 
    v-else 
    src="static/icons/monitor/user-groups-96.png"
  />
</template>

<script>
export default {
  name: 'ContactAvatar',
  
  props: {
    contact: {
      type: Object,
      required: true
    }
  }
}
</script> 