import { Business } from '@/modules/shared/config/Business/Business'
import { SaleFilters } from './SaleFilters'
import {
  PERMISSION_SALES_EXPORT_SALES,
  PERMISSION_SALES_EXPORT_SALES_TO_XLSX
} from '@/default/constants/permissions'

class Sale extends Business {
  constructor (listTitle, stage, fieldDate) {
    super()
    this.list = true
    this.id = {
      id: 'fbded5ac',
      create: '22982ab2',
      update: 'b41dcfec',
      delete: '80f80378'
    }
    this.name = 'sale'
    this.title = {
      list: listTitle,
      edit: {
        insert: 'Nova Venda',
        update: 'Editar Venda'
      }
    }
    this.apiUrl = 'sales/sale'
    if (stage) {
      this.params['stage'] = stage
    }
    this.urlDetails = { name: 'sale', params: {} }
    this.icon = 'shopping_cart'
    // Fields
    this.fields[0].text = 'Informe os dados básicos desta Venda.'
    this.fields[2].text = 'Cliente'
    this.fields[2].wiConfig = 'customer'
    this.fields[4].apiUrl = 'sales/config/status/sale'
    this.fields[5].apiUrl = 'sales/config/category/sale'
    this.fields[5].text = 'Categoria/Tipo de Venda'
    this.fields[7].text = 'Emissão'
    this.fields[8].text = 'Entrega'
    this.fields[9].text = 'Valor Adicional'
    this.fields[14].text = 'Descreva abaixo os detalhes desta venda.'
    this.fields[16].text = 'Descreva abaixo as formas/condições de pagamento desta venda.'
    this.fields[19].text = 'O campo abaixo pode ser usado para registrar observações sobre esta venda.'

    this.fields[24].value = fieldDate.value
    this.fields[24].text = fieldDate.text
    this.fields[25] = {
      value: 'date_end',
      text: 'Entrega',
      list: true
    }

    

    this.filters = new SaleFilters()

    if (
      this.permissionIsAvaiable('0bae9ce3-400f-8818-94a0-15d1b3bdf088') && 
      this.permissionIsAvaiable('4d8hd50b')
    ) {
      this.fields.splice(1, 0,
        this.fieldWiListDialog({
          value: 'business_id',
          text: 'Projeto',
          required: true,
          size: ['xs12', 'sm12', 'md12', 'lg12'],
          wiConfig: 'graduation-open',
          show: 'business.title',
          icon: 'select_check_box',
          disabled: (form) => { return form.id != null }
        })
      )
    }

    const isTemplateIndex = this.fields.findIndex(field => field.value === 'is_template')
    this.fields[isTemplateIndex].text = 'Ex: Seu código'
    // remove the field isTemplateIndex
    this.fields.splice(isTemplateIndex, 1)

    this.exports = {
      permission: PERMISSION_SALES_EXPORT_SALES,
      title: 'Exportar Vendas',
      origins: {
        excel: {
          title: 'Exportar para o Excel',
          permission: PERMISSION_SALES_EXPORT_SALES_TO_XLSX,
          description: 'Exportar vendas para o Excel.',
          image: '/static/icons/excel_icon.png',
          wiConfig: 'sale-export-filters'
        }
      }
    }
  }
}

export default {
  sale: new Sale('Vendas Em Aberto', null, { value: 'opened_at', text: 'Criação' }),
  open: new Sale('Vendas Em Aberto', 'open', { value: 'opened_at', text: 'Criação' }),
  close: new Sale('Histórico de Vendas', 'close', { value: 'closed_at', text: 'Realização' }),
  cancel: new Sale('Vendas Canceladas', 'cancel', { value: 'canceled_at', text: 'Cancelamento' })
}
