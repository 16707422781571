var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"chamado-card",on:{"click":_vm.navigateToChamado}},[_c('div',{staticClass:"card-header"},[_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"codigo"},on),[_vm._v("#"+_vm._s(_vm.chamado.id))])]}}])},[_c('span',[_vm._v("Código do chamado: #"+_vm._s(_vm.chamado.id))])]),(_vm.chamado.sla)?[_c('div',{staticClass:"sla-wrapper"},[_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"sla-badge",style:(_vm.slaStyle)},on),[_vm._v(" SLA: "+_vm._s(_vm.chamado.sla)+"% ")])]}}],null,false,2193039568)},[_c('span',[_vm._v("Nível de Serviço (SLA): "+_vm._s(_vm.chamado.sla)+"% do tempo consumido")])])],1)]:_vm._e()],2),(_vm.chamado.sla)?_c('v-progress-linear',{staticClass:"sla-progress",attrs:{"height":"2","color":_vm.slaColor},model:{value:(_vm.chamado.sla),callback:function ($$v) {_vm.$set(_vm.chamado, "sla", $$v)},expression:"chamado.sla"}}):_vm._e(),(_vm.chamado.deadline)?[_c('div',{staticClass:"deadline-row"},[_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"deadline-info"},on),[_c('div',{staticClass:"deadline-date"},[_c('v-icon',{attrs:{"small":"","color":_vm.deadlineColor}},[_vm._v("schedule")]),_c('span',{style:({ color: _vm.deadlineColor })},[_vm._v(" "+_vm._s(_vm.chamado.deadline_formated)+" ")])],1),_c('span',{staticClass:"deadline-status",style:(_vm.deadlineStyle)},[_vm._v(" "+_vm._s(_vm.deadlineStatusText)+" ")])])]}}],null,false,1340088614)},[_c('span',[_vm._v(" "+_vm._s(_vm.deadlineTooltipText)+" ")])])],1)]:_vm._e(),_c('div',{staticClass:"card-body"},[(_vm.chamado.assunto_chamado)?[_c('div',{staticClass:"info-row assunto"},[_c('v-icon',{attrs:{"small":"","color":"#1C8ADB"}},[_vm._v("chevron_right")]),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.chamado.assunto_chamado))])],1)]:_vm._e(),_c('div',{staticClass:"info-row"},[_c('v-icon',{attrs:{"small":"","color":"#1C8ADB"}},[_vm._v("person")]),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.chamado.nome_pessoa))])],1),_c('div',{staticClass:"info-row categoria"},[_c('v-icon',{attrs:{"small":"","color":_vm.chamado.cor_categoria}},[_vm._v(_vm._s(_vm.chamado.icone_categoria))]),_c('div',{staticClass:"categoria-container"},[_c('div',{staticClass:"categoria-tree"},_vm._l((_vm.todasCategorias),function(cat,index){return _c('div',{key:index,staticClass:"categoria-item",style:({ paddingLeft: index === 0 ? '0' : '12px' })},[(index > 0)?_c('v-icon',{staticClass:"categoria-arrow",attrs:{"x-small":""}},[_vm._v(" subdirectory_arrow_right ")]):_vm._e(),_c('span',{staticClass:"categoria-nome"},[_vm._v(_vm._s(cat))])],1)}),0)])],1),(_vm.chamado.grupos)?[_c('div',{staticClass:"info-row grupos"},[_c('v-icon',{attrs:{"small":"","color":"grey"}},[_vm._v("mdi-network")]),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.gruposResumidos))]),_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"info-icon",attrs:{"small":"","color":"grey lighten-1"},on:{"click":function($event){$event.stopPropagation();_vm.showGruposDialog = true}}},on),[_vm._v(" info ")])]}}],null,false,3821602236)},[_c('span',[_vm._v("Clique para ver todas as redes envolvidas")])]),_c('v-dialog',{attrs:{"max-width":"400","lazy":""},on:{"click:outside":function($event){_vm.showGruposDialog = false}},model:{value:(_vm.showGruposDialog),callback:function ($$v) {_vm.showGruposDialog=$$v},expression:"showGruposDialog"}},[(_vm.showGruposDialog)?_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Redes Envolvidas "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.showGruposDialog = false}}},[_vm._v("close")])],1),_c('v-card-text',[_c('div',{staticClass:"grupos-list"},_vm._l((_vm.gruposList),function(grupo,index){return _c('div',{key:index,staticClass:"grupo-item"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v("mdi-network")]),_vm._v(" "+_vm._s(grupo)+" ")],1)}),0)])],1):_vm._e()],1)],1)]:_vm._e()],2),(_vm.chamado.num_anexos)?[_c('div',{staticClass:"attachment-info"},[_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"display":"flex","align-items":"center","gap":"4px"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("attachment")]),_c('span',[_vm._v(_vm._s(_vm.chamado.num_anexos))])],1)]}}],null,false,3897122176)},[_c('span',[_vm._v(_vm._s(_vm.chamado.num_anexos)+" arquivo(s) anexado(s)")])])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }