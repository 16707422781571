<template>
  <div
    class="chat__toolbar-area"
    :style="{
      'background': $wiDark ? '#1C252E' : '#F9FAFB'
    }"
  >
    <v-toolbar dense flat :color="primaryColor" class="white--text elevation-10 chat__toolbar">
      <v-toolbar-title class="chat__toolbar-title" @click="$emit('onProfileClick')">
        <v-avatar size="40">
          <img v-if="chat.image && chat.image" :src="chat.image.url">
          <img v-else-if="chat.type_id == 1" src="static/icons/user_icon_1.png" />
          <img v-else-if="[2, 3].includes(chat.type_id)" src="static/icons/group_icon_1.jpg" />
        </v-avatar>
        <span v-if="chat.type_id == 3 && chat.topic" class="chat__toolbar-title-text">
          <span class="ml-2" style="font-size: 16px;">
            Tópico: {{ chat.topic.title | stringlength(20) }}
          </span>
          <span v-if="chat.name" class="ml-2" style="font-size: 12px; font-weight: 100;">
            Grupo: {{ chat.name | stringlength(20) }}
          </span>
        </span>
        <span v-else class="chat__toolbar-title-text">
          <span class="ml-2" style="font-size: 16px;">
            {{ chat.name | stringlength(20) }}
          </span>
          <span v-if="chat.type_id == 1" class="ml-2" style="font-size: 12px; font-weight: 100;">
            <!-- Online -->
          </span>
          <span v-else-if="chat.type_id == 2" class="ml-2" style="font-size: 12px; font-weight: 100;">
            {{ `${chat.persons.length} membro${chat.persons.length > 1 ? 's' : ''}` }}
          </span>
          <span v-else-if="chat.type_id == 3 && chat.topic" class="ml-2" style="font-size: 12px; font-weight: 100;">
            Tópico: {{ chat.topic.title }}
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="actions"></slot>
      <!-- <v-btn icon @click="$emit('onInfoClick', { chatUuid: chatUuid, topicUuid: topicUuid })" class="hidden-xs-only hidden-sm-only">
        <v-icon color="white">info</v-icon>
      </v-btn> -->
      <v-btn icon small @click="$emit('onRefreshClick', { chatUuid: chatUuid, topicUuid: topicUuid })">
        <v-icon color="white">refresh</v-icon>
      </v-btn>
      <v-btn icon small @click="$emit('onCloseClick')">
        <v-icon color="white">close</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'ChatToolbar',
  props: {
    chat: {
      type: Object,
      required: true
    },
    chatUuid: {
      type: String,
      required: true
    },
    topicUuid: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    primaryColor () {
      return !this.$wiDark ? 'primary' : '#212121'
    }
  }
}
</script>

<style scoped>
.chat__toolbar-area {
  padding: 10px 10px 0px 10px;
  z-index: 2;
  border-radius: 10px !important;
}
.chat__toolbar {
  border-radius: 10px !important;
  z-index: 2;
}
.chat__toolbar-title {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.chat__toolbar-title-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style> 