import { callApi } from '@/default/service/Api'
import { Auth } from '@/default/service/Auth'

export default {
  namespaced: true,
  state: {
    chat: {},
    chatUuid: null,
    topics: [],
    paginate: {},
    page: 1,
    loadings: {
      load: false,
      paginate: false
    },
    topicsDialogActive: false
  },
  getters: {
    topicsOrdered: (state) => {
      return [...state.topics].sort((a, b) => {
        const dateA = a.last_message_at || a.created_at
        const dateB = b.last_message_at || b.created_at
        return new Date(dateB) - new Date(dateA)
      }).filter(topic => topic.status_id == 1)
    }
  },
  mutations: {
    SET_CHAT(state, chat) {
      if (chat.id == state.chat.id && (!chat?.image && state?.chat?.image)) {
        chat['image'] = state.chat.image
      }
      state.chat = chat
    },
    SET_CHAT_UUID(state, chatUuid) {
      state.chatUuid = chatUuid
    },
    SET_TOPICS(state, topics) {
      state.topics = topics
    },
    SET_PAGINATE(state, paginate) {
      state.paginate = paginate
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    INCREMENT_PAGE(state) {
      state.page = state.page + 1
    },
    TOGGLE_LOADING(state, loading) {
      state.loadings[loading] = !state.loadings[loading]
    },
    UPDATE_TOPIC(state, { key, topic }) {
      state.topics.splice(key, 1, topic)
    },
    ADD_TOPIC(state, topic) {
      state.topics.unshift(topic)
    },
    ADD_TOPIC_IN_END(state, topic) {
      state.topics.push(topic)
    },
    TOGGLE_TOPICS_DIALOG(state, active) {
      state.topicsDialogActive = active
    }
  },
  actions: {
    getTopics({ commit, dispatch }, chatUuid) {
      commit('TOGGLE_LOADING', 'load')
      dispatch('resetTopics')
      commit('SET_CHAT_UUID', chatUuid)
      callApi.get({
        uri: `chats/chat/${chatUuid}/topics`,
        params: {
          page: 1,
          limit: 10,
          order: 'created_at,desc',
          status_id: 1
        },
        sucess: response => {
          commit('SET_TOPICS', response.data.topics.data)
          delete response.data.topics.data
          commit('SET_PAGINATE', response.data.topics)
          delete response.data.topics
          commit('SET_CHAT', response.data)
          commit('TOGGLE_LOADING', 'load')
        },
        error: () => {
          commit('SET_TOPICS', [])
          commit('SET_CHAT', {})
          commit('TOGGLE_LOADING', 'load')
        }
      })
    },
    setTopic({ state, commit }, { topic, insertInEnd }) {
      const key = state.topics.findIndex(t => t.id === topic.id)
      if (key > -1) {
        commit('UPDATE_TOPIC', { key, topic })
      } else {
        if (insertInEnd) {
          commit('ADD_TOPIC_IN_END', topic)
        } else {
          commit('ADD_TOPIC', topic)
        }
      }
    },
    makeTopics({ dispatch }, topics) {
      topics.map(topic => {
        dispatch('setTopic', { topic, insertInEnd: true })
      })
    },
    paginateTopics({ state, commit, dispatch }) {
      commit('TOGGLE_LOADING', 'paginate')
      
      callApi.get({
        uri: 'chats/chat-topic',
        params: {
          page: state.page,
          chat_id: state.chat.id,
          order: 'created_at,desc;id,desc',
          limit: 20
        },
        sucess: (response) => {
          dispatch('makeTopics', response.data.data)
          delete response.data.data
          commit('SET_PAGINATE', response.data)
          commit('TOGGLE_LOADING', 'paginate')
          commit('INCREMENT_PAGE')
        },
        error: () => {
          commit('TOGGLE_LOADING', 'paginate')
        }
      })
    },
    resetTopics({ commit }) {
      commit('SET_TOPICS', [])
      commit('SET_PAGINATE', {})
      commit('SET_PAGE', 1)
      commit('SET_CHAT', {})
      commit('SET_CHAT_UUID', null)
    },
    subscribeOnTopics({ dispatch }, chatUuid) {
      window.Echo.private(Auth.account().id + '.chat.' + chatUuid + '.topics')
        .listen('.topic.new', (response) => {
          response.unread_messages = response?.persons?.find(person => person?.id == Auth.user().id)?.unread_messages
          dispatch('setTopic', { topic: response })
        })
        .listen('.topic.change', (response) => {
          response.unread_messages = response?.persons?.find(person => person?.id == Auth.user().id).unread_messages
          console.log(response.unread_messages)
          dispatch('setTopic', { topic: response })
        })
    },
    unsubscribeOnTopics(root, chatUuid) {
      window.Echo.leave(Auth.account().id + '.chat.' + chatUuid + '.topics')
    },
    unseenMessages({ commit, state }, topicUuid) {
      const topicSelected = state.topics.find(topic => topic.uuid === topicUuid)
      const topicIndex = state.topics.findIndex(topic => topic.uuid === topicUuid)

      if (topicSelected && topicSelected.unread_messages > 0) {
        topicSelected.unread_messages = 0
        commit('UPDATE_TOPIC', { key: topicIndex, topic: topicSelected })
        
        callApi.post({
          uri: 'chats/chat-topic-unread-messages/' + topicUuid,
          data: {}
        })
      }
    },
    openTopicsDialog({ commit }, chatUuid) {
      commit('SET_CHAT_UUID', chatUuid)
      commit('TOGGLE_TOPICS_DIALOG', true)
    },
    closeTopicsDialog({ commit }) {
      commit('SET_CHAT_UUID', null)
      commit('TOGGLE_TOPICS_DIALOG', false)
    }
  }
}
