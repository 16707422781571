<template>
  <div class="chat-topics__empty">
    <div class="chat-topics__empty-content">
      <div class="chat-topics__empty-icon">
        <v-icon size="64" color="primary">forum</v-icon>
        <v-icon size="32" color="primary" class="chat-topics__empty-icon-plus">add_circle</v-icon>
      </div>

      <h3 :class="{'white--text': $wiDark}">
        Nenhum tópico encontrado
      </h3>

      <div class="chat-topics__empty-description">
        <p :class="{'grey--text': $wiDark}">
          Tópicos são conversas organizadas por assunto. Você pode criar tópicos para:
        </p>
        
        <ul class="chat-topics__empty-features">
          <li>
            <v-icon small color="primary">check_circle</v-icon>
            <span>Separar diferentes assuntos em uma mesma conversa</span>
          </li>
          <li>
            <v-icon small color="primary">check_circle</v-icon>
            <span>Manter o histórico organizado por tema</span>
          </li>
          <li>
            <v-icon small color="primary">check_circle</v-icon>
            <span>Facilitar o acompanhamento de cada assunto</span>
          </li>
        </ul>
      </div>

      <v-btn 
        @click="$emit('add-topic')"
        color="primary"
        class="chat-topics__empty-button"
        :class="{'white--text': $wiDark}"
        large
        rounded
      >
        <v-icon left>add_circle</v-icon>
        Criar primeiro tópico
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatTopicsEmpty'
}
</script>

<style scoped>
.chat-topics__empty {
  height: calc(100vh - 307px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
}

.chat-topics__empty-content {
  max-width: 420px;
  text-align: center;
  padding: 10px 32px;
  background: rgba(var(--v-primary-base), 0.02);
  border-radius: 24px;
  border: 1px solid rgba(var(--v-primary-base), 0.05);
}

.chat-topics__empty-icon {
  position: relative;
  display: inline-block;
  margin-bottom: 24px;
}

.chat-topics__empty-icon-plus {
  position: absolute;
  bottom: -8px;
  right: -8px;
  background: var(--bg-color, #f8fafc);
  border-radius: 50%;
}

.chat-topics__empty h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 16px;
  color: var(--v-primary-base);
}

.chat-topics__empty-description {
  margin-bottom: 5px;
}

.chat-topics__empty-description p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 0 0 24px;
  color: rgba(var(--v-primary-base), 0.7);
}

.chat-topics__empty-features {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.chat-topics__empty-features li {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
  color: rgba(var(--v-primary-base), 0.8);
  font-size: 0.95rem;
}

.chat-topics__empty-button {
  text-transform: none !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  height: 48px !important;
  padding: 0 32px !important;
}

/* Tema escuro */
.theme--dark .chat-topics__empty-content {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.05);
}

.theme--dark .chat-topics__empty-icon-plus {
  background: #1E1E1E;
}

.theme--dark .chat-topics__empty-features li {
  color: rgba(255, 255, 255, 0.8);
}

/* Responsivo */
@media (max-width: 600px) {
  .chat-topics__empty {
    padding: 16px;
    height: calc(100vh - 96px);
  }

  .chat-topics__empty-content {
    padding: 24px 16px;
  }

  .chat-topics__empty h3 {
    font-size: 1.25rem;
  }

  .chat-topics__empty-description p {
    font-size: 0.9rem;
  }

  .chat-topics__empty-features li {
    font-size: 0.85rem;
    gap: 8px;
  }

  .chat-topics__empty-button {
    height: 44px !important;
    padding: 0 24px !important;
  }
}
</style> 