<template>
  <v-container fluid style="padding: 0;">
    <v-layout row>
      <ChatList />
      <div class="talk-selected-area hidden-xs-only hidden-sm-only">
        <v-card tile style="width: 100%;">
          <router-view v-if="$route.params.id"></router-view>
          <div v-else style="width: 100%; text-align: center; margin: auto; max-width: 400px;">
            <img src="static/background/chat-background-1-1.png?url=temp2" alt="" style="width: 100%">
            <br>
            <span
              style="font-size: 20px;"
              v-t="'modules.talks.pages.talkView.talk.noTalkSelectedText'"
            ></span>
          </div>
        </v-card>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
  import ChatList from './ChatList.vue'
  import { mapState, mapGetters, mapActions } from 'vuex'
  export default {
    name: 'ChatView',
    data: () => ({
      optionsMenu: false
    }),
    computed: {
      ...mapState('Chats', ['chats', 'loadings']),
      ...mapGetters('Chats', ['myChats']),
      user () {
        return this.$store.state.user
      },
      primaryColor () {
        return !this.$wiDark ? 'primary' : 'dark'
      }
    },
    methods: {
      ...mapActions('Chats', ['getChats', 'paginateChats']),
      newChat: function () {
        this.$WiListDialog({
          wiConfig: 'collaborator',
          mode: 'select',
          callback: (collaborator) => {
            this.$WiApiPost({
              uri: 'chats/make-chat',
              data: { person_id: collaborator.id },
              callback: ({ sucess }) => {
                if (sucess) {
                  this.getChats()
                  this.$router.push({
                    name: 'chat-view-messages',
                    params: { id: sucess.uuid }
                  })
                }
              },
              config: {
                msgLoad: {
                  title: 'Criando chat...',
                  text: 'Aguarde enquanto o chat é criado.'
                },
                msgSucess: {
                  title: 'Chat criado com sucesso',
                  text: 'O chat foi criado com sucesso.'
                },
                msgError: {
                  title: 'Erro ao criar chat',
                  text: 'Tente novamente ou contacte o suporte.'
                }
              }
            })
          },
          params: { order: 'created_at,desc'},
          filter: { order: 'name,asc', where: 'collaborator,1' }
        })
      },
      getDateTimeFormated: function (time) {
        if (time) {
          const date = this.$moment(time)
          const today = this.$moment().startOf('day')
          const days = Math.round(this.$moment.duration(today - date).asDays())
          if (days > 1) {
            return this.$moment(time).format('DD/MM HH:mm')
          }
          return this.$moment(time).fromNow()
        } else if (time === null) {
          return '-'
        } else {
          return 'Agora'
        }
      },
      goToChat: function (uuid) {
        const breackPoint = this.$vuetify.breakpoint.name
        let routeName = 'chat-view-messages'
        if (breackPoint === 'xs' || breackPoint === 'sm') {
          routeName = 'chat-view-messages-page'
        }
        this.$router.push({
          name: routeName,
          params: { id: uuid },
          query: { previousChatId: this.$route.params.id }
        })
      },
      createNewGroup: function () {
        this.$WiEditDialog({
          wiConfig: 'chat-group',
          onSubmit: ({ data }) => {
            this.goToChat(data.uuid)
          },
          redirectOnSubmit: false
        })
      },
      formatLastMessage: function (message) {
        return message.text_html
          .replaceAll('<br>', ' ')
          .replaceAll('<br/>', ' ')
          .replaceAll('<br />', ' ')
          .replaceAll('<p>', ' ')
          .replaceAll('</p>', ' ')
      }
    },
    props: {
      drawer: {
        type: Boolean,
        default: false
      }
    },
    components: {
      ChatList
    }
  }
</script>

<style scoped>
  .chats-area {
    width: 330px;
    min-width: 330px;
  }
  .chats__list {
    height: calc(100vh - 106px) !important;
    overflow-y: auto;
    padding: 20px 0;
    top: -10px;
  }
  .chats__toolbar {
    border-radius: 10px !important;
  }
  .chats__toolbar >>> .v-toolbar__content {
    padding: 0 14px !important;
  }
  .chats__toolbar-area {
    background: transparent;
    padding: 10px 10px 0px 10px;
  }
  .chats-unseen-messages-count {
    padding: 0 5px;
    color: white;
    border-radius: 20px;
    margin-right: 5px;
  }
  .chats__list-tile {
    cursor: pointer;
  }
  .chats__list-items {
    padding: 0 !important;
    overflow-y: auto;
  }
  /* media queries para mobile */
  @media (max-width: 959px) {
    .chats-area {
      width: 100%;
    }
  }
</style>
