
const listFields = [
  {
    value: 'id',
    text: 'ID',
    list: true,
    edit: false,
    search: false,
    align: 'center',
    sortable: true
  },
  {
    value: 'nome_status',
    text: 'Nome',
    edit: false,
    list: true,
    search: true,
    align: 'center',
    sortable: true
  },
  // Contar SLA
  {
    value: 'tipo_status',
    text: 'Contar SLA?',
    list: true,
    edit: false,
    search: false,
    align: 'center',
    sortable: false
  },
  // Exigir data limite
  {
    value: 'exigir_data',
    text: 'Exigir data limite?',
    list: true,
    edit: false,
    search: false,
    align: 'center',
    sortable: false
  },
  // Confirmar ocorrência
  {
    value: 'get_confirmation',
    text: 'Confirmar ocorrência?',
    list: true,
    edit: false,
    search: false,
    align: 'center',
    sortable: false
  },
  // Ordem do status
  {
    value: 'ordem_status',
    text: 'Ordem',
    list: true,
    edit: false,
    search: false,
    align: 'center',
    sortable: true
  }
]

const editFields = [
    {text: 'Nome e tipo de status', type: 'subheader', edit: true},
    {value: 'nome_status', text: 'Nome do Status*', type: 'text', size: ['xs12', 'sm12', 'md6', 'lg6'], required: true, list: false, edit: true, search: true, align: 'center', sortable: true},
    {value: 'stage', text: 'Tipo de Status', type: 'select', required: true, size: ['xs12', 'sm12', 'md6', 'lg6'], options: [{value: 1, text: 'Em andamento'}, {value: 2, text: 'Encerrado'}, {value: 3, text: 'Cancelado'}], list: false, edit: true, disabled: (form) => { return form.id < 4 }, align: 'center', sortable: false},
    
    {text: 'Aparência do status', type: 'subheader', edit: true},
    {value: 'ordem_status', text: 'Ordem do Status', placeholder: 'Posição na lista', type: 'int', size: ['xs12', 'sm6', 'md4', 'lg4'], required: false, list: false, edit: true, align: 'center', sortable: false},
    {value: 'cor_status', text: 'Cor', type: 'color', size: ['xs12', 'sm6', 'md3', 'lg3'], required: false, list: false, edit: true, align: 'center', sortable: false},
    {value: 'icone_status', text: 'Ícone', type: 'wi-google-icons', size: ['xs12', 'sm6', 'md5', 'lg5'], required: false, list: false, edit: true, align: 'center', sortable: false},
    
    {text: 'Chamados neste status devem contar no SLA?', type: 'subheader', style: {height: '50px'}, edit: true, size: ['xs12', 'sm12', 'md6', 'lg6']},
    {text: 'Exigir data limite ao entrar neste status?', type: 'subheader', style: {height: '50px'}, edit: true, size: ['xs12', 'sm12', 'md6', 'lg6']},
    {value: 'tipo_status', text: 'Contar SLA?*', placeholder: 'Influenciar no SLA', type: 'select', required: true, size: ['xs12', 'sm12', 'md6', 'lg6'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], list: false, edit: true, align: 'center', sortable: false},
    {value: 'exigir_data', text: 'Exigir data limite?*', placeholder: 'Uma data será exigida', type: 'select', required: true, size: ['xs12', 'sm12', 'md6', 'lg6'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], list: false, edit: true, disabled: (form) => { return form.id === 1 }, align: 'center', sortable: false},
    
    {text: 'Ao entrar neste status, confirmar ocorrência?', type: 'subheader', edit: true, size: ['xs12', 'sm12', 'md12', 'lg12']},
    {value: 'get_confirmation', text: 'Confirmar Ocorrência?*', placeholder: 'O usuário terá de confirmar ao colocar neste status', type: 'select', required: true, size: ['xs12', 'sm12', 'md12', 'lg12'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], list: false, edit: true, disabled: (form) => { return form.id === 1 }, align: 'center', sortable: false},
    
    {text: 'Só permite entrar neste status se o status anterior for:', type: 'subheader', edit: true, size: ['xs12', 'sm12', 'md12', 'lg12']},
    {value: 'chamado_status_id', text: 'Status Dependente (Opcional)', placeholder: 'Depende deste Status para ser selecionável', autocomplete: true, required: false, size: ['xs12', 'sm12', 'md12', 'lg12'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/status', apiParams: {fields: 'id,nome_status'}, show: 'nome_status', disabled: (form) => { return form.id === 1 }, align: 'center', sortable: false},
    
    {text: 'Só permite entrar neste status se o perfil do usuário for:', type: 'subheader', edit: true, size: ['xs12', 'sm12', 'md12', 'lg12']},
    {value: 'show_status', text: 'Será exibido se o perfil do usuário for: (Opcional)', autocomplete: true, multiple: true, size: ['xs12', 'sm12', 'md12', 'lg12'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'system/user/profile', apiParams: {where: 'id,>,1', fields: 'id,name'}, show: 'name', align: 'center', sortable: false},
    // {value: 'hide_status', text: 'Não será exibido se o perfil do usuário for:', disabled: true, autocomplete: true, multiple: true, size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'usuario/permissao', apiParams: {fields: 'id,nome_permissao'}, show: 'nome_permissao'},
    // {value: 'config_status', text: 'Conigurações do status', autocomplete: true, multiple: true, size: ['xs12', 'sm12', 'md4', 'lg4'], type: 'select', options: [{value: 'nao_ela_100', text: 'Não exibir se o SLA for maior que 100%'}], list: false, edit: true},
    {text: 'Observações internas', type: 'subheader', edit: true, size: ['xs12', 'sm12', 'md12', 'lg12']},
    {value: 'obs_status', text: 'Observações', type: 'textarea', required: false, list: false, edit: true, align: 'center', sortable: false}
]

export default {
  id: '4f1be58b',
  list: true,
  title: 'Status do chamado',
  apiUrl: 'chamado/status',
  icon: 'verified_user',
  apiParams: {
    order: 'ordem_status,asc'
  },
  actions: {
    create: true,
    update: true,
    delete: (form) => { return form.id !== 1 && form.id !== 2 && form.id !== 3 }
  },
  components: {
    list: {
      tableRow: () => import('@/modules/service-desk/pages/Status')
    }
  },
  maxWidth: '500px',
  fields: [
    ...listFields,
    ...editFields
    // {value: 'id', text: 'ID', type: 'int', list: true, edit: false, search: false},
    // {text: 'Nome e tipo de status', type: 'subheader', edit: true},
    // {value: 'nome_status', text: 'Nome do Status*', type: 'text', size: ['xs12', 'sm12', 'md6', 'lg6'], required: true, list: true, edit: true, search: true, align: 'center', sortable: true},
    // {value: 'stage', text: 'Tipo de Status', type: 'select', required: true, size: ['xs12', 'sm12', 'md6', 'lg6'], options: [{value: 1, text: 'Em andamento'}, {value: 2, text: 'Encerrado'}, {value: 3, text: 'Cancelado'}], list: false, edit: true, disabled: (form) => { return form.id < 4 }, align: 'center', sortable: false},
    
    // {text: 'Aparência do status', type: 'subheader', edit: true},
    // {value: 'ordem_status', text: 'Ordem do Status', placeholder: 'Posição na lista', type: 'int', size: ['xs12', 'sm6', 'md4', 'lg4'], required: false, list: true, edit: true, align: 'center', sortable: false},
    // {value: 'cor_status', text: 'Cor', type: 'color', size: ['xs12', 'sm6', 'md3', 'lg3'], required: false, list: false, edit: true, align: 'center', sortable: false},
    // {value: 'icone_status', text: 'Ícone', type: 'wi-google-icons', size: ['xs12', 'sm6', 'md5', 'lg5'], required: false, list: false, edit: true, align: 'center', sortable: false},
    
    // {text: 'Chamados neste status devem contar no SLA?', type: 'subheader', style: {height: '50px'}, edit: true, size: ['xs12', 'sm12', 'md6', 'lg6']},
    // {text: 'Exigir data limite ao entrar neste status?', type: 'subheader', style: {height: '50px'}, edit: true, size: ['xs12', 'sm12', 'md6', 'lg6']},
    // {value: 'tipo_status', text: 'Contar SLA?*', placeholder: 'Influenciar no SLA', type: 'select', required: true, size: ['xs12', 'sm12', 'md6', 'lg6'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], list: true, edit: true, align: 'center', sortable: false},
    // {value: 'exigir_data', text: 'Exigir data limite?*', placeholder: 'Uma data será exigida', type: 'select', required: true, size: ['xs12', 'sm12', 'md6', 'lg6'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], list: true, edit: true, disabled: (form) => { return form.id === 1 }, align: 'center', sortable: false},
    
    // {text: 'Ao entrar neste status, confirmar ocorrência?', type: 'subheader', edit: true, size: ['xs12', 'sm12', 'md12', 'lg12']},
    // {value: 'get_confirmation', text: 'Confirmar Ocorrência?*', placeholder: 'O usuário terá de confirmar ao colocar neste status', type: 'select', required: true, size: ['xs12', 'sm12', 'md12', 'lg12'], options: [{value: 'sim', text: 'Sim'}, {value: 'nao', text: 'Não'}], list: true, edit: true, disabled: (form) => { return form.id === 1 }, align: 'center', sortable: false},
    
    // {text: 'Só permite entrar neste status se o status anterior for:', type: 'subheader', edit: true, size: ['xs12', 'sm12', 'md12', 'lg12']},
    // {value: 'chamado_status_id', text: 'Status Dependente', placeholder: 'Depende deste Status para ser selecionável', autocomplete: true, required: false, size: ['xs12', 'sm12', 'md12', 'lg12'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'chamado/status', apiParams: {fields: 'id,nome_status'}, show: 'nome_status', disabled: (form) => { return form.id === 1 }, align: 'center', sortable: false},
    
    // {text: 'Só permite entrar neste status se o perfil do usuário for:', type: 'subheader', edit: true, size: ['xs12', 'sm12', 'md12', 'lg12']},
    // {value: 'show_status', text: 'Será exibido se o perfil do usuário for:', autocomplete: true, multiple: true, size: ['xs12', 'sm12', 'md12', 'lg12'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'system/user/profile', apiParams: {where: 'id,>,1', fields: 'id,name'}, show: 'name', align: 'center', sortable: false},
    // // {value: 'hide_status', text: 'Não será exibido se o perfil do usuário for:', disabled: true, autocomplete: true, multiple: true, size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'usuario/permissao', apiParams: {fields: 'id,nome_permissao'}, show: 'nome_permissao'},
    // // {value: 'config_status', text: 'Conigurações do status', autocomplete: true, multiple: true, size: ['xs12', 'sm12', 'md4', 'lg4'], type: 'select', options: [{value: 'nao_ela_100', text: 'Não exibir se o SLA for maior que 100%'}], list: false, edit: true},
    // {text: 'Observações internas', type: 'subheader', edit: true, size: ['xs12', 'sm12', 'md12', 'lg12']},
    // {value: 'obs_status', text: 'Observações', type: 'textarea', required: false, list: false, edit: true, align: 'center', sortable: false}
  ],
  loading_edit: false,
  loading_list: false,
  loading_view: false
}
