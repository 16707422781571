import { Model } from '@/default/model/Model'
import {
  PERMISSION_TICKET_EXPORT_TICKETS,
  PERMISSION_TICKET_EXPORT_TICKETS_TO_XLSX
} from '@/default/constants/permissions'
class Ticket extends Model {
  constructor (stage) {
    super()
    this.list = true
    this.id = {
      id: '5dc282ab',
      create: '2c491b4c',
      update: '1a825af1'
    }
    this.actions = {
      list: true
    }
    this.name = 'ticket'
    this.title = {
      list: 'Chamados',
      edit: {
        insert: 'Novo Chamado',
        update: 'Editar Chamado'
      }
    }
    this.apiUrl = 'service-desk/ticket'
    this.urlDetails = { name: 'chamado', params: {} }
    this.icon = 'message'
    this.params = {
      limit: 50,
      with: 'networks'
    }
    if (stage) {
      this.params['stage'] = stage
    }
    this.icon = 'message'
    this.search = {
      placeholder: 'Pesquise ID, Status, Ocorrência, Equipe ou Responsável',
      fields: ['id', 'status.nome_status', 'category.nome_categoria', 'team.name', 'responsible.name']
    }
    this.components.list.tableRow = () => import('@/modules/service-desk/pages/Tickets/Tickets')
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']

    this.exports = {
      permission: PERMISSION_TICKET_EXPORT_TICKETS,
      title: 'Exportar Chamados',
      origins: {
        excel: {
          title: 'Exportar para o Excel',
          permission: PERMISSION_TICKET_EXPORT_TICKETS_TO_XLSX,
          description: 'Exportar chamados para o Excel.',
          image: '/static/icons/excel_icon.png',
          wiConfig: 'ticket-export-filters'
        }
      }
    }

    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID',
        align: 'center'
      }),
      this.fieldList({
        value: 'person.name',
        text: 'Cliente/Fornecedor',
        align: 'center',
        sortable: false
      }),
      this.fieldList({
        value: 'assunto_chamado',
        text: 'Assunto/Título',
        align: 'center',
        sortable: false
      }),
      this.fieldList({
        value: 'chamado_status_id',
        text: 'Status',
        align: 'center'
      }),
      this.fieldList({
        value: 'team_id',
        text: 'Redes'
      }),
      this.fieldList({
        value: 'chamado_categoria_id',
        text: 'Ocorrência',
        align: 'center'
      }),
      this.fieldList({
        value: 'deadline_at',
        text: 'Nota',
        align: 'center'
      }),
      this.fieldList({
        value: 'team_id',
        text: 'Equipe',
        align: 'center'
      }),
      this.fieldList({
        value: 'person_responsible_id',
        text: 'Responsável',
        align: 'center'
      })
    ])
  }
}

class TicketTitle extends Ticket {
  constructor (stage) {
    super(stage)
    this.id = {
      id: '8c272772',
      create: '8c272772',
      update: '8c272772'
    }
    this.actions = {
      list: false,
      insert: true,
      update: true,
      delete: false
    }
    this.icon = 'title'
    this.title = {
      list: 'Chamados',
      edit: {
        insert: 'Novo Chamado',
        update: 'Editar Título/Assunto do Chamado'
      }
    }
    this.fields = [
      this.fieldText({
        value: 'assunto_chamado',
        text: 'Assunto/Título',
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        required: true
      })
    ]
  }

}

export default {
  ticket: new Ticket(null),
  opened: new Ticket('opened'),
  closed: new Ticket('closed'),
  canceled: new Ticket('canceled'),
  title: new TicketTitle(),
}
