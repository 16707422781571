<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="90%" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card style="border-radius: 8px;">
      <v-toolbar dense flat :color="!$wiDark ? '#FFFFFF': '#212121'" style="border-radius: 8px;">
        <v-icon>{{ business.info.icon }}</v-icon>
        <v-toolbar-title>
          <span v-text="business.info.title"></span>
          <span> - </span>
          <span v-text="business.person.name"></span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn v-prms="{'id': updatePermission}" :disabled="disableEditButtons" class="hidden-xs-only hidden-sm-only" outline small @click="editLayout()">
          <v-icon>edit</v-icon>
          <span>Editar</span>
        </v-btn> -->
        <v-btn v-prms="{'id': updatePermission}" :disabled="disableEditButtons" class="hidden-xs-only hidden-sm-only" outline small @click="recreateLayout()">
          <v-icon>refresh</v-icon>
          <span class="hidden-xs-only hidden-sm-only ml-1">Recriar</span>
        </v-btn>
        <v-btn outline small @click="printLayout()" class="hidden-xs-only hidden-sm-only">
          <v-icon>print</v-icon>
          <span class="hidden-xs-only hidden-sm-only ml-1">Imprimir</span>
        </v-btn>
        <v-btn outline small @click="pdfLayout()" class="hidden-xs-only hidden-sm-only">
          <v-icon>
            file_download
          </v-icon>
          <span class="hidden-xs-only hidden-sm-only ml-1">PDF</span>
        </v-btn>
        <v-btn
          v-if="business.file && business.file.url"
          outline
          small
          link
          target="_blank"
          :href="business.file.url"
          class="hidden-xs-only hidden-sm-only"
        >
          <v-icon>download</v-icon>
          <span>download</span>
        </v-btn>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn> 
      </v-toolbar>
      <v-card-text
        v-if="loading"
        class="d-flex justify-center align-center"
        style="min-height: 300px; text-align: center;"
      >
        <div>
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          ></v-progress-circular>
          <h2 class="mt-5">Criando documento...</h2>
          <h3 class="mt-2">Não irá demorar mais que alguns segundos.</h3>
        </div>
      </v-card-text>
      <v-card-text v-else-if="dialog">
        <div v-show="!$vuetify.breakpoint.xsOnly">
          <BusinessLayoutA4View
            v-if="!loading && layoutData.html"
            :layoutData="layoutData"
          ></BusinessLayoutA4View>
        </div>
        <div v-if="$vuetify.breakpoint.xsOnly">
          <!-- <v-btn v-prms="{'id': updatePermission}" block :disabled="disableEditButtons" outline small @click="recreateLayout()">
            <v-icon>refresh</v-icon>
            <span>Recriar</span>
          </v-btn> -->
          <br/>
          <v-btn block outline small @click="printLayout()">
            <v-icon>print</v-icon>
            <span>Imprimir</span>
          </v-btn>
          <br/>
          <v-btn block outline small @click="pdfLayout()">
            <v-icon>
              file_download
            </v-icon>
            <span class="ml-1">PDF</span>
          </v-btn>
          <br/>
          <v-btn
            v-if="business.file && business.file.url"
            outline
            small
            link block
            target="_blank"
            :href="business.file.url"
          >
            <v-icon>download</v-icon>
            <span>download</span>
          </v-btn>  
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import { callApi } from '@/default/service/Api'
  import BusinessLayoutA4View from './BusinessLayoutA4View'
  export default {
    name: 'BusinessLayoutV2Dialog',
    data: () => ({
      apiKey: '1pwn5rw26syn27rz79wgwnapcgc98crz45y7ec41lhi2hare',
      config: {
        height: window.innerHeight - 170,
        menubar: false,
        readonly : true,
        content_css: 'document',
        content_style: 'body { margin: 1rem auto; max-width: 900px; }',
        language: 'pt_BR',
        plugins: ['print'],
        toolbar: [],
        loading: false,
        layoutData: {}
      },
      acceptRecreateLayoutDialog: false
    }),
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      disableEditButtons () {
        return this.loading || this.business.stage == 2 || this.business.stage == 3
      },
      updatePermission () {
        return this.$route.meta.config?.permission?.update
      }
    },
    methods: {
      printLayout() {
        // Configurações para uma janela popup menor
        const windowFeatures = 'width=800,height=600,menubar=no,toolbar=no,location=no,status=no';
        const printWindow = window.open('', '_blank', windowFeatures);
        
        if (printWindow) {
          printWindow.document.write(this.layoutData.html);
          printWindow.document.close();
          
          // Múltiplos listeners para garantir o fechamento
          printWindow.onload = function() {
            printWindow.focus();
            
            // Tenta fechar após um pequeno delay da impressão
            setTimeout(() => {
              printWindow.print();
              
              // Primeira tentativa de fechamento - após imprimir
              printWindow.onafterprint = function() {
                printWindow.close();
              };
              
              // Segunda tentativa - após um delay
              setTimeout(() => {
                if (!printWindow.closed) {
                  printWindow.close();
                }
              }, 1000);
            }, 250);
          };
        }
      },
      editLayout: function () {
        const typeName = this.$route?.meta?.config?.name
        this.$WiEditDialog({
          wiConfig: `${typeName}-layout`,
          onSubmit: this.refresh,
          data: {
            id: this.business.id,
            description: this.business.description
          }
        })
        this.dialog = false
      },
      recreateLayout: function () {
        this.acceptRecreateLayoutDialog = true
      },
      recreatingDocument: function () {
        this.dialog = false
      },
      recreatedDocument: function () {
        this.dialog = true
      },
      getBusinessLayout: function () {
        this.loading = true
        callApi.get({
          uri: `business/layout/generate/${this.business.id}`,
          params: {},
          sucess: ({ data }) => {
            this.layoutData = data
            this.$forceUpdate()
            this.loading = false
          },
          error: (err) => {
            this.loading = false
            console.log(err)
          }
        })
      },
      pdfLayout: function () {
        console.log('pdfLayout')
        this.$WiApiPost({
          uri: `business/layout/${this.business.id}/pdf`,
          data: {
            business_id: this.business.id
          },
          config: {
            msgLoad: {
              title: `Criando PDF...`,
              text: 'Não irá demorar mais que alguns segundos.'
            },
            msgSucess: {
              title: 'Solicitação enviada com sucesso.',
              text: 'O resultado da solicitação irá aparecer na timeline.'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: false
          },
          callback: ({ sucess }) => {
            if (sucess && sucess.file_url) {
              window.open(sucess.file_url, '_blank')
            }
          }
        })
      }
    },
    created: function () {
      this.getBusinessLayout()
    },
    props: {
      value: {
        required: true
      },
      business: {
        type: Object,
        required: true
      },
      refresh: {
        type: Function,
        required: true
      }
    },
    components: {
      BusinessLayoutA4View
    }
  }
</script>