import { Model } from '@/default/model/Model'

export class BusinessAgency extends Model {
  constructor () {
    super()
    this.list = true
    this.title = {
      list: 'Órgãos',
      edit: {
        insert: 'Novo Órgão',
        update: 'Editar Órgão'
      }
    }
    this.search = {
      placeholder: 'Pesquise ID ou nome do órgão',
      fields: ['id', 'name', 'slug']
    }
    this.params = {
      limit: 10
    }
    this.apiUrl = 'sales/budget/agency'
    this.icon = 'handshake'

    this.maxWidth = '500px'
    
    this.size = ['xs12', 'sm12', 'md8', 'ls8', 'xl8', 'offset-md2', 'offset-ls2', 'offset-xl2']

    this.components.list.tableRow = () => import('@/modules/business/pages/Agreements')

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }
  
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome do Órgão'
    }),
    slug: this.fieldList({
      value: 'slug',
      text: 'Identificador'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, identificador e ícone deste órgão.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome do Órgão',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }),
    agreement: this.fieldSelectApi({
      value: 'business_agreement_id',
      text: 'Convênio',
      show: 'name',
      api: {
        url: 'sales/budget/agreement',
        params: {
          fields: 'id,name',
          where: 'is_active,1'
        }
      },
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone do Órgão',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Descreva os detalhes deste órgão.'
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Descrição',
      placeholder: 'Descreva os detalhes deste órgão...'
    })
  }
} 