import { TestManagementEventService } from '@/modules/service-desk/services/TestManagement/TestManagementEventService'
import { TestManagementEventSocket } from '@/modules/service-desk/services/TestManagement/TestManagementEventSocket'

export default {
  data: () =>({
    fileListDialog: false,
    data: {},
    labels: {
      customer: {
        image: 'company_96.png',
        text: 'Cliente:',
        data: (item, data) => {
          return data.person.first_name + ' (' + data.person.name + ')'
        },
        actions: [ 
          data => ({
            icon: 'info',
            route: {name: 'customer', params: {id: data.person_id}},
            description: 'Informações do Cliente'
          })
        ]
      },
      provider: {
        image: 'company_96.png',
        text: 'Cliente:',
        data: (item, data) => {
          return data.person.first_name + ' (' + data.person.name + ')'
        },
        actions: [ 
          data => ({
            icon: 'info',
            route: {name: 'customer', params: {id: data.person_id}},
            description: 'Informações do Cliente'
          })
        ]
      },
      status: {
        icon: (item, data) => { return data.status.icon },
        color: (item, data) => { return data.status.color },
        text: 'Status/Etapa:',
        data: (item, data) => { return data.status.name }
      },
      id: {
        image: 'fingerprint_96.png',
        text: 'Código/ID:',
        data: (item, data) => { return '#' + data.id },
        actions: [
          {
            copy: true,
            description: 'Copiar Código/ID'
          }
        ]
      },
      category: {
        icon: (item, data) => { return data.category.icon },
        color: (item, data) => { return data.category.color },
        text: 'Categoria:',
        data: (item, data) => { return data.category.name }
      },
      collaborator: {
        image: (item, data) => { return data.collaborator?.file?.url ?? 'worker_male_96.png' },
        text: 'Responsável',
        data: (item, data) => { return data.collaborator.name },
        actions: [
          data => ({
            icon: 'info',
            route: {name: 'collaborator', params: {id: data.collaborator_id}},
            description: 'Abrir detalhes do colaborador Responsável'
          })
        ]
      },
      dateStart: {
        image: 'date_start_96.png',
        text: 'Início Perfuração:',
        value: 'date_start_format'
      },
      dateEnd: {
        image: 'event_time_96.png',
        text: 'Término Perfuração:',
        value: 'date_end_format'
      },
      duration: {
        image: 'timer_96.png',
        text: 'Duração Perfuração:',
        data: '- - -'
      },
      files: {
        image: 'anexo_96.png',
        text: 'Arquivos/Anexos:',
        value: 'count_files',
        actions: [
          (business) => {
            return {
              icon: 'info',
              WiFileListDialog: {
                api: {
                  url: 'internet/contract/file',
                  filter: {
                    business_id: business.id
                  }
                },
                info: {
                  business_id: business.id
                }
              },
              description: 'Ver todos os anexos/arquivos...'
            }
          }
        ]
      },
      externalCode: {
        image: 'dog_tag_96.png',
        text: 'Código Externo:',
        value: 'external_code',
        actions: [
          {
            copy: true,
            description: 'Copiar Código Externo.'
          }
        ]
      },
      notes: {
        image: 'note_96.png',
        text: 'Notas/Ações:',
        style: {'font-size': '12px'},
        value: 'last_note',
        actions: [
          (data) => {
            return {
              icon: 'info',
              WiEventsDialog: {
                title: 'Ações/Eventos',
                icon: 'message',
                service: TestManagementEventService,
                serviceParams: {
                  where: 'business_id,' + data.id
                },
                socket: TestManagementEventSocket,
                socketParams: {
                  id: data.id
                },
                business: data
              },
              description: 'Ver todas as notas...'
            }
          }
        ]
      }
    }
  }),
  computed: {
    configuration () {
      return [
        {
          size: ['xs12', 'sm12', 'md8', 'lg8'],
          items: [
            this.labels.customer
          ]
        },
        {
          size: ['xs12', 'sm12', 'md4', 'lg4'],
          items: [
            this.labels.status
          ]
        },
        {
          size: ['xs12', 'sm12', 'md5', 'lg5'],
          items: [
            this.labels.id,
            this.labels.category,
            this.labels.collaborator
          ]
        },
        {
          size: ['xs12', 'sm12', 'md3', 'lg3'],
          items: [
            this.labels.dateStart,
            this.labels.dateEnd,
            this.labels.duration
          ]
        },
        {
          size: ['xs12', 'sm12', 'md4', 'lg4'],
          items: [
            this.labels.files,
            this.labels.externalCode,
            this.labels.notes
          ]
        }
      ]
    }
  },
  methods: {
    updateView: function () {
      this.$forceUpdate()
    }
  },
  watch: {
    '$route': 'updateView'
  }
}
