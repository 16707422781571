import url from '@/default/store/modules/url/url'

const system = url.state.system

export default [
  {
    id: '4f1be58b',
    icon: 'category',
    title: 'Tipos de Ocorrência',
    route: { name: 'chamados-ocorrencias' }
  },
  {
    id: '4f1be58b',
    icon: 'verified_user',
    title: 'Status do Chamado',
    route: { name: 'service-desk-list', params: { page: 'status-do-chamado' } }
  },
  {
    id: '4f1be58b',
    icon: 'error',
    title: 'Impacto do Chamado',
    route: { name: 'service-desk-list', params: { page: 'ticket-impact' } }
  },
  {
    id: '4f1be58b',
    icon: 'error_outline',
    title: 'Urgência do Chamado',
    route: { name: 'service-desk-list', params: { page: 'ticket-urgency' } }
  },
  {
    id: '4f1be58b',
    icon: 'bolt',
    title: 'Motivos do Chamado',
    route: { name: 'service-desk-list', params: { page: 'ticket-justify' } }
  },
  {
    id: '4f1be58b',
    icon: 'code',
    title: 'Status/Etapas de Ordem de Serviço',
    route: { name: 'service-desk-list', params: { page: 'config-status-order' } }
  },
  {
    id: '4f1be58b',
    icon: 'category',
    title: 'Categorias de Ordem de Serviços',
    route: { name: 'service-desk-list', params: { page: 'config-category-order' } }
  },
  system === 'wise-view' && {
    id: '4f1be58b',
    icon: 'category',
    title: 'Categorias de Diagnósticos',
    route: { name: 'service-desk-list', params: { page: 'config-category-diagnostic' } }
  },
  // { id: '4f1be58b', icon: 'settings', title: 'Configurações do Service Desk', route: { name: 'configuracoes-do-chamado', params: { id: 3 } } }
]
