<template>
  <v-dialog 
    v-model="topicsDialogActive" 
    scrollable 
    max-width="70%" 
    persistent 
    lazy 
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card :dark="$wiDark" style="min-height: 350px; border-radius: 5px;">
      <ChatTopics
        v-if="topicsDialogActive && chatUuid"
        :setChatUuid="chatUuid"
        mode="dialog"
        @onClose="closeTopicsDialog"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import ChatTopics from './ChatTopics.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ChatTopicsDialog',
  components: {
    ChatTopics
  },
  computed: {
    ...mapState('ChatTopics', ['topicsDialogActive', 'chatUuid'])
  },
  methods: {
    ...mapActions('ChatTopics', ['closeTopicsDialog'])
  }
}
</script> 