<template>
  <v-menu v-if="talk.status < 3" v-model="menu" class="talk-menu" right transition="slide-y-transition" lazy>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="talk-menu__btn" icon ripple :small="small">
        <v-icon :color="color">more_vert</v-icon>
      </v-btn>
    </template>
    <v-list v-if="menu">
      <v-list-tile
        class="talk-menu__item"
        @click="$WiApiPut({
          uri: 'talk/talk',
          id: talk.id,
          data: {
            muted: !talk.muted
          }
        })"
      >
        <v-list-tile-avatar>
          <v-icon v-if="talk.muted">volume_up</v-icon>
          <v-icon v-else>volume_off</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title>
          <span v-if="talk.muted" v-t="'modules.talks.pages.talkView.menu.mutedActive'"></span>
          <span v-else v-t="'modules.talks.pages.talkView.menu.mutedDesactive'"></span>
        </v-list-tile-title>
      </v-list-tile>
      <v-divider></v-divider>
      <v-list-tile
        class="talk-menu__item"
        v-if="talk.status < 3"
        @click="$WiApiPut({
          uri: 'talk/talk',
          id: talk.id,
          data: {
            status: 3
          }
        })"
      >
        <v-list-tile-avatar>
          <v-icon color="green">done</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-t="'modules.talks.pages.talkView.menu.closeTalk'"
        ></v-list-tile-title>
      </v-list-tile>
      <v-divider></v-divider>
      <v-list-tile
        class="talk-menu__item"
        v-if="talk.status < 3"
        @click="$WiApiPut({
          uri: 'talk/talk',
          id: talk.id,
          data: {
            status: 4
          }
        })"
      >
        <v-list-tile-avatar>
          <v-icon color="red">close</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-t="'modules.talks.pages.talkView.menu.cancelTalk'"
        ></v-list-tile-title>
      </v-list-tile>
      <v-divider></v-divider>
      <v-list-tile class="talk-menu__item" v-if="talk.status < 3" @click="selectNewCollaborator()">
        <v-list-tile-avatar>
          <v-icon color="blue">transfer_within_a_station</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-t="'modules.talks.pages.talkView.menu.transferTalk'"
        ></v-list-tile-title>
      </v-list-tile>
      <v-divider></v-divider>
      <v-list-tile class="talk-menu__item" v-if="talk.status < 3" @click="selectNewTeam()">
        <v-list-tile-avatar>
          <v-icon color="blue">move_up</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-t="'modules.talks.pages.talkView.menu.transferTalkTeam'"
        ></v-list-tile-title>
      </v-list-tile>
      <v-divider v-if="permissionTalkCategorySetInTalk"></v-divider>
      <v-list-tile v-if="permissionTalkCategorySetInTalk" class="talk-menu__item" @click="openCategoryDialog()">
        <v-list-tile-avatar>
          <v-icon color="purple darken-1">category</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title>
          <span
            v-if="talk.talk_category_id"
            v-t="'modules.talks.pages.talkView.menu.changeCategory'"
          ></span>
          <span
            v-else
            v-t="'modules.talks.pages.talkView.menu.setCategory'"
          ></span>
        </v-list-tile-title>
      </v-list-tile>
      <v-divider v-if="parseInt(talk.unseen_messages) == 0"></v-divider>
      <v-list-tile class="talk-menu__item" v-if="parseInt(talk.unseen_messages) == 0" @click="setTalkUnseenMessages()">
        <v-list-tile-avatar>
          <v-icon color="#009600">mark_chat_unread</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title>
          <span
            v-t="'modules.talks.pages.talkView.menu.markToUnread'"
          ></span>
        </v-list-tile-title>
      </v-list-tile>
      <v-divider></v-divider>
      <v-list-tile class="talk-menu__item" v-if="talk.status < 3" @click="editTalkContact()">
        <v-list-tile-avatar>
          <v-icon color="orange">edit</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-if="talk.contact.type == 1"
          v-t="'modules.talks.pages.talkView.menu.editContact'"
        ></v-list-tile-title>
        <v-list-tile-title
          v-else
          v-t="'modules.talks.pages.talkView.menu.editGroup'"
        ></v-list-tile-title>
      </v-list-tile>
      <!-- <v-divider></v-divider>
      <v-list-tile :disabled="true">
        <v-list-tile-avatar>
          <v-icon color="black">translate</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-t="'modules.talks.pages.talkView.menu.configureTanslation'"
        ></v-list-tile-title>
      </v-list-tile> -->
      <v-divider v-if="talk.contact.person"></v-divider>
      <v-list-tile class="talk-menu__item" v-if="talk.contact.person" @click="unsetTalkContactPerson(talk.contact)">
        <v-list-tile-avatar>
          <v-icon color="pink">backspace</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-t="'modules.talks.pages.talkView.menu.unsetProfile'"
        ></v-list-tile-title>
      </v-list-tile>
      <v-divider v-if="talk.contact.person"></v-divider>
      <v-list-tile class="talk-menu__item" v-if="talk.contact.person" @click="editTalkContactPerson()">
        <v-list-tile-avatar>
          <v-icon color="pink">person</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-t="'modules.talks.pages.talkView.menu.editProfile'"
        ></v-list-tile-title>
      </v-list-tile>
      <!--
      <v-divider v-if="talk.contact.file_id && talk.contact.person_id"></v-divider>
      <v-list-tile class="talk-menu__item"
        v-if="talk.contact.file_id && talk.contact.person_id"
        @click="useTalkContactPhotoInPerson(talk.contact)"
      >
        <v-list-tile-avatar>
          <v-icon color="black">photo</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-t="'modules.talks.pages.talkView.menu.useContactImageInProfile'"
        ></v-list-tile-title>
      </v-list-tile>
      -->
      <!--
      <v-divider></v-divider>
      <v-list-tile class="talk-menu__item"
        @click="$WiListDialog({
          wiConfig: 'talk-schedule',
          filter: {
            talk_contact_id: talk.contact.id
          },
          wiEditData: {
            talk_contact_id: talk.contact.id,
            contact: talk.contact,
            talk_channel_id: talk.talk_channel_id
          }
        })"
      >
        <v-list-tile-avatar>
          <v-icon color="black">event</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title>
          Listar agendamentos
        </v-list-tile-title>
      </v-list-tile>
      -->
      <v-divider></v-divider>
      <v-list-tile class="talk-menu__item" @click="transferToChatbot()">
        <v-list-tile-avatar>
          <v-icon color="orange">smart_toy</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title
          v-t="'modules.talks.pages.talkView.menu.transferToChatbot'"
        ></v-list-tile-title>
      </v-list-tile>
      <v-divider v-if="$WiCheckPermission('03fe44da')"></v-divider>
      <v-list-tile
        v-if="$WiCheckPermission('03fe44da')"
        class="talk-menu__item"
        @click="makeChatTopicToContact()"
      >
        <v-list-tile-avatar>
          <v-icon color="blue">topic</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title>
          Criar tópico para este contato
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>
<script>
  import { mapActions } from 'vuex'
  import { PERMISSION_TALKS_TALK_CATEGORY_SET_IN_TALK } from '@/default/constants/permissions'
  export default {
    name: 'TalkMenu',
    data: () => ({
      menu: false
    }),
    computed: {
      permissionTalkCategorySetInTalk() {
        return this.$WiCheckPermission(PERMISSION_TALKS_TALK_CATEGORY_SET_IN_TALK)
      }
    },
    methods: {
      ...mapActions('Talks', ['openTalkCategorySelector']),
      ...mapActions('Chat', ['openChatDialog']),
      ...mapActions('ChatTopics', ['openTopicsDialog']),
      selectNewCollaborator: function () {
        this.$WiListDialog({
          wiConfig: 'collaborator-select',
          mode: 'select',
          callback: (collaborator) => {
            if (collaborator) {
              this.checkIfTalkIsCollaboratorDiff(collaborator)
            }
          },
          params: {order: 'persons.name,asc'},
          filter: {order: 'persons.name,asc', active: true}
        })
      },
      selectNewTeam: function () {
        this.$WiListDialog({
          wiConfig: 'team',
          mode: 'select',
          callback: (team) => {
            if (team) {
              this.checkIfTalkIsTeamDiff(team)
            }
          },
          params: {order: 'created_at,desc'},
          filter: {order: 'created_at,desc'}
        })
      },
      checkIfTalkIsCollaboratorDiff: function (collaborator) {
        if (collaborator.id == this.talk.operator_id) {
          this.$swal({
            type: 'info',
            title: 'Não é possível transferir!',
            text: 'Este atendimento já está com este colaborador.'
          })
        } else {
          this.transferTalkToCollaborator(collaborator)
        }
      },
      checkIfTalkIsTeamDiff: function (team) {
        if (team.id == this.talk.team_id) {
          this.$swal({
            type: 'info',
            title: 'Não é possível transferir!',
            text: 'Este atendimento já está neste departamento.'
          })
        } else {
          this.transferTalkToTeam(team)
        }
      },
      transferTalkToCollaborator: function (collaborator) {
        this.$WiApiPut({
          uri: 'talk/talk',
          id: this.talk.id,
          data: {
            operator_id: collaborator.id,
            status: 2
          }
        })
      },
      transferTalkToTeam: function (team) {
        this.$WiApiPut({
          uri: 'talk/talk',
          id: this.talk.id,
          data: {
            team_id: team.id,
            status: 2
          }
        })
      },
      editTalkContact: function () {
        this.$WiEditDialog({
          wiConfig: this.talk.contact.type == 1 ? 'talk-contact' : 'talk-group',
          data: {
            id: this.talk.contact.id,
            talk_channel_id: this.talk.contact.talk_channel_id,
            name: this.talk.contact.name,
            type: this.talk.contact.type,
            person_type: this.talk.contact.person_type,
            person: this.talk.contact.person,
            observation: this.talk.contact.observation,
            locale: this.talk.contact.locale,
          },
          onSubmit: () => {
            if (this.refresh) {
              this.refresh(this.$route.params.id)
            }
          }
        })
      },
      setTalkUnseenMessages: function () {
        this.$WiApiPut({
          uri: 'talk/talk',
          id: this.talk.id,
          data: {
            unseen_messages: 1
          },
          config: {
            msgLoad: true,
            msgSucess: true
          },
          callback: () => {
            // this.refresh()
          }
        })
      },
      editTalkContactPerson: function () {
        const personProfileName = this.talk?.contact?.person?.profiles[0]?.name
        if (personProfileName) {
          delete this.talk?.contact?.person?.status
          this.$WiEditDialog({
            wiConfig: personProfileName,
            data: this.talk?.contact?.person,
            onSubmit: () => {
              if (this.refresh) {
                this.refresh(this.$route.params.id)
              }
            }
          })
        }

      },
      useTalkContactPhotoInPerson: function (talkContact) {
        this.$WiApiPost({
          uri: 'talk/contact/use-photo-in-person',
          id: talkContact.person_id,
          data: {
            file_id: talkContact.file_id,
            talk_contact_id: talkContact.id,
            person_id: talkContact.person_id
          },
          config: {
            msgLoad: true,
            msgSucess: true,
            msgError: true,
            msgAsk: true
          },
          callback: () => {
            if (this.refresh && this.talk) {
              this.refresh(this.talk.id)
            }
          }
        })
      },
      unsetTalkContactPerson: function (talkContact) {
        this.$WiApiPut({
          uri: 'talk/contact',
          id: talkContact.id,
          data: {
            person_id: null
          },
          config: {
            msgLoad: true,
            msgSucess: true,
            msgError: true,
            msgAsk: true
          },
          callback: () => {
            if (this.refresh && this.talk) {
              this.refresh(this.talk.id)
            }
          }
        })
      },
      transferToChatbot: function () {
        this.$WiListDialog({
          wiConfig: 'talk-menu',
          mode: 'select',
          callback: (talkMenu) => {
            if (talkMenu) {
              const talkMenuId = talkMenu.id
              this.$WiApiPost({
                uri: 'talks/talk-transfer-to-menu',
                data: {
                  talk_id: this.talk.id,
                  talk_menu_id: talkMenuId
                },
                config: {
                  msgLoad: true,
                  msgSucess: true,
                  msgError: true,
                  msgAsk: true
                },
                callback: () => {
                  if (this.refresh && this.talk) {
                    this.refresh(this.talk.id)
                  }
                }
              })
            }
          },
          filter: {
            type_id: 8
          }
        })
        
      },
      openCategoryDialog: function () {
        this.openTalkCategorySelector({
          currentCategoryId: this.talk.talk_category_id,
          callback: (category) => {
            this.$WiApiPut({
              uri: 'talks/talk',
              id: this.talk.id,
              data: {
                talk_category_id: category.id
              }
            })
          }
        })
      },
      makeChatTopicToContact: function () {
        this.$WiEditDialog({
          wiConfig: 'chat-topic-group',
          data: {
            talk_id: this.talk?.id,
            person_id: this.talk?.contact?.person_id,
            talk_contact_id: this.talk?.contact?.id,
            talk_channel_id: this.talk?.talk_channel_id,
            talk_category_id: this.talk?.talk_category_id
          },
          onSubmit: ({ data: { chat_uuid, uuid: topic_uuid } }) => {
            console.log(chat_uuid, topic_uuid)
            this.openChatDialog({
              chatUuid: chat_uuid,
              topicUuid: topic_uuid
            })
          },
          redirectOnSubmit: false
        })
      },
      // openChatTopicToContact: function (response) {
      //   this.openTopicsDialog({
      //     chatUuid: response.uuid,
      //     talkContactId: this.talk?.contact?.id
      //   })
      // }
    },
    props: ['talk', 'color', 'small', 'refresh']
  }
</script>
<style scoped>
  .talk-menu__btn {
    margin: 0px !important;
  }
  .talk-menu__list {
    padding: 0px !important;
  }
  .talk-menu__item >>> .v-list__tile {
    height: 38px !important;
  }
</style>