import url from '@/default/store/modules/url/url'

const system = url.state.system

const date_start = new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01T00:00:00'
const date_end = new Date().toISOString().slice(0, 19)

export default [
  // {id: 'svdk', icon: 'insert_chart_outlined', title: 'Por Tipo de Ocorrência', route: {name: 'chamados-por-ocorrencia'}, selected: true, permission: ['admin']},
  // {id: 'svdk', icon: 'insert_chart_outlined', title: 'Por Rede e Ocorrência', route: {name: 'tickets-by-networks'}, selected: true, permission: ['admin']},
  {
    id: '4dd2e87d',
    icon: 'insert_chart_outlined',
    title: 'Estatísticas por Ocorrência',
    route: {
      name: 'tickets-by-category-statistics'
    },
    selected: true,
    permission: ['admin']
  },
  system === 'wise-view' && {
    id: 'bef5093c',
    icon: 'insert_chart_outlined',
    title: 'Estatísticas por Rede',
    route: {
      name: 'tickets-by-network-statistics',
      query: {
        ticket_impact: '1,2,3,4,5,6',
        start_date: date_start
      }
    },
    selected: true,
    permission: ['admin', 'write', 'read']
  },
  system === 'wise-view' && {
    id: 'bef5093c',
    icon: 'insert_chart_outlined',
    title: 'Estatísticas por Rede v2',
    route: {
      name: 'tickets-by-network-statistics-v2',
      query: {
        network_id: -1,
        ticket_impact_ids: '1,2,3,4,5,6',
        date_start,
        date_end
      }
    },
    selected: true,
    permission: ['admin', 'write', 'read']
  },
  {
    id: '0b291944',
    icon: 'insert_chart_outlined',
    title: 'Chamados Encerrados',
    route: {
      name: 'tickets-list',
      query: {
        start_date: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01T00:00:00'
      }
    },
    selected: true,
    permission: ['admin']
  }
]