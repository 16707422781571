<template>
  <v-toolbar dense class="tickets-kanban-toolbar">
    <div class="toolbar-content">
      <!-- Status Icon and Name -->
      <div class="status-info">
        <v-tooltip bottom lazy>
          <template v-slot:activator="{ on }">
            <v-btn icon small class="status-icon-btn" v-on="on" @click="$emit('edit-status')">
              <v-icon :style="{ color: status.cor_status }">{{ status.icone_status }}</v-icon>
            </v-btn>
          </template>
          <span>Clique para editar o status</span>
        </v-tooltip>
        <div class="status-name-container">
          <span class="status-name">{{ status.nome_status }}</span>
        </div>
      </div>

      <!-- Metrics -->
      <div class="metrics">
        <v-tooltip bottom lazy>
          <template v-slot:activator="{ on }">
            <div class="metric-item" v-on="on">
              <v-icon size="10" class="metric-icon">list_alt</v-icon>
              {{ status.itens_count }}
            </div>
          </template>
          <span>Total de chamados neste status</span>
        </v-tooltip>

        <v-tooltip bottom lazy v-if="status.itens_acima_sla">
          <template v-slot:activator="{ on }">
            <div class="metric-item alert" v-on="on">
              <v-icon size="10" class="metric-icon">schedule</v-icon>
              {{ status.itens_acima_sla }}
            </div>
          </template>
          <span>Chamados acima do SLA</span>
        </v-tooltip>

        <v-tooltip bottom lazy v-if="status.itens_atrasados">
          <template v-slot:activator="{ on }">
            <div class="metric-item alert" v-on="on">{{ status.itens_atrasados }}</div>
          </template>
          <span>Chamados atrasados</span>
        </v-tooltip>

        <v-tooltip bottom lazy v-if="status.itens_deadline_atrasado">
          <template v-slot:activator="{ on }">
            <div class="metric-item alert" v-on="on">
              <v-icon size="10" class="metric-icon">event</v-icon>
              {{ status.itens_deadline_atrasado }}
            </div>
          </template>
          <span>Chamados com agendamento atrasado</span>
        </v-tooltip>
      </div>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  name: 'TicketsKanbanStatusToolbar',
  props: {
    status: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.tickets-kanban-toolbar {
  padding: 0 2px !important;
  min-height: 36px !important;
  max-width: 290px !important;
  border-radius: 4px 4px 0 0;
}

.tickets-kanban-toolbar >>> .v-toolbar__content {
  padding: 0 2px !important;
  height: 42px !important;
}

.toolbar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-info {
  display: flex;
  align-items: center;
  gap: 2px;
  min-width: 0;
  flex: 1;
}

.status-name-container {
  min-width: 0;
  flex: 1;
}

.status-name {
  display: block;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status-icon-btn {
  margin: 0;
  padding: 0;
  flex-shrink: 0;
}

.metrics {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
}

.metric-item {
  font-size: 12px;
  font-weight: 600;
  padding: 2px 4px;
  border-radius: 3px;
  min-width: 24px;
  text-align: center;
  cursor: help;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 2px;
}

.metric-icon {
  opacity: 0.9;
  margin-top: -1px;
}

/* Alert Style */
.alert {
  background-color: rgba(244, 67, 54, 0.15);
  color: #d32f2f;
}

/* Dark Mode Support */
.application.theme--dark .tickets-kanban-toolbar {
  background-color: #1E1E1E !important;
}

.application.theme--dark .metric-item {
  background-color: rgba(255, 255, 255, 0.05);
}

.application.theme--dark .alert {
  color: #ef5350;
}

/* Light Mode */
.application.theme--light .tickets-kanban-toolbar {
  background-color: #FFFFFF;
}

.application.theme--light .metric-item {
  background-color: rgba(0, 0, 0, 0.05);
}
</style> 