<template>
  <div class="audio-recorder-inline" :class="{ 'theme--dark': $vuetify.theme.dark }" :style="{ height: height + 'px' }">
    <!-- Estado inicial - Botão de microfone -->
    <div v-if="!isRecording && !isPaused && !audioUrl" class="recorder-initial">
      <div class="mic-button-wrapper">
        <v-btn icon :color="iconColor" @click="startRecording">
          <v-icon :class="{ 'pulse': isRecording }">mic</v-icon>
        </v-btn>
        <div v-if="isRecording" class="pulse-ring" :style="{ borderColor: recordingColor }"></div>
      </div>
      <span class="hint-text">Clique para gravar áudio</span>
    </div>

    <!-- Estado de gravação -->
    <div v-else-if="isRecording || isPaused" class="recorder-active">
      <div class="recording-status">
        <div class="recording-info">
          <v-icon :color="recordingColor" class="status-icon">
            {{ isPaused ? 'pause_circle' : 'radio_button_checked' }}
          </v-icon>
          <div class="status-details">
            <span class="status-text">{{ isPaused ? 'Gravação pausada' : 'Gravando...' }}</span>
            <span class="timer" :class="{ 'paused': isPaused }">{{ formatTime(recordingTime) }}</span>
          </div>
        </div>

        <div class="recording-controls">
          <v-btn
            small
            icon
            :color="isPaused ? accentColor : recordingColor"
            @click="toggleRecording"
            class="mx-1"
          >
            <v-icon>{{ isPaused ? 'fiber_manual_record' : 'pause_circle' }}</v-icon>
          </v-btn>

          <v-btn
            small
            icon
            :color="recordingColor"
            @click="stopRecording"
            class="mx-1"
          >
            <v-icon>check_circle</v-icon>
          </v-btn>

          <!-- <v-btn
            small
            icon
            color="grey"
            @click="cancelRecording"
            class="mx-1"
          >
            <v-icon>delete</v-icon>
          </v-btn> -->
        </div>
      </div>
    </div>

    <!-- Estado de preview -->
    <div v-else-if="audioUrl" class="recorder-preview">
      <div class="preview-container">
        <audio
          ref="audioPlayer" 
          :src="audioUrl"
          controls 
          class="audio-player"
        ></audio>
        
        <div class="preview-controls">
          <v-btn
            icon
            @click="resetRecording"
            class="mx-1"
          >
            <v-icon color="red">delete</v-icon>
          </v-btn>

          <v-btn
            icon
            @click="sendAudio"
            :loading="isProcessing"
            class="mx-1"
          >
            <v-icon color="primary">send</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import audioRecorderMixin from './audioRecorderMixin'

export default {
  name: 'WNAudioRecorderInline',
  mixins: [audioRecorderMixin],
  
  props: {
    height: {
      type: Number,
      default: 70
    }
  }
}
</script>

<style scoped>
.audio-recorder-inline {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: transparent;
  border-radius: 4px;
}

.recorder-initial,
.recorder-active,
.recorder-preview {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recorder-initial {
  justify-content: flex-start;
}

.mic-button-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.hint-text {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 8px;
  font-size: 0.9rem;
}

.recording-status {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recording-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.status-icon {
  animation: blink 2s infinite ease-in-out;
}

.status-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.status-text {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 2px;
}

.timer {
  font-family: 'Roboto Mono', monospace;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--v-recordingColor-base, rgba(0, 0, 0, 0.87));
  transition: all 0.3s ease;
}

.timer.paused {
  opacity: 0.7;
}

.recording-controls,
.preview-controls {
  display: flex;
  align-items: center;
  gap: 4px;
}

.audio-player {
  width: 100%;
  height: 32px;
}

.pulse-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 2.5px solid;
  border-radius: 50%;
  animation: pulse-ring 1s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-ring {
  0% {
    width: 40px;
    height: 40px;
    opacity: 0.8;
  }
  70% {
    width: 60px;
    height: 60px;
    opacity: 0.2;
  }
  100% {
    width: 65px;
    height: 65px;
    opacity: 0;
  }
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}

.pulse {
  animation: pulse-icon 1s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-icon {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.theme--dark .hint-text {
  color: rgba(255, 255, 255, 0.7);
}

.theme--dark .status-text {
  color: rgba(255, 255, 255, 0.7);
}

.theme--dark .timer {
  color: rgba(255, 255, 255, 0.9);
}

.theme--dark .audio-player {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.recorder-preview {
  width: 100%;
}

.preview-container {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.audio-player {
  flex: 1;
  height: 32px;
  min-width: 0; /* Importante para evitar overflow */
}

.preview-controls {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0; /* Impede que os botões encolham */
}
</style> 