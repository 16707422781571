<template>
  <WiView
    v-if="apiParams"
    index="finance-dre"
    :apiUrl="'finance/report/dre'"
    :apiParams="apiParams"
    :notShowToolbar="true"
    :notGetID="true"
    @afterLoad="setContent"
  >
    <template slot-scope="{ content, refresh }">
      <v-container style="padding: 10px;">
        <v-layout row wrap>
          <!-- Header -->
          <v-flex sm12 style="padding: 20px 5px 10px 5px;">
            <v-toolbar dense class="elevation-10" style="border-radius: 10px;">
              <v-btn icon ripple @click="$router.go(-1)">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-list-tile v-ripple avatar class="hidden-xs-only">
                <v-list-tile-avatar @click="refresh()" style="cursor: pointer;" size="45">
                  <img src="static/systems/modules/wises-technology-module-finance.png" alt="DRE">
                </v-list-tile-avatar>
                <v-list-tile-content @click="refresh()" style="cursor: pointer;">
                  <v-list-tile-sub-title style="font-size: 12px;">
                    Relatório Gerencial
                  </v-list-tile-sub-title>
                  <v-list-tile-title style="font-size:18px;">
                    Demonstrativo de Resultados
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-spacer></v-spacer>
              
              <!-- Filtro de Data -->
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" outline small>
                    <v-icon left>event</v-icon>
                    <span v-if="selectedPeriodType === 'custom'" class="hidden-xs-only">
                      {{ dateStart | moment('DD/MM/YY') }} - {{ dateEnd | moment('DD/MM/YY') }}
                    </span>
                    <span v-else-if="selectedPeriodType === 'month'" class="hidden-xs-only">
                      {{ selectedMonth | moment('MMMM/YYYY') }}
                    </span>
                    <span v-else class="hidden-xs-only">
                      {{ selectedYear }}
                    </span>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dense flat color="transparent">
                    <v-toolbar-title>Filtrar Período</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  
                  <v-card-text>
                    <v-select
                      v-model="selectedPeriodType"
                      :items="periodTypes"
                      item-text="text"
                      item-value="value"
                      label="Tipo de Período"
                      outline
                      dense
                    ></v-select>

                    <!-- Seletor de Mês -->
                    <v-menu
                      v-if="selectedPeriodType === 'month'"
                      ref="monthMenu"
                      :close-on-content-click="false"
                      v-model="monthMenu"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="selectedMonth"
                          label="Selecione o Mês"
                          persistent-hint
                          readonly
                          outline
                          dense
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker 
                        v-model="selectedMonth" 
                        type="month"
                        no-title 
                        @input="monthMenu = false"
                      ></v-date-picker>
                    </v-menu>

                    <!-- Seletor de Ano -->
                    <v-menu
                      v-if="selectedPeriodType === 'year'"
                      ref="yearMenu"
                      :close-on-content-click="false"
                      v-model="yearMenu"
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="selectedYear"
                          label="Selecione o Ano"
                          persistent-hint
                          readonly
                          outline
                          dense
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker 
                        v-model="selectedYear" 
                        type="year"
                        no-title 
                        @input="yearMenu = false"
                      ></v-date-picker>
                    </v-menu>

                    <!-- Período Customizado -->
                    <template v-if="selectedPeriodType === 'custom'">
                      <v-text-field
                        dense
                        outline
                        v-model="dateStart"
                        type="date"
                        label="Data Inicial"
                      ></v-text-field>
                      <v-text-field
                        dense
                        outline
                        v-model="dateEnd"
                        type="date"
                        label="Data Final"
                      ></v-text-field>
                    </template>
                  </v-card-text>

                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn block outline color="green" @click="changeFilters()">
                      <v-icon>filter_list</v-icon>
                      <span>Filtrar</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              
              <v-btn flat icon color="green" @click="refresh()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
          <v-flex sm12 style="padding: 5px;">
            <strong>
              Exibindo dados do período de {{ content.current_period.start | moment('DD/MM/YY') }} a {{ content.current_period.end | moment('DD/MM/YY') }}, 
              comparando com o período anterior de {{ content.previous_period.start | moment('DD/MM/YY') }} a {{ content.previous_period.end | moment('DD/MM/YY') }}.
            </strong>
          </v-flex>
        </v-layout>
        <!-- Cards Comparativos -->
        <v-layout row wrap>
          <v-flex
            v-if="
              content.comparison && content.comparison.revenues
            "
            xs12 sm12 md4
          >
            <DRECard
              title="Receitas"
              icon="trending_up"
              color="green"
              :current_value="content.comparison.revenues.current_value || 0"
              :previous_value="content.comparison.revenues.previous_value || 0"
              :absolute_variation="content.comparison.revenues.absolute_variation || 0"
              :percentage_variation="content.comparison.revenues.percentage_variation || 0"
            />
          </v-flex>
          <v-flex
            v-if="
              content.comparison && content.comparison.expenses
            "
            xs12 sm12 md4
          >
            <DRECard
              title="Despesas"
              icon="trending_down"
              color="red"
              :current_value="content.comparison.expenses.current_value || 0"
              :previous_value="content.comparison.expenses.previous_value || 0"
              :absolute_variation="content.comparison.expenses.absolute_variation || 0"
              :percentage_variation="content.comparison.expenses.percentage_variation || 0"
            />
          </v-flex>
          <v-flex
            v-if="
              content.comparison && content.comparison.result
            "
            xs12 sm12 md4
          >
            <DRECard
              title="Resultado"
              icon="assessment"
              color="blue"
              :current_value="content.comparison.result.current_value || 0"
              :previous_value="content.comparison.result.previous_value || 0"
              :absolute_variation="content.comparison.result.absolute_variation || 0"
              :percentage_variation="content.comparison.result.percentage_variation || 0"
            />
          </v-flex>
        </v-layout>

        <!-- Tabelas DRE -->
        <v-layout row wrap>
          <v-flex
            v-if="
              content.current_period && content.current_period.revenues &&
              content.previous_period && content.previous_period.revenues &&
              content.current_period.revenues.categories && content.previous_period.revenues.categories
            "
            xs12 sm12 md6
          >
            <DRETable
              title="Receitas"
              :categories="content.current_period.revenues.categories || []"
              :previousCategories="content.previous_period.revenues.categories || []"
              :previousTotal="content.previous_period.revenues.total || 0"
              :currentTotal="content.current_period.revenues.total || 0"
              :currentPeriodStart="content.current_period.start"
              :currentPeriodEnd="content.current_period.end"
              :previousPeriodStart="content.previous_period.start"
              :previousPeriodEnd="content.previous_period.end"
            />
          </v-flex>
          <v-flex
            v-if="
              content.current_period && content.current_period.expenses &&
              content.previous_period && content.previous_period.expenses &&
              content.current_period.expenses.categories && content.previous_period.expenses.categories
            "
            xs12 sm12 md6
          >
            <DRETable
              title="Despesas" 
              :categories="content.current_period.expenses.categories || []"
              :previousCategories="content.previous_period.expenses.categories || []"
              :previousTotal="content.previous_period.expenses.total || 0"
              :currentTotal="content.current_period.expenses.total || 0"
              :currentPeriodStart="content.current_period.start"
              :currentPeriodEnd="content.current_period.end"
              :previousPeriodStart="content.previous_period.start"
              :previousPeriodEnd="content.previous_period.end"
            />
          </v-flex>
        </v-layout>

      </v-container>
    </template>
  </WiView>
</template>

<script>
import DRECard from './Components/DRECard'
import DRETable from './Components/DRETable'

export default {
  name: 'FinanceDRE1',
  
  components: {
    DRECard,
    DRETable
  },

  data: () => ({
    dialog: false,
    dateStart: null,
    dateEnd: null,
    apiParams: null,
    selectedPeriodType: 'month',
    monthMenu: false,
    yearMenu: false,
    selectedMonth: null,
    selectedYear: null,
    periodTypes: [
      { text: 'Mensal', value: 'month' },
      { text: 'Anual', value: 'year' },
      { text: 'Personalizado', value: 'custom' }
    ]
  }),

  methods: {
    setInitialDateInterval() {
      const today = new Date()
      this.selectedMonth = today.toISOString().substr(0, 7)
      this.selectedYear = today.getFullYear().toString()
      this.dateStart = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0]
      this.dateEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0]
    },
    
    setApiParams() {
      if (this.selectedPeriodType === 'custom') {
        this.apiParams = `?period_type=custom&start_date=${this.dateStart}&end_date=${this.dateEnd}`
      } else if (this.selectedPeriodType === 'month') {
        this.apiParams = `?period_type=month&reference_date=${this.selectedMonth}`
      } else if (this.selectedPeriodType === 'year') {
        this.apiParams = `?period_type=year&reference_date=${this.selectedYear}`
      }
    },
    
    changeFilters() {
      this.setApiParams()
      setTimeout(() => {
        this.refresh()
        this.dialog = false
      }, 50)
    },

    setContent({ content, refresh }) {
      this.content = content
      this.refresh = refresh
    }
  },

  created() {
    this.setInitialDateInterval()
    this.setApiParams()
  },

  watch: {
    selectedPeriodType(newValue) {
      if (newValue === 'custom') {
        this.setInitialDateInterval()
      }
    }
  }
}
</script>
