<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center">
      <strong>
        {{ props.item.id }}
      </strong>
    </td>
    <td class="justify-center">
      <div style="text-align: center;">
        <v-icon> {{props.item.icone_status}} </v-icon>
        <br>
        <strong :style="{ color: props.item.cor_status }">
          {{ props.item.nome_status }}
        </strong>
      </div>
    </td>
    <td class="justify-center">
      <v-chip
        :color="props.item.tipo_status === 'sim' ? 'success' : 'error'"
        small
        label
        text-color="white"
      >
        {{ props.item.tipo_status === 'sim' ? 'Sim' : 'Não' }}
      </v-chip>
    </td>
    <td class="justify-center">
      <v-chip
        :color="props.item.exigir_data === 'sim' ? 'success' : 'error'"
        small
        label
        text-color="white"
      >
        {{ props.item.exigir_data === 'sim' ? 'Sim' : 'Não' }}
      </v-chip>
    </td>
    <td class="justify-center">
      <v-chip
        :color="props.item.get_confirmation === 'sim' ? 'success' : 'error'"
        small
        label
        text-color="white"
      >
        {{ props.item.get_confirmation === 'sim' ? 'Sim' : 'Não' }}
      </v-chip>
    </td>
    <td class="justify-center">
      <div class="ordem-badge">
        <div class="ordem-badge__inner">
          {{ props.item.ordem_status }}
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'TicketStatus',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
.business__date {
  border-width: 1px;
  border-style: solid;
  padding: 0px 3px;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
}
.business__date--new {
  border-color: green;
  background: rgba(0, 128, 0, 0.589);
}
.business__date--expired {
  border-color: red;
  background: rgba(255, 0, 0, 0.507);
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.business__is-template {
  background: orange;
  border-color: rgb(145, 94, 0);
}
.ordem-badge {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  /* cursor: pointer; */
}

.ordem-badge__inner {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.3);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  z-index: 1;
}

.ordem-badge::before {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%);
  border-radius: 50%;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ordem-badge:hover .ordem-badge__inner {
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0 8px 16px rgba(99, 102, 241, 0.4);
}

.ordem-badge:hover::before {
  opacity: 1;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>