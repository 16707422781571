<template>
  <div style="width: 100%; text-align: center; padding: 10px;">
    <v-btn 
      @click="$emit('click')" 
      outline 
      block 
      :loading="loading"
    >
      <v-icon>refresh</v-icon>
      <span v-t="'modules.talks.pages.talkView.talks.labels.loadMore'"></span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'LoadMoreButton',
  
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script> 