<template>
  <v-dialog v-model="active" max-width="550px" scrollable persistent>
    <v-card color="gray">
      <v-toolbar dense flat color="transparent">
        <v-toolbar-title>
          <v-icon>description</v-icon>
          <span> Solicitação</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <div class="pb-0 pt-2" style="text-align: center !important; font-size: 25px;">
        {{title || 'Informe abaixo da forma que desejar...'}}
      </div>
      <v-card-text style="overflow-y: auto; max-height: 700px;">
        <v-select
          v-model="subject"
          :items="subjects"
          outline
          label="Por favor, selecione o assunto"
        ></v-select>
        <v-select
          v-model="wantWhatsapp"
          :items="whatsappOptions"
          outline
          label="Deseja receber retorno via WhatsApp?"
        ></v-select>
        <v-text-field
          v-if="wantWhatsapp === 'yes'"
          v-model="whatsappNumber"
          outline
          label="Número do WhatsApp (com DDD)"
          placeholder="11999999999"
          mask="(##) #####-####"
          maxlength="13"
        ></v-text-field>
        <v-textarea
          v-model="message"
          outline
          rows="7"
          label="Informe sua solicitação, sugestão ou reclamação aqui"
        ></v-textarea>
        <v-btn
          :disabled="!subject || !message"
          @click="sendHelpDialogContent()"
          color="primary"
          block
        >Enviar Agora</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'WiHelpDialog',
    data: () => ({
      subjects: [
        { text: 'Dúvida sobre o sistema', value: 'doubt' },
        { text: 'Sugestão de nova funcionalidade', value: 'suggestion' },
        { text: 'Reportar um erro', value: 'error' },
        { text: 'Homologação', value: 'homolog' }
      ],
      whatsappOptions: [
        { text: 'Sim', value: 'yes' },
        { text: 'Não', value: 'no' }
      ],
      rules: {
        whatsappFormat: v => !v || /^(55)?[1-9][0-9]{9,10}$/.test(v) || 'Formato inválido. Digite apenas números com DDD'
      }
    }),
    computed: {
      ...mapState('WiHelpDialog', ['active', 'title']),
      subject: {
        get () {
          return this.$store.state.WiHelpDialog.subject
        },
        set (value) {
          this.$store.commit('WiHelpDialog/SET_SUBJECT', value)
        }
      },
      message: {
        get () {
          return this.$store.state.WiHelpDialog.message
        },
        set (value) {
          this.$store.commit('WiHelpDialog/SET_MESSAGE', value)
        }
      },
      system () {
        return this.$store.state.system
      },
      user () {
        return this.$store.state.User
      },
      callback () {
        console.log(this.$store.state.WiHelpDialog)
        return this.$store.state.WiHelpDialog.callback
      },
      wantWhatsapp: {
        get () {
          return this.$store.state.WiHelpDialog.wantWhatsapp
        },
        set (value) {
          this.$store.commit('WiHelpDialog/SET_WANT_WHATSAPP', value)
        }
      },
      whatsappNumber: {
        get () {
          return this.$store.state.WiHelpDialog.whatsappNumber
        },
        set (value) {
          this.$store.commit('WiHelpDialog/SET_WHATSAPP_NUMBER', value)
        }
      }
    },
    methods: {
      ...mapActions('WiHelpDialog', ['send', 'close']),
      sendHelpDialogContent: function () {
        this.message = this.makeHelpMessage()
        this.send()
      },
      makeHelpMessage: function () {
        var messageData = ':money_mouth_face: \n\n'
        messageData = '*' + this.system.name + '* (' + this.system.version + ') : \n\n'
        if (this.user && this.user.profile && this.user.profile.id) {
          let user = this.user
          messageData += '*Usuário ID*: ' + user.id + '\n'
          messageData += '*Usuário Nome*: ' + user.name + '\n'
          messageData += '*Usuário Perfil ID*: ' + user.profile.id + '\n'
          messageData += '*Usuário Perfil*: ' + user.profile.name + '\n'
          messageData += '*Usuário IP*: ' + user.last_ip + '\n'
          messageData += '*Versão do sistema*: ' + user.version + '\n'
        }
        messageData += '*URL Local*: ' + window.location.href + '\n\n'
        messageData += '*Assunto*: ' + this.getSubjectText(this.subject) + '\n'
        messageData += '*Mensagem*: ' + this.message + '\n\n'
        if (this.wantWhatsapp === 'yes' && this.whatsappNumber) {
          messageData += '*Retorno via WhatsApp*: Sim\n'
          messageData += '*Número WhatsApp*: ' + this.whatsappNumber + '\n\n'
        }
        messageData += '*Horário local*: ' + new Date().toLocaleString()
        return messageData
      },
      getSubjectText: function (subjectValue) {
        const subject = this.subjects.find(sub => sub.value == subjectValue)
        return subject ? subject.text : subjectValue
      },
      formatWhatsappNumber(value) {
        if (!value) return

        // Remove todos os caracteres não numéricos
        let numbers = value.replace(/\D/g, '')

        // Remove o 55 inicial se existir
        if (numbers.startsWith('55')) {
          numbers = numbers.substring(2)
        }

        // Limita o tamanho para 11 dígitos (DDD + número)
        numbers = numbers.substring(0, 11)

        // Valida se começa com DDD válido (2-9)
        if (numbers.length > 0 && !/^[2-9]/.test(numbers)) {
          numbers = ''
        }

        // Atualiza o valor formatado
        this.$store.commit('WiHelpDialog/SET_WHATSAPP_NUMBER', numbers)
      }
    }
  }
</script>
