import url from '@/default/store/modules/url/url'

const system = url.state.system

export default [
  {
    id: '800034c4',
    icon: 'space_dashboard',
    title: 'Service Desk',
    route: {
      name: 'service-desk-dashboard'
    }
  },
  system === 'wise-view' && {
    id: 'b87a2ece',
    icon: 'leak_add',
    title: 'Chamados por Tipo de Rede',
    route: {
      name: 'tickets-by-network-type-dashboard',
      params: {
        id: 3
      }
    }
  },
  {
    id: '80953a95',
    icon: 'space_dashboard',
    title: 'Dashboard de Ordens de Serviço',
    route: {
      name: 'orders-dashboard'
    }
  }
]
