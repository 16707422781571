<template>
  <v-card v-if="category" class="selected-category" elevation="10">
    <div class="category-header" :style="{ backgroundColor: category.color + '15' }">
      <div class="header-content">
        <v-icon :color="category.color" size="36">{{ category.icon }}</v-icon>
        <div class="category-title">
          <h2>{{ category.name }}</h2>
          <div class="category-path" v-if="category.parents.length">
            <div v-for="parent in orderedParents" :key="parent.id" class="path-item">
              <v-icon small color="rgba(0, 0, 0, 0.4)" class="path-icon">subdirectory_arrow_right</v-icon>
              <span class="parent-name">
                <span class="level-badge">{{ parent.level }}</span>
                {{ parent.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="action-buttons">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="action-button edit-button"
              @click="handleEdit"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>Editar categoria</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="action-button add-button"
              @click="handleAddChild"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Adicionar subcategoria</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="action-button delete-button"
              
              @click="handleDelete"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>Excluir categoria</span>
        </v-tooltip>
      </div>
    </div>

    <div class="category-content">
      <div class="info-grid">
        <div class="info-item">
          <div class="info-label">ID</div>
          <div class="info-value">{{ category.id }}</div>
        </div>
        
        <div class="info-item">
          <div class="info-label">Nível</div>
          <div class="info-value">{{ category.level }}</div>
        </div>

        <div class="info-item" v-if="category.children_count">
          <div class="info-label">Subcategorias diretas</div>
          <div class="info-value">{{ category.children_count }}</div>
        </div>

        <div class="info-item" v-if="category.all_children_count">
          <div class="info-label">Total de subcategorias</div>
          <div class="info-value">{{ category.all_children_count }}</div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'SelectedCategory',
  props: {
    category: {
      type: Object,
      default: null
    }
  },
  computed: {
    orderedParents() {
      return [...this.category.parents]
        .sort((a, b) => a.level - b.level)
        .reverse()
    }
  },
  methods: {
    handleEdit() {
      this.$emit('edit-category', this.category)
    },
    handleAddChild() {
      this.$emit('add-child-category', this.category)
    },
    handleDelete() {
      this.$emit('delete-category', this.category)
    }
  }
}
</script>

<style scoped>
.selected-category {
  border-radius: 16px !important;
  overflow: hidden;
}

.theme--dark.selected-category {
  background-color: #1E1E1E !important;
}

.category-header {
  padding: 24px;
  transition: background-color 0.3s ease;
}

.theme--dark .category-header {
  background-color: #272727 !important;
}

.header-content {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
}

.category-title {
  flex: 1;
}

.category-title h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.theme--dark .category-title h2 {
  color: rgba(255, 255, 255, 0.87);
}

.category-path {
  margin-top: 12px;
  padding-left: 8px;
  border-left: 2px solid rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.theme--dark .category-path {
  border-left-color: rgba(255, 255, 255, 0.08);
}

.path-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
}

.theme--dark .path-item {
  color: rgba(255, 255, 255, 0.6);
}

.parent-name {
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
  /* cursor: pointer; */
  position: relative;
  background: rgba(0, 0, 0, 0.04);
}

.theme--dark .parent-name {
  background: rgba(255, 255, 255, 0.04);
}

.parent-name:hover {
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.87);
  transform: translateX(4px);
}

.theme--dark .parent-name:hover {
  background: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.87);
}

.breadcrumb {
  margin-top: 8px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.breadcrumb-item {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.parent-name {
  transition: all 0.2s ease;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.parent-name:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.87);
}

.breadcrumb-separator {
  margin: 0 4px;
  opacity: 0.5;
  font-size: 18px !important;
}

.breadcrumb-item.current {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 600px) {
  .breadcrumb {
    font-size: 0.75rem;
  }
  
  .breadcrumb-separator {
    font-size: 16px !important;
  }
}

.action-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  /* margin-left: 60px; */
}

.category-content {
  padding: 24px;
}

.theme--dark .category-content {
  background-color: #1E1E1E;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

@media (max-width: 1264px) {
  .info-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 960px) {
  .info-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .info-grid {
    grid-template-columns: 1fr;
  }
}

.info-item {
  background: #f5f5f5;
  padding: 16px;
  border-radius: 12px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.theme--dark .info-item {
  background: #272727;
}

.info-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}

.theme--dark .info-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  background: #323232;
}

.info-label {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
}

.theme--dark .info-label {
  color: rgba(255, 255, 255, 0.6);
}

.info-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark .info-value {
  color: rgba(255, 255, 255, 0.87);
}

/* Animações */
.selected-category {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Estilos dos botões de ação */
.action-button {
  transition: all 0.3s ease;
}

.action-button:hover {
  transform: scale(1.1);
}

.edit-button {
  background-color: rgba(72, 137, 242, 0.1) !important;
}

.edit-button:hover {
  background-color: rgba(72, 137, 242, 0.2) !important;
}

.edit-button .v-icon {
  color: #4889f2 !important;
}

.add-button {
  background-color: rgba(76, 175, 80, 0.1) !important;
}

.add-button:hover {
  background-color: rgba(76, 175, 80, 0.2) !important;
}

.add-button .v-icon {
  color: #4CAF50 !important;
}

.delete-button {
  background-color: rgba(244, 67, 54, 0.1) !important;
}

.delete-button:hover {
  background-color: rgba(244, 67, 54, 0.2) !important;
}

.delete-button .v-icon {
  color: #F44336 !important;
}

.delete-button:disabled {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.delete-button:disabled .v-icon {
  color: rgba(0, 0, 0, 0.26) !important;
}

.parent-name::before {
  content: '';
  position: absolute;
  left: -2px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: transparent;
  transition: background-color 0.2s ease;
}

.parent-name:hover::before {
  background: currentColor;
}

@media (max-width: 600px) {
  .category-path {
    margin-top: 8px;
  }

  .path-item {
    font-size: 0.75rem;
  }

  .path-icon {
    font-size: 16px !important;
  }
}

.level-badge {
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.6);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-right: 8px;
  font-weight: 500;
}

.theme--dark .level-badge {
  background: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.6);
}

.parent-name:hover .level-badge {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark .parent-name:hover .level-badge {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
}
</style> 