<template>
  <v-card class="chamado-card" @click="navigateToChamado">
    <!-- Header com Código e SLA -->
    <div class="card-header">
      <v-tooltip bottom lazy>
        <template v-slot:activator="{ on }">
          <div class="codigo" v-on="on">#{{ chamado.id }}</div>
        </template>
        <span>Código do chamado: #{{ chamado.id }}</span>
      </v-tooltip>
      <template v-if="chamado.sla">
        <div class="sla-wrapper">
          <v-tooltip bottom lazy>
            <template v-slot:activator="{ on }">
              <span class="sla-badge" :style="slaStyle" v-on="on">
                SLA: {{ chamado.sla }}%
              </span>
            </template>
            <span>Nível de Serviço (SLA): {{ chamado.sla }}% do tempo consumido</span>
          </v-tooltip>
        </div>
      </template>
    </div>

    <!-- Barra de Progresso SLA -->
    <v-progress-linear
      v-if="chamado.sla"
      height="2"
      :color="slaColor"
      v-model="chamado.sla"
      class="sla-progress"
    ></v-progress-linear>

    <!-- Agendamento se existir -->
    <template v-if="chamado.deadline">
      <div class="deadline-row">
        <v-tooltip bottom lazy>
          <template v-slot:activator="{ on }">
            <div class="deadline-info" v-on="on">
              <div class="deadline-date">
                <v-icon small :color="deadlineColor">schedule</v-icon>
                <span :style="{ color: deadlineColor }">
                  {{ chamado.deadline_formated }}
                </span>
              </div>
              <span class="deadline-status" :style="deadlineStyle">
                {{ deadlineStatusText }}
              </span>
            </div>
          </template>
          <span>
            {{ deadlineTooltipText }}
          </span>
        </v-tooltip>
      </div>
    </template>

    <!-- Informações principais -->
    <div class="card-body">
      <template v-if="chamado.assunto_chamado">
        <div class="info-row assunto">
          <v-icon small color="#1C8ADB">chevron_right</v-icon>
          <span class="text-truncate">{{ chamado.assunto_chamado }}</span>
        </div>
      </template>

      <div class="info-row">
        <v-icon small color="#1C8ADB">person</v-icon>
        <span class="text-truncate">{{ chamado.nome_pessoa }}</span>
      </div>

      <div class="info-row categoria">
        <v-icon small :color="chamado.cor_categoria">{{ chamado.icone_categoria }}</v-icon>
        <div class="categoria-container">
          <div class="categoria-tree">
            <div v-for="(cat, index) in todasCategorias" 
                 :key="index" 
                 class="categoria-item"
                 :style="{ paddingLeft: index === 0 ? '0' : '12px' }">
              <v-icon v-if="index > 0" 
                      x-small 
                      class="categoria-arrow">
                subdirectory_arrow_right
              </v-icon>
              <span class="categoria-nome">{{ cat }}</span>
            </div>
          </div>
        </div>
      </div>

      <template v-if="chamado.grupos">
        <div class="info-row grupos">
          <v-icon small color="grey">mdi-network</v-icon>
          <span class="text-truncate">{{ gruposResumidos }}</span>
          <v-tooltip bottom lazy>
            <template v-slot:activator="{ on }">
              <v-icon 
                small 
                color="grey lighten-1"
                class="info-icon"
                @click.stop="showGruposDialog = true"
                v-on="on"
              >
                info
              </v-icon>
            </template>
            <span>Clique para ver todas as redes envolvidas</span>
          </v-tooltip>

          <v-dialog
            v-model="showGruposDialog"
            max-width="400"
            @click:outside="showGruposDialog = false"
            lazy
          >
            <v-card v-if="showGruposDialog">
              <v-card-title class="headline">
                Redes Envolvidas
                <v-spacer></v-spacer>
                <v-icon @click="showGruposDialog = false">close</v-icon>
              </v-card-title>
              <v-card-text>
                <div class="grupos-list">
                  <div v-for="(grupo, index) in gruposList" 
                       :key="index"
                       class="grupo-item"
                  >
                    <v-icon small color="grey" class="mr-2">mdi-network</v-icon>
                    {{ grupo }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </div>

    <!-- Footer com anexos -->
    <template v-if="chamado.num_anexos">
      <div class="attachment-info">
        <v-tooltip bottom lazy>
          <template v-slot:activator="{ on }">
            <div v-on="on" style="display: flex; align-items: center; gap: 4px;">
              <v-icon small>attachment</v-icon>
              <span>{{ chamado.num_anexos }}</span>
            </div>
          </template>
          <span>{{ chamado.num_anexos }} arquivo(s) anexado(s)</span>
        </v-tooltip>
      </div>
    </template>
  </v-card>
</template>

<script>
export default {
  name: 'ChamadoCard',
  props: {
    chamado: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showGruposDialog: false
    }
  },
  computed: {
    slaColor() {
      return this.chamado.sla > 99 ? '#c90000' : (this.chamado.sla > 85) ? '#e59100' : 'green'
    },
    slaStyle() {
      return {
        padding: '0px 3px',
        background: this.slaColor,
        color: 'white'
      }
    },
    deadlineColor() {
      if (!this.chamado.deadline_seconds) return 'grey';
      return this.chamado.deadline_seconds < 0 ? '#c90000' : 
             this.chamado.deadline_seconds < 86400 ? '#e59100' : '#1976d2';
    },
    deadlineStyle() {
      return {
        background: this.deadlineColor,
        color: 'white',
        padding: '2px 6px',
        borderRadius: '3px',
        fontSize: '0.75rem',
        fontWeight: '500',
        display: 'inline-flex',
        alignItems: 'center',
        height: '20px'
      }
    },
    deadlineStatusText() {
      if (!this.chamado.deadline_seconds) return 'Sem prazo';
      const days = Math.abs(Math.floor(this.chamado.deadline_seconds / 86400));
      const hours = Math.abs(Math.floor((this.chamado.deadline_seconds % 86400) / 3600));
      
      if (this.chamado.deadline_seconds < 0) {
        return days > 0 ? `${days}d atraso` : `${hours}h atraso`;
      }
      if (days === 0) {
        if (hours === 0) return 'Prazo hoje';
        return `${hours}h restantes`;
      }
      return `${days}d restantes`;
    },
    deadlineTooltipText() {
      if (!this.chamado.deadline_seconds) return 'Chamado sem prazo definido';
      const status = this.chamado.deadline_seconds < 0 ? 'Atrasado desde' : 'Prazo final';
      return `${status}: ${this.chamado.deadline_formated}`;
    },
    todasCategorias() {
      return this.chamado.nome_categoria_grupos.split(' > ').map(cat => cat.trim());
    },
    gruposList() {
      if (!this.showGruposDialog) return []; // Só processa quando necessário
      return this.chamado.grupos.split(',').map(g => g.trim());
    },
    gruposResumidos() {
      const grupos = this.chamado.grupos.split(',').map(g => g.trim());
      if (grupos.length <= 2) return this.chamado.grupos;
      return `${grupos[0]}, ${grupos[1]} +${grupos.length - 2}`;
    }
  },
  methods: {
    navigateToChamado() {
      this.$router.push({
        name: 'chamado',
        params: { id: this.chamado.id }
      })
    }
  }
}
</script>

<style scoped>
.chamado-card {
  min-width: 271px;
  max-width: 271px;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 5px !important;
  overflow: hidden;
}

.chamado-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.12) !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.codigo {
  font-weight: 600;
  font-size: 0.9rem;
}

.sla-wrapper {
  text-align: right;
  min-width: 60px;
}

.sla-badge {
  font-size: 0.8rem;
  padding: 2px 6px;
  border-radius: 3px;
  font-weight: 500;
}

.agendamento-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.agendamento-badge {
  font-size: 0.8rem;
  padding: 2px 6px;
  border-radius: 3px;
}

.agendamento-dias {
  font-size: 0.8rem;
}

.card-body {
  padding: 4px 8px;
}

.info-row {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
  font-size: 0.85rem;
}

.info-row .v-icon {
  flex-shrink: 0;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grupos {
  position: relative;
}

.info-icon {
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.info-icon:hover {
  opacity: 1;
}

.grupos-list {
  max-height: 300px;
  overflow-y: auto;
}

.grupo-item {
  padding: 8px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.grupo-item:last-child {
  border-bottom: none;
}

/* Tema escuro */
.theme--dark .grupo-item {
  border-color: rgba(255, 255, 255, 0.05);
}

.attachment-info {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  font-size: 0.8rem;
  opacity: 0.8;
  border-top: 1px solid rgba(0,0,0,0.05);
}

/* Suporte ao tema escuro */
.theme--dark .card-header,
.theme--dark .agendamento-row,
.theme--dark .attachment-info {
  border-color: rgba(255,255,255,0.05);
}

.theme--dark .chamado-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.3) !important;
}

.sla-progress {
  margin: 0 !important;
}

.categoria {
  align-items: flex-start;
}

.categoria-text {
  font-size: 0.85rem;
  line-height: 1.2;
  word-break: break-word;
  white-space: normal;
}

.categoria-container {
  flex: 1;
  min-width: 0;
}

.categoria-tree {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.categoria-item {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  line-height: 1.2;
  position: relative;
}

.categoria-arrow {
  color: var(--v-primary-base);
  opacity: 0.6;
  margin-right: 4px;
  font-size: 16px !important;
}

.categoria-nome {
  color: var(--v-primary-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categoria-item:first-child .categoria-nome {
  font-weight: 600;
}

.categoria-item:last-child .categoria-nome {
  color: inherit;
}

/* Tema escuro */
.theme--dark .categoria-arrow {
  opacity: 0.4;
}

.theme--dark .categoria-nome {
  opacity: 0.9;
}

.deadline-row {
  padding: 4px 8px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  min-height: 32px;
  display: flex;
  align-items: center;
}

.deadline-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.deadline-date {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.85rem;
  font-weight: 500;
}

/* Tema escuro */
.theme--dark .deadline-row {
  border-color: rgba(255,255,255,0.05);
}
</style> 