<template>
  <div class="talk-loading" :class="{'talk-loading--dialog': mode === 'dialog'}" :data-theme="isDark ? 'dark' : 'light'">
    <!-- Loading centralizado -->
    <div class="talk-loading__center">
      <div class="talk-loading__center-content">
        <v-progress-circular
          indeterminate
          :size="50"
          :width="5"
          color="primary"
        ></v-progress-circular>
        <h2 class="talk-loading__center-text" v-t="'modules.talks.pages.talkView.talk.messages.loading.getMessages'">
        </h2>
      </div>
    </div>

    <div class="talk-loading__main-content" :class="{'talk-loading__main-content--full': !showDrawer}">
      <!-- Header principal -->
      <div class="talk-loading__header" :class="headerClass">
        <div class="talk-loading__avatar pulse"></div>
        <div class="talk-loading__user-info">
          <div class="talk-loading__name-skeleton pulse"></div>
          <div class="talk-loading__status-skeleton pulse"></div>
        </div>
      </div>

      <!-- Subheader de tags -->
      <div class="talk-loading__subheader">
        <div class="talk-loading__tags">
          <div class="talk-loading__tag pulse"></div>
          <div class="talk-loading__tag pulse" style="width: 80px;"></div>
          <div class="talk-loading__tag pulse" style="width: 120px;"></div>
        </div>
      </div>

      <!-- Messages container -->
      <div class="talk-loading__messages">
        <!-- Mensagens recebidas (esquerda) -->
        <div class="talk-loading__message-left">
          <div class="talk-loading__message-avatar pulse"></div>
          <div class="talk-loading__message-content">
            <div class="talk-loading__message-bubble pulse"></div>
            <div class="talk-loading__message-bubble pulse" style="width: 60%"></div>
          </div>
        </div>

        <!-- Mensagens enviadas (direita) -->
        <div class="talk-loading__message-right">
          <div class="talk-loading__message-content">
            <div class="talk-loading__message-bubble pulse" style="width: 70%"></div>
          </div>
        </div>

        <!-- Mais mensagens recebidas -->
        <div class="talk-loading__message-left">
          <div class="talk-loading__message-avatar pulse"></div>
          <div class="talk-loading__message-content">
            <div class="talk-loading__message-bubble pulse" style="width: 85%"></div>
            <div class="talk-loading__message-bubble pulse" style="width: 40%"></div>
          </div>
        </div>

        <!-- Mais mensagens enviadas -->
        <div class="talk-loading__message-right">
          <div class="talk-loading__message-content">
            <div class="talk-loading__message-bubble pulse" style="width: 55%"></div>
            <div class="talk-loading__message-bubble pulse" style="width: 75%"></div>
          </div>
        </div>

        <!-- Última mensagem recebida -->
        <div class="talk-loading__message-left">
          <div class="talk-loading__message-avatar pulse"></div>
          <div class="talk-loading__message-content">
            <div class="talk-loading__message-bubble pulse" style="width: 65%"></div>
          </div>
        </div>


      </div>

      <!-- Footer skeleton -->
      <div class="talk-loading__footer">
        <div class="talk-loading__input pulse"></div>
        <div class="talk-loading__send-btn pulse"></div>
      </div>
    </div>

    <!-- Right drawer skeleton -->
    <div v-if="showDrawer" class="talk-loading__drawer">
      <!-- Drawer header -->
      <div class="talk-loading__drawer-toolbar" :class="headerClass">
        <div class="talk-loading__drawer-toolbar-content pulse"></div>
      </div>

      <!-- Drawer content -->
      <div class="talk-loading__drawer-body">
        <div class="talk-loading__drawer-header">
          <div class="talk-loading__drawer-avatar pulse"></div>
          <div class="talk-loading__drawer-name pulse"></div>
        </div>
        <div class="talk-loading__drawer-content">
          <div class="talk-loading__drawer-line pulse"></div>
          <div class="talk-loading__drawer-line pulse" style="width: 60%"></div>
          <div class="talk-loading__drawer-line pulse" style="width: 75%"></div>
          <div class="talk-loading__drawer-line pulse" style="width: 40%"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TalkLoading',
  props: {
    mode: {
      type: String,
      default: 'page'
    },
    showDrawer: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isDark() {
      return this.$wiDark
    },
    headerClass() {
      return {
        'primary': !this.isDark,
        'white--text': !this.isDark
      }
    }
  }
}
</script>

<style scoped>
.talk-loading {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 48px);
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
}

/* Adicionando suporte ao tema dark */
.talk-loading[data-theme="dark"] {
  background-color: #121212;
}

.talk-loading--dialog {
  height: calc(100vh - 97px);
}

@media (max-width: 960px) {
  .talk-loading {
    height: calc(100vh - 48px);
  }

  .talk-loading--dialog {
    height: calc(100vh - 93px);
  }
}

.talk-loading__main-content {
  display: grid;
  grid-template-rows: 48px 40px 1fr auto;
  width: calc(100% - 350px);
  height: 100%;
}

.talk-loading__main-content--full {
  width: 100%;
}

.talk-loading__messages {
  padding: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  background: white;
  background-repeat: repeat;
}

.talk-loading[data-theme="dark"] .talk-loading__messages {
  background: #2d2d2d;
}

.talk-loading__drawer {
  width: 350px;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.talk-loading[data-theme="dark"] .talk-loading__drawer {
  background: #1E1E1E;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
}

@media (max-width: 960px) {
  .talk-loading__drawer {
    display: none;
  }

  .talk-loading__main-content {
    width: 100%;
  }
}

.talk-loading__drawer-toolbar {
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 0 16px;
  flex-shrink: 0;
}

.talk-loading[data-theme="dark"] .talk-loading__drawer-toolbar {
  background: #1E1E1E;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.talk-loading__drawer-toolbar-content {
  height: 24px;
  width: 100%;
  background: #bdbdbd;
  border-radius: 4px;
}

.talk-loading__drawer-body {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.talk-loading__drawer-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.talk-loading__footer {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 16px;
  background: white;
  gap: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.talk-loading[data-theme="dark"] .talk-loading__footer {
  background: #1E1E1E;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.talk-loading__header {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 48px;
  position: relative;
  z-index: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.talk-loading[data-theme="dark"] .talk-loading__header {
  background: #1E1E1E;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.talk-loading__subheader {
  height: 48px;
  background: white;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

.talk-loading[data-theme="dark"] .talk-loading__subheader {
  background: #262626;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.talk-loading__tags {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}

.talk-loading__tag {
  height: 24px;
  width: 100px;
  background: #bdbdbd;
  border-radius: 12px;
}

.talk-loading__avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #bdbdbd;
  margin-right: 12px;
  flex-shrink: 0;
}

.talk-loading__user-info {
  flex: 1;
}

.talk-loading__name-skeleton {
  height: 14px;
  width: 150px;
  background: #bdbdbd;
  border-radius: 4px;
  margin-bottom: 4px;
}

.talk-loading__status-skeleton {
  height: 12px;
  width: 80px;
  background: #d4d4d4;
  border-radius: 4px;
}

.talk-loading__message-left,
.talk-loading__message-right {
  display: flex;
  align-items: flex-start;
  max-width: 80%;
  margin-bottom: 24px;
}

.talk-loading__message-right {
  margin-left: auto;
  justify-content: flex-end;
}

.talk-loading__message-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #bdbdbd;
  margin-right: 8px;
  flex-shrink: 0;
}

.talk-loading__message-bubble {
  height: 32px;
  border-radius: 16px;
  margin-bottom: 4px;
  min-width: 200px;
}

/* Estiliza os balões da esquerda (recebidos) */
.talk-loading__message-left .talk-loading__message-bubble {
  border-top-left-radius: 4px;
  background: #e3e3e3;
}

/* Estiliza os balões da direita (enviados) */
.talk-loading__message-right .talk-loading__message-bubble {
  border-top-right-radius: 4px;
  background: #c8e6c9;
}

.talk-loading__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.talk-loading__center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background: rgba(255, 255, 255, 0.95);
  padding: 32px 48px;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(8px);
}

.talk-loading[data-theme="dark"] .talk-loading__center-content {
  background: rgba(30, 30, 30, 0.95);
}

.talk-loading__center-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 25px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  line-height: 1.3;
}

.talk-loading[data-theme="dark"] .talk-loading__center-text {
  color: rgba(255, 255, 255, 0.87);
}

.talk-loading__input {
  flex: 1;
  height: 40px;
  background: #bdbdbd;
  border-radius: 20px;
}

.talk-loading__send-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #bdbdbd;
  flex-shrink: 0;
}

.talk-loading__drawer-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #bdbdbd;
  margin-bottom: 16px;
}

.talk-loading__drawer-name {
  height: 20px;
  width: 180px;
  background: #bdbdbd;
  border-radius: 4px;
}

.talk-loading__drawer-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.talk-loading__drawer-line {
  height: 14px;
  width: 100%;
  background: #d4d4d4;
  border-radius: 4px;
}

/* Animação de pulse para os skeletons */
.pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.8;
  }
}

.talk-loading[data-theme="dark"] .talk-loading__message-left .talk-loading__message-bubble {
  background: #424242;
}

.talk-loading[data-theme="dark"] .talk-loading__message-right .talk-loading__message-bubble {
  background: #1B5E20;
}

.talk-loading[data-theme="dark"] .talk-loading__center-content {
  background: rgba(30, 30, 30, 0.95);
}

.talk-loading[data-theme="dark"] .talk-loading__center-text {
  color: rgba(255, 255, 255, 0.87);
}
</style> 