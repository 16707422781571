import { BusinessCategory } from '@/modules/business/config/BusinessCategory'

class SalesCategory extends BusinessCategory {
  constructor() {
    super()
    this.id = 'a02e979e'
  }
}

export class SaleCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Venda'
    this.apiUrl = 'sales/config/category/sale'
  }
}

export class BudgetCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Orçamento'
    this.apiUrl = 'sales/config/category/budget'

    if (this.permissionIsAvaiable('185a4bb9')) {
      this.clearFields()
      this.setFields(this.fieldsList)
      this.setFields(this.fieldsEdit)
    }
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, cor e ícone desta categoria.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da Categoria',
      size: ['xs12', 'sm6', 'md3', 'lg3']
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor',
      size: ['xs12', 'sm6', 'md1', 'lg1']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Categoria',
      size: ['xs12', 'sm6', 'md2', 'lg2']
    }),
    // form: this.fieldWiListDialog({
    //   value: 'form_id',
    //   text: 'Formulário Complementar',
    //   required: false,
    //   size: ['xs12', 'sm12', 'md3', 'lg3'],
    //   wiConfig: 'service-desk-config-form',
    //   show: 'form.name',
    //   icon: 'list_alt'
    // }),
    bank_id: this.fieldSelectApi({
      value: 'bank_id',
      text: 'Banco',
      api: {
        url: 'sales/config/bank',
        params: {
          fields: 'id,name',
          where: 'is_active,1',
          order: 'order,asc'
        }
      },
      show: 'name',
      size: ['xs12', 'sm6', 'md3', 'lg3'],
      change: ({ form, selected }) => {
        console.log(form, selected)
        // this.form.bank_product_id = null
        // this.form.bank_product_id = selected
        // this.$forceUpdate()
      }
    }),
    bank_product_id: this.fieldSelectApi({
      value: 'bank_product_id',
      text: 'Produto do Banco',
      api: {
        url: 'sales/config/bank-product',
        params: form => ({
          fields: 'id,name',
          bank_id: `${form.bank_id}`,
          order: 'order,asc'
        })
      },
      show: 'name',
      disabled: form => !form.bank_id,
      size: ['xs12', 'sm6', 'md3', 'lg3'],
      dependOn: ['bank_id'],
      showField: form => form.bank_id
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Crie um layout de descrição para esta categoria.'
    }),
    layout: this.fieldTinyEditor({
      value: 'layout',
      text: 'Layout desta categoria',
      placeholder: 'Crie a estrutura da categoria.'
    })
  }
}

export class ContractCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Contrato'
    this.apiUrl = 'sales/config/category/contract'
  }
}

export class GuaranteeCategory extends SalesCategory {
  constructor() {
    super()
    this.title.list = 'Categorias de Termo de Garantia'
    this.apiUrl = 'sales/config/category/guarantee'
  }
}
