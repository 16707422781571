<template>
  <v-card 
    flat
    class="wn-audio-player elevation-0" 
    :class="{ 'is-playing': isPlaying }"
  >
    <v-card-text class="pa-2">
      <div class="player-main">
        <!-- Botão play/pause -->
        <v-btn 
          icon 
          small
          :color="isPlaying ? 'primary' : 'primary'"
          @click="togglePlay"
        >
          <v-icon>{{ isPlaying ? 'pause' : 'play_arrow' }}</v-icon>
        </v-btn>

        <!-- Waveform e Timeline -->
        <div class="player-content">
          <div class="timeline-container" ref="timelineContainer" @click="seek">
            <div class="timeline-progress" :style="{ width: `${progress}%` }"></div>
            <div class="timeline-background"></div>
          </div>
          
          <!-- Timer -->
          <div class="time-display">
            <span class="current-time">{{ formatTime(currentTime) }}</span>
            <span class="duration">{{ formatTime(duration) }}</span>
          </div>
        </div>

        <!-- Controles à direita (visível apenas em desktop) -->
        <div class="right-controls desktop-only">
          <v-btn 
            flat 
            small 
            class="speed-button ma-0 pa-1" 
            @click="cyclePlaybackRate"
          >
            {{ playbackRate }}x
          </v-btn>

          <v-btn 
            icon 
            small
            :href="`${download}&d=true`" 
            download 
            @click.stop
            class="ma-0"
          >
            <v-icon small>download</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- Controles móveis (visível apenas em mobile) -->
      <div class="mobile-controls">
        <v-btn 
          flat
          block 
          class="mobile-button speed-button" 
          @click="cyclePlaybackRate"
        >
          {{ playbackRate }}x
        </v-btn>

        <v-btn
          flat
          block
          :href="`${download}&d=true`"
          download
          @click.stop
          class="mobile-button"
        >
          <v-icon>download</v-icon>
        </v-btn>
      </div>
    </v-card-text>

    <!-- Audio Element (hidden) -->
    <audio
      ref="audio"
      :src="src"
      @timeupdate="onTimeUpdate"
      @loadedmetadata="onLoadedMetadata"
      @ended="onEnded"
      @play="onPlay"
      @pause="onPause"
    ></audio>
  </v-card>
</template>

<script>
export default {
  name: 'WNAudioPlayer',
  
  props: {
    src: {
      type: String,
      required: true
    },
    download: {
      type: String,
      required: false
    }
  },

  data: () => ({
    isPlaying: false,
    duration: 0,
    currentTime: 0,
    progress: 0,
    playbackRate: 1,
    playbackRates: [1, 1.5, 2, 0.5]
  }),

  created() {
    // Carrega a velocidade salva ao criar o componente
    const savedRate = localStorage.getItem('audioPlayerPlaybackRate');
    if (savedRate) {
      this.playbackRate = parseFloat(savedRate);
    }
  },

  mounted() {
    // Aplica a velocidade ao elemento de áudio quando montado
    this.$nextTick(() => {
      if (this.$refs.audio) {
        this.$refs.audio.playbackRate = this.playbackRate;
      }
    });
  },

  methods: {
    togglePlay() {
      if (this.isPlaying) {
        this.$refs.audio.pause();
      } else {
        this.$refs.audio.play();
      }
    },

    cyclePlaybackRate() {
      const currentIndex = this.playbackRates.indexOf(this.playbackRate);
      const nextIndex = (currentIndex + 1) % this.playbackRates.length;
      this.playbackRate = this.playbackRates[nextIndex];
      this.$refs.audio.playbackRate = this.playbackRate;
      
      // Salva a nova velocidade no localStorage
      localStorage.setItem('audioPlayerPlaybackRate', this.playbackRate.toString());
    },

    seek(event) {
      const container = this.$refs.timelineContainer;
      const rect = container.getBoundingClientRect();
      const percent = (event.clientX - rect.left) / rect.width;
      const time = percent * this.duration;
      this.$refs.audio.currentTime = time;
    },

    formatTime(seconds) {
      const mins = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${mins}:${secs.toString().padStart(2, '0')}`;
    },

    onTimeUpdate() {
      const audio = this.$refs.audio;
      this.currentTime = audio.currentTime;
      this.progress = (audio.currentTime / audio.duration) * 100;
    },

    onLoadedMetadata() {
      this.duration = this.$refs.audio.duration;
    },

    onEnded() {
      this.isPlaying = false;
      this.progress = 0;
    },

    onPlay() {
      this.isPlaying = true;
    },

    onPause() {
      this.isPlaying = false;
    }
  }
};
</script>

<style scoped>
.wn-audio-player {
  border-radius: 24px !important;
  width: 100%;
  /* max-width: 400px; */
}

/* Tema claro (padrão) */
.theme--light .wn-audio-player {
  background: #f0f2f5 !important;
}

.theme--light .timeline-container {
  background: #e1e1e1;
}

.theme--light .time-display,
.theme--light .speed-button,
.theme--light .download-button {
  color: #54656f;
}

.theme--light .speed-button:hover,
.theme--light .download-button:hover {
  background: rgba(0, 0, 0, 0.05);
}

.theme--light .mobile-button {
  background: rgba(0, 0, 0, 0.05);
}

/* Tema escuro */
.theme--dark .wn-audio-player {
  background: #2a2f32 !important;
}

.theme--dark .timeline-container {
  background: #404449;
}

.theme--dark .time-display,
.theme--dark .speed-button,
.theme--dark .download-button {
  color: #aebac1;
}

.theme--dark .speed-button:hover,
.theme--dark .download-button:hover {
  background: rgba(255, 255, 255, 0.05);
}

.theme--dark .mobile-button {
  background: rgba(255, 255, 255, 0.1);
}

/* Estilos comuns que não dependem do tema */
.player-main {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 48px;
}

.speed-button {
  min-width: 48px !important;
  letter-spacing: normal !important;
}

.mobile-button {
  height: 40px;
  border-radius: 8px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  min-width: 48% !important;
}

.player-content {
  flex: 1;
  min-width: 0;
}

.timeline-container {
  position: relative;
  height: 4px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 4px;
}

.timeline-progress {
  position: absolute;
  height: 100%;
  background: #00a884;
  border-radius: 2px;
  transition: width 0.1s linear;
}

.time-display {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.right-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Mobile Controls */
.mobile-controls {
  display: none;
  padding-top: 8px;
  gap: 8px;
  justify-content: space-around;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .desktop-only {
    display: none;
  }

  .mobile-controls {
    display: flex;
  }
}
</style> 