export const PERMISSION_STOCK_MODULE = 'b0ce8f81-78ff-4d79-a9f3-d7415343d2e5';

export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN = 'ddeb28a2';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CREATE = '2ce443c0';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_UPDATE = '8daadae5';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_DELETE = '45834591';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_LIST_OF_OTHER_USERS = 'ffd72bc2';

export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR = 'fcbb4878';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_CREATE = '0fae219a';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_UPDATE = '3efbd1a0';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_DELETE = 'fd81239a';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_COLLABORATOR_LIST_OF_OTHER_USERS = '1243e987';

export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER = '92b77ae3';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER_CREATE = '94bcb218';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER_UPDATE = '7d7a9902';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER_DELETE = 'ab01a1ad';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_IN_CUSTOMER_LIST_OF_OTHER_USERS = '71354228';

export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT = 'fdf1fbac';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CREATE = '573efe93';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_UPDATE = '837e5919';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_DELETE = '03a547d3';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_LIST_OF_OTHER_USERS = 'bed8e5e8';

export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR = '0177ebc5';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR_CREATE = '8d15d0f5';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR_UPDATE = '1c090520';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR_DELETE = '1ae02bff';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_COLLABORATOR_LIST_OF_OTHER_USERS = '3eb3bf3d';

export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER = 'ee1f4e17';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_CREATE = '43123979';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_UPDATE = 'a7bde8a2';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_DELETE = '04135928';
export const PERMISSION_STOCK_PRODUCT_TRANSFER_OUT_CUSTOMER_LIST_OF_OTHER_USERS = 'b174ae2a';


export const PERMISSION_STOCK_PRODUCT_ENTRY = '9e2ff571';
export const PERMISSION_STOCK_PRODUCT_ENTRY_CREATE = 'b40547b2';
export const PERMISSION_STOCK_PRODUCT_ENTRY_UPDATE = '0dba144a';
export const PERMISSION_STOCK_PRODUCT_ENTRY_DELETE = '38f8301f';
export const PERMISSION_STOCK_PRODUCT_ENTRY_LIST_OF_OTHER_USERS = 'd70be73b';

export const PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR = 'b231d083';
export const PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_CREATE = '159d8edd';
export const PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_UPDATE = 'e40b18b8';
export const PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_DELETE = 'e8220a49';
export const PERMISSION_STOCK_PRODUCT_ENTRY_COLLABORATOR_LIST_OF_OTHER_USERS = '0ea4b535';

export const PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER = '8df2aae9';
export const PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_CREATE = 'df1fcff5';
export const PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_UPDATE = '4cd3838e';
export const PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_DELETE = 'ffafc2be';
export const PERMISSION_STOCK_PRODUCT_ENTRY_CUSTOMER_LIST_OF_OTHER_USERS = 'e4a8bde4';

export const PERMISSION_STOCK_PRODUCT_REMOVAL = 'e8181b79';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_CREATE = 'd2e1fb88';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_UPDATE = '7c513c2b';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_DELETE = '3205d9ac';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_LIST_OF_OTHER_USERS = '8a181da3';

export const PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR = '992a4987';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_CREATE = '7ec93d91';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_UPDATE = '8fda0328';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_DELETE = '32e8bc30';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_COLLABORATOR_LIST_OF_OTHER_USERS = '7fb9b320';

export const PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER = '7d32f783';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_CREATE = '8013b34b';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_UPDATE = '797ac00b';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_DELETE = '0eb2d3c5';
export const PERMISSION_STOCK_PRODUCT_REMOVAL_CUSTOMER_LIST_OF_OTHER_USERS = '4d03f89e';


export const PERMISSION_STOCK_REGISTRATION = 'e42ea891'

export const PERMISSION_STOCK_CONFIGURATION = 'aeebefc2'

export const PERMISSION_TICKET_EXPORT_TICKETS = '49944f98';
export const PERMISSION_TICKET_EXPORT_TICKETS_TO_XLSX = '9ec0a917';

export const PERMISSION_TICKET = '5dc282ab'
export const PERMISSION_TICKET_CREATE = '2c491b4c'
export const PERMISSION_TICKET_UPDATE = '1a825af1'
export const PERMISSION_TICKET_LIST_OF_OTHER_USERS = '4aa0482a'

export const PERMISSION_ORDER = '74cc44a0'
export const PERMISSION_ORDER_CREATE = '8e45510b'
export const PERMISSION_ORDER_UPDATE = '18baa289'
export const PERMISSION_ORDER_DELETE = 'e2dd4c31'
export const PERMISSION_ORDER_LIST_OF_OTHER_USERS = '5bb7bdb1'
export const PERMISSION_SERVICE_DESK_EXPORT_ORDERS = '0f5c3cdf'
export const PERMISSION_SERVICE_DESK_EXPORT_ORDERS_TO_XLSX = 'e904521d'

export const PERMISSION_TEST_MANAGEMENT_VTAL_IN = 'a84c3a89'
export const PERMISSION_TEST_MANAGEMENT_VTAL_IN_CREATE = 'b077d059'
export const PERMISSION_TEST_MANAGEMENT_VTAL_IN_UPDATE = 'fc5de984'
export const PERMISSION_TEST_MANAGEMENT_VTAL_IN_DELETE = 'd83fdfbd'


export const PERMISSION_BUDGET = '10b505c9'
export const PERMISSION_BUDGET_CREATE = 'b9fba2ea'
export const PERMISSION_BUDGET_UPDATE = 'd3c48579'
export const PERMISSION_BUDGET_DELETE = '55bf11f3'
export const PERMISSION_BUDGET_LIST_OF_OTHER_USERS = '08d95aba'

export const PERMISSION_SALE = 'fbded5ac'
export const PERMISSION_SALE_CREATE = '22982ab2'
export const PERMISSION_SALE_UPDATE = 'b41dcfec'
export const PERMISSION_SALE_DELETE = '80f80378'
export const PERMISSION_SALE_LIST_OF_OTHER_USERS = 'b3a70cbd'

export const PERMISSION_CONTRACT = '1731ce93'
export const PERMISSION_CONTRACT_CREATE = 'c0bc70b8'
export const PERMISSION_CONTRACT_UPDATE = 'a3eb1322'
export const PERMISSION_CONTRACT_DELETE = 'a2175f11'
export const PERMISSION_CONTRACT_LIST_OF_OTHER_USERS = 'ba8bbd88'

export const PERMISSION_GUARANTEE = '20cb4a0c'
export const PERMISSION_GUARANTEE_CREATE = '07bfd5ac'
export const PERMISSION_GUARANTEE_UPDATE = '9a5adb57'
export const PERMISSION_GUARANTEE_DELETE = '2f812a9f'
export const PERMISSION_GUARANTEE_LIST_OF_OTHER_USERS = '24fa0c34'


export const PERMISSION_JURIDIC_MODULE = 'e80a117e-895f-48be-8c1a-47fd93f5b814'

export const PERMISSION_PROCESS = 'e2a51798'
export const PERMISSION_PROCESS_CREATE = '44f4ac80'
export const PERMISSION_PROCESS_UPDATE = 'db3ace23'
export const PERMISSION_PROCESS_DELETE = '57157ade'


export const PERMISSION_WELLS_MODULE = 'd522cb74-5904-48b3-9e8b-4cb834448b17'

export const PERMISSION_WELL = '38b25faf'
export const PERMISSION_WELL_CREATE = 'e87347cb'
export const PERMISSION_WELL_UPDATE = '4d87fa15'
export const PERMISSION_WELL_DELETE = 'eee440a2'

export const PERMISSION_WELLS_REGISTRATION = '54f8aa8f'

export const PERMISSION_WELLS_CONFIGURATION = '7582a155'



export const PERMISSION_FORMS_MODULE = '201f4ca9-2dbb-4a85-b7ca-87d593c5fed8'

export const PERMISSION_FORM = '2a9e78b0'
export const PERMISSION_FORM_CREATE = 'cc550b47'
export const PERMISSION_FORM_UPDATE = '3358db39'
export const PERMISSION_FORM_DELETE = '2df4c31e'

export const PERMISSION_FORM_ITEM = '75edd950'
export const PERMISSION_FORM_ITEM_CREATE = '7eef299e'
export const PERMISSION_FORM_ITEM_UPDATE = 'd9e0afc4'
export const PERMISSION_FORM_ITEM_DELETE = 'dfca0bd7'

export const PERMISSION_FORM_REPLY = 'd72eee5b'
export const PERMISSION_FORM_REPLY_CREATE = '0b570f54'
export const PERMISSION_FORM_REPLY_UPDATE = '51daccc0'
export const PERMISSION_FORM_REPLY_DELETE = 'e70d14fc'

export const PERMISSION_FORMS_REGISTRATION = '09e03370'

export const PERMISSION_FORMS_CONFIGURATION = '09e03370'



export const PERMISSION_CLINIC_MODULE = '85352c3c-1321-4cba-b009-d5c4dc909159'

export const PERMISSION_REGISTER_MODULE = 'bec249c8-3ddc-4113-8dd5-e558fd30baa8'

export const PERMISSION_REGISTER_CHANNEL = '2bcbe112'
export const PERMISSION_REGISTER_CHANNEL_CREATE = '7c150184'
export const PERMISSION_REGISTER_CHANNEL_UPDATE = '05f7df9d'
export const PERMISSION_REGISTER_CHANNEL_DELETE = '52fc7f93'

export const PERMISSION_REGISTER_IMPORT_CUSTOMER = 'a9eb9517'
export const PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_IMANAGER = 'ada70d8b'
export const PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_HUBSPOT = '82a7fd28'

export const PERMISSION_REGISTER_IMPORT_PRODUCTS = 'bc7f01b4';
export const PERMISSION_REGISTER_IMPORT_PRODUCTS_FROM_XLSX = 'dc2f22e3';

export const PERMISSION_REGISTER_IMPORT_SERVICES = '97990c51';
export const PERMISSION_REGISTER_IMPORT_SERVICES_FROM_XLSX = '0bc34e01';

export const PERMISSION_NETWORK_HOST = 'a090e093'
export const PERMISSION_NETWORK_HOST_CREATE = '55aa9d8c'
export const PERMISSION_NETWORK_HOST_UPDATE = '02c22553'

export const PERMISSION_INTERNET_MODULE = '84489c03-1cbd-4455-98c0-973c08aea582'
export const PERMISSION_INTERNET_REPORTS = '8e0f9bd4'
export const PERMISSION_INTERNET_REGISTRATION = 'fd8e4ff3'
export const PERMISSION_INTERNET_CONFIGURATION = '18cda1f3'

export const PERMISSION_TALKS_DASHBOARD_TALKS = '52bd718a'
export const PERMISSION_TALKS_DASHBOARD_MESSAGES = 'f8422235'

export const PERMISSION_TALKS_TALK_CATEGORY = '4af41ee5';
export const PERMISSION_TALKS_TALK_CATEGORY_CREATE = '4f4ab210';
export const PERMISSION_TALKS_TALK_CATEGORY_UPDATE = '9713ffe3';
export const PERMISSION_TALKS_TALK_CATEGORY_DELETE = '41db7ef7';
export const PERMISSION_TALKS_TALK_CATEGORY_SET_IN_TALK = '1994293a';

export const PERMISSION_REGISTER_ACCOUNT = 'e11b93ee'
export const PERMISSION_REGISTER_ACCOUNT_CREATE = '149e929f'
export const PERMISSION_REGISTER_ACCOUNT_UPDATE = 'f1922793'
export const PERMISSION_REGISTER_ACCOUNT_DELETE = 'bf37ff31'

export const PERMISSION_BUDGET_EXPORT_BUDGETS = '498c7109'
export const PERMISSION_BUDGET_EXPORT_BUDGETS_TO_XLSX = 'd3a5fac1'

export const PERMISSION_SALES_EXPORT_SALES = '2e3d941f'
export const PERMISSION_SALES_EXPORT_SALES_TO_XLSX = '0db1daa0'

export const PERMISSION_STOCK_EXPORT_STOCK_PRODUCTS = 'a5d47e5e'
export const PERMISSION_STOCK_EXPORT_STOCK_PRODUCTS_TO_PDF = '0de303ec';
export const PERMISSION_STOCK_EXPORT_STOCK_PRODUCTS_TO_XLSX = 'dc2ba91a';

export const PERMISSION_PURCHASES_IMPORT_PURCHASE = '50120b72';
export const PERMISSION_PURCHASES_IMPORT_PURCHASE_TO_XML = '4a324925';

export const PERMISSION_TALKS_CAMPAIGN_EXPORT = '39e9e78a';
export const PERMISSION_TALKS_CAMPAIGN_EXPORT_XLSX = '3bdad850';

export const PERMISSION_FINANCE_EXPORT_XLSX = 'dbcf47ec';
export const PERMISSION_FINANCE_EXPORT_IN_XLSX = '29e5d4f4';
export const PERMISSION_FINANCE_EXPORT_OUT_XLSX = '815c37dc';
