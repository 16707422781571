var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"tickets-kanban-toolbar",attrs:{"dense":""}},[_c('div',{staticClass:"toolbar-content"},[_c('div',{staticClass:"status-info"},[_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"status-icon-btn",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('edit-status')}}},on),[_c('v-icon',{style:({ color: _vm.status.cor_status })},[_vm._v(_vm._s(_vm.status.icone_status))])],1)]}}])},[_c('span',[_vm._v("Clique para editar o status")])]),_c('div',{staticClass:"status-name-container"},[_c('span',{staticClass:"status-name"},[_vm._v(_vm._s(_vm.status.nome_status))])])],1),_c('div',{staticClass:"metrics"},[_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"metric-item"},on),[_c('v-icon',{staticClass:"metric-icon",attrs:{"size":"10"}},[_vm._v("list_alt")]),_vm._v(" "+_vm._s(_vm.status.itens_count)+" ")],1)]}}])},[_c('span',[_vm._v("Total de chamados neste status")])]),(_vm.status.itens_acima_sla)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"metric-item alert"},on),[_c('v-icon',{staticClass:"metric-icon",attrs:{"size":"10"}},[_vm._v("schedule")]),_vm._v(" "+_vm._s(_vm.status.itens_acima_sla)+" ")],1)]}}],null,false,4186922017)},[_c('span',[_vm._v("Chamados acima do SLA")])]):_vm._e(),(_vm.status.itens_atrasados)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"metric-item alert"},on),[_vm._v(_vm._s(_vm.status.itens_atrasados))])]}}],null,false,2677276634)},[_c('span',[_vm._v("Chamados atrasados")])]):_vm._e(),(_vm.status.itens_deadline_atrasado)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"metric-item alert"},on),[_c('v-icon',{staticClass:"metric-icon",attrs:{"size":"10"}},[_vm._v("event")]),_vm._v(" "+_vm._s(_vm.status.itens_deadline_atrasado)+" ")],1)]}}],null,false,2469948100)},[_c('span',[_vm._v("Chamados com agendamento atrasado")])]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }