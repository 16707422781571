<template>
  <v-list-tile
    avatar ripple
    class="wn-notification"
    :class="{selected: selected}"
    :to="notification.route(notification)"
  >
    <v-list-tile-avatar class="wn-notification__avatar">
      <img v-if="notification.data.creator && notification.data.creator.image" :src="notification.data.creator.image"/>
      <img v-else src="static/icons/user_icon_1.png"/>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-title class="text--primary">
        <strong 
          v-if="notification.data.creator && notification.data.creator.name"
          v-text="notification.data.creator.name"
        ></strong>
        <span v-else>
          - - -
        </span>
      </v-list-tile-title>
      <v-list-tile-sub-title class="text--primary">
        <span v-html="notification.text(notification)"></span>
      </v-list-tile-sub-title>
    </v-list-tile-content>
    <v-list-tile-action class="wn-notification__action">
      <v-list-tile-action-text class="wn-notification__date">
        {{ notification.created_at | moment('DD/MM/YY HH:mm') }}
      </v-list-tile-action-text>
    </v-list-tile-action>
  </v-list-tile> 
</template>
<script>
  export default {
    name: 'Notification',
    computed: {
      selected () {
        return this.$route.query.notification == this.notification.id
      }
    },
    methods: {
      goRoute: function (route) {
        if (route) {
          this.drawer = false
          this.$router.push(route)
        }
      }
    },
    props: {
      notification: {
        required: true
      }
    }
  }
</script>
<style scoped>
  .selected {
    background: rgb(219, 219, 159);
  }
  .wn-notification {
    padding: 0 !important;
    margin: 0 !important;
  }
  .wn-notification >>> .v-list__tile {
    padding: 8px !important;
    margin: 0 !important;
    height: unset !important;
    min-height: 80px !important;
  }
  .wn-notification__avatar {
    min-width: 50px !important;
    min-height: 50px !important;
  }
  .wn-notification__action {
    padding-top: 1px;
    align-items: start;
  }
  .wn-notification__date {
    font-size: 12px;
    color: #999;
    text-align: right;
  }
</style>