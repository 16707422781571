<template>
  <v-card
    :key="topic.uuid"
    class="topic-card elevation-10"
    :class="{
      'topic-card--dark': $wiDark,
      'topic-card--unread': topic.unread_messages > 0,
      'primary--text': topic.unread_messages > 0
    }"
    hover
  >
    <div class="topic-card__content">
      <!-- Área 1: Ícone/Foto -->
      <div class="topic-card__media">
        <v-avatar v-if="topic.talk_contact && topic.talk_contact.file && topic.talk_contact.file.url" 
          size="44"
          class="topic-card__avatar"
        >
          <v-img :src="topic.talk_contact.file.url" />
        </v-avatar>
        <div v-else class="topic-card__icon">
          <v-icon>topic</v-icon>
        </div>
      </div>

      <!-- Área 2: Conteúdo Principal -->
      <div class="topic-card__main" @click="$emit('click', topic)">
        <div class="topic-card__header">
          <h3 class="topic-card__title" :class="{'white--text': $wiDark}">
            {{ truncateText(topic.title || 'Tópico sem título', 50) }}
          </h3>
        </div>
        
        <div class="topic-card__info">
          <span v-if="topic.talk_contact" class="topic-card__contact">
            {{ truncateText(topic.talk_contact.name, 30) }}
          </span>
          
          <p v-if="topic.description" class="topic-card__description">
            {{ truncateText(topic.description, 80) }}
          </p>

          <p v-if="topic.last_message" class="topic-card__last-message">
            <v-icon small>message</v-icon>
            <span>
              {{ truncateText(topic.last_message.text_html, 60) }}
            </span>
          </p>
        </div>
      </div>

      <!-- Área 3: Data e Ações -->
      <div class="topic-card__actions">
        <time class="topic-card__date">
          {{ formatDate(topic.last_message_at || topic.created_at) }}
        </time>
        <div v-if="topic.unread_messages > 0" class="topic-card__unread">
          <span class="topic-card__unread-badge">
            {{ topic.unread_messages > 99 ? '99+' : topic.unread_messages }}
          </span>
        </div>
        <div class="topic-card__buttons-area">
          <div class="topic-card__buttons">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn 
                  icon outline
                  small
                  v-on="on"
                  @click.stop="$emit('cancel', topic)"
                  class="topic-card__action-btn"
                  color="error"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              <span>Cancelar tópico</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn 
                  outline
                  small icon
                  v-on="on"
                  @click.stop="$emit('complete', topic)"
                  class="topic-card__action-btn"
                  color="success"
                >
                  <v-icon>done</v-icon>
                </v-btn>
              </template>
              <span>Concluir tópico</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ChatTopicCard',
  props: {
    topic: {
      type: Object,
      required: true
    }
  },
  methods: {
    truncateText(text, length) {
      if (!text) return '';
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
    formatDate(time) {
      if (time) {
        const date = this.$moment(time)
        const today = this.$moment().startOf('day')
        const days = Math.round(this.$moment?.duration(today - date).asDays())
        if (days > 1) {
          return this.$moment(time).format('DD/MM HH:mm')
        }
        return this.$moment(time).fromNow()
      } else if (time === null) {
        return '-'
      } else {
        return 'Agora'
      }
    }
  }
}
</script>

<style scoped>
.topic-card {
  margin-bottom: 12px;
  border-radius: 12px !important;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border-left: 4px solid transparent;
  z-index: 1;
}

.topic-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.topic-card--dark {
  background-color: #1E1E1E !important;
  border-color: rgba(255, 255, 255, 0.05);
}

.topic-card--unread {
  border-left-color: #1976D2;
  background-color: rgba(25, 118, 210, 0.04);
}

/* Tema escuro */
.theme--dark >>> .topic-card--unread {
  border-left-color: #2196F3;
  background-color: rgba(33, 150, 243, 0.08);
}

/* Hover effects */
.topic-card--unread:hover {
  background-color: rgba(25, 118, 210, 0.08);
}

.theme--dark >>> .topic-card--unread:hover {
  background-color: rgba(33, 150, 243, 0.12);
}

.topic-card__content {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  gap: 16px;
  min-height: 68px;
}

/* Área 1: Mídia */
.topic-card__media {
  flex-shrink: 0;
}

.topic-card__avatar {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.topic-card__icon {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  background: rgba(var(--v-primary-base), 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Área 2: Conteúdo Principal */
.topic-card__main {
  flex: 1;
  min-width: 0;
  cursor: pointer;
  padding: 4px 0;
  transition: opacity 0.2s ease;
}

.topic-card__main:hover {
  opacity: 0.85;
}

.topic-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 4px;
}

.topic-card__title {
  margin: 0 0 4px;
  font-size: 1rem;
  font-weight: 600;
  color: var(--v-primary-base);
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topic-card__info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.topic-card__contact {
  font-size: 0.85rem;
  color: rgba(var(--v-primary-base), 0.7);
}

.topic-card__description,
.topic-card__last-message {
  margin: 0;
  font-size: 0.85rem;
  color: rgba(var(--v-primary-base), 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 4px;
}

/* Área 3: Data e Ações */
.topic-card__actions {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 100px;
}

.topic-card__date {
  font-size: 0.8rem;
  color: rgba(var(--v-primary-base), 0.6);
  white-space: nowrap;
}

.topic-card__buttons-area {
  min-height: 40px;
}

.topic-card__buttons {
  display: none;
  gap: 0px;
  justify-content: flex-end;
}

.topic-card:hover .topic-card__buttons {
  display: flex;
}

.topic-card__action-btn {
  margin-top: 5px !important;
  margin-right: 0 !important;
}

.topic-card__unread {
  flex-shrink: 0;
}

.topic-card__unread-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  background-color: green;
  color: white;
  border-radius: 10px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: badge-pulse 2s infinite;
}

@keyframes badge-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsivo */
@media (max-width: 960px) {
  .topic-card__content {
    padding: 20px;
    gap: 16px;
  }
  
  .topic-card__avatar,
  .topic-card__icon {
    width: 48px;
    height: 48px;
  }
}

@media (max-width: 600px) {
  .topic-card__content {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;
  }

  .topic-card__media {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .topic-card__main {
    width: 100%;
    padding: 0;
  }

  .topic-card__actions {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    border-top: 1px solid rgba(var(--v-primary-base), 0.05);
  }

  .topic-card__icon,
  .topic-card__avatar {
    width: 40px;
    height: 40px;
  }

  .topic-card__title {
    font-size: 0.95rem;
  }

  .topic-card__contact,
  .topic-card__description,
  .topic-card__last-message {
    font-size: 0.8rem;
  }

  .topic-card__unread-badge {
    min-width: 18px;
    height: 18px;
    font-size: 0.7rem;
  }
}

/* Tema escuro */
.theme--dark .topic-card__unread-badge {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
</style> 