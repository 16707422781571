<template>
  <div center style="width: 100%; text-align:center;">
    <br><br>
    <v-progress-circular 
      indeterminate 
      :size="70" 
      :width="7" 
      color="blue"
    />
    
    <div v-if="type === 'queue'">
      <h2 v-t="'modules.talks.pages.talkView.talks.messages.loading.queue'"></h2>
    </div>
    <div v-if="type === 'my-talks'">
      <h2 v-t="'modules.talks.pages.talkView.talks.messages.loading.myTalks'"></h2>
    </div>
    <div v-if="type === 'others'">
      <h2 v-t="'modules.talks.pages.talkView.talks.messages.loading.others'"></h2>
    </div>
    <div v-if="type === 'groups'">
      <h2 v-t="'modules.talks.pages.talkView.talks.messages.loading.groups'"></h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TalksListLoading',
  props: {
    type: {
      type: String,
      required: true
    }
  }
}
</script> 