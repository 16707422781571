<template>
  <view-detail
    index="budget-template"
    title="Detalhes do Modelo de Orçamento"
    apiUrl="sales/budget"
    apiParams="?with=events;finances;category:id,name,icon,color,form_id;category.form;items;items.item:id,name,type,item_unit_id;items.item.unit:id,code;business:id,type,form_reply_id,created_at,business_category_id;business.category.form;businesses:id,type,business_id,created_at"
    store="budget-template"
  >
    <template slot-scope="{ content, refresh }">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <v-card class="elevation-17">
              <v-card-text>
                <h4>Nome: {{ content.title || ' Modelo de orçamento #' + content.id }}</h4>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessInfo
              :info="configuration"
              :data="content"
            ></BusinessInfo>
          </v-flex>
          <v-flex v-if="content.observation" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessObservation
              :observation="content.observation"
            ></BusinessObservation>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessItems
              :business="content"
              wiConfigId="sale"
              :onRefresh="refresh"
              :showAmounts="true"
            ></BusinessItems>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessFinance
              :business="content"
              wiConfig="budget"
              :refresh="refresh"
            ></BusinessFinance>
          </v-flex>
          <v-flex v-if="content.category.form && content.category.form.id" xs12 sm12 md12 lg12 style="padding-bottom: 20px;">
            <BusinessForm
              :refresh="refresh"
              :reply="content.reply"
              :businessId="content.id"
              :form="content.category.form"
              :replyEditor="content.reply_editor"
            ></BusinessForm>
          </v-flex>
          <v-flex 
            v-if="
              content.business &&
              content.business.category &&
              content.business.category.form &&
              content.business.category.form.id
            " 
            xs12 sm12 md12 lg12 
            style="padding-bottom: 20px;"
          >
            <BusinessForm
              :refresh="refresh"
              :reply="content.business.reply"
              :businessId="content.business.id"
              :businessBusinessId="content.id"
              :form="content.business.category.form"
              :replyEditor="content.business.reply_editor"
            ></BusinessForm>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </view-detail>
</template>
<script>
  import BusinessMixin from '@/modules/business/mixins/BusinessMixin'
  import BusinessInfo from './BudgetTemplateInfo'
  import BusinessForm from '@/modules/business/pages/Business/BusinessForm'
  import BusinessItems from '@/modules/business/pages/Business/BusinessItems'
  import BusinessFinance from '@/modules/business/pages/Business/BusinessFinance'
  import BusinessObservation from '@/modules/business/pages/Business/BusinessObservation'
  export default {
    name: 'BudgetTemplate',
    components: {
      BusinessInfo,
      BusinessForm,
      BusinessItems,
      BusinessFinance,
      BusinessObservation
    },
    mixins: [BusinessMixin]
  }
</script>
