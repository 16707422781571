<template>
  <div>
    <WNCalendar
      title="Agenda de Ordens de Serviço"
      addButtonText="Nova Ordem de Serviço"
      defaultCalendarView="dayGridMonth"
      :filters="filters"
      @addEvent="addEvent"
      @getEvents="getEvents"
      @dateClick="dateClick"
      @eventDrop="eventDrop"
      @eventClick="eventClick"
      @onFilter="onFilter"
      :filtersConfig="filtersConfig"
    />
    <v-dialog v-model="orderDialog" scrollable persistent>
      <v-card>
        <router-view></router-view>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { OrderCalendarFilters } from '@/modules/service-desk/config/Order/Order/OrderFilters'
  import WNCalendar from '@/default/component/WNCalendar/WNCalendar'
  export default {
    name: 'OrdersCalendar',
    data: () => ({
      refresh: null,
      orderDialog: false,
      filters: {},
      params: {},
      filtersConfig: new OrderCalendarFilters(),
    }),
    methods: {
      getEvents({ fetchInfo, successCallback, errorCallback, refresh, setEvents }) {
        this.refresh = refresh
        this.params = {
          ...this.filters,
          date_start: fetchInfo.startStr,
          date_end: fetchInfo.endStr
        }
        this.$Api.get({
          uri: 'service-desk/order/by-period',
          params: this.params,
          sucess: ({ data }) => {
            if (data?.events?.length > 0) {
              const events = data.events
              setEvents(events)
              successCallback(this.formatCalendarEvents(events))
              this.$forceUpdate()
            } else {
              setEvents([])
              successCallback([])
            }
          },
          error: (error) => {
            errorCallback(error)
          }
        })
      },
      onFilter: function (filters) {
        this.filters = filters
      },
      formatCalendarEvents: function (events) {
        let eventsFormated = [];
        events.map(event => {
          let date = event.date_start
          let title = event.person.name
          if (event.time_start) {
            date = event.date_start + ' ' + event.time_start
          }
          eventsFormated.push({
            id: event.id,
            title: title,
            date: date,
            color: event.status.color
          })
        })
        return eventsFormated
      },
      dateClick: function (date) {
        this.createNewOrder(date.dateStr)
      },
      eventClick: function (event) {
        this.$router.push({
          name: 'orders-calendar-order',
          params: {
            id: event.id
          }
        })
      },
      eventDrop: function ({ event, newDate}) {
        this.$WiApiPut({
          uri: `service-desk/order/${event.id}`,
          data: {
            id: event.id,
            date_start: newDate,
            date_end: newDate
          },
          callback: () => {
            this.refresh()
          }
        })
      },
      addEvent: function () {
        this.createNewOrder()
      },
      createNewOrder: function (startDate = null) {
        this.$WiEditDialog({
          wiConfig: 'order',
          data: {
            date_start: startDate,
            date_end: startDate
          },
          onSubmit: ({ data }) => {
            this.$router.push({
              name: 'orders-calendar-order',
              params: {
                id: data.id
              }
            })
          },
          redirectOnSubmit: false
        })
      },
      whathRoute: function () {
        if (this.$route.name == 'orders-calendar-order') {
          this.orderDialog = true
        } else {
          this.refresh()
          this.orderDialog = false
        }
      }
    },
    mounted: function () {
      this.whathRoute()
    },
    components: {
      WNCalendar
    },
    watch: {
      $route: function () {
        this.whathRoute()
      }
    }
  }
</script>