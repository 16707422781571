<template>
  <div class="talks-list__not-message">
    <div class="talks-list__not-message-content">
      <img 
        :src="getEmptyStateImage" 
        :alt="type"
        :style="type.includes('others') || type === 'groups' ? 'max-width: 150px;' : ''"
      >
      
      <div class="talks-list__not-message-text" 
        v-t="`modules.talks.pages.talkView.talks.messages.notFound.${type}`">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TalksListEmpty',
  
  props: {
    type: {
      type: String,
      required: true
    }
  },

  computed: {
    getEmptyStateImage() {
      const images = {
        'queue': 'static/icons/talks-queue-clear.png',
        'my-talks': 'static/icons/talks-my-talks-clear-transparent.png',
        'others': 'static/background/whatsapp-not-found.png?url=temp1',
        'groups': 'static/background/whatsapp-not-found.png?url=temp1'
      }
      return images[this.type] || ''
    }
  }
}
</script>

<style scoped>
.talks-list__not-message {
  align-items: center;
}

.talks-list__not-message-content {
  padding: 50px 20px;
  text-align: -webkit-center;
  font-size: 20px;
}

.talks-list__not-message img {
  width: 100%;
}

.talks-list__not-message-text {
  padding-bottom: 20px;
}
</style> 