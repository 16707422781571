<template>
  <tr class="collaborator-row" :class="{
    'collaborator-row--inactive': props.item.status === 'inactive',
    'collaborator-row--negatived': props.item.negatived_count > 0
  }">
    <!-- Ações -->
    <wi-list-data-table-actions
      class="collaborator-row__actions"
      :config="config" :props="props"
    />
    <!-- Foto e Informações Principais -->
    <td class="collaborator-row__profile" @click="openPerson">
      <div class="profile-container">
        <div class="avatar">
          <img 
            v-if="props.item.file && props.item.file.url" 
            :src="props.item.file.url" 
            :alt="props.item.name"
            class="avatar__image"
          >
          <div v-else class="avatar__fallback">
            {{ getInitials(props.item.name) }}
          </div>
        </div>
        <div class="info-container">
          <div class="info-container__main">
            <strong class="name">{{ props.item.name | stringlength(25) }}</strong>
          </div>
        </div>
      </div>
    </td>

    <!-- Equipes -->
    <td class="collaborator-row__teams">
      <div class="teams-container">
        <span v-if="props.item.teams &&props.item.teams.length" class="teams-badge">
          {{ props.item.teams.length }} equipe(s)
        </span>
        <span v-else class="teams-badge teams-badge--empty">
          Sem equipe
        </span>
      </div>
    </td>

    <!-- Botão de Detalhes -->
    <td class="collaborator-row__actions">
      <v-btn outline small color="grey" @click="showDetails">
        <v-icon small>person</v-icon>
        <span> Info</span>
      </v-btn>
    </td>

    <!-- Dialog de Detalhes -->
    <collaborator-details-dialog
      v-model="showDialog"
      :collaborator="props.item"
    />
  </tr>
</template>

<script>
import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
import CollaboratorDetailsDialog from './CollaboratorDetailsDialog'
export default {
  name: 'CollaboratorsSelect',
  components: {
    WiListDataTableActions,
    CollaboratorDetailsDialog
  },
  props: {
    config: Object,
    props: Object
  },
  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    getInitials(name) {
      return name
        .split(' ')
        .map(word => word[0])
        .slice(0, 2)
        .join('')
        .toUpperCase()
    },
    openPerson() {
      if (this.config.mode !== 'select') {
        const route = this.config.config.urlDetails
        route.params.id = this.props.item.id
        this.$router.push(route)
      }
    },
    showDetails() {
      this.showDialog = true
    }
  }
}
</script>

<style scoped>
.collaborator-row {
  transition: all 0.3s ease;
  border-bottom: 1px solid #eee;
}

.collaborator-row__profile {
  padding: 0px 5px !important;
  cursor: pointer;
}

.profile-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.collaborator-row__actions {
  padding: 0 5px !important;
  padding-right: 20px !important;
}

.collaborator-row:hover {
  background-color: #f5f5f5;
}

.collaborator-row--inactive {
  background-color: #f5f5f5;
  color: #666;
}

.collaborator-row--negatived {
  background-color: #000;
  color: #fff;
}

.collaborator-row__teams {
  text-align: center;
}

.collaborator-row td {
  padding: 12px;
  vertical-align: middle;
}

/* Avatar */
.avatar {
  min-width: 40px; /* Garante que o avatar não encolha */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e0e0e0;
  border: 2px solid #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.avatar__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar__fallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2196f3;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

/* Informações */
.info-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}

.info-container__main {
  display: flex;
  align-items: center;
  gap: 8px;
}

.name {
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 0.25px;
}

/* Status Badge */
.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.status-badge--active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-badge--inactive {
  background-color: #eeeeee;
  color: #616161;
}

/* Teams Badge */
.teams-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  background-color: #e3f2fd;
  color: #1976d2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.teams-badge--empty {
  background-color: #eeeeee;
  color: #616161;
}

/* Details Button */
.details-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.details-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.details-button .material-icons {
  font-size: 20px;
  color: #666;
}
</style>