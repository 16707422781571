import { Model } from '@/default/model/Model'

export default {
  list: true,
  edit: true,
  title: {
    list: 'Contas Bancárias',
    edit: {
      insert: 'Nova Conta Bancária',
      update: 'Editar Conta Bancária'
    }
  },
  icon: {
    list: 'account_balance',
    edit: {
      insert: 'account_balance',
      update: 'account_balance'
    }
  },
  apiUrl: {
    list: 'finance/account',
    edit: {
      insert: 'finance/account',
      update: 'finance/account',
      delete: 'finance/account'
    }
  },
  search: {
    placeholder: 'Pesquise ID, nome da conta ou do banco',
    fields: ['id', 'name', 'bank.name']
  },
  size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
  components: {
    list: {
      header: null,
      tableRow: () => import('@/modules/finance/pages/Account/Accounts')
    },
    edit: {
      header: null,
      fields: null
    }
  },
  fields: [
    {
      text: 'Informe o nome, saldo inicial e o tipo desta conta.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'id',
      text: 'ID',
      type: 'int',
      list: true,
      edit: false,
      search: true,
      align: 'center'
    },
    {
      value: 'company_id',
      text: 'Empresa Matriz ou Filial',
      type: 'db-autocomplete',
      autocomplete: true,
      required: true,
      edit: true,
      apiUrl: 'register/person/company',
      apiParams: {
        fields: 'id,name',
        order: 'name,asc'
      },
      show: 'name',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    },
    {
      value: 'name',
      text: 'Nome da Conta',
      type: 'text',
      list: true,
      edit: true,
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      search: true,
      align: 'center'
    },
    {
      value: 'balance_current',
      text: 'Saldo Atual',
      list: true,
      align: 'center'
    },
    {
      value: 'balance_foreseen',
      text: 'Saldo Previsto',
      list: true,
      align: 'center'
    },
    {
      value: 'bank.name',
      text: 'Banco',
      type: 'text',
      list: true,
      edit: false,
      search: true,
      sortable: false,
      align: 'center'
    },
    {
      value: 'options',
      text: 'Opções',
      type: 'text',
      list: true,
      align: 'center',
      sortable: false
    },
    {
      value: 'finance_account_type_id',
      text: 'Tipo de Conta',
      type: 'db-autocomplete',
      autocomplete: true,
      required: true,
      list: false,
      edit: true,
      apiUrl: 'finance/account/type',
      apiParams: {fields: 'id,name', order: 'name,asc'},
      show: 'name',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      value: 'is_master',
      text: 'Conta principal?',
      align: 'center',
      type: 'select',
      edit: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      options: [{value: true, text: 'Sim'}, {value: false, text: 'Não'}]
    },
    {
      value: 'balance_opening',
      text: 'Saldo inicial',
      type: 'money',
      edit: true,
      search: false,
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      text: 'Vincule a uma Instituição Bancária e se desejar, informe n° da conta e agência.',
      type: 'subheader',
      edit: true,
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    },
    {
      value: 'finance_bank_id',
      text: 'Instituição Bancária',
      autocomplete: true,
      required: true,
      type: 'db-autocomplete',
      list: false,
      edit: true,
      apiUrl: 'finance/bank',
      apiParams: {fields: 'id,name,logo_url', order: 'name,asc'},
      show: 'name',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    },
    {
      value: 'account',
      text: 'N° da Conta',
      type: 'text',
      edit: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 },
      search: true
    },
    {
      value: 'agency',
      text: 'Agência',
      type: 'text',
      edit: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 },
      search: true
    },
    {
      value: 'operation',
      text: 'Operação',
      type: 'text',
      edit: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 },
      search: true
    },
    {
      text: 'Use o campo Descrição/Observações para registrar detalhes desta conta.',
      type: 'subheader',
      edit: true,
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    },
    {
      value: 'person_type',
      text: 'Definir o tipo de Beneficiário',
      align: 'center',
      type: 'select',
      edit: true,
      size: (form) => { return form.person_type ? ['xs12', 'sm12', 'md5', 'lg5'] : ['xs12', 'sm12', 'md12', 'lg12'] },
      options: [{value: 'customer', text: 'Vincular a um Cliente'}, {value: 'company', text: 'Vincular a uma Empresa Matriz ou Filial'}, {value: 'provider', text: 'Vincular a um Fornecedor'}, {value: 'collaborator', text: 'Vincular a um Colaborador'}],
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    },
    {
      value: 'person_id',
      text: 'Cliente Beneficiário',
      type: 'db-autocomplete',
      autocomplete: true,
      required: true,
      list: false,
      edit: true,
      apiUrl: 'register/person/customer',
      apiParams: {
        fields: 'id,name',
        order: 'name,asc'
      },
      show: 'name',
      size: ['xs12', 'sm12', 'md7', 'lg7'],
      showField: (form) => { return (form.finance_account_type_id === 2 || form.finance_account_type_id === 4) && form.person_type === 'customer' }
    },
    {
      value: 'person_id',
      text: 'Fornecedor Beneficiário',
      type: 'db-autocomplete',
      autocomplete: true,
      required: true,
      list: false,
      edit: true,
      apiUrl: 'register/person/provider',
      apiParams: {
        fields: 'id,name',
        order: 'name,asc'
      },
      show: 'name',
      size: ['xs12', 'sm12', 'md7', 'lg7'],
      showField: (form) => { return (form.finance_account_type_id === 2 || form.finance_account_type_id === 4) && form.person_type === 'provider' }
    },
    {
      value: 'person_id',
      text: 'Empresa Matriz ou Filial Beneficiário',
      type: 'db-autocomplete',
      autocomplete: true,
      required: true,
      list: false,
      edit: true,
      apiUrl: 'register/person/company',
      apiParams: {
        fields: 'id,name',
        order: 'name,asc'
      },
      show: 'name',
      size: ['xs12', 'sm12', 'md7', 'lg7'],
      showField: (form) => { return (form.finance_account_type_id === 2 || form.finance_account_type_id === 4) && form.person_type === 'company' }
    },
    {
      value: 'person_id',
      text: 'Colaborador como Beneficiário',
      type: 'db-autocomplete',
      autocomplete: true,
      required: true,
      list: false,
      edit: true,
      apiUrl: 'register/person/collaborator',
      apiParams: {
        fields: 'id,name',
        order: 'name,asc'
      },
      show: 'name',
      size: ['xs12', 'sm12', 'md7', 'lg7'],
      showField: (form) => { return (form.finance_account_type_id === 2 || form.finance_account_type_id === 4) && form.person_type === 'collaborator' }
    },
    {
      text: 'Use o campo Descrição/Observações para registrar detalhes desta conta.',
      type: 'subheader',
      edit: true
    },
    {
      value: 'description',
      text: 'Descrição/Observações',
      type: 'textarea',
      edit: true
    }
  ]
}

export class FinanceAccount extends Model {
  constructor() {
    super()
    
    this.id = {
      id: 'finance-account',
      create: 'finance-account-create', 
      update: 'finance-account-update',
      delete: 'finance-account-delete'
    }

    this.edit = true
    
    this.title = {
      list: 'Contas Bancárias',
      edit: {
        insert: 'Nova Conta Bancária',
        update: 'Editar Conta Bancária'
      }
    }

    this.icon = 'account_balance'

    this.apiUrl = 'finance/account'

    this.search = {
      placeholder: 'Pesquise ID, nome da conta ou do banco',
      fields: ['id', 'name', 'bank.name']
    }

    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']

    this.components = {
      list: {
        header: null,
        tableRow: () => import('@/modules/finance/pages/Account/Accounts')
      }
    }

    this.setFields(this.fieldsList)
  }

  editFields = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, saldo inicial e o tipo desta conta.'
    }),

    company_id: this.fieldSelectApi({
      value: 'company_id',
      text: 'Empresa Matriz ou Filial',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      api: {
        url: 'register/person/company',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),

    name: this.fieldText({
      value: 'name',
      text: 'Nome da Conta',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),

    finance_account_type_id: this.fieldSelectApi({
      value: 'finance_account_type_id',
      text: 'Tipo de Conta',
      required: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      api: {
        url: 'finance/account/type',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),

    is_master: this.fieldSelect({
      value: 'is_master',
      text: 'Conta principal?',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      options: [{value: true, text: 'Sim'}, {value: false, text: 'Não'}]
    }),

    balance_opening: this.fieldMoney({
      value: 'balance_opening',
      text: 'Saldo inicial',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),

    subHeader2: this.fieldSubHeader({
      text: 'Vincule a uma Instituição Bancária e se desejar, informe n° da conta e agência.',
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    }),

    finance_bank_id: this.fieldSelectApi({
      value: 'finance_bank_id',
      text: 'Instituição Bancária',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'finance/bank',
        params: {
          fields: 'id,name,logo_url',
          order: 'name,asc'
        }
      },
      show: 'name',
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    }),

    account: this.fieldText({
      value: 'account',
      text: 'N° da Conta',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    }),

    agency: this.fieldText({
      value: 'agency',
      text: 'Agência',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    }),

    operation: this.fieldText({
      value: 'operation',
      text: 'Operação',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    }),

    person_type: this.fieldSelect({
      value: 'person_type',
      text: 'Definir o tipo de Beneficiário',
      size: (form) => { return form.person_type ? ['xs12', 'sm12', 'md5', 'lg5'] : ['xs12', 'sm12', 'md12', 'lg12'] },
      options: [
        {value: 'customer', text: 'Vincular a um Cliente'},
        {value: 'company', text: 'Vincular a uma Empresa Matriz ou Filial'},
        {value: 'provider', text: 'Vincular a um Fornecedor'},
        {value: 'collaborator', text: 'Vincular a um Colaborador'}
      ],
      showField: (form) => { return form.finance_account_type_id === 2 || form.finance_account_type_id === 4 }
    }),

    subHeader3: this.fieldSubHeader({
      text: 'Use o campo Descrição/Observações para registrar detalhes desta conta.'
    }),

    description: this.fieldTextArea({
      value: 'description',
      text: 'Descrição/Observações'
    })
  }
}

export class FinanceAccountPerson extends Model {
  constructor() {
    super()

    this.id = {
      id: 'c80ca89f',
      create: 'dd723990', 
      update: '9717e418',
      delete: 'c191fa40'
    }

    this.edit = true
    
    this.title = {
      list: 'Contas Bancárias',
      edit: {
        insert: 'Nova Conta Bancária',
        update: 'Editar Conta Bancária'
      }
    }

    this.icon = 'account_balance'

    this.apiUrl = 'finance/account'

    this.maxWidth = '858px'
    this.size = ['xs12', 'sm12', 'md12', 'lg12', 'xl12', 'offset-xl1']

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }

  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID',
    }),
    accountTypeName: this.fieldList({
      value: 'type.name',
      text: 'Tipo de Conta'
    }),
    bankName: this.fieldList({
      value: 'bank.name',
      text: 'Instituição Bancária'
    }),
    account: this.fieldList({
      value: 'account',
      text: 'N° da Conta'
    }),
    agency: this.fieldList({
      value: 'agency',
      text: 'Agência'
    }),
    operation: this.fieldList({
      value: 'operation',
      text: 'Operação'
    }),
    integration_code: this.fieldList({
      value: 'integration_code',
      text: 'Chave Pix'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome, saldo inicial e o tipo desta conta.'
    }),
    finance_account_type_id: this.fieldSelectApi({
      value: 'finance_account_type_id',
      text: 'Tipo de Conta',
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      api: {
        url: 'finance/account/type',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),
    bank_id: this.fieldSelectApi({
      value: 'finance_bank_id',
      text: 'Instituição Bancária',
      required: true,
      size: ['xs12', 'sm12', 'md8', 'lg8'],
      api: {
        url: 'finance/bank',
        params: {
          fields: 'id,name,logo_url',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),
    account: this.fieldText({
      value: 'account',
      text: 'N° da Conta',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      required: true
    }),
    agency: this.fieldText({
      value: 'agency',
      text: 'Agência',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      required: true
    }),
    operation: this.fieldText({
      value: 'operation',
      text: 'Operação',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    keyPix: this.fieldText({
      value: 'integration_code',
      text: 'Chave Pix',
      placeholder: 'Ex: 12934587890',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    }),

    subHeader3: this.fieldSubHeader({
      text: 'Use o campo Descrição/Observações para registrar detalhes desta conta.'
    }),
    description: this.fieldTextArea({
      value: 'description',
      text: 'Descrição/Observações'
    })
  }
}

