<template>
  <v-dialog
    v-model="dialog" width="500"
    @click="handleOverlayClick"
    scrollable lazy
     style="border-radius: 12px;"
  >
    <v-card style="border-radius: 12px;">
      <!-- Cabeçalho -->
      <v-toolbar flat dense color="transparent">
        <v-toolbar-title>Detalhes do Colaborador</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text v-if="dialog" class="dialog-body">
        <!-- Seção Principal -->
        <div class="profile-hero">
          <div class="profile-image-wrapper">
            <div class="profile-image">
              <img 
                v-if="collaborator.file && collaborator.file.url" 
                :src="collaborator.file.url" 
                :alt="collaborator.name"
              >
              <div v-else class="profile-initials">
                {{ getInitials(collaborator.name) }}
              </div>
            </div>
            <span class="status" :class="collaborator.status">
              {{ collaborator.status === 'active' ? 'Ativo' : 'Inativo' }}
            </span>
          </div>
          
          <div class="profile-main-info">
            <h1 class="profile-name">{{ collaborator.name }}</h1>
            <div v-if="collaborator.cpf_cnpj" class="profile-details">
              <div class="detail-item">
                <span class="detail-label">CPF</span>
                <span class="detail-value">{{ collaborator.cpf_cnpj }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Grid de Informações -->
        <div class="info-grid">
          <!-- Telefones -->
          <div class="info-section">
            <h3 class="section-title">Contatos</h3>
            <div v-if="collaborator.phones && collaborator.phones.length" class="contact-list">
              <div v-for="phone in collaborator.phones" :key="phone.id" class="contact-item">
                <div class="contact-icon">
                  <i v-if="phone.type === 'Celular'" class="fas fa-mobile-alt"></i>
                  <i v-else class="fas fa-phone"></i>
                </div>
                <div class="contact-details">
                  <span class="contact-type">{{ phone.type }}</span>
                  <span class="contact-number">{{ formatPhone(phone.phone_full) }}</span>
                </div>
              </div>
            </div>
            <div v-else class="empty-state">
              Nenhum telefone cadastrado
            </div>
          </div>

          <!-- Equipes -->
          <div class="info-section">
            <h3 class="section-title">Equipes</h3>
            <div v-if="collaborator.teams && collaborator.teams.length" class="teams-list">
              <div v-for="team in collaborator.teams" :key="team.id" class="team-item">
                <div class="team-icon">
                  <i class="fas fa-users"></i>
                </div>
                <div class="team-details">
                  <span class="team-name">Equipe {{ team.team_id }}</span>
                  <span v-if="team.function" class="team-function">{{ team.function }}</span>
                  <span class="team-date">Desde {{ formatDate(team.created_at_format) }}</span>
                </div>
              </div>
            </div>
            <div v-else class="empty-state">
              Não participa de nenhuma equipe
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CollaboratorDetailsDialog',
  props: {
    value: Boolean,
    collaborator: {
      type: Object,
      required: true
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    getInitials(name) {
      return name
        .split(' ')
        .map(word => word[0])
        .slice(0, 2)
        .join('')
        .toUpperCase()
    },
    closeDialog() {
      this.$emit('input', false)
    },
    handleOverlayClick(event) {
      if (event.target === event.currentTarget) {
        this.closeDialog()
      }
    },
    formatPhone(phone) {
      if (!phone) return '';
      // Remove todos os caracteres não numéricos
      const numbers = phone.replace(/\D/g, '');
      
      if (numbers.length === 13) { // Com código do país
        return `+${numbers.slice(0, 2)} (${numbers.slice(2, 4)}) ${numbers.slice(4, 9)}-${numbers.slice(9)}`;
      }
      return phone; // Retorna original se não conseguir formatar
    },
    formatDate(date) {
      if (!date) return '';
      return date.split(' ')[0]; // Retorna apenas a data, removendo o horário
    }
  }
}
</script>

<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog-content {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.dialog-header {
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #1a1a1a;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 4px 8px;
}

.dialog-body {
  padding: 24px;
  overflow: auto !important;
}

.profile-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 24px;
  background: linear-gradient(to bottom, #f8f9fa, white);
  border-radius: 12px;
  /* margin-bottom: 32px; */
}

.profile-image-wrapper {
  position: relative;
  margin-bottom: 24px;
}

.profile-image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-initials {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #2196f3, #1976d2);
  color: white;
  font-size: 48px;
  font-weight: 500;
}

.profile-main-info {
  width: 100%;
  max-width: 500px;
}

.profile-name {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 5px 0;
}

.profile-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detail-label {
  font-size: 14px;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.detail-value {
  font-size: 18px;
  color: #2c3e50;
  font-weight: 500;
}

.status {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.status.active {
  background-color: #e3f2fd;
  color: #1976d2;
  border: 2px solid #1976d2;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  font-size: 12px;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.value {
  font-size: 16px;
  color: #1a1a1a;
}

.dialog-footer {
  padding: 16px 24px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}

.button-secondary {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.button-secondary:hover {
  background: #f5f5f5;
}

.info-section {
  grid-column: 1 / -1;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  margin-top: 16px;
}

.section-title {
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 16px;
}

.contact-list, .teams-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact-item, .team-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;
}

.contact-item:hover, .team-item:hover {
  transform: translateX(4px);
}

.contact-icon, .team-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e3f2fd;
  color: #1976d2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.contact-details, .team-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contact-type, .team-name {
  font-size: 14px;
  font-weight: 500;
  color: #2c3e50;
}

.contact-number {
  font-size: 15px;
  color: #666;
}

.team-function {
  font-size: 13px;
  color: #666;
}

.team-date {
  font-size: 12px;
  color: #999;
}

.empty-state {
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 14px;
  background: white;
  border-radius: 8px;
  border: 1px dashed #ddd;
}

@media (max-width: 600px) {
  .dialog-content {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    border-radius: 0;
  }

  .profile-hero {
    padding: 24px 16px;
  }

  .profile-image {
    width: 140px;
    height: 140px;
  }

  .profile-initials {
    font-size: 36px;
  }

  .profile-name {
    font-size: 24px;
  }

  .detail-value {
    font-size: 16px;
  }

  .info-grid {
    grid-template-columns: 1fr;
  }

  .info-section {
    padding: 16px;
  }

  .contact-item, .team-item {
    padding: 10px;
  }

  .contact-icon, .team-icon {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }
}
</style>