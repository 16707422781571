<template>
  <div>
    <div v-if="loadings.load">
      <div center style="width: 100%; text-align:center;">
        <br><br>
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <h1
          v-t="'modules.talks.pages.talkView.talk.messages.loading.getMessages'"
        ></h1>
      </div>
    </div>
    <div v-else>
      <ChatToolbar
        :chat="chat"
        :chatUuid="chatUuid"
        :topicUuid="topicUuid"
        @onProfileClick="profileDialog = true"
        @onInfoClick="goToChatInfo"
        @onRefreshClick="getChat"
        @onCloseClick="closeAction"
      />
      <div style="
        padding:0 !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
      ">
        <div v-if="chat && chat.id" style="width: 100%;">
          <div
            style="
              padding:0 !important;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: stretch;
              align-content: stretch;
            "
          >
            <v-container
              id="container"
              fluid
              class="chat__message-area"
              :class="{
                'chat__message-area--dialog': mode === 'dialog'
              }"
              :style="{
                'background': $wiDark ? '#1C252E' : '#F9FAFB'
              }"
            >
              <div class="chat__message-area-content">
                <div style="width: 100%; text-align: center; padding-top: 20px;">
                  <v-btn
                    @click="paginateMessages()"
                    :loading="loadings.paginate"
                    :disabled="!paginate.next_page_url"
                  >
                    <v-icon>refresh</v-icon>
                    <span
                      v-t="'modules.talks.pages.talkView.talk.labels.loadMore'"
                    ></span>
                  </v-btn>
                </div>
                <TalkMessage
                  v-for="(message, messageKey) in messagesOrdered"
                  :key="messageKey"
                  :chat="chat"
                  :message="message"
                  :topicUuid="topicUuid"
                ></TalkMessage>
              </div>
              <div>
                <ChatFooter
                  :chatUuid="chatUuid"
                  :topicUuid="topicUuid"
                  v-on:scroll="scrollToEnd"
                  v-on:refresh="() => { getChat({ chatUuid: chatUuid, topicUuid: topicUuid }) }"
                ></ChatFooter>  
              </div>
            </v-container>
          </div>      
        </div>
        <div v-else style="width: 100%; display: flex; flex-direction: column; justify-content: center;">
          <div style="width: 100%; text-align: center; margin: auto; max-width: 400px;">
            <img src="static/background/whatsapp-not-found.png?url=temp1" alt="" style="width: 100%">
            <br>
            <span
              style="font-size: 20px;"
              v-t="'modules.talks.pages.talkView.talk.notFound'"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <ChatProfileDialog
      :chat="chat"
      :active="profileDialog"
      @onActive="profileDialog = $event"
    ></ChatProfileDialog>
  </div>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import TalkMessage from '@/default/component/WiMessage'
  import ChatFooter from './ChatFooter'
  import ChatProfileDialog from './ChatProfileDialog'
  import ChatToolbar from './components/ChatToolbar'
  export default {
    name: 'ChatMessages',
    data: () => ({
      currentHeight: 0,
      pageHeight: innerHeight,
      drawer: false,
      profileDialog: false
    }),
    computed: {
      ...mapState('Chat', ['chat', 'messages', 'paginate', 'loadings', 'lastMessage']),
      ...mapGetters('Chat', ['messagesOrdered']),
      chatUuid () {
        return this.setChatUuid || this.$route.params.id
      },
      topicUuid () {
        return this.setTopicUuid || this.$route.params.topic_id
      },
      primaryColor () {
        return !this.$wiDark ? 'primary' : '#212121'
      }
    },
    methods: {
      ...mapActions('Chat', ['getChat', 'resetChat', 'subscribeOnChat', 'unsubscribeOnChat', 'paginateMessages', 'unseenMessages']),
      chatChange: function () {
        this.resetChat()
        this.getChat({
          chatUuid: this.chatUuid,
          topicUuid: this.topicUuid
        })
        this.unsubscribeOnChat(this.$route.query.previousChatId)
        this.subscribeOnChat(this.chatUuid)
      },
      goToChatInfo: function ({ chatUuid, topicUuid }) {
        const breackPoint = this.$vuetify.breakpoint.name
        let routeName = 'chat-view-messages'
        if (breackPoint === 'xs' || breackPoint === 'sm') {
          routeName = 'chat-view-messages-page'
        }
        this.$router.push({
          name: routeName,
          params: { id: chatUuid, topic_id: topicUuid },
          query: { previousChatId: this.$route.params.id }
        })
      },
      scrollToEnd: function (timeout) {
        setTimeout(() => {
          var container = this.$el.querySelector('#container')
          if (container) {
            container.scrollTop = container?.scrollHeight || 0
          }
        }, timeout)
      },
      onMessageChange: function () {
        this.scrollToEnd(500)
        this.scrollToEnd(1000)
        this.chatUnseenMessages()
        this.$forceUpdate()
      },
      chatUnseenMessages: function () {
        var previousChatId = this.$route.query.previousChatId
        if (previousChatId) {
          this.unseenMessages(previousChatId)
        }
      },
      setCurrentHeight: function (heigth) {
        this.currentHeight = heigth
        this.setInnerHeight()
      },
      setInnerHeight: function () {
        let heightPerson = 46
        let heightDiff = 145
        const breackPoint = this.$vuetify.breakpoint.name
        if (this.mode == 'dialog') {
          if (breackPoint === 'xs' || breackPoint === 'sm') {
            heightDiff = 93
          } else {
            heightDiff = 188
          }
        }
        if (breackPoint === 'xs' || breackPoint === 'sm') {
          heightPerson = 0
        }
        this.pageHeight = (((this.currentHeight || innerHeight) - heightDiff) - heightPerson) + 'px'
      },
      closeAction: function () {
        if (this.mode === 'dialog') {
          this.$emit('onClose')
        } else if (this.topicUuid) {
          if (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm') {
            this.$router.push({
              name: 'chat-view-topics-page',
              params: {
                id: this.chatUuid
              }
            })
          } else {
            this.$router.push({
              name: 'chat-view-topics',
              params: {
                id: this.chatUuid
              }
            })
          }
        } else {
          this.$router.push({ name: 'chat-view' })
        }
      }
    },
    mounted: function () {
      window.addEventListener('resize', () => {
        this.setInnerHeight(innerHeight)
      }, true)
      this.setInnerHeight(innerHeight)
      console.log('mounted', this.chatUuid, this.topicUuid)
      this.getChat({
        chatUuid: this.chatUuid,
        topicUuid: this.topicUuid
      })
      this.subscribeOnChat(this.chatUuid)
    },
    beforeDestroy: function () {
      this.unsubscribeOnChat(this.chatUuid || this.chat.uuid)
      this.resetChat()
      this.unseenMessages(this.chatUuid || this.chat.uuid)
    },
    props: {
      setChatUuid: {
        type: String,
        default: null
      },
      setTopicUuid: {
        type: String,
        default: null
      },
      mode: {
        type: String,
        default: 'page'
      }
    },
    watch: {
      '$route': 'chatChange',
      'loadings.load': 'onMessageChange',
      'lastMessage': 'onMessageChange',
      innerHeight: 'setCurrentHeight',
      chat: 'setInnerHeight',
    },
    components: {
      TalkMessage,
      ChatFooter,
      ChatProfileDialog,
      ChatToolbar
    }
  }
</script>
  
<style scoped>
  .chat__message-area { 
    margin-bottom: 45px;
    padding: 0 !important;
    overflow-y: scroll;
    background-repeat: repeat;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: calc(100vh - 168px);
  }
  .chat__message-area--dialog { 
    height: calc(100vh - 238px)
  }
  .chat__message-area-content {
    padding: 0px 20px 20px 20px;
  }
</style>
