<template>
  <v-dialog 
    v-model="dialog" 
    persistent scrollable 
    max-width="500px" 
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="category-selector">
      <!-- Header -->
      <v-toolbar dense class="dialog-toolbar">
        <v-icon large color="primary" class="mr-2">{{ icon }}</v-icon>
        <v-toolbar-title>
          <div class="title-content">
            <strong>{{ title }}</strong>
            <span class="subtitle" v-if="content && content.data && content.data.length">
              <span>Clique no </span>
              <v-icon color="green" small style="border: 1px solid green; border-radius: 50%; padding: 2px;">done</v-icon>
              <span> para selecionar a categoria.</span>
            </span>
            <span class="subtitle" v-else>
              <span>Nenhuma categoria encontrada.</span>
            </span>
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- Botão de expandir/recolher com tooltip -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="content && content.data && content.data.length" 
              icon @click="closeTreeview" v-on="on"
            >
              <v-icon>{{ isExpanded ? 'unfold_less' : 'unfold_more' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ isExpanded ? 'Recolher todas' : 'Expandir todas' }}</span>
        </v-tooltip>
        <v-btn icon @click="handleClose">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- WiView para buscar as categorias -->
      <WiView 
        ref="WNCategorySelector"
        index="wn-category-selector" 
        :apiUrl="apiUrl" 
        :notShowToolbar="true" 
        :notGetID="true" 
        @afterLoad="setContent"
      >
        <template slot-scope="{ content }">
          <!-- Conteúdo -->
          <v-card-text class="dialog-content">
            <v-container fluid class="pa-0">
              <v-treeview
                v-if="content.data && content.data.length"
                :items="content.data"
                :open="expandedItems"
                :item-key="itemIdField"
                :item-text="itemTextField"
                :item-children="itemChildrenField"
                open-on-click hoverable
                dense shaped open-all
                class="category-tree"
              >
                <template v-slot:prepend="{ item }">
                  <div class="d-flex align-center category-item">
                    <v-btn
                      @click.stop="selectItem(item)"
                      outline icon color="green" small class="mr-2"
                    >
                      <v-icon color="green">
                        done
                      </v-icon>
                    </v-btn>
                    <v-icon :color="item[itemColorField] || 'primary'" class="category-icon">
                      {{ item[itemIconField] || 'folder' }}
                    </v-icon>
                  </div>
                </template>

                <template v-slot:label="{ item }">
                  <div class="tree-label" :class="{
                    'current-category': isCurrentCategory(item),
                    'parent-category': isParentCategory(item)
                  }">
                    <span>{{ itemText(item) }}</span>
                  </div>
                </template>
              </v-treeview>

              <!-- Estado vazio redesenhado -->
              <div v-else class="empty-state">
                <div class="empty-state-container">
                  <div class="empty-state-icon-wrapper">
                    <v-icon :color="emptyStateIconColor" class="empty-state-icon">
                      {{ emptyStateIcon }}
                    </v-icon>
                    <div class="empty-state-icon-circle"></div>
                  </div>
                  <div class="empty-state-content">
                    <h3 class="empty-state-title primary--text">{{ emptyStateTitle }}</h3>
                    <p class="empty-state-message">{{ emptyStateMessage }}</p>
                  </div>
                  <div class="empty-state-footer">
                    <v-btn 
                      flat 
                      color="black" 
                      class="mt-4" 
                      @click="handleClose"
                    >
                      <v-icon left>arrow_back</v-icon>
                      Cancelar
                    </v-btn>
                    <v-btn 
                      v-if="
                        emptyStateAddCategory &&
                        emptyStateAddCategoryRoute &&
                        emptyStateAddCategoryPermission &&
                        $WiCheckPermission(emptyStateAddCategoryPermission)
                      "
                      flat 
                      color="green" 
                      class="mt-4" 
                      @click="handleAddCategory"
                    >
                      <v-icon left>open_in_new</v-icon>
                      Adicionar Categoria
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-container>
          </v-card-text>
        </template>
      </WiView>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WNCategorySelectorDialog',

  data: () => ({
    selectedItem: null,
    content: null,
    isExpanded: true,
    expandedItems: [],
    parentCategories: []
  }),

  computed: {
    dialog: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },

    hasSelection() {
      return !!this.selectedItem
    }
  },

  methods: {
    setContent({ content }) {
      this.content = content
      if (this.currentCategoryId) {
        this.expandPathToItem(this.content.data)
        this.findParentCategories(this.content.data)
      }
    },

    expandPathToItem(items) {
      for (const item of items) {
        if (item[this.itemIdField] === this.currentCategoryId) {
          return true
        }
        
        if (item[this.itemChildrenField] && item[this.itemChildrenField].length) {
          if (this.expandPathToItem(item[this.itemChildrenField])) {
            this.expandedItems.push(item[this.itemIdField])
            return true
          }
        }
      }
      return false
    },

    selectItem(item) {
      this.$emit('select', item)
      if (this.closeOnSelect) {
        this.handleClose()
      }
    },
    
    itemText(item) {
      return item[this.itemTextField]
    },

    handleClose() {
      this.dialog = false
      this.selectedItem = null
      this.$emit('close')
      this.$emit('cancel')
    },

    closeTreeview() {
      this.isExpanded = !this.isExpanded
      
      if (this.isExpanded) {
        // Expande todos os itens
        this.expandedItems = this.getAllIds(this.content?.data || [])
      } else {
        // Recolhe todos os itens
        this.expandedItems = []
      }
    },

    getAllIds(items) {
      let ids = []
      items.forEach(item => {
        ids.push(item.id)
        if (item.children && item.children.length) {
          ids = [...ids, ...this.getAllIds(item.children)]
        }
      })
      return ids
    },

    isCurrentCategory(item) {
      return item[this.itemIdField] === this.currentCategoryId;
    },

    findParentCategories(items, parentPath = []) {
      for (const item of items) {
        const currentPath = [...parentPath, item[this.itemIdField]]
        
        if (item[this.itemIdField] === this.currentCategoryId) {
          this.parentCategories = parentPath
          return true
        }
        
        if (item[this.itemChildrenField] && item[this.itemChildrenField].length) {
          if (this.findParentCategories(item[this.itemChildrenField], currentPath)) {
            return true
          }
        }
      }
      return false
    },

    isParentCategory(item) {
      return this.parentCategories.includes(item[this.itemIdField])
    },

    handleAddCategory() {
      if (this.emptyStateAddCategoryRoute) {
        this.$router.push(this.emptyStateAddCategoryRoute)
      }
    }
  },
  
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Selecionar Categoria'
    },
    subtitle: {
      type: String,
      default: 'Escolha uma categoria para continuar'
    },
    icon: {
      type: String,
      default: 'category'
    },
    openExpanded: {
      type: Boolean,
      default: true
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    apiUrl: {
      type: String,
      required: true
    },
    itemIdField: {
      type: String,
      default: 'id'
    },
    itemTextField: {
      type: String,
      default: 'name'
    },
    itemChildrenField: {
      type: String,
      default: 'children'
    },
    itemIconField: {
      type: String,
      default: 'icon'
    },
    itemColorField: {
      type: String,
      default: 'color'
    },
    currentCategoryId: {
      type: Number,
      default: null
    },
    emptyStateTitle: {
      type: String,
      default: 'Nenhuma categoria encontrada'
    },
    emptyStateMessage: {
      type: String,
      default: 'No momento não existem categorias cadastradas.'
    },
    emptyStateIcon: {
      type: String,
      default: 'category'
    },
    emptyStateIconColor: {
      type: String,
      default: 'grey'
    },
    emptyStateAddCategory: {
      type: Boolean,
      default: true
    },
    emptyStateAddCategoryText: {
      type: String,
      default: 'Adicionar Categoria'
    },
    emptyStateAddCategoryIcon: {
      type: String,
      default: 'open_in_new'
    },
    emptyStateAddCategoryPermission: {
      type: String,
      default: null
    },
    emptyStateAddCategoryRoute: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped>
.category-selector {
  display: flex;
  flex-direction: column;
  /* max-height: 90vh; */
  border-radius: 8px;
  min-height: 400px;
}

.dialog-toolbar {
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.title-content {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.title-content .subtitle {
  font-size: 12px;
  opacity: 0.7;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.category-item {
  cursor: pointer;
  border-radius: 8px;
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
}

.dialog-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  max-height: calc(100vh - 150px);
  /* max-height: 400px; */
  min-height: 400px;
}

.tree-label {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  padding: 4px 0;
}

.category-icon {
  margin-right: 4px;
}

.category-tree {
  padding: 8px;
}

.category-tree :deep(.v-treeview-node__root) {
  border-radius: 8px;
  margin: 2px 0;
  transition: all 0.2s ease;
  padding: 4px 8px;
  cursor: pointer;
}

.category-tree :deep(.v-treeview-node__root:hover) {
  background-color: rgba(var(--v-primary-base), 0.05);
}

/* Dark Theme */
.theme--dark .category-tree :deep(.v-treeview-node__root:hover) {
  background-color: rgba(255, 255, 255, 0.08);
}

.theme--dark .category-tree :deep(.v-treeview-node--selected > .v-treeview-node__root) {
  background-color: rgba(255, 255, 255, 0.12);
}

.tree-label.current-category {
  color: #f57c00;
  font-weight: bold;
  background-color: rgba(245, 124, 0, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  margin: -4px 0;
}

.tree-label.parent-category {
  color: #f57c00;
  font-weight: 500;
  font-style: italic;
}

/* Versão dark theme */
.theme--dark .tree-label.current-category {
  color: #ffb74d;
  background-color: rgba(255, 183, 77, 0.15);
}

.theme--dark .tree-label.parent-category {
  color: #ffb74d;
  opacity: 0.8;
}

.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: 2rem;
}

.empty-state-container {
  text-align: center;
  max-width: 400px;
}

.empty-state-icon-wrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 4rem;
}

.empty-state-icon {
  font-size: 64px !important;
  position: relative;
  z-index: 2;
}

.empty-state-icon-circle {
  position: absolute;
  width: 120px;
  height: 120px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.empty-state-content {
  padding: 0 1rem;
}

.empty-state-title {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.empty-state-message {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

/* Tema escuro */
.theme--dark .empty-state-icon-circle {
  background: rgba(255, 255, 255, 0.05);
}

.theme--dark .empty-state-message {
  color: rgba(255, 255, 255, 0.7);
}

/* Animações básicas compatíveis */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.empty-state-container {
  animation: fadeIn 0.5s ease-out;
}

.empty-state-icon {
  animation: iconFloat 2s ease-in-out infinite;
}

@keyframes iconFloat {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>