import Vue from 'vue'
import Vuex from 'vuex'
import {Api} from '@/default/service/Api'
import {Error} from '@/default/service/Error'
// Modules
import url from '@/default/store/modules/url/url'
import pages from '@/default/store/modules/pages/pages'
import Pages from '@/default/pages/pages'
import style from '@/default/store/modules/style/style'
import system from '@/default/store/modules/system/system'
import auth from '@/default/store/modules/auth/auth'
import User from '@/default/store/modules/User/User'
import WiView from '@/default/store/modules/components/WiView'
import WiDialog from '@/default/store/modules/components/WiDialog'
import WiFileDialog from '@/default/store/modules/components/WiFileDialog'
import WiEditDialog from '@/default/store/modules/components/WiEditDialog'
import WiListDialog from '@/default/store/modules/components/WiListDialog'
import WiHelpDialog from '@/default/store/modules/components/WiHelpDialog'
import WiEventsDialogs from '@/default/store/modules/components/WiEventsDialogs'
import WiFileListDialog from '@/default/store/modules/components/WiFileListDialog'
import WiRemediesDialog from '@/default/store/modules/components/WiRemediesDialog'
import WiCalendar from '@/default/store/modules/components/WiCalendar/WiCalendar'
import Talk from '@/default/store/modules/Talks/Talk'
import Talks from '@/default/store/modules/Talks/Talks'
import Chat from '@/default/store/modules/Chats/Chat'
import Chats from '@/default/store/modules/Chats/Chats'
import ChatTopics from '@/default/store/modules/Chats/ChatTopics'
import DailyReport from '@/default/store/modules/Reports/DailyReport'
import ProcessExecution from '@/default/store/modules/ProcessExecution'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    data: {}
  },
  mutations: {
    data (state, {name, data}) {
      Vue.set(state.data, name, data)
    },
    dataF (state, {name, key, data}) {
      Vue.set(state.data[name], key, data)
    },
    loading (state, {name, prefix}) {
      state.pages[name]['loading_' + prefix] = !state.pages[name]['loading_' + prefix]
    },
    style (state, data) {
      state.style = data
    },
    auth (state, data) {
      state.auth = data
    },
    user (state, data) {
      state.user = data
    }
  },
  actions: {
    getArray: function (state, {name, params, action}) {
      var urlParams = ''
      var url = state.state.url
      if (params) {
        var i = 0
        for (var key in params) {
          if (!i) {
            urlParams = '?' + key + '=' + params[key]
          } else {
            urlParams += '&' + key + '=' + params[key]
          }
          i++
        }
      }
      store.commit('loading', {name: name, prefix: 'list'})
      Api.get({url: url.base + url.version + url.group + state.state.pages[name].apiUrl + urlParams})
      .then((data) => {
        store.commit('data', {name: name + '_list', data: data.data})
        store.commit('loading', {name: name, prefix: 'list'})
      })
      .catch((error) => {
        Error.make({ error: error, id: 105, title: 'Oops... ocorreu um erro ao buscar informações.' })
        if (action) {
          action(false)
        }
        store.commit('loading', {name: name, prefix: 'list'})
      })
    },
    getObject: function (state, {name, id, params, action, prefix}) {
      var url = state.state.url
      var urlParams = ''
      var pre = prefix || 'edit'
      if (params) {
        var i = 0
        for (var key in params) {
          if (!i) {
            urlParams = '?' + key + '=' + params[key]
          } else {
            urlParams += '&' + key + '=' + params[key]
          }
          i++
        }
      }
      store.commit('loading', {name: name, prefix: pre})
      Api.get({url: url.base + url.version + url.group + state.state.pages[name].apiUrl + '/' + id + urlParams})
      .then((data) => {
        store.commit('data', {name: name + '_' + pre, data: data.data})
        store.commit('loading', {name: name, prefix: pre})
      })
      .catch((error) => {
        Error.make({ error: error, id: 105, title: 'Oops... ocorreu um erro ao buscar informações.' })
        if (action) {
          action(false)
        }
        store.commit('loading', {name: name, prefix: pre})
      })
    },
    postObject: function (state, {name, send, action}) {
      var url = state.state.url
      var uri = state.state.pages[name].apiUrlEdit || state.state.pages[name].apiUrl
      store.commit('loading', {name: name, prefix: 'edit'})
      Api.post({url: url.base + url.version + url.group + uri}, send)
      .then((data) => {
        store.commit('data', {name: name + '_edit', data: data.data})
        store.commit('loading', {name: name, prefix: 'edit'})
        action(data.data)
      })
      .catch((error) => {
        Error.make({ error: error, id: 205, title: 'Oops... ocorreu um erro ao inserir' })
        if (action) {
          action(false)
        }
        store.commit('loading', {name: name, prefix: 'edit'})
      })
    },
    putObject: function (state, {name, id, send, action}) {
      var url = state.state.url
      var uri = state.state.pages[name].apiUrlEdit || state.state.pages[name].apiUrl
      store.commit('loading', {name: name, prefix: 'edit'})
      Api.put({url: url.base + url.version + url.group + uri + '/' + id}, send)
      .then((data) => {
        store.commit('data', {name: name + '_edit', data: data.data})
        store.commit('loading', {name: name, prefix: 'edit'})
        action(data.data)
      })
      .catch((error) => {
        Error.make({ error: error, id: 305, title: 'Oops... ocorreu um erro ao atualizar' })
        if (action) {
          action(false)
        }
        store.commit('loading', {name: name, prefix: 'edit'})
      })
    },
    deleteObject: function (state, {name, id, action}) {
      var url = state.state.url
      var uri = state.state.pages[name].apiUrlEdit || state.state.pages[name].apiUrl
      store.commit('loading', {name: name, prefix: 'edit'})
      Api.delete({url: url.base + url.version + url.group + uri + '/' + id})
      .then((data) => {
        store.commit('data', {name: name + '_edit', data: {}})
        store.commit('loading', {name: name, prefix: 'edit'})
        action(data.data)
      })
      .catch((error) => {
        Error.make({ error: error, id: 405, title: 'Oops... ocorreu um erro ao apagar' })
        if (action) {
          action(false)
        }
        store.commit('loading', {name: name, prefix: 'edit'})
      })
    },
    deleteData: function (state, name) {
      if (state.state.data[name]) {
        delete state.state.data[name]
      }
    }
  },
  getters: {},
  modules: {
    url,
    pages,
    Pages,
    style,
    system,
    auth,
    User,
    WiView,
    WiDialog,
    WiEditDialog,
    WiFileDialog,
    WiListDialog,
    WiHelpDialog,
    WiEventsDialogs,
    WiRemediesDialog,
    WiFileListDialog,
    WiCalendar,
    Talk,
    Talks,
    Chat,
    Chats,
    ChatTopics,
    ProcessExecution,
    DailyReport
  }
})
export default store