import i18n from '@/lang/i18n'
import {
  PERMISSION_FORM
} from '@/default/constants/permissions'
import ContractMenu from '@/modules/sales/menu/ContractMenu'

export default [
  {
    id: '8123ce38-df17-4302-b84f-fe90f7171fe0',
    icon: 'dashboard',
    title: i18n.t('modules.sales.menu.dashboard.title'),
    route: {
      name: 'dashboard-sales'
    }
  },
  {
    id: 'fbded5ac',
    icon: 'local_grocery_store',
    title: i18n.t('modules.sales.menu.sales.title'),
    children: [
      {
        id: 'fbded5ac',
        icon: 'view_week',
        title: i18n.t('modules.sales.menu.sales.children.process.title'),
        route: {
          name: 'process-of-sales',
          params: {
            business: 'sale'
          }
        }
      },
      {
        id: 'fbded5ac',
        icon: 'select_all',
        title: i18n.t('modules.sales.menu.sales.children.open.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'sale-open'
          }
        }
      },
      {
        id: 'fbded5ac',
        icon: 'done_outline',
        title: i18n.t('modules.sales.menu.sales.children.history.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'sale-close'
          }
        }
      },
      {
        id: 'fbded5ac',
        icon: 'close',
        title: i18n.t('modules.sales.menu.sales.children.canceled.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'sale-cancel'
          }
        }
      }
    ]
  },
  {
    id: '10b505c9',
    icon: 'shop',
    title: i18n.t('modules.sales.menu.budgets.title'),
    children: [
      {
        id: '10b505c9',
        icon: 'view_week',
        title: i18n.t('modules.sales.menu.budgets.children.process.title'),
        route: {
          name: 'process-of-sales',
          params: {
            business: 'budget'
          }
        }
      },
      {
        id: '10b505c9',
        icon: 'select_all',
        title: i18n.t('modules.sales.menu.budgets.children.open.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'budget-open'
          }
        }
      },
      {
        id: '10b505c9',
        icon: 'done_outline',
        title: i18n.t('modules.sales.menu.budgets.children.approved.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'budget-close'
          }
        }
      },
      {
        id: '10b505c9',
        icon: 'file_copy',
        title: i18n.t('modules.sales.menu.budgets.children.templates.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'budget-template'
          }
        }
      },
      {
        id: '10b505c9',
        icon: 'close',
        title: i18n.t('modules.sales.menu.budgets.children.rejected.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'budget-cancel'
          }
        }
      }
    ]
  },
  ContractMenu,
  {
    id: '7bea0895',
    icon: 'bar_chart',
    title: i18n.t('modules.sales.menu.reports.title'),
    children: [
      {
        id: '1b4f745c',
        icon: 'bar_chart',
        title: 'Vendas por período',
        route: {
          name: 'sales-by-period',
          query: {
            date_field: 'date_start',
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01',
            date_end: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + String(new Date().getDate()).padStart(2, '0')
          }
        }
      },
      {
        id: '1b4f745c',
        icon: 'people_outline',
        title: i18n.t('modules.sales.menu.reports.children.salesByCustomer.title'),
        route: {
          name: 'sales-by-customer',
          query: {
            date_field: 'date_start',
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01'
          }
        }
      },
      {
        id: '919bb8fa',
        icon: 'people_outline',
        title: i18n.t('modules.sales.menu.reports.children.budgetsByCustomer.title'),
        route: {
          name: 'budgets-by-customer',
          query: {
            date_field: 'date_start',
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01'
          }
        }
      },
      {
        id: 'dab5ce52',
        icon: 'assignment_ind',
        title: i18n.t('modules.sales.menu.reports.children.salesByCollaborator.title'),
        route: {
          name: 'sales-by-collaborator',
          query: {
            date_field: 'date_start',
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01'
          }
        }
      },
      {
        id: 'bffe8c55',
        icon: 'assignment_ind',
        title: i18n.t('modules.sales.menu.reports.children.budgetsByCollaborator.title'),
        route: {
          name: 'budgets-by-collaborator',
          query: {
            date_field: 'date_start',
            date_start: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0') + '-' + '01'
          }
        }
      }
    ]
  },
  {
    id: 'a02e979e',
    icon: 'description',
    title: i18n.t('modules.sales.menu.register.title'),
    children: [
      {
        id: 'a02e979e',
        icon: 'local_grocery_store',
        title: i18n.t('modules.sales.menu.register.children.products.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-product'
          }
        }
      },
      {
        id: 'a02e979e',
        icon: 'room_service',
        title: i18n.t('modules.sales.menu.register.children.services.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-service'
          }
        }
      },
      {
        id: 'a02e979e',
        icon: 'assignment_ind',
        title: i18n.t('modules.sales.menu.register.children.collaborators.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-collaborator'
          }
        },
        selected: false,
        permission: ['admin']
      },
      {
        id: 'a02e979e',
        icon: 'people_outline',
        title: i18n.t('modules.sales.menu.register.children.customers.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-customer'
          }
        },
        selected: false
      },
      {
        id: 'a02e979e',
        icon: 'card_giftcard',
        title: i18n.t('modules.sales.menu.register.children.coupons.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'sales-register-coupon'
          }
        },
        selected: false
      }
    ]
  },
  {
    id: 'b9a915a3',
    icon: 'settings',
    title: i18n.t('modules.sales.menu.settings.title'),
    children: [
      {
        id: 'b9a915a3',
        icon: 'code',
        title: i18n.t('modules.sales.menu.settings.children.contractStatus.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'config-status-contract'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'category',
        title: i18n.t('modules.sales.menu.settings.children.contractCategories.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'config-category-contract'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'code',
        title: i18n.t('modules.sales.menu.settings.children.saleStatus.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'config-status-sale'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'category',
        title: i18n.t('modules.sales.menu.settings.children.saleCategories.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'config-category-sale'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'code',
        title: i18n.t('modules.sales.menu.settings.children.budgetStatus.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'config-status-budget'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'category',
        title: i18n.t('modules.sales.menu.settings.children.budgetCategories.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'config-category-budget'
          }
        }
      },
      {
        id: PERMISSION_FORM,
        icon: 'list_alt',
        title: i18n.t('modules.sales.menu.settings.children.forms.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'service-desk-config-form'
          }
        }
      },
      {
        id: 'b9a915a3',
        icon: 'category',
        title: i18n.t('modules.sales.menu.settings.children.businessItemCategories.title'),
        route: {
          name: 'sales-list',
          params: {
            page: 'business-item-category'
          }
        }
      }
    ]
  }
]