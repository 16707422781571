import Notifications from '@/default/pages/MainNew/store/modules/Notifications'

export default {
  namespaced: true,
  state: {
    dark: false,
    drawerLeft: {
      active: null,
      isMini: false
    },
    drawerRight: {
      active: false
    },
    drawerTalks: {
      active: false
    },
    drawerChats: {
      active: false
    },
    selectedModule: null
  },
  mutations: {
    SET_DARK (state, data) {
      state.dark = data
    },
    TOUGGLE_DRAWER_LEFT_ACTIVE (state) {
      state.drawerLeft.active = !state.drawerLeft.active
    },
    SET_DRAWER_LEFT_ACTIVE (state, active) {
      state.drawerLeft.active = active
    },
    SET_DRAWER_LEFT_IS_MINI (state, data) {
      state.drawerLeft.isMini = data
    },
    TOUGGLE_DRAWER_RIGHT_ACTIVE (state) {
      state.drawerRight.active = !state.drawerRight.active
    },
    TOUGGLE_DRAWER_TALKS_ACTIVE (state) {
      state.drawerTalks.active = !state.drawerTalks.active
    },
    TOUGGLE_DRAWER_CHATS_ACTIVE (state) {
      state.drawerChats.active = !state.drawerChats.active
    },
    SET_SELECTED_MODULE (state, data) {
      state.selectedModule = data
    }
  },
  actions: {
    touggleDrawerLeftIsMini: ({ state, commit, dispatch }) => {
      commit('SET_DRAWER_LEFT_IS_MINI', !state.drawerLeft.isMini)
      dispatch('setDrawerLeftIsMiniInStorage')
    },
    getDrawerLeftIsMiniFromStorage: ({ commit }) => {
      if (localStorage.getItem('8b398d37')) {
        commit('SET_DRAWER_LEFT_IS_MINI', true)
      } else {
        commit('SET_DRAWER_LEFT_IS_MINI', false)
      }
    },
    setDrawerLeftIsMiniInStorage: ({ state }) => {
      if (state.drawerLeft.isMini) {
        localStorage.setItem('8b398d37', true )
      } else {
        localStorage.removeItem('8b398d37')
      }
    },
    touggleDark: ({ state, commit, dispatch }) => {
      commit('SET_DARK', !state.dark)
      dispatch('setDarkInStorage')
    },
    getDarkFromStorage: ({ commit }) => {
      if (localStorage.getItem('cc7ef09e')) {
        commit('SET_DARK', true)
        commit('style/SET_DARK', true, {root: true})
      } else {
        commit('SET_DARK', false)
        commit('style/SET_DARK', false, {root: true})
      }
    },
    setDarkInStorage: ({ state }) => {
      if (state.dark) {
        localStorage.setItem('cc7ef09e', state.dark)
      } else {
        localStorage.removeItem('cc7ef09e')
      }
    },
    selectModule: ({ commit }, module) => {
      commit('SET_SELECTED_MODULE', module)
    }
  },
  modules: {
    Notifications
  }
}