export default {
  data: () => ({
    openBudgetFullLoading: false
  }),
  methods: {
    openBudgetFull: function (data) {
      this.openBudgetFullLoading = true
      this.$WiApiGet({
        uri: `sales/budget/${data.id}`,
        params: {
          with: 'person;address;finance_account;talk_contact'
        },
        callback: ({ success }) => {
          const { person, address, finance_account, talk_contact } = success
          delete success.events
          delete success.collaborator
          delete success.status
          delete success.category
          this.$WiEditDialog({
            wiConfig: 'budget-full',
            data: {
              ...success,
              address: address,
              finance_account: finance_account,
              talk_contact: talk_contact,
              birthday: person.birthday,
              rg: person.rg,
              email: person.email,
              rg_issuing_agency: person.rg_issuing_agency,
              rg_state_id: person.rg_state_id,
              rg_issue_date: person.rg_issue_date,
              additional_info: person.additional_info,
              mothers_name: person.mothers_name,
              fathers_name: person.fathers_name,
              naturalness: person.naturalness,
              nationality: person.nationality,
              sex: person.sex,
              marital_status: person.marital_status,
              income_gross: person.income_gross,
              salary_liquid: person.salary_liquid,
              social_name: person.social_name
            },
            onSubmit: (response) => {
              console.log('response', response)
              this.$emit('refresh')
              this.$emit('onSubmit')
            }
          })
          this.openBudgetFullLoading = false
        }
      })
    }
  }
}
