<template>
  <v-card flat style="width: 350px;">
    <v-toolbar dense flat :color="$wiDark ? 'dark' : 'primary'" class="white--text ml-0 pl-0">
      <h3
        v-if="contact && contact.type == 1"
        v-t="'modules.talks.pages.talkView.talk.contact.title.contact'"
      ></h3>
      <h3
        v-if="contact && contact.type == 2"
        v-t="'modules.talks.pages.talkView.talk.contact.title.group'"
      ></h3>
      <v-spacer></v-spacer>
      <!-- <v-btn icon @click="refreshContact()">
        <v-icon color="white">refresh</v-icon>
      </v-btn>
      <v-btn icon @click="onClose()">
        <v-icon color="white">close</v-icon>
      </v-btn> -->
      <v-btn v-if="!$vuetify.breakpoint.xsOnly" icon small ripple @click="onClose()" style="color: white;">
        <v-icon>menu_open</v-icon>
      </v-btn>
      <v-btn icon small ripple @click="getTalk(talkId)" :loading="loadings.load" style="color: white;">
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-btn v-if="!$vuetify.breakpoint.xsOnly" icon small ripple @click="closeAction()" style="color: white;">
        <v-icon>close</v-icon>
      </v-btn>
      <v-btn v-else icon small ripple @click="onClose()" style="color: white;">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <div style="text-align: center !important; border-left: solid 1px #80808038; border-right: solid 1px #80808038;">
      <TalkContactName :contact="contact" :refresh="refresh" />
      <TalkContactProfiles :contact="contact" :refresh="refresh" />
    </div>
    <v-card-text style="text-align: center !important; max-height: 1000px; padding: 0; border-left: solid 1px #80808038; border-right: solid 1px #80808038;">
      <TalkContactObservations :contact="contact" />
      <TalkContactMembers :contact="contact" />
      <TalkContactTabs :talk="talk" :contact="contact" :refresh="refresh" />
      <TalkContactSelectProfile :contact="contact" :refresh="refresh" />
    </v-card-text>  
  </v-card>
</template>
<script>
  import talkViewMixin from '../../talkViewMixin'
  import TalkContactTabs from './TalkContactTabs'
  import TalkContactName from './TalkContactName'
  import TalkContactMembers from './TalkContactMembers'
  import TalkContactProfiles from './TalkContactProfiles'
  import { mapState, mapMutations, mapActions } from 'vuex'
  import TalkContactObservations from './TalkContactObservations'
  import TalkContactSelectProfile from './TalkContactSelectProfile'
  export default {
    data () {
      return {
        dialog: false,
        profileImageDialog: false,
        memberSearch: ''
      }
    },
    computed: {
      ...mapState('Talk', ['showContactInfo', 'talk', 'loadings'])
    },
    methods: {
      ...mapMutations('Talk', ['TOUGGLE_SHOW_CONTACT_INFO']),
      ...mapActions('Talk', ['getTalk']),
      onClose: function () {
        this.TOUGGLE_SHOW_CONTACT_INFO()
      },
      closeAction: function () {
        if (this.mode === 'dialog') {
          this.$emit('onClose')
        } else {
          this.$router.push({name: 'talk-view'})
        }
      }
    },
    props: {
      contact: {
        required: true
      },
      refresh: {
        required: true
      },
      mode: {
        type: String,
        default: 'page'
      },
      talkId: {
        required: true
      }
    },
    mixins: [talkViewMixin],
    components: {
      TalkContactTabs,
      TalkContactName,
      TalkContactMembers,
      TalkContactProfiles,
      TalkContactObservations,
      TalkContactSelectProfile
    }
  }
</script>