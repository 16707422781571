<template>
  <v-card class="empty-state-card">
    <div class="empty-state-content">
      <div class="illustration-container">
        <v-icon size="80" color="primary">folder_open</v-icon>
        <div class="illustration-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      
      <h2>Nenhuma categoria disponível</h2>
      <p>
        Você ainda não possui categorias cadastradas.
        Crie sua primeira categoria para começar a organizar seus itens.
      </p>
      
      <v-btn
        color="primary"
        class="mt-4"
        @click="$emit('create')"
      >
        <v-icon left>add</v-icon>
        Criar primeira categoria
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'EmptyCategories'
}
</script>

<style scoped>
.empty-state-card {
  max-width: 600px;
  margin: 32px auto;
  text-align: center;
  padding: 48px 24px;
}

.theme--dark.empty-state-card {
  background-color: #1E1E1E !important;
}

.empty-state-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin: 32px 0 16px;
  font-size: 24px;
}

.theme--dark h2 {
  color: #fff;
}

p {
  color: rgba(0, 0, 0, 0.6);
  max-width: 400px;
  line-height: 1.6;
  margin-bottom: 16px;
}

.theme--dark p {
  color: rgba(255, 255, 255, 0.7);
}

.illustration-dots {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  justify-content: center;
}

.illustration-dots span {
  width: 8px;
  height: 8px;
  background: currentColor;
  border-radius: 50%;
  opacity: 0.3;
}

@media (max-width: 600px) {
  .empty-state-card {
    margin: 16px;
    padding: 32px 16px;
  }
}
</style> 