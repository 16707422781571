<template>
  <div style="max-width: 100%;">
    <div style="max-width: 100%;">
      <v-list dense two-line>
        <v-list-tile :disabled="disabled" v-ripple avatar @click="dialog = true" style="border-style: solid; border-color: gray; border-radius: 10px; border-width: 1px; height: 45px !important; padding-left: 5px !important; padding-right: 5px !important;" class="asdasdasd">
          <v-list-tile-avatar>
            <v-icon large>{{icon}}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title>Ícone</v-list-tile-sub-title>
            <v-list-tile-title v-if="icon">
              <span>{{ icon | stringlength(10) }}</span>
            </v-list-tile-title>
            <v-list-tile-title v-else>
              <span>{{ 'Selecione...' | stringlength(10) }}</span>
            </v-list-tile-title>
            <v-input style="display: none;" :required="required" :rules="rules" v-model="setIcon"></v-input>
          </v-list-tile-content>
          <v-list-tile-action style="min-width: unset !important;">
            <v-btn flat icon color="green" @click="dialog = true">
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </div>
    <v-dialog v-model="dialog" scrollable max-width="85%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card :style="{ background: $vuetify.theme.dark ? '#1E1E1E' : '#fafafa' }" style="border-radius: 10px;">
        <v-toolbar dense primary style="z-index: 10; border-top-left-radius: 10px; border-top-right-radius: 10px;">
          <v-toolbar-title>
            Google Icons
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="hidden-xs-only hidden-sm-only" style="min-width: 450px;">
            <div class="text-xs-center">
              <v-text-field 
                style="color: white; width: 100%" 
                v-model="search"  
                color="white"  
                :append-icon="search != '' ? 'close' : 'search'"  
                placeholder="Pesquise o nome do ícone (English)"
                solo   
                @click:append="() => { if(search != '') { search = '' } }" 
                hide-details  
                single-line
                :autofocus="true"
                dark>
              </v-text-field>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon flat @click="closeDialog()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text v-if="dialog" style="height: calc(100vh - 228px); overflow-y: auto;">
          <v-container fluid style="padding: 0 !important;">
            <v-layout row wrap>
              <v-flex xs12 style="text-align: center !important; padding-bottom: 20px;">
                <h3>Clique sobre o ícone que desejar</h3>
              </v-flex>
              <v-flex xs12 sm4 md3 lg2 v-for="(thisIcon, iconKey) in paginatedIcons" :key="iconKey">
                <v-card 
                  style="margin: 10px; cursor: pointer; border-radius: 10px;" 
                  @click="select(thisIcon)" 
                  :color="icon == thisIcon ? 'orange' : undefined"
                >
                  <v-card-text style="text-align: center !important;">
                    <v-icon x-large :color="$vuetify.theme.dark ? 'white' : 'black'">{{thisIcon}}</v-icon>
                    <br>
                    <v-tooltip bottom>
                      <template slot="activator">
                        <span>{{ thisIcon | stringlength(18) }}</span>
                      </template>
                      <span>{{ thisIcon }}</span>
                    </v-tooltip>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <!-- Paginação adaptada para tema dark -->
        <div :style="{
          background: $vuetify.theme.dark ? '#272727' : 'white',
          height: '48px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          boxShadow: $vuetify.theme.dark 
            ? '0 -4px 6px -1px rgba(0, 0, 0, 0.2), 0 -2px 4px -1px rgba(0, 0, 0, 0.15)'
            : '0 -4px 6px -1px rgba(0, 0, 0, 0.05), 0 -2px 4px -1px rgba(0, 0, 0, 0.03)'
        }">
          <v-btn :disabled="currentPage === 1" @click="prevPage" icon small>
            <v-icon>chevron_left</v-icon>
          </v-btn>
          <span class="px-2">Página {{ currentPage }} de {{ totalPages }}</span>
          <v-btn :disabled="currentPage === totalPages" @click="nextPage" icon small>
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import {Icons} from '@/default/component/WiGoogleIcons/WiGoogleIcons'
  export default {
    data: () => ({
      dialog: false,
      search: '',
      itemsPerPage: 48,
      currentPage: 1
    }),
    methods: {
      select: function (icon) {
        this.$emit('select', {icon: icon, field: this.field})
        this.dialog = false
      },
      closeDialog: function () {
        this.dialog = false
      },
      nextPage () {
        if (this.currentPage < this.totalPages) {
          this.currentPage++
        }
      },
      prevPage () {
        if (this.currentPage > 1) {
          this.currentPage--
        }
      }
    },
    computed: {
      icons () {
        return Icons.get()
      },
      filteredList () {
        let filtered = this.icons.filter(thisIcon => {
          return thisIcon.toLowerCase().includes(this.search.toLowerCase())
        })

        // Se houver um ícone selecionado, move ele para o início da lista
        if (this.icon) {
          const selectedIndex = filtered.indexOf(this.icon)
          if (selectedIndex > -1) {
            filtered = [
              filtered[selectedIndex],
              ...filtered.slice(0, selectedIndex),
              ...filtered.slice(selectedIndex + 1)
            ]
          }
        }

        return filtered
      },
      paginatedIcons () {
        const start = (this.currentPage - 1) * this.itemsPerPage
        const end = start + this.itemsPerPage
        return this.filteredList.slice(start, end)
      },
      totalPages () {
        return Math.ceil(this.filteredList.length / this.itemsPerPage)
      },
      setIcon: {
        get () {
          return this.icon
        },
        set () {}
      }
    },
    watch: {
      // Resetar para primeira página quando buscar
      search() {
        this.currentPage = 1
      }
    },
    props: {
      'icon': {
        type: String
      },
      'field': {
        type: String
      },
      'disabled': {
        required: false,
        type: Boolean,
        default: false
      },
      'required': {
        required: false,
        type: Boolean,
        default: false
      },
      'rules': {
        required: false,
        type: Array
      }
    }
  }
</script>
<style scoped>
.asdasdasd >>> a {
  height: 45px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
}

.v-card__text {
  overflow-y: auto;
}

/* Ajustes para tema dark */
.v-application.theme--dark .v-card {
  background-color: #1E1E1E;
}

.v-application.theme--dark .v-toolbar {
  background-color: #272727;
}
</style>