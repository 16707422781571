<template>
  <v-switch
    :label="formatLabel(field)"
    v-model="localValue"
    @change="handleChange"
    :rules="field.required ? validField(field) : []"
    :required="isRequired"
    :disabled="isDisabled"
  ></v-switch>
</template>

<script>
export default {
  name: 'WiEditFieldSwitch',
  
  props: {
    field: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    value: {
      required: true
    }
  },

  mounted() {
    if (
      // (this.value == undefined || this.value == null) &&
      this.field?.defaultValue
    ) {
      this.localValue = this.field.defaultValue
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    isRequired() {
      return typeof this.field.required === 'function' 
        ? this.field.required(this.form) 
        : this.field.required
    },

    isDisabled() {
      return typeof this.field.disabled === 'function'
        ? this.field.disabled(this.form)
        : this.field.disabled
    }
  },

  methods: {
    formatLabel({ required = false, text = 'Campo' }) {
      if (required) {
        return `${text}*`
      }
      return text
    },

    validField(field) {
      if (field.rules) {
        if (typeof field.rules === 'function') {
          return field.rules(this.form)
        } else {
          return field.rules
        }
      }
      return [v => !!v || 'Campo obrigatório']
    },

    handleChange(event) {
      this.$emit('change', event)
    }
  }
}
</script> 