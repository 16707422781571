<template>
  <div class="business-actions">
    <v-btn
      v-prms="{'id': $route.meta.permission}"
      small outline color="white"
      class="hidden-xs-only hidden-sm-only"
      @click="addNote()" 
      :disabled="disableButtons"
    >
      <v-icon>add</v-icon>
      <span>Adicionar Nota</span>
    </v-btn>
    <v-btn
      v-if="!business.description"
      small
      outline
      color="white"
      @click="createDocument()"
      v-prms="{'id': $route.meta.permission}"
      :disabled="disableButtons"
    >
      <v-icon>note_add</v-icon>
      <span>Criar Documento</span>
    </v-btn>
    <v-btn
      v-else
      small
      outline
      color="white"
      @click="viewLayoutDialog = true"
    >
      <v-icon>description</v-icon>
      <span>Acessar Documento</span>
    </v-btn>
    <BusinessLayoutDialog
      v-if="viewLayoutDialog"
      v-model="viewLayoutDialog"
      :business="business"
      :refresh="refresh"
    ></BusinessLayoutDialog>
  </div>
</template>
<script>
  import BusinessLayoutDialog from '../BusinessLayout/BusinessLayoutV2Dialog'
  export default {
    name: 'BusinessActions',
    data: () => ({
      viewLayoutDialog: false
    }),
    computed: {
      disableButtons () {
        return this.loading || this.business.stage == 2 || this.business.stage == 3
      }
    },
    methods: {
      addNote: function () {
        this.$WiEditDialog({
          wiConfig: 'sale-event',
          onSubmit: this.refresh,
          data: {
            business_id: this.business.id
          }
        })
      },
      createDocument: function () {
        this.$WiApiPost({
          uri: `business/layout/${this.business.id}`,
          callback: this.refresh,
          config: {
            msgLoad: {
              title: `Criando documento...`,
              text: `Processando e criando ${this.business.info.title}`
            },
            msgSucess: {
              title: 'Documento criado com sucesso!',
              text: 'O documento irá abrir em instantes.'
            },
            msgError: {
              title: 'Erro ao criar documento',
              text: 'Tente novamente ou contacte o suporte.'
            }
          }
        })
      }
    },
    props: {
      business: {
        type: Object,
        required: true
      },
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Function,
        required: true
      }
    },
    components: {
      BusinessLayoutDialog
    }
  }
</script>
