import { Model } from '@/default/model/Model'

export class BudgetTemplate extends Model {
  constructor () {
    super()
    this.id = {
      id: '10b505c9',
      create: 'b9fba2ea',
      update: 'd3c48579',
      delete: '55bf11f3'
    }
    this.title = {
      list: 'Modelos de Orçamentos',
      edit: {
        create: 'Novo Modelo de Orçamento',
        update: 'Editar Modelo de Orçamento'
      }
    }
    this.params = {
      limit: 10,
      where: 'is_template,1'
    }
    this.urlDetails = {
      name: 'budget-template',
      params: {}
    }
    this.icon = 'shop'
    this.apiUrl = 'sales/budget/template'
    this.maxWidth = '700px'
    this.search = {
      placeholder: 'Pesquise ID ou Nome do Modelo',
      fields: ['id', 'title']
    }
    this.maxWidth = '480px'

    this.components = {
      list: {
        tableRow: () => import('@/modules/sales/pages/Budgets/BudgetTemplates')
      }
    }
    
    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)

    this.fieldsFixed = [
      {
        value: 'is_template',
        data: 1
      }
    ]
  }
  
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    title: this.fieldList({
      value: 'title',
      text: 'Nome do Modelo'
    }),
    options: this.fieldList({
      value: 'options',
      text: 'Opções',
      align: 'center'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome do modelo.'
    }),
    title: this.fieldText({
      value: 'title',
      text: 'Nome do Modelo',
      required: true,
    }),
    category: this.fieldSelectApi({
      value: 'business_category_id',
      text: 'Categoria',
      required: true,
      api: {
        url: 'sales/config/category/budget',
        params: {fields: 'id,name,icon,color', order: 'name,asc'}
      },
      show: 'name',
      icon: 'icon',
      color: 'color'
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Se quiser, informe o Deslocamento e Desconto.'
    }),
    amountFreight: this.fieldMoney({
      value: 'amount_freight',
      text: 'Valor Deslocamento',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    amountDiscount: this.fieldMoney({
      value: 'amount_discount',
      text: 'Valor de Desconto',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),
    subHeaderObservation: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para registrar observações.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Use este campo para adicionar observações...'
    })
  }
}
