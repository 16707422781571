<template>
  <v-card class="elevation-17">
    <v-card-text style="padding: 0;">
      <v-container style="padding: 0px !important;">
        <v-layout row wrap>
          <v-flex v-for="(info, infoKey) in info" :key="infoKey" v-bind="sizeField(info.size)">
            <v-list two-line style="padding: 0 !important;">
              <template v-for="(item, itemKey) in info.items">
                <div :key="itemKey">
                  <v-list-tile avatar>
                    <v-list-tile-avatar>
                      <v-avatar>
                        <img v-if="item.image" :src="'/static/icons/monitor/' + getItem('image', item)" :alt="getItem('title', item)">
                        <v-icon v-if="item.icon" large :style="{color: item.color ? getItem('color', item) : '#000'}">{{getItem('icon', item)}}</v-icon>
                      </v-avatar>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-sub-title v-if="item.text" v-html="getItem('text', item)"></v-list-tile-sub-title>
                      <v-list-tile-title v-if="item.data || item.value">
                        <strong v-html="getItem('data', item) || '---'" :style="getItem('style', item)"></strong>
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action v-if="item.actions">
                      <div v-for="(action, actionKey) in item.actions" :key="actionKey">
                        <wi-copy-to v-if="action.copy && getItem('data', item)" :text="getItem('data', item)"></wi-copy-to>
                        <v-btn v-else icon flat v-prms="{'id': action.id}" :disabled="getItem('disabled', action)" @click="runAction(action, data)">
                          <v-tooltip v-if="getItem('description', action)" top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">{{getItem('icon', action)}}</v-icon>
                            </template>
                            <span>{{getItem('description', action)}}</span>
                          </v-tooltip>
                          <v-icon v-else>{{getItem('icon', action)}}</v-icon>
                        </v-btn>
                      </div>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider></v-divider>
                </div>
              </template>
            </v-list>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    name: 'BudgetTemplateInfo',
    data () {
      return {
        info: [
          {
            size: ['xs12', 'sm12', 'md5', 'lg5'],
            items: [
              {
                image: 'fingerprint_96.png',
                text: 'Código/ID:',
                data: (item, data) => { return '#' + data.id },
                actions: [
                  {
                    copy: true,
                    description: 'Copiar Código/ID'
                  }
                ]
              },
              {
                icon: (item, data) => { return data.category.icon },
                color: (item, data) => { return data.category.color },
                text: 'Categoria:',
                data: (item, data) => { return data.category.name }
              }
            ]
          },
          {
            size: ['xs12', 'sm12', 'md3', 'lg3'],
            items: [
              {
                image: 'date_start_96.png',
                text: 'Data de Criação:',
                value: 'date_start_format'
              },
              {
                image: 'worker_male_96.png',
                text: 'Criador',
                data: (item, data) => { return data.collaborator.name },
                actions: [
                  {
                    icon: 'info',
                    route: {name: 'collaborator', params: {id: this.data.collaborator_id}},
                    description: 'Abrir detalhes do colaborador Responsável'
                  }
                ]
              }
            ]
          },
          {
            size: ['xs12', 'sm12', 'md4', 'lg4'],
            items: [
              {
                image: 'anexo_96.png',
                text: 'Arquivos/Anexos:',
                value: 'count_files',
                actions: [
                  (data) => {
                    return {
                      icon: 'info',
                      WiDialog: {
                        title: 'Arquivos/Anexos',
                        icon: 'attachment',
                        component: {
                          content: () => import('@/default/component/WiFile/WiFileList'),
                          props: {
                            index: 'budget-fileList',
                            api: {
                              url: 'sales/sale/file',
                              params: '?order=id,desc&where=business_id,' + data.id
                            },
                            permission: 'b41dcfec'
                          }
                        },
                        button: {
                          icon: 'add',
                          text: 'Adicionar Arquivo',
                          permission: 'b41dcfec',
                          action: () => {
                            this.$WiDialog({
                              title: 'Arquivos/Anexos',
                              icon: 'attachment',
                              component: {
                                content: () => import('@/default/component/WiFile/WiFile'),
                                props: {
                                  business_id: data.id
                                }
                              },
                              onClose: () => {
                                this.$WiView('budget-fileList').refresh()
                                this.$WiView('business').refresh()
                              }
                            })
                          }
                        }
                      },
                      description: 'Ver todas as notas...'
                    }
                  }
                ]
              },
              {
                image: 'dog_tag_96.png',
                text: 'Código Externo:',
                value: 'external_code',
                actions: [
                  {
                    copy: true,
                    description: 'Copiar código externo.'
                  }
                ]
              }
            ]
          },
          {
            size: ['xs12', 'sm12', 'md12', 'lg12'],
            items: [
              {
                image: 'note_96.png',
                text: 'Última Nota:',
                style: {'font-size': '12px'},
                value: 'last_note',
                actions: [
                  (data) => {
                    return {
                      icon: 'info',
                      WiDialog: {
                        title: 'Anotações/Eventos ' + this.idTitle,
                        icon: 'message',
                        component: {
                          content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
                          props: {
                            index: 'budget-event',
                            api: {
                              url: 'sales/sale/event',
                              params: '?order=id,desc&where=business_id,' + data.id
                            }
                          }
                        },
                        button: {
                          icon: 'add',
                          text: 'Adicionar Nota',
                          action: () => {
                            this.$WiEditDialog({
                              wiConfig: 'budget-event',
                              onSubmit: () => {
                                this.$WiView('budget-event').refresh()
                                this.$WiView('business').refresh()
                              },
                              data: {business_id: data.id}
                            })
                          }
                        }
                      },
                      description: 'Ver todas as notas...'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    methods: {
      getItem: function (name, item) {
        if (typeof item === 'function') {
          item = item(this.data)
        }
        if (name === 'data' && item.value) {
          if (typeof item.value === 'function') {
            return this.data[item.value(item, this.data)]
          } else {
            return this.data[item.value]
          }
        } else {
          if (typeof item[name] === 'function') {
            return item[name](item, this.data)
          } else {
            return item[name]
          }
        }
      },
      runAction: function (item, data) {
        if (typeof item === 'function') {
          item = item(data)
        }
        if (item.route) {
          this.$router.push(item.route)
        }
        if (item.WiEditDialog) {
          this.$WiEditDialog({wiConfig: item.WiEditDialog.wiConfig, onSubmit: item.WiEditDialog.onSubmit, data: item.WiEditDialog.data})
        }
        if (item.WiDialog) {
          this.$WiDialog(item.WiDialog)
        }
      },
      sizeField: function (sizes) {
        var size = {}
        sizes = sizes ? (typeof sizes === 'function' ? sizes(this.form) : sizes) : ['xs12', 'sm12', 'md12', 'lg12']
        for (var i in sizes) {
          size[sizes[i]] = true
        }
        return size
      }
    },
    props: ['config', 'data']
  }
</script>
