import { Model } from '@/default/model/Model'

const fields = {
  service: [
    { value: 'id', text: 'ID', type: 'int', list: true, edit: false, search: true },
    { text: 'Selecione o Serviço e informe a quantidade e valores.', type: 'subheader', edit: true },
    { value: 'item_id', text: 'Serviço*', autocomplete: true, required: true, size: ['xs12', 'sm12', 'md12', 'lg12'], type: 'db-autocomplete', list: false, edit: true, apiUrl: 'register/service', apiParams: { fields: 'id,name', order: 'name,asc' }, show: 'name' },
    { value: 'description', text: 'Descrição do Serviço', size: ['xs12', 'sm6', 'md6', 'lg6'], type: 'text', list: false, edit: true },
    { value: 'quantity', text: 'Quantidade', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, type: 'int', step: '.01', list: false, edit: true },
    { value: 'amount_unit', text: 'Valor Unitário', type: 'money', prefix: 'R$', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, list: true, edit: true },
    { value: 'amount_discount', text: 'Valor Desconto', type: 'money', prefix: 'R$', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, list: true, edit: true },
    { text: 'Use este campo para registrar alguma observação interna.', type: 'subheader', edit: true },
    { value: 'observation', text: 'Observações do Serviço', type: 'textarea', list: false, edit: true }
  ],
  product: [
    { value: 'id', text: 'ID', type: 'int', list: true, edit: false, search: true },
    { text: 'Selecione o Produto e informe a quantidade e valores.', type: 'subheader', edit: true },
    { value: 'stock_id', text: 'Estoque*', autocomplete: true, required: true, size: ['xs12', 'sm12', 'md3', 'lg3'], type: 'db-autocomplete', list: false, edit: true, disabled: (form) => (form.id > 0), apiUrl: 'stock', apiParams: { fields: 'id,name', order: 'name,asc' }, show: 'name' },
    // { value: 'item_id', text: 'Produto*', autocomplete: true, required: true, size: ['xs12', 'sm12', 'md9', 'lg9'], type: 'db-autocomplete', list: false, edit: { insert: true, update: false }, apiUrl: 'register/product', apiParams: { fields: 'id,name', order: 'name,asc' }, show: 'name' },
    {
      value: 'item_id',
      text: 'Produto',
      size: ['xs12', 'sm12', 'md9', 'lg9'],
      type: 'wi-list-dialog',
      edit: true,
      wiConfig: 'product',
      show: 'item.name',
      icon: 'person_outline',
      callbackFields: [
        {get: 'amount_sale', set: 'amount_unit'}
      ]
    },
    { value: 'description', text: 'Descrição do Produto', size: ['xs12', 'sm6', 'md6', 'lg6'], type: 'text', list: false, edit: true },
    { value: 'quantity', text: 'Quantidade', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, type: 'int', list: false, edit: true },
    { value: 'amount_unit', text: 'Valor Unitário', type: 'money', prefix: 'R$', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, list: true, edit: true },
    { value: 'amount_discount', text: 'Valor Desconto', type: 'money', prefix: 'R$', size: ['xs12', 'sm6', 'md2', 'lg2'], required: true, list: true, edit: true },
    { text: 'Use este campo para registrar alguma observação interna.', type: 'subheader', edit: true },
    { value: 'observation', text: 'Observações do Produto', type: 'textarea', list: false, edit: true }
  ]
}

const item = (id, name, item, title) => {
  return {
    id: id,
    title: {
      edit: {
        create: 'Incluir ' + title,
        update: 'Editar ' + title
      }
    },
    apiUrl: 'sales/' + name + '/item',
    fields: fields[item],
    fieldsFixed: [
      {
        value: 'amount_total', data: 0
      }
    ]
  }
}

export default {
  product: {
    sale: item('b41dcfec', 'sale', 'product', 'Produto'),
    budget: item('d3c48579', 'budget', 'product', 'Produto')
  },
  service: {
    sale: item('b41dcfec', 'sale', 'service', 'Serviço'),
    budget: item('d3c48579', 'budget', 'service', 'Serviço')
  }
}

class BusinessItem extends Model {
  constructor () {
    super()
    this.list = false
    this.title = {
      edit: {
        insert: 'Incluir',
        update: 'Editar'
      }
    }
    this.params = {
      limit: 10
    }
    this.icon = 'list'
    this.maxWidth = '1000px'
    this.setFields(this.fieldsEdit)
    this.fieldsFixed = [
      {
        value: 'amount_total', data: 0
      }
    ]
  }
  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Selecione o Item, informe a quantidade e valores.'
    }),
    stockId: this.fieldSelectApi({
      value: 'stock_id',
      text: 'Estoque',
      required: true,
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      disabled: (form) => (form.id > 0),
      api: {
        url: 'stock',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name'
    }),
    itemId: this.fieldWiListDialog({
      value: 'item_id',
      text: 'Item',
      required: true,
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      wiConfig: null,
      show: 'item.name',
      icon: null,
      filter: {
        where: 'active,1'
      },
      // disabled: form => !!form.id,
      callbackFields: [
        {
          get: 'amount_sale',
          set: 'amount_unit'
        },
        {
          get: 'amount_cost',
          set: 'amount_cost'
        },
        {
          get: 'margin',
          set: 'amount_margin'
        }
      ]
    }),
    description: this.fieldText({
      value: 'description',
      text: 'Descrição',
      placeholder: 'Insira a descrição aqui...',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    }),
    quantity: this.fieldNumber({
      value: 'quantity',
      text: 'Quantidade',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true,
      step: '.01'
    }),
    calculateMargin: this.fieldAmountMargin({
      value: 'amount_margin',
      text: 'Calcular margem',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      config: {
        fields: {
          quantity: 'quantity',
          amountCost: 'amount_cost',
          marginPercent: 'amount_margin',
          amountDiscount: 'amount_discount'
        },
        result: 'amount_unit'
      }
    }),
    amountUnit: this.fieldMoney({
      value: 'amount_unit',
      text: 'Valor Unitário',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true
    }),
    amountDiscount: this.fieldMoney({
      value: 'amount_discount',
      text: 'Valor Desconto',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true
    }),
    itemCategoryId: this.fieldSelectApi({
      value: 'business_item_category_id',
      text: 'Categoria do Item',
      required: this.permissionIsAvaiable('db89e384'),
      api: {
        url: 'sales/sale/item-category',
        params: {
          fields: 'id,name',
          order: 'name,asc'
        }
      },
      show: 'name',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Use este campo para registrar alguma observação interna.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Use este campo para observações.'
    })
  }
}

class BusinessItemProduct extends BusinessItem {
  constructor () {
    super()
    this.title = {
      edit: {
        insert: 'Incluir Produto',
        update: 'Editar Produto'
      }
    }
    this.icon = 'inventory_2'
    this.apiUrl = 'sales/sale/item'
    this.fieldsEdit.itemId.text = 'Produto'
    this.fieldsEdit.itemId.wiConfig = 'product'
    this.fieldsEdit.itemId.icon = 'inventory_2'
    this.fieldsEdit.description.text = 'Descrição do Produto'
  }
}

class BusinessItemService extends BusinessItem {
  constructor () {
    super()
    this.title = {
      edit: {
        insert: 'Incluir Serviço',
        update: 'Editar Serviço'
      }
    }
    this.icon = 'room_service'
    this.apiUrl = 'sales/sale/item'
    delete this.fieldsEdit.stockId
    this.fieldsEdit.itemId.size = ['xs12', 'sm12', 'md6', 'lg6']
    this.fieldsEdit.description.size = ['xs12', 'sm12', 'md6', 'lg6']
    this.fieldsEdit.itemId.text = 'Serviço'
    this.fieldsEdit.itemId.wiConfig = 'service'
    this.fieldsEdit.itemId.icon = 'room_service'
    this.fieldsEdit.description.text = 'Descrição do Serviço'
    this.clearFields()
    this.setFields(this.fieldsEdit)
  }
}

export class SaleItemProduct extends BusinessItemProduct {
  constructor () {
    super()
    this.id = 'b41dcfec'
  }
}

export class SaleItemService extends BusinessItemService {
  constructor () {
    super()
    this.id = 'b41dcfec'
  }
}

export class BudgetItemProduct extends BusinessItemProduct {
  constructor () {
    super()
    this.id = 'd3c48579'
  }
}

export class BudgetItemService extends BusinessItemService {
  constructor () {
    super()
    this.id = 'd3c48579'
  }
}
