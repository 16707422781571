<template>
  <div v-if="data">
    <v-card-text>
      <WiLoading :loading="status.code == 'opened'" message="Analisando equipamento..." min-height="200px">
        <v-layout row wrap>
          <v-flex v-if="stage.result" xs12 sm12 md12 lg12 xl12 d-flex style="padding-right: 5px;">
            <v-list class="mb-2">
              <v-list-tile avatar color="success" class="white--text"  style="border-style: dotted;" :style="{ background: stage.result.result.color }">
                <v-list-tile-avatar>
                  <v-icon large class="white--text">{{ stage.result.result.icon }}</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <strong>
                      {{ stage.result.result.name }}
                    </strong>
                  </v-list-tile-title>
                  <v-list-tile-sub-title class="white--text"> {{ stage.result.result.description }} </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider></v-divider>
            </v-list>
          </v-flex>
          <v-flex v-if="host" xs12 sm12 md12 lg12 xl12 d-flex style="padding-right: 5px;">
            <HostCheckModel
              :host="host"
              :status="status"
              :stage="stage"
              @onSelectModel="onSelectModel"
              @onSaveConfig="onSaveConfig"
            ></HostCheckModel>
          </v-flex>
          <v-flex v-if="!data.status && data['status-type'] == 'error'" xs12 sm12 md12 lg12 xl12 d-flex style="padding-right: 5px;">
            
            <v-list class="mb-2">
              <v-list-tile avatar color="success" class="white--text"  style="border-style: dotted; background: red;">
                <v-list-tile-avatar>
                  <v-icon large class="white--text">
                    close
                  </v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <strong>
                      Ocorreu um erro ao analisar o equipamento
                      <span v-if="data.date_time"> às {{ data.date_time | moment('DD/MM/YYYY HH:mm:ss') }}</span>
                    </strong>
                  </v-list-tile-title>
                  <v-list-tile-sub-title class="white--text">
                    Você pode tentar novamente clicando no botão abaixo.
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-btn outline color="primary" class="diagnostic-host-check__try-again" @click="onTryAgain">
                <v-icon>refresh</v-icon>
                <span> Tentar novamente</span>
              </v-btn>
              <v-divider></v-divider>
            </v-list>
          </v-flex>
          <v-flex v-if="host" xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
            <DiagnosticSignal
              :host="host"
            ></DiagnosticSignal>
          </v-flex>
          <v-flex xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
            <DiagnosticDevice
              :device="device"
            ></DiagnosticDevice>
          </v-flex>
          <v-flex xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
            <DiagnosticTickets
              title="Chamado(s) diretos"
              :data="ticketsDirect"
            ></DiagnosticTickets>
          </v-flex>
          <v-flex xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
            <DiagnosticTickets
              title="Chamado(s) indiretos"
              :data="ticketsIndirect"
            ></DiagnosticTickets>
          </v-flex>
        </v-layout>
      </WiLoading>
    </v-card-text>
    <v-divider></v-divider>
  </div>
</template>
<script>
  import DiagnosticTickets from './DiagnosticTickets'
  import DiagnosticSignal from './DiagnosticSignal'
  import DiagnosticDevice from './DiagnosticDevice'
  import HostCheckModel from './HostCheckModel'
  export default {
    name: 'DiagnosticHostCheck',
    computed: {
      signal () {
        return this.data?.signal
      },
      ticketsDirect () {
        return this.data?.tickets?.device
      },
      ticketsIndirect () {
        return this.data?.tickets?.network
      },
      device () {
        return this.data?.device
      },
      host () {
        return this.formatHostData()
      }
    },
    methods: {
      onSelectModel: function (selectedModel) {
        this.$emit('updateProcess', {
          data: selectedModel,
          isValid: true,
          autoSendProcessStage: true
        })
      },
      onSaveConfig: function (selectedModel) {
        this.$emit('updateProcess', {
          data: selectedModel,
          isValid: true,
          autoSendProcessStage: true
        })
      },
      formatHostData: function () {
        let host = this.data?.host
        if (host && !host?.active_devices || host?.active_devices?.length < 1) {
          host['active_devices'] = [this.data?.device]
        }
        return host
      },
      onTryAgain: function () {
        this.$emit('updateProcess', {
          data: this.data,
          isValid: true,
          autoSendProcessStage: true
        })
      }
    },
    props: {
      data: {
        required: true,
        default: {}
      },
      status: {
        required: true,
        default: {}
      },
      stage: {
        required: true,
        default: {}
      }
    },
    components: {
      DiagnosticTickets,
      DiagnosticSignal,
      DiagnosticDevice,
      HostCheckModel
    }
  }
</script>
<style scoped>
  .diagnostic-host-check__try-again {
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 152, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 152, 0, 0);
    }
  }
</style>

