import url from '@/default/store/modules/url/url'

// Budget
import Budget from '@/modules/sales/config/Budget/Budget'
import { BudgetSimple } from '@/modules/sales/config/Budget/BudgetSimple'
import { BudgetExportFilters } from '@/modules/sales/config/Budget/BudgetExportFilters'
import { BudgetCred, BudgetCredOpened, BudgetCredClosed, BudgetCredCanceled } from '@/modules/sales/config/Budget/BudgetCred'
import { BudgetCredFull } from '@/modules/sales/config/Budget/BudgetCredFull'
import { BudgetTemplate } from '@/modules/sales/config/Budget/BudgetTemplate'

// Sale
import Sale from '@/modules/sales/config/Sale/Sale'
import { SaleExportFilters } from '@/modules/sales/config/Sale/SaleExportFilters'

// Contract
import {
  ContractSales,
  ContractOpened,
  ContractClosed,
  ContractCanceled,
  ContractRecurrent
} from './Contract/Contract'

// Guarantee
import { Guarantee, GuaranteeOpened, GuaranteeClosed, GuaranteeCanceled } from './Guarantee/Guarantee'

// Common
import Event from '@/modules/sales/config/Common/Event'
import {
  SaleItemProduct,
  SaleItemService,
  BudgetItemProduct,
  BudgetItemService
} from '@/modules/sales/config/Common/Item'
import Finances from '@/modules/sales/config/Common/Finance'
import Person from '@/modules/sales/config/Common/Person'
import { FinanceReceive } from '@/modules/sales/config/Common/FinanceReceive'
import { FinanceEdit } from '@/modules/sales/config/Common/FinanceEdit'
import BusinessCodes from '@/modules/sales/config/Common/BusinessCodes'

// Configurations
import { SaleStatus, BudgetStatus, ContractStatus, GuaranteeStatus } from '@/modules/sales/config/Config/Status'
import { SaleCategory, BudgetCategory, ContractCategory, GuaranteeCategory } from '@/modules/sales/config/Config/Category'

// Registers
import Product from '@/modules/sales/config/Register/Product'
import { SalesService } from '@/modules/sales/config/Register/Service'
import Customer from '@/modules/sales/config/Register/Customer'
import Collaborator from '@/modules/sales/config/Register/Collaborator'
import { Coupon } from '@/modules/sales/config/Register/Coupon'

import {Business} from '@/modules/shared/config/Business/Business'

import { TaskSalesOpened, TaskSalesClosed, TaskSalesCanceled } from '@/modules/sales/config/Task/TaskSalesAll'

// layout
import { SaleLayout } from '@/modules/sales/config/Layout/SaleLayout'
import { BudgetLayout } from '@/modules/sales/config/Layout/BudgetLayout'
import { ContractLayout } from '@/modules/sales/config/Layout/ContractLayout'
// import { BusinessItemProduct } from '@/modules/business/config/BusinessItem'

import { BusinessItemCategory } from '@/modules/business/config/BusinessItemCategory'

import { BudgetAgreement } from './Config/Agreement'

const system = url.state.system

let budgetConfigs = {
  'budget': Budget.budget,
  'budget-open': Budget.open,
  'budget-close': Budget.close,
  'budget-template': new BudgetTemplate(),
  'budget-cancel': Budget.cancel,
  'budget-simple': new BudgetSimple(),
}

if (system == 'wise-message') {
  budgetConfigs = {
    'budget': new BudgetCred(),
    'budget-open': new BudgetCredOpened(),
    'budget-close': new BudgetCredClosed(),
    'budget-template': new BudgetTemplate(),
    'budget-cancel': new BudgetCredCanceled(),
    'budget-simple': new BudgetCred(),
    'budget-full': new BudgetCredFull(),
  }
}

export default {
  'sale': Sale.sale,
  'sale-open': Sale.open,
  'sale-close': Sale.close,
  'sale-cancel': Sale.cancel,
  'sale-event': Event.sale,
  'sale-person': Person,
  'sale-item-product': new SaleItemProduct(),
  'sale-item-service': new SaleItemService(),
  'sale-finances': Finances.sale,
  'sale-finance': new FinanceReceive(),
  'sale-finance-edit': new FinanceEdit(),
  'sale-layout': new SaleLayout(),
  'sale-codes': BusinessCodes,
  'sale-export-filters': new SaleExportFilters(),

  // 'budget': Budget.budget,
  // 'budget-open': Budget.open,
  // 'budget-close': Budget.close,
  // 'budget-template': Budget.template,
  // 'budget-cancel': Budget.cancel,
  ...budgetConfigs,
  'budget-event': Event.budget,
  'budget-item-product': new BudgetItemProduct(),
  'budget-item-service': new BudgetItemService(),
  'budget-finances': Finances.budget,
  'budget-finance': new FinanceReceive(),
  'budget-layout': new BudgetLayout(),
  'budget-export-filters': new BudgetExportFilters(),

  'contract': new ContractSales(),
  'contract-open': new ContractOpened(),
  'contract-close': new ContractClosed(),
  'contract-recurrent': new ContractRecurrent(),
  'contract-cancel': new ContractCanceled(),
  'contract-layout': new ContractLayout(),
  
  'guarantee': new Guarantee(),
  'guarantee-open': new GuaranteeOpened(),
  'guarantee-close': new GuaranteeClosed(),
  'guarantee-cancel': new GuaranteeCanceled(),

  'config-status-sale': new SaleStatus(),
  'config-status-budget': new BudgetStatus(),
  'config-status-contract': new ContractStatus(),
  'config-status-guarantee': new GuaranteeStatus(),
  'config-category-sale': new SaleCategory(),
  'config-category-budget': new BudgetCategory(),
  'config-category-contract': new ContractCategory(),
  'config-category-guarantee': new GuaranteeCategory(),

  'sales-register-product': Product,
  'sales-register-service': new SalesService(),
  'sales-register-customer': Customer,
  'sales-register-collaborator': Collaborator,
  'sales-register-coupon': new Coupon(),

  'business': new Business(),

  'task-sales-opened': new TaskSalesOpened(),
  'task-sales-closed': new TaskSalesClosed(),
  'task-sales-canceled': new TaskSalesCanceled(),

  'business-item-category': new BusinessItemCategory(),
  'sales-agreement': new BudgetAgreement()
}
