<template>
  <div class="chat-topics__loading" :class="{'chat-topics__loading--dark': $wiDark}">
    <div class="chat-topics__loading-content">
      
      <div class="chat-topics__loading-text">
        <v-icon large color="primary" class="chat-topics__loading-icon">{{ icon }}</v-icon>
        <h2 :class="{'white--text': $wiDark}">{{ title }}</h2>
        <p :class="{'grey--text': $wiDark}">
          {{ description }}
        </p>
      </div>
      <v-progress-circular 
        indeterminate 
        :size="60" 
        :width="6" 
        color="primary"
        class="chat-topics__loading-spinner"
      ></v-progress-circular>
      <div class="chat-topics__loading-skeleton">
        <div 
          v-for="n in skeletonCount" 
          :key="n" 
          class="chat-topics__loading-item" 
          :class="{'chat-topics__loading-item--dark': $wiDark}"
        >
          <div class="chat-topics__loading-avatar pulse" :class="{'chat-topics__loading-avatar--dark': $wiDark}"></div>
          <div class="chat-topics__loading-content-skeleton">
            <div class="chat-topics__loading-title pulse" :class="{'chat-topics__loading-title--dark': $wiDark}"></div>
            <div class="chat-topics__loading-description pulse" :class="{'chat-topics__loading-description--dark': $wiDark}"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatTopicsLoading',
  props: {
    title: {
      type: String,
      default: 'Carregando tópicos'
    },
    description: {
      type: String,
      default: 'Aguarde enquanto preparamos seus tópicos de conversa'
    },
    icon: {
      type: String,
      default: 'forum'
    },
    skeletonCount: {
      type: Number,
      default: 3
    }
  }
}
</script>

<style scoped>
.chat-topics__loading {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px;
  height: calc(100vh - 207px);
}

.chat-topics__loading--dark {
  background: #1C252E;
}

.chat-topics__loading-content {
  text-align: center;
  padding: 32px;
  max-width: 480px;
  width: 100%;
}

.chat-topics__loading-spinner {
  margin-bottom: 0px;
}

.chat-topics__loading-text {
  margin-bottom: 10px;
}

.chat-topics__loading-icon {
  margin-bottom: 0px;
  opacity: 0.9;
}

.chat-topics__loading-text h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 8px;
  color: #1976D2;
}

.chat-topics__loading-text p {
  font-size: 1rem;
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.5;
}

.chat-topics__loading-skeleton {
  margin-top: 10px;
}

.chat-topics__loading-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  margin-bottom: 12px;
  background: rgba(25, 118, 210, 0.03);
  border-radius: 12px;
}

.chat-topics__loading-item--dark {
  background: rgba(255, 255, 255, 0.05);
}

.chat-topics__loading-avatar {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  background: rgba(25, 118, 210, 0.1);
  flex-shrink: 0;
}

.chat-topics__loading-avatar--dark {
  background: rgba(255, 255, 255, 0.1);
}

.chat-topics__loading-content-skeleton {
  flex: 1;
}

.chat-topics__loading-title {
  height: 20px;
  background: rgba(25, 118, 210, 0.1);
  border-radius: 4px;
  margin-bottom: 8px;
  width: 70%;
}

.chat-topics__loading-title--dark {
  background: rgba(255, 255, 255, 0.1);
}

.chat-topics__loading-description {
  height: 16px;
  background: rgba(25, 118, 210, 0.1);
  border-radius: 4px;
  width: 90%;
}

.chat-topics__loading-description--dark {
  background: rgba(255, 255, 255, 0.1);
}

.pulse {
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 0.3; }
  100% { opacity: 0.6; }
}

@media (max-width: 600px) {
  .chat-topics__loading-content {
    padding: 24px 16px;
  }

  .chat-topics__loading-text h2 {
    font-size: 1.25rem;
  }

  .chat-topics__loading-text p {
    font-size: 0.9rem;
  }

  .chat-topics__loading-spinner {
    margin-bottom: 0px;
  }

  .chat-topics__loading-skeleton {
    margin-top: 24px;
  }

  .chat-topics__loading-item {
    padding: 10px;
    margin-bottom: 10px;
  }

  .chat-topics__loading-avatar {
    width: 40px;
    height: 40px;
  }
}
</style> 