import { BudgetFilters } from "./BudgetFilters"
import {
  PERMISSION_BUDGET_EXPORT_BUDGETS,
  PERMISSION_BUDGET_EXPORT_BUDGETS_TO_XLSX
} from '@/default/constants/permissions'

var budget = (title, name, isTemplate = false) => {
  var date = {
    open: {
      value: 'opened_at',
      text: 'Criação',
      list: true
    },
    close: {
      value: 'closed_at',
      text: 'Realização',
      list: true
    },
    template: {
      value: 'opened_at',
      text: 'Criação',
      list: true
    },
    cancel: {
      value: 'canceled_at',
      text: 'Cancelamento',
      list: true
    }
  }
  var config = {
    id: {
      id: '10b505c9',
      create: 'b9fba2ea',
      update: 'd3c48579',
      delete: '55bf11f3'
    },
    list: true,
    title: {
      list: 'Orçamentos ' + title,
      edit: {
        create: 'Novo Orçamento',
        update: 'Editar Orçamento'
      }
    },
    icon: 'shop',
    apiUrl: 'sales/budget',
    params: {
      limit: 10,
      is_template: isTemplate
    },
    search: {
      placeholder: 'Pesquise ID, Cliente, Status, Nota ou Responsável',
      fields: ['id', 'person.name', 'person.first_name', 'status.name', 'invoice', 'external_code', 'collaborator.name', 'collaborator.first_name']
    },
    urlDetails: { name: 'budget', params: {} },
    filters: new BudgetFilters(),
    components: {
      list: {
        tableRow: () => import(`@/modules/shared/pages/Businesses/${isTemplate ? 'BusinessesTemplate' : 'Businesses'}`)
      }
    },
    size: ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1'],
    maxWidth: '1000px',
    exports: {
      permission: PERMISSION_BUDGET_EXPORT_BUDGETS,
      title: 'Exportar Orçamentos',
      origins: {
        excel: {
          title: 'Exportar para o Excel',
          permission: PERMISSION_BUDGET_EXPORT_BUDGETS_TO_XLSX,
          description: 'Exportar orçamentos para o Excel.',
          image: '/static/icons/excel_icon.png',
          wiConfig: 'budget-export-filters'
        }
      }
    },
    fields: [
      { value: 'id', text: 'ID', type: 'int', list: true },
      {
        value: 'person.type',
        text: 'PF/PJ',
        list: true
      },
      { value: 'person.name', text: 'Nome do Cliente', type: 'text', list: true },
      { value: 'status.name', text: 'Status/Etapa', type: 'text', align: 'center', list: true },
      date[name || 'open'],
      {
        value: 'date_validity',
        text: 'Validade',
        list: true
      },
      { text: 'Informe os dados básicos deste orçamento.', type: 'subheader', edit: true },
      {
        value: 'company_id',
        text: 'Empresa (Matriz ou Filial)*',
        autocomplete: true,
        required: true,
        placeholder: 'Ex: Nome da empresa, etc.',
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        type: 'db-autocomplete',
        disabled: (form) => { return form.id != null },
        edit: true,
        apiUrl: 'register/person/company',
        apiParams: {
          fields: 'id,name',
          order: 'name,asc'
        },
        show: 'name'
      },
      {
        value: 'person_id',
        text: 'Cliente*',
        autocomplete: true,
        required: true,
        size: ['xs12', 'sm12', 'md5', 'lg5'],
        type: 'wi-list-dialog',
        edit: true,
        wiConfig: 'customer',
        show: 'person.first_name',
        icon: 'person_outline'
      },
      {
        value: 'external_code',
        text: 'Código Externo',
        placeholder: 'Ex: Seu código',
        size: ['xs12', 'sm12', 'md2', 'lg2'],
        type: 'text',
        list: true,
        edit: true
      },
      {
        value: 'collaborator.name',
        text: 'Responsável',
        list: true
      },
      {
        value: 'business_status_id',
        text: 'Status do Orçamento*',
        autocomplete: true,
        required: true,
        placeholder: 'Ex: Em Aberto, etc.',
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        type: 'db-autocomplete',
        edit: true,
        apiUrl: 'sales/config/status/budget',
        apiParams: { fields: 'id,name', where: 'active,1', order: 'order,asc' },
        show: 'name'
      },
      {
        value: 'business_category_id',
        text: 'Categoria do Orçamento*',
        autocomplete: true,
        required: true,
        placeholder: 'Ex: Serviço, etc.',
        size: ['xs12', 'sm12', 'md3', 'lg3'],
        type: 'db-autocomplete',
        edit: true,
        apiUrl: 'sales/config/category/budget',
        apiParams: { fields: 'id,name', order: 'name,asc' },
        show: 'name'
      },
      { value: 'date_start', text: 'Data de Emissão', required: false, size: ['xs12', 'sm12', 'md2', 'lg2'], type: 'date', edit: true, search: false },
      { value: 'date_end', text: 'Data de Entrega', required: false, size: ['xs12', 'sm12', 'md2', 'lg2'], type: 'date', edit: true, search: false },
      { value: 'date_validity', text: 'Data de Validade*', required: true, size: ['xs12', 'sm12', 'md2', 'lg2'], type: 'date', edit: true },
      { value: 'amount_freight', text: 'Valor Frete', type: 'money', prefix: 'R$', size: ['xs12', 'sm4', 'md2', 'lg2'], required: false, edit: true },
      { value: 'amount_discount', text: 'Valor de Desconto', type: 'money', prefix: 'R$', size: ['xs12', 'sm4', 'md2', 'lg2'], required: false, edit: true },
      {
        value: 'collaborator_id',
        text: 'Colaborador Responsável',
        autocomplete: true,
        required: true,
        placeholder: 'Ex: Nome do colaborador, etc.',
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        type: 'db-autocomplete',
        edit: true,
        apiUrl: 'register/person/collaborator',
        apiParams: { fields: 'id,name', order: 'name,asc' },
        show: 'name'
      },
      // {
      //   value: 'is_template',
      //   text: 'É um orçamento modelo',
      //   type: 'switch',
      //   size: ['xs12', 'sm12', 'md2', 'lg2'],
      //   edit: true, 
      //   showField: (form) => { return !form.id },
      // },
      { value: 'integration', text: 'Código interno', required: false, size: ['xs12', 'sm12', 'md2', 'lg2'], type: 'text', edit: true },
      { text: 'Descreva abaixo os detalhes deste orçamento.', type: 'subheader', edit: true },
      { value: 'description', text: 'Descrição do Orçamento', placeholder: 'Ex: Prazos, detalhes importanes, etc.', type: 'editor', edit: true },
      { text: 'Descreva abaixo as formas/condições de pagamento deste orçamento.', type: 'subheader', edit: true },
      { value: 'information', text: 'Forma/Condições de Pagamento', placeholder: 'Ex: Parcelado em 3x.', type: 'editor', edit: true },
      { text: 'O campo abaixo pode ser usado para registrar observações sobre este orçamento.', type: 'subheader', edit: true },
      { value: 'observation', text: 'Observações do Orçamento', placeholder: 'Ex: Negociação iniciada em...', type: 'textarea', edit: true },
      {
        value: 'total_liquid',
        text: 'Total',
        list: true
      }
    ]
  }
  if (name) {
    config.params['stage'] = name
  }
  return config
}

export default {
  budget: budget('Em Aberto', null, false),
  open: budget('Em Aberto', 'open', false),
  close: budget('Aprovados', 'close', false),
  cancel: budget('Rejeitados', 'cancel', false),
  template: budget('Modelos', null, true)
}
