import url from '@/default/store/modules/url/url'

import messages from './pt-BR/_global/messages.json'
import components from './pt-BR/_global/components.json'
import vuetify from './pt-BR/_global/vuetify.json'
import pages from './pt-BR/_global/pages.json'

// Módulo talks
import talksIndex from './pt-BR/modules/talks/index.json'
import talksMenu from './pt-BR/modules/talks/menu.json'
import talksPages from './pt-BR/modules/talks/pages.json'

// Módulo registers
import registersIndex from './pt-BR/modules/registers/index.json'
import registersMenu from './pt-BR/modules/registers/menu.json'
import registersPages from './pt-BR/modules/registers/pages.json'

// Módulo sales
import salesIndex from './pt-BR/modules/sales/index.json'
import salesMenu from './pt-BR/modules/sales/menu.json'
import salesPages from './pt-BR/modules/sales/pages.json'

// Módulo service-desk
import serviceDeskIndex from './pt-BR/modules/service-desk/index.json'
import serviceDeskPages from './pt-BR/modules/service-desk/pages.json'

import wiseMessageSalesMenu from './pt-BR/wise-message/modules/sales/menu.json'

let salesMenuContent = salesMenu
const system = url.state.system

if (system == 'wise-message') {
  salesMenuContent = wiseMessageSalesMenu
}

export default {
  messages,
  components,
  $vuetify: vuetify,
  pages,
  modules: {
    talks: {
      ...talksIndex,
      menu: talksMenu,
      pages: talksPages
    },
    registers: {
      ...registersIndex,
      menu: registersMenu,
      pages: registersPages
    },
    sales: {
      ...salesIndex,
      menu: salesMenuContent,
      // pages: salesPages
      pages: salesPages
    },
    serviceDesk: {
      ...serviceDeskIndex,
      pages: serviceDeskPages
    }
  }
} 