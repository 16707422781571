<template>
  <div>
    <div v-for="(dialog, dialogKey) in WiEditDialogs" :key="'editDialog' + dialogKey">
      <wi-edit-dialog :index="dialogKey"></wi-edit-dialog>
    </div>
    <div v-for="(list, listKey) in WiListDialogs" :key="'listDialog' + listKey">
      <wi-list-dialog :index="listKey"></wi-list-dialog>
    </div>
    <div v-for="(dialog, dialogKey) in WiDialogs" :key="'dialog' + dialogKey">
      <wi-dialog :index="dialogKey"></wi-dialog>
    </div>
    <div v-for="(dialog, fileDialogKey) in WiFileDialogs" :key="'file-dialog' + fileDialogKey">
      <WiFileDialog :index="fileDialogKey"></WiFileDialog>
    </div>
    <div v-for="(dialog, fileListDialogKey) in WiFileListDialog" :key="'file-list-dialog' + fileListDialogKey">
      <WiFileListDialog :index="fileListDialogKey"></WiFileListDialog>
    </div>
    <div v-for="(dialog, eventsDialogKey) in WiEventsDialogs" :key="'events-dialog' + eventsDialogKey">
      <wi-events-dialog :index="eventsDialogKey"></wi-events-dialog>
    </div>
    <div v-for="(dialog, remediesDialogKey) in WiRemediesDialogs" :key="'remedies-dialog' + remediesDialogKey">
      <WiRemediesDialog :index="remediesDialogKey"></WiRemediesDialog>
    </div>
    <div>
      <wi-help-dialog></wi-help-dialog>
    </div>
    <div>
      <ChatMessagesDialog />
    </div>
    <div>
      <ChatTopicsDialog />
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import WiHelpDialog from '@/default/component/WiHelp/WiHelpDialog'
  import WiEventsDialog from '@/default/component/WiEvents/Dialog'
  import WiRemediesDialog from '@/default/component/WiRemedies/Dialog'
  import WiFileDialog from '@/default/component/WiFile/WiFileDialog'
  import WiFileListDialog from '@/default/component/WiFile/WiFileListDialog'
  import ChatMessagesDialog from '@/default/pages/ChatView/ChatMessagesDialog.vue'
  import ChatTopicsDialog from '@/default/pages/ChatView/ChatTopicsDialog.vue'
  export default {
    name: 'Components',
    methods: {
      systemNotifications: function () {
        if (!('Notification' in window)) {
          alert('Este browser não suporta notificações de Desktop')
        } else if (Notification.permission === 'granted') {
          // console.log('Notificações ativadas.')
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission(function (permission) {
            if (permission !== 'granted') {
              alert('É altamente recomendado que você ative as notificações para receber alertas do sistema.')
            }
          })
        }
      }
    },
    computed: {
      ...mapState('Chat', ['chatDialogActive']),
      ...mapState('ChatTopics', ['topicsDialogActive']),
      WiEditDialogs () {
        return this.$store.state.WiEditDialog.dialogs || []
      },
      WiListDialogs () {
        return this.$store.state.WiListDialog.lists || []
      },
      WiDialogs () {
        return this.$store.state.WiDialog.dialogs || []
      },
      WiFileDialogs () {
        return this.$store.state.WiFileDialog.dialogs || []
      },
      WiEventsDialogs () {
        return this.$store.state.WiEventsDialogs.dialogs || []
      },
      WiFileListDialog () {
        return this.$store.state.WiFileListDialog.dialogs || []
      },
      WiRemediesDialogs () {
        return this.$store.state.WiRemediesDialog.dialogs || []
      }
    },
    created: function () {
      this.systemNotifications()
    },
    components: {
      WiHelpDialog,
      WiFileDialog,
      WiEventsDialog,
      WiFileListDialog,
      WiRemediesDialog,
      ChatMessagesDialog,
      ChatTopicsDialog
    }
  }
</script>