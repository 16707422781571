<template>
  <WiView 
    ref="wiView"
    index="talks-categories" 
    apiUrl="talks/category-hierarchy" 
    :notShowToolbar="true" 
    :notGetID="true" 
    @afterLoad="setContent"
  >
    <template slot-scope="{ content, refresh }">
      <v-container fluid class="pa-0 categories-container">
        <div class="categories-header">
          <div class="header-content">
            <v-toolbar dense class="elevation-10" style="border-radius: 10px;">
              <v-toolbar-title>Categorias de Atendimento</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn flat icon color="green" @click="refresh()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
        </div>

        <div class="categories-wrapper">
          <template v-if="hasCategories(content)">
            <v-layout row wrap class="categories-content">
              <v-flex xs12 md7 class="tree-container">
                <CategoryTree 
                  :items="content.data || []"
                  @select="selectCategory"
                  @add-category="handleAddCategory"
                  @add-child-category="handleAddChildCategory"
                />
              </v-flex>

              <v-flex xs12 md5 class="selected-container">
                <SelectedCategory 
                  v-if="selectedCategory"
                  :category="selectedCategory"
                  @edit-category="handleEditCategory"
                  @add-child-category="handleAddChildCategory"
                  @delete-category="handleDeleteCategory"
                  @select-category="selectCategory"
                />
              </v-flex>
            </v-layout>
          </template>
          <template v-else>
            <EmptyCategories @create="handleAddCategory" />
          </template>
        </div>
      </v-container>
    </template>
  </WiView>
</template>

<script>
import EmptyCategories from './EmptyCategories.vue'
import CategoryTree from './CategoryTree.vue'
import SelectedCategory from './SelectedCategory.vue'

export default {
  name: 'talk-categories',
  components: {
    EmptyCategories,
    CategoryTree,
    SelectedCategory
  },
  data: () => ({
    refresh: null,
    content: null,
    selectedCategory: null
  }),
  methods: {
    setContent({ content, refresh }) {
      this.content = content
      this.refresh = refresh
    },
    selectCategory(item) {
      this.selectedCategory = item
    },
    handleEditCategory(category) {
      this.$WiEditDialog({
        wiConfig: 'talk-category',
        data: category,
        redirectOnSubmit: false,
        onSubmit: () => {
          this.refresh()
        }
      })
    },
    handleAddChildCategory(parentCategory) {
      this.$WiEditDialog({
        wiConfig: 'talk-category',
        data: {
          talk_category_id: parentCategory.id,
          color: parentCategory.color,
          icon: parentCategory.icon
        },
        redirectOnSubmit: false,
        onSubmit: () => {
          this.refresh()
        }
      })
    },
    handleDeleteCategory(category) {
      this.$WiApiDelete({
        uri: `talks/category`,
        id: category.id,
        config: {
          msgLoad: {
            title: `Removendo categoria`,
            text: `Aguarde enquanto a categoria é removida.`
          },
          msgSucess: {
            title: 'Categoria removida com sucesso!',
            text: 'A categoria foi removida com sucesso.'
          },
          msgError: {
            title: 'Erro ao remover categoria',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: 'Excluir categoria',
            text: `Deseja realmente excluir a categoria "${category.name}"?`,
            confirmText: 'Excluir',
            confirmColor: 'error',
          }
        },
        callback: () => {
          this.refresh()
        }
      })
    },
    hasCategories(content) {
      return content?.data && content.data.length > 0
    },
    handleAddCategory() {
      console.log('handleAddCategory')
      this.$WiEditDialog({
        wiConfig: 'talk-category',
        data: {},
        redirectOnSubmit: false,
        onSubmit: () => {
          this.refresh()
        }
      })
    }
  }
}
</script>

<style scoped>
.categories-container {
  min-height: calc(100vh - 47px);
}

.theme--dark .categories-container {
  background-color: #121212 !important;
}

.categories-header {
  position: sticky;
  top: 47px;
  z-index: 3;
  padding: 16px 24px;
}

.theme--dark .categories-header {
  background-color: #121212;
}

.header-content {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
}

.categories-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 24px;
}

.theme--dark .categories-wrapper {
  background-color: #121212;
}

.categories-content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.theme--dark .categories-content {
  background-color: #121212;
}

.tree-container {
  padding: 16px 24px;
  height: calc(100vh - 47px - 80px);
  overflow-y: auto;
}

.selected-container {
  padding: 16px 24px;
  position: sticky;
  top: calc(47px + 80px);
  height: calc(100vh - 47px - 80px);
  overflow-y: auto;
}

@media (max-width: 960px) {
  .categories-wrapper {
    padding: 0 16px;
  }

  .tree-container,
  .selected-container {
    padding: 16px;
  }

  .selected-container {
    position: relative;
    top: 0;
    height: auto;
  }

  .tree-container {
    height: auto;
  }
}

@media (max-width: 600px) {
  .categories-wrapper {
    padding: 0 12px;
  }

  .tree-container,
  .selected-container {
    padding: 12px;
  }
}
</style>
