<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-icon>mic</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat dense color="transparent">
        <v-toolbar-title>
          Gravação de áudio
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="text-center">
        <div class="audio-recorder">
          <!-- Mensagem de orientação inicial -->
          <div v-if="!isRecording && !isPaused && !audioUrl" class="instruction-text">
            <v-icon large color="primary" class="mb-2">mic</v-icon>
            <p>Clique no botão do microfone para iniciar sua gravação</p>
          </div>

          <!-- Visualizador e timer -->
          <template v-if="isRecording || isPaused">
            <canvas
              ref="visualizer"
              class="visualizer"
              height="60"
            ></canvas>
            <div class="timer">
              {{ formatTime(recordingTime) }}
            </div>
            <div class="instruction-text subtle" v-if="isRecording">
              Você pode pausar ou finalizar a gravação a qualquer momento
            </div>
            <div class="instruction-text subtle" v-if="isPaused">
              Sua gravação está pausada. Continue ou finalize quando desejar
            </div>
          </template>

          <!-- Controles principais -->
          <div class="controls">
            <v-btn
              fab
              :color="isRecording ? 'red' : 'primary'"
              @click="toggleRecording"
              class="ma-2"
              :disabled="isProcessing"
            >
              <v-icon v-if="!isRecording && !isPaused">mic</v-icon>
              <v-icon v-else-if="isPaused">play_arrow</v-icon>
              <v-icon v-else>pause</v-icon>
            </v-btn>

            <v-btn
              v-if="isRecording || isPaused"
              fab
              color="error"
              @click="stopRecording"
              class="ma-2"
              :disabled="isProcessing"
            >
              <v-icon>stop</v-icon>
            </v-btn>
          </div>

          <!-- Player de áudio após gravação -->
          <div v-if="audioUrl" class="audio-player mt-4">
            <div class="instruction-text mb-3">
              Ouça sua gravação antes de enviar
            </div>
            <audio ref="audioPlayer" :src="audioUrl" controls></audio>
            <div class="action-buttons">
              <v-btn
                text
                color="grey"
                @click="resetRecording"
                class="mr-2"
                :disabled="isProcessing"
              >
                <v-icon left>refresh</v-icon>
                Gravar novamente
              </v-btn>
              <v-btn
                color="primary"
                @click="sendAudio"
                :loading="isProcessing"
                :disabled="isProcessing"
              >
                <v-icon left>send</v-icon>
                Enviar Áudio
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>

      <!-- Snackbar para feedback -->
      <v-snackbar
        v-model="showMessage"
        :color="messageType"
        :timeout="3000"
        bottom
      >
        {{ message }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import audioRecorderMixin from './audioRecorderMixin'

export default {
  name: 'WNAudioRecorder',
  mixins: [audioRecorderMixin],
  
  data: () => ({
    dialog: false
  }),

  mounted() {
    if (this.autoStart) {
      this.dialog = true;
      // O mixin cuidará de iniciar a gravação
    }
  },

  methods: {
    closeDialog() {
      if (this.isRecording) {
        this.stopRecording();
      }
      this.dialog = false;
      this.resetState();
    }
  }
}
</script>

<style scoped>
.audio-recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.visualizer {
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 20px;
}

.timer {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: monospace;
}

.controls {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
}

.audio-player {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-player audio {
  width: 100%;
  margin-bottom: 10px;
}

.instruction-text {
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.95rem;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instruction-text.subtle {
  font-size: 0.85rem;
  opacity: 0.7;
}

.action-buttons {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.visualizer {
  transition: all 0.3s ease;
}

.timer {
  transition: all 0.3s ease;
}

.v-btn {
  transition: all 0.3s ease;
}
</style>