import Business from '@/modules/shared/pages/Business/Business'
import Contract from '@/modules/sales/pages/Contract'
import Guarantee from '@/modules/sales/pages/Guarantee'
import BusinessPrint from '@/modules/shared/pages/Business/BusinessPrint'
import BusinessOfProcess from '@/modules/business/pages/BusinessOfProcess'

import BusinessDashboard from '@/modules/shared/pages/BusinessDashboard/BusinessDashboard'
import SalesModuleBudgetDashboard from '@/modules/sales/pages/Dashboard/SalesModuleBudgetDashboard'
import ExportSalesReport from '@/modules/sales/pages/Reports/ExportSalesReport'

import BusinessByPersonReport from '@/modules/shared/pages/BusinessReport/BusinessByPersonReport'
import BusinessByPeriodReport from '@/modules/shared/pages/BusinessReport/BusinessByPeriodReport'
import Task from '@/modules/sales/pages/Task/Task'
import BudgetTemplate from '@/modules/sales/pages/Budget/BudgetTemplate'

import {
  PERMISSION_CONTRACT,
  PERMISSION_GUARANTEE,
  PERMISSION_SALE_UPDATE,
  PERMISSION_BUDGET_UPDATE,
  PERMISSION_CONTRACT_UPDATE,
} from '@/default/constants/permissions'

export default [
  {
    path: 'sale/:id',
    name: 'sale',
    component: Business,
    meta: {
      id: 'fbded5ac',
      auth: true,
      title: 'Detalhes da venda',
      icon: 'local_grocery_store',
      config: {
        code: 4,
        name: 'sale',
        title: 'Venda',
        icon: 'local_grocery_store',
        permission: {
          update: PERMISSION_SALE_UPDATE
        }
      }
    }
  },
  {
    path: 'budget/:id',
    name: 'budget',
    component: Business,
    meta: {
      id: '10b505c9',
      auth: true,
      title: 'Detalhes do Orçamento',
      icon: 'shop',
      config: {
        code: 1,
        name: 'budget',
        title: 'Orçamento',
        icon: 'shop',
        permission: {
          update: PERMISSION_BUDGET_UPDATE
        }
      }
    }
  },
  {
    path: 'budget-template/:id',
    name: 'budget-template',
    component: BudgetTemplate,
    meta: {
      id: '10b505c9',
      auth: true,
      title: 'Detalhes do Modelo de Orçamento',
      icon: 'shop',
      config: {
        code: 1,
        name: 'budget',
        title: 'Modelo de Orçamento',
        icon: 'shop',
        permission: {
          update: PERMISSION_BUDGET_UPDATE
        }
      }
    }
  },
  {
    path: 'contract/:id',
    name: 'contract',
    component: Contract,
    meta: {
      id: PERMISSION_CONTRACT,
      auth: true,
      title: 'Detalhes do Contrato',
      icon: 'border_color',
      permission: PERMISSION_CONTRACT,
      config: {
        code: 31,
        name: 'contract',
        title: 'Contrato',
        icon: 'border_color',
        permission: {
          update: PERMISSION_CONTRACT_UPDATE
        }
      }
    }
  },
  {
    path: 'guarantee/:id',
    name: 'guarantee',
    component: Guarantee,
    meta: {
      id: PERMISSION_GUARANTEE,
      auth: true,
      title: 'Detalhes da Garantia',
      icon: 'privacy_tip'
    }
  },
  {
    path: 'task-sale/:id',
    name: 'task-sale',
    component: Task,
    meta: {
      id: 'c4f8c247',
      auth: true,
      title: 'Detalhes do Agendamento',
      icon: 'event'
    }
  },
  {
    path: 'business-print',
    name: 'business-print',
    component: BusinessPrint,
    meta: {
      id: '8123ce38-df17-4302-b84f-fe90f7171fe0',
      auth: true,
      title: 'Impressão',
      icon: 'print'
    }
  },
  {
    path: 'dashboard-sales',
    name: 'dashboard-sales',
    component: BusinessDashboard,
    meta: {
      id: '8123ce38-df17-4302-b84f-fe90f7171fe0',
      auth: true,
      title: 'Dashboard de Vendas',
      icon: 'dashboard',
      moduleName: 'sales'
    }
  },
  {
    path: 'dashboard-budget',
    name: 'dashboard-budget',
    component: SalesModuleBudgetDashboard,
    meta: {
      id: '8123ce38-df17-4302-b84f-fe90f7171fe0',
      auth: true,
      title: 'Dashboard de Orçamentos',
      icon: 'dashboard',
      moduleName: 'budget'
    }
  },
  {
    path: 'process-of-sales/:business',
    name: 'process-of-sales',
    component: BusinessOfProcess,
    meta: {
      id: '8123ce38-df17-4302-b84f-fe90f7171fe0',
      auth: true,
      title: 'Comercial',
      icon: 'local_grocery_store',
      touch: true
    }
  },
  {
    path: 'report-sales-by-customer',
    name: 'sales-by-customer',
    component: BusinessByPersonReport,
    meta: {
      id: '1b4f745c',
      auth: true,
      title: 'Relatório de Vendas por Cliente',
      icon: 'bar_chart',
      touch: true,
      business: 'sale',
      person: 'customer'
    }
  },
  {
    path: 'report-sales-by-period',
    name: 'sales-by-period',
    component: BusinessByPeriodReport,
    meta: {
      id: '1b4f745c',
      auth: true,
      title: 'Relatório de Vendas por Cliente',
      icon: 'bar_chart',
      touch: true,
      business: 'sale',
      person: 'customer'
    }
  },
  {
    path: 'report-budgets-by-customer',
    name: 'budgets-by-customer',
    component: BusinessByPersonReport,
    meta: {
      id: '919bb8fa',
      auth: true,
      title: 'Relatório de Orçamentos por Cliente',
      icon: 'bar_chart',
      touch: true,
      business: 'budget',
      person: 'customer'
    }
  },
  {
    path: 'report-sales-by-collaborator',
    name: 'sales-by-collaborator',
    component: BusinessByPersonReport,
    meta: {
      id: 'dab5ce52',
      auth: true,
      title: 'Relatório de Vendas por Colaborador',
      icon: 'bar_chart',
      touch: true,
      business: 'sale',
      person: 'collaborator'
    }
  },
  {
    path: 'report-budgets-by-collaborator',
    name: 'budgets-by-collaborator',
    component: BusinessByPersonReport,
    meta: {
      id: 'bffe8c55',
      auth: true,
      title: 'Relatório de Vendas por Colaborador',
      icon: 'bar_chart',
      touch: true,
      business: 'budget',
      person: 'collaborator'
    }
  },
  {
    path: 'report-budgets-export',
    name: 'report-budgets-export',
    component: ExportSalesReport,
    meta: {
      id: 'bffe8c55',
      auth: true,
      title: 'Exportar',
      icon: 'bar_chart',
      touch: true,
      business: 'budget',
      person: 'collaborator'
    }
  }
]
