<template>
  <img 
    :src="getChannelIcon" 
    :style="getIconStyle"
  >
</template>

<script>
export default {
  name: 'ChannelIcon',
  
  props: {
    channelType: {
      type: String,
      required: true
    }
  },

  computed: {
    getChannelIcon() {
      const icons = {
        'wpp': 'static/icons/monitor/whatsapp.png',
        'instagram': 'static/icons/monitor/instagram.png',
        'facebook': 'static/icons/monitor/facebook.png',
        'email': 'static/icons/monitor/email.png',
        'sms': 'static/icons/monitor/sms.png'
      }
      return icons[this.channelType] || ''
    },

    getIconStyle() {
      const styles = {
        'instagram': 'width: 20px; height: 20px;',
        'facebook': 'border-radius: 5px; width: 18px; height: 18px;',
        'email': 'width: 20px; height: 20px;',
        'sms': 'width: 20px; height: 20px;'
      }
      return styles[this.channelType] || ''
    }
  }
}
</script> 