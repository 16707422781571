export default {
  props: {
    iconColor: {
      type: String,
      default: 'primary'
    },
    recordingColor: {
      type: String,
      default: 'red'
    },
    accentColor: {
      type: String,
      default: 'primary'
    },
    autoStart: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isRecording: false,
    isPaused: false,
    isProcessing: false,
    mediaRecorder: null,
    audioChunks: [],
    audioUrl: null,
    recordingTime: 0,
    timerInterval: null,
    audioContext: null,
    analyser: null,
    visualizerData: null,
    showMessage: false,
    message: '',
    messageType: 'info'
  }),

  methods: {
    async startRecording() {
      try {
        const mediaDevices = navigator.mediaDevices || 
          ((navigator.mozGetUserMedia || navigator.webkitGetUserMedia) ? {
            getUserMedia: function(c) {
              return new Promise((resolve, reject) => {
                (navigator.mozGetUserMedia ||
                navigator.webkitGetUserMedia).call(navigator, c, resolve, reject);
              });
            }
          } : null);

        if (!mediaDevices) {
          throw new Error('Seu navegador não suporta gravação de áudio');
        }

        const stream = await mediaDevices.getUserMedia({ 
          audio: {
            channelCount: 1,
            sampleRate: 44100
          }
        });
        
        this.setupAudioContext(stream);
        
        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: 'audio/webm',
          bitsPerSecond: 128000,
          audioBitsPerSecond: 128000
        });
        
        this.audioChunks = [];
        
        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, { 
            type: 'audio/webm'
          });
          this.audioUrl = URL.createObjectURL(audioBlob);
        };

        this.mediaRecorder.start(10);
        this.isRecording = true;
        this.startTimer();
        this.visualize();
        this.$emit('recording-started');
        this.$emit('stream', stream);
      } catch (error) {
        console.error('Erro ao acessar microfone:', error);
        this.$emit('error', error.message);
      }
    },

    setupAudioContext(stream) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.analyser = this.audioContext.createAnalyser();
      const source = this.audioContext.createMediaStreamSource(stream);
      source.connect(this.analyser);
      this.analyser.fftSize = 2048;
      this.visualizerData = new Uint8Array(this.analyser.frequencyBinCount);
    },

    visualize() {
      if (!this.isRecording) return;

      const canvas = this.$refs.visualizer;
      if (!canvas) {
        console.warn('Canvas não encontrado');
        return;
      }

      this.$nextTick(() => {
        const canvasCtx = canvas.getContext('2d');
        if (!canvasCtx) {
          console.warn('Contexto 2D não pôde ser criado');
          return;
        }

        const WIDTH = canvas.width;
        const HEIGHT = canvas.height;

        const draw = () => {
          if (!this.isRecording) return;
          
          requestAnimationFrame(draw);
          
          this.analyser.getByteTimeDomainData(this.visualizerData);
          
          canvasCtx.fillStyle = 'rgb(200, 200, 200)';
          canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
          canvasCtx.lineWidth = 2;
          canvasCtx.strokeStyle = 'rgb(0, 123, 255)';
          canvasCtx.beginPath();

          const sliceWidth = WIDTH / this.visualizerData.length;
          let x = 0;

          for (let i = 0; i < this.visualizerData.length; i++) {
            const v = this.visualizerData[i] / 128.0;
            const y = v * HEIGHT / 2;

            if (i === 0) {
              canvasCtx.moveTo(x, y);
            } else {
              canvasCtx.lineTo(x, y);
            }

            x += sliceWidth;
          }

          canvasCtx.lineTo(WIDTH, HEIGHT / 2);
          canvasCtx.stroke();
        };

        draw();
      });
    },

    pauseRecording() {
      this.mediaRecorder.pause();
      this.isRecording = false;
      this.isPaused = true;
      this.stopTimer();
      this.$emit('recording-paused');
    },

    resumeRecording() {
      this.mediaRecorder.resume();
      this.isRecording = true;
      this.isPaused = false;
      this.startTimer();
      this.$emit('recording-resumed');
    },

    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
      this.isPaused = false;
      this.stopTimer();
      this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
      this.$emit('recording-stopped');
    },

    toggleRecording() {
      if (!this.isRecording && !this.isPaused) {
        this.startRecording();
      } else if (this.isRecording) {
        this.pauseRecording();
      } else if (this.isPaused) {
        this.resumeRecording();
      }
    },

    startTimer() {
      this.timerInterval = setInterval(() => {
        this.recordingTime++;
      }, 1000);
    },

    stopTimer() {
      clearInterval(this.timerInterval);
    },

    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    },

    async sendAudio() {
      try {
        this.isProcessing = true;
        
        const webmBlob = new Blob(this.audioChunks, { 
          type: 'audio/webm'
        });
        
        const finalBlob = new Blob([webmBlob], { 
          type: 'application/octet-stream'
        });
        
        this.$emit('send-audio', finalBlob);
        this.resetState();
      } catch (error) {
        console.error('Erro ao processar áudio:', error);
        this.$emit('error', 'Erro ao processar áudio');
      } finally {
        this.isProcessing = false;
      }
    },

    resetState() {
      this.audioUrl = null;
      this.recordingTime = 0;
      this.isRecording = false;
      this.isPaused = false;
      this.audioChunks = [];
      
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
      
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
        this.analyser = null;
        this.visualizerData = null;
      }

      if (this.mediaRecorder && this.mediaRecorder.stream) {
        this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
      }
    },

    cancelRecording() {
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();
        this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
      }
      this.resetState();
      this.$emit('recording-cancelled');
    },

    resetRecording() {
      this.resetState();
      this.$emit('recording-reset');
    }
  },

  mounted() {
    if (this.autoStart) {
      // Pequeno delay para garantir que o componente está totalmente montado
      this.$nextTick(() => {
        this.startRecording();
      });
    }
  }
}; 