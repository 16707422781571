<template>
  <tr ripple>
    <td class="justify-center" style="padding-top: 4px; cursor: pointer;">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" >
            <v-icon>settings</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile 
            @click="editBusiness()" 
            v-prms="{'id': getIdPermission('update', config.config), mode: 'disabled'}"
          >
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="orange">edit</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Editar</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$WiApiDelete({ uri: config.config.apiUrl, id: business.id, callback: config.refresh })">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="red">delete</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Apagar</v-list-tile-title>
          </v-list-tile>
          <!--
          <v-divider></v-divider>
          <v-list-tile
            @click="copyBudgetTemplate()" 
            v-prms="{'id': getIdPermission('create', config.config), mode: 'disabled'}"
          >
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="green">content_copy</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Duplicar</v-list-tile-title>
          </v-list-tile>
          -->
        </v-list>
      </v-menu>
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(business.id)">
      {{ business.id }}
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(business.id)">
      <v-list dense two-line style="padding: 0; background: transparent;">
        <v-list-tile avatar style="background: transparent;">
          <v-list-tile-avatar>
            <v-icon :color="business.category.color" large>
              {{ business.category.icon }}
            </v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title style="font-weight: bold;">
              {{ business.title || `Modelo de orçamento #${business.id}` }}
            </v-list-tile-title>
            <v-list-tile-sub-title>
              by {{ business.category.name }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </td>
    <td class="justify-center">
      <v-btn
        @click="useBudgetTemplate()" outline
        v-prms="{'id': getIdPermission('create', config.config), mode: 'disabled'}"
      >
        <v-icon>auto_awesome_motion</v-icon>
        <span class="ml-1"> Usar modelo de orçamento</span>
      </v-btn>
    </td>
  </tr>
</template>
<script>
  import { Auth } from '@/default/service/Auth'
  export default {
    name: 'Businesses',
    computed: {
      business () {
        return this.props.item
      }
    },
    methods: {
      goToRouteDetail: function (id) {
        this.$router.push({
          name: 'budget-template',
          params: {
            id: id
          }
        })
      },
      useBudgetTemplate: function () {
        this.$WiEditDialog({
          wiConfig: 'budget',
          onSubmit: this.config.refresh,
          data: {
            ...this.props.item,
            id: null,
            uuid: null,
            business_id: null,
            person_id: null,
            person: null,
            collaborator_id: Auth.user().id,
            is_template: false,
            date_start: new Date().toISOString().split('T')[0], //current date
            date_end: null,
            date_validity: null,
            copy_business_id: this.props.item.id,
            copy_business: 'd711a3c2-3809-4ef2-9052-e34fb11ac908',
            created_at: null,
            updated_at: null,
            opened_at: null,
            closed_at: null,
            canceled_at: null,
            ...this.config?.wiEditData || {}
          }
        })
      },
      copyBudgetTemplate: function () {
        this.$WiEditDialog({
          wiConfig: 'budget-template',
          onSubmit: this.config.refresh,
          data: {
            ...this.props.item,
            id: null,
            uuid: null,
            business_id: null,
            person_id: null,
            person: null,
            collaborator_id: Auth.user().id,
            is_template: false,
            date_start: new Date().toISOString().split('T')[0], //current date
            date_end: null,
            date_validity: null,
            copy_business_id: this.props.item.id,
            copy_business: 'd711a3c2-3809-4ef2-9052-e34fb11ac908',
            created_at: null,
            updated_at: null,
            opened_at: null,
            closed_at: null,
            canceled_at: null,
            ...this.config?.wiEditData || {}
          }
        })
      },
      editBusiness: function () {
        this.$WiEditDialog({
          wiConfig: this.config.store,
          onSubmit: this.config.refresh,
          data: this.props.item
        })
      },
      getIdPermission: function (action, config) {
        if (config && config.id) {
          return config.id[action] || config.id
        }
        return null
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
.business__date {
  border-width: 1px;
  border-style: solid;
  padding: 0px 3px;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
}
.business__date--new {
  border-color: green;
  background: rgba(0, 128, 0, 0.589);
}
.business__date--expired {
  border-color: red;
  background: rgba(255, 0, 0, 0.507);
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.business__is-template {
  background: orange;
  border-color: rgb(145, 94, 0);
}
</style>