import { Model } from '@/default/model/Model'

export class TicketExport extends Model {
  constructor () {
    super()
    this.id = '49944f98'
    this.apiUrl = 'service-desk/ticket-export'
    this.title = 'Exportar Chamados'
    this.icon = 'download'
    this.maxWidth = '598px'

    this.setFields(this.fieldsEdit)
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Selecione uma ou mais opções para filtrar.'
    }),
    ticket_status_id: this.fieldSelectApi ({
      value: 'ticket_status_id',
      text: 'Status',
      show: 'nome_status',
      icon: 'icone_status',
      color: 'cor_status',
      api: {
        url: 'chamado/status',
        params: {
          fields: 'id,nome_status,icone_status,cor_status',
          order: 'ordem_status,asc'
        }
      },
      size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
    }),
    dateField: this.fieldSelect({
      value: 'date_field',
      text: 'Campo de Data',
      placeholder: 'Selecione o campo...',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: true,
      options: [
        {
          value: 'last_event_time',
          text: 'Última interação'
        },
        {
          value: 'opened_at',
          text: 'Data de abertura'
        },
        {
          value: 'closed_at',
          text: 'Data de conclusão'
        },
        {
          value: 'canceled_at',
          text: 'Data de cancelamento'
        },
        {
          value: 'competence_at',
          text: 'Data de Competência'
        }
      ],
      defaultOption: 'opened_at'
    }),
    subHeader2: this.fieldSubHeader({
      text: 'O período máximo de exportação é de 31 dias.'
    }),
    dateStart: this.fieldDateTime({
      value: 'date_start',
      text: 'Data Inicial',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: true
    }),
    dateEnd: this.fieldDateTime({
      value: 'date_end',
      text: 'Data Final',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      required: true
    })
  }
}
