<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      {{props.item.id}}
    </td>
    <td class="justify-left" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <strong> {{ props.item.person.name | stringlength(30) }} </strong><br>
      <span style="color: gray;"> {{props.item.person.first_name | stringlength(30) }} </span>
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <div style="text-align: center;">
        <v-icon>{{props.item.status.icon}}</v-icon><br>
        <strong :style="{color: props.item.status.color}">{{props.item.status.name}}</strong>
      </div>
    </td>
    <td class="justify-center" style="padding-top: 4px; cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <div style="text-align: center;">
        <v-icon>{{props.item.category.icon}}</v-icon><br>
        <strong :style="{color: props.item.category.color}">
          {{props.item.category.name}}
        </strong>
      </div>
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <div class="payment-day">
        <v-icon class="payment-day__icon">event</v-icon>
        <div class="payment-day__number">
          {{ props.item.invoice_pay_day }}
        </div>
      </div>
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <div :class="getPaymentStatusClass">
        <template v-if="props.item.invoices_late.length > 0 && props.item.invoices_late[0].date_validity_days">
          <div class="status-text__title">
            <v-icon class="status-text__icon">warning</v-icon>
            Em atraso há
          </div>
          <div class="status-text__days">
            {{ props.item.invoices_late[0].date_validity_days }} dias
          </div>
        </template>
        <template v-else>
          <div class="status-text__title">
            <v-icon class="status-text__icon">check_circle</v-icon>
            Em dia
          </div>
        </template>
      </div>
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <div :class="getContractEndClass">
        <div class="status-text__title">
          <v-icon class="status-text__icon">{{ getContractEndIcon }}</v-icon>
          Encerra em
        </div>
        <div class="status-text__days">{{ props.item.date_end_days }} dias</div>
      </div>
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <div class="money-value">
        <div class="money-value__amount">
          {{ props.item.total_liquid | money }}
        </div>
      </div>
    </td>
    <!-- <td class="justify-center" style="padding-top: 4px; cursor: pointer;">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile>
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>message</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Visualizar Notas</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile>
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>attachment</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Arquivos/Anexos</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td> -->
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  import { TestManagementEventService } from '@/modules/service-desk/services/TestManagement/TestManagementEventService'
  import { TestManagementEventSocket } from '@/modules/service-desk/services/TestManagement/TestManagementEventSocket'
  export default {
    name: 'ContractsRecurrent',
    computed: {
      address () {
        return this.props.item.address
      },
      paymentStatus() {
        if (this.props.item.invoices_late?.[0]?.date_validity_days) {
          return `Em atraso há ${this.props.item.invoices_late[0].date_validity_days} dias`
        }
        return 'Em dia'
      },
      getPaymentStatusClass() {
        return {
          'status-text': true,
          'status-text--late': this.props.item.invoices_late?.[0]?.date_validity_days,
          'status-text--ontime': !this.props.item.invoices_late?.[0]?.date_validity_days
        }
      },
      contractEndStatus() {
        return this.props.item.date_end_days
      },
      getContractEndClass() {
        const days = this.props.item.date_end_days
        return {
          'status-text': true,
          'status-text--warning': days < 90 && days >= 0,
          'status-text--danger': days < 0
        }
      },
      getContractEndIcon() {
        const days = this.props.item.date_end_days
        if (days < 0) {
          return 'error'
        } else if (days < 90 && days >= 0) {
          return 'warning'
        }
        return 'check_circle'
      }
    },
    methods: {
      goToRouteDetail: function (id) {
        var url = this.config.config.urlDetails
        url.params['id'] = id
        this.$router.push(url)
      },
      openEvents: function (business) {
        this.$WiEventsDialog({
          title: 'Ações/Eventos',
          icon: 'message',
          service: TestManagementEventService,
          serviceParams: {
            where: 'business_id,' + business.id
          },
          socket: TestManagementEventSocket,
          socketParams: {
            id: business.id
          },
          business: business
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
.business__date {
  border-width: 1px;
  border-style: solid;
  padding: 0px 3px;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
}
.business__date--new {
  border-color: green;
  background: rgba(0, 128, 0, 0.589);
}
.business__date--expired {
  border-color: red;
  background: rgba(255, 0, 0, 0.507);
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.business__is-template {
  background: orange;
  border-color: rgb(145, 94, 0);
}
.contract__address .v-list__tile {
  padding: 0 !important;
}
.status-text {
  /* padding: 8px; */
  border-radius: 4px;
  font-weight: bold;
  text-align: center;
}

.status-text__title {
  font-size: 12px;
  /* margin-bottom: 2px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.status-text__icon {
  font-size: 16px !important;
}

.status-text__days {
  font-size: 16px;
}

.status-text--late {
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
}

.status-text--ontime {
  padding: 8px;
  background-color: rgba(0, 255, 0, 0.1);
  color: green;
}

.status-text--warning {
  background-color: rgba(255, 165, 0, 0.1);
  color: orange;
}

.status-text--danger {
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
}

.payment-day {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 8px;
  padding: 4px 0px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.payment-day__icon {
  color: #1976d2;
  font-size: 20px !important;
}

.payment-day__number {
  font-size: 18px;
  font-weight: bold;
  color: #1976d2;
}

.money-value {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 5px;
  background-color: rgba(76, 175, 80, 0.1);
  border-radius: 8px;
  color: #2e7d32;
}

.money-value__icon {
  font-size: 18px !important;
  opacity: 0.8;
}

.money-value__amount {
  font-size: 16px;
  font-weight: bold;
}
</style>