import { Model } from '@/default/model/Model'

export class ChatTopic extends Model {
  constructor () {
    super()
    this.list = true
    this.id = '550a585c'
    this.name = 'chat-topic'
    this.actions = {
      list: false,
      create: true,
      update: true,
      delete: false
    }
    this.title = {
      list: 'Tópicos de chat',
      edit: {
        insert: 'Criar novo tópico',
        update: 'Editar tópico'
      }
    }
    this.apiUrl = 'chats/chat-topic'
    this.icon = 'topic'
    this.maxWidth = '500px'

    this.editFields = {
      header1: this.fieldSubHeader({
        text: 'Informe o nome do tópico e o tipo do tópico.'
      }),
      title: this.fieldText({
        value: 'title',
        text: 'Título',
        placeholder: 'Ex: Grupo de atendimento',
        required: true,
        size: form => form.id ? ['xs12'] : ['xs12', 'sm12', 'md8', 'lg8'],
      }),
      type_id: this.fieldSelect({
        value: 'type_id',
        text: 'Tipo',
        options: [
          { value: 1, text: 'Padrão' }
        ],
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        defaultOption: 1,
        showField: form => !form.id
      }),
      header3: this.fieldSubHeader({
        text: 'Defina a descrição do tópico.',
        showField: form => !form.id
      }),
      description: this.fieldTextArea({
        value: 'description',
        text: 'Descrição',
        placeholder: 'Ex: Tópico de atendimento ao cliente',
        required: true,
        size: ['xs12', 'sm12', 'md12', 'lg12'],
      })
    }
    this.setFields(this.editFields)
  }
}

export class ChatTopicGroup extends ChatTopic {
  constructor () {
    super()
    this.list = false

    this.clearFields()

    this.editFields = {
      chat_id: this.fieldSelectApi({
        value: 'chat_id',
        text: 'Grupo',
        show: 'name',
        api: {
          url: 'chats/chat',
          params: {
            fields: 'id,uuid,name',
            type_id: 3
          }
        },
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        required: true
      }),
      ...this.editFields
    }
    this.setFields(this.editFields)
  }
}
