<template>
  <div class="page-container">
    <div class="a4-page">
      <iframe
        ref="contentFrame"
        class="page-content"
        :srcdoc="getIframeContent"
        frameborder="0"
        @load="adjustIframeHeight"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessLayoutA4View',
  props: ['layoutData'],
  computed: {
    getIframeContent() {
      // Injeta um estilo para remover scrollbars no conteúdo do iframe
      const styleTag = '<style>html, body { overflow: visible !important; }</style>';
      const htmlContent = this.layoutData.html;
      
      // Insere o style tag após a tag <head>
      return htmlContent.replace('</head>', `${styleTag}</head>`);
    }
  },
  methods: {
    adjustIframeHeight() {
      const iframe = this.$refs.contentFrame;
      if (iframe) {
        iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
      }
    }
  },
  watch: {
    'layoutData.html': {
      handler() {
        this.$nextTick(() => {
          this.adjustIframeHeight();
        });
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.page-container {
  height: 100%;
  padding: 2rem;
  background-color: #f0f2f5;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  max-height: calc(100vh - 212px);
}

.a4-page {
  background: white;
  width: 21cm;
  min-height: 29.7cm;
  height: auto;
  /* padding: 1.27cm; */
  margin: 2rem auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  flex-shrink: 0;
}

.page-content {
  width: 100%;
  border: none;
  border-radius: 10px;
}

@media print {
  .page-container {
    padding: 0;
    background: none;
  }

  .a4-page {
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 21cm) {
  .a4-page {
    width: 100%;
    min-height: auto;
    padding: 1.5cm;
  }
}
</style>