import {
  PERMISSION_REGISTER_IMPORT_CUSTOMER,
  PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_IMANAGER,
  PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_HUBSPOT
} from '@/default/constants/permissions'
import { PersonFilters } from './PersonFilters'
import Customer from '@/modules/register/config/Person/Person?url=Customer'

var customer = Customer({name: 'Cliente', field: 'customer'})

customer.id = {
  id: '549b7548',
  create: 'ba7aae1c',
  update: '1bced885',
  delete: 'd99b53a2'
}
customer.title = {
  list: 'Clientes',
  edit: {
    insert: 'Novo cliente',
    update: 'Editar cliente'
  }
}
customer.icon = 'people_outline'
customer.apiUrl = 'register/person/customer'
customer.urlDetails.name = 'customer'
customer.params['with'] = 'address_master'
customer.components = {
  list: {
    tableRow: () => import('@/modules/register/pages/Persons/Customers')
  }
}
customer['imports'] = {
  permission: PERMISSION_REGISTER_IMPORT_CUSTOMER,
  title: 'Importar Cliente',
  origins: {
    imanager: {
      title: 'I-Manager',
      description: 'Importar cliente do I-Manager através do CPF/CNPJ.',
      permission: PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_IMANAGER,
      image: '/static/integration/logos/interfocus-tecnologia-logo.jpg',
      wiConfig: 'import-customer-from-imanager'
    },
    hubspot: {
      title: 'HubSpot',
      description: 'Importar cliente do HubSpot através do e-mail.',
      permission: PERMISSION_REGISTER_IMPORT_CUSTOMER_FROM_HUBSPOT,
      image: '/static/integration/logos/hubspot-logo.png',
      wiConfig: ''
    }
  }
}
customer['filters'] = new PersonFilters()

export default customer
