<template>
  <v-container fluid>
    <div class="loading-container">
      <div class="loading-indicator">
        <v-progress-circular indeterminate :size="32" :width="3" color="grey"></v-progress-circular>
        <span class="loading-text">{{ loadingText }}</span>
      </div>
    </div>
    <div class="kanban-skeleton">
      <div v-for="n in 4" :key="n" class="column-skeleton">
        <div class="column-header">
          <div class="header-title-skeleton"></div>
          <div class="header-count-skeleton"></div>
        </div>
        
        <div v-for="card in 3" :key="card" class="card-skeleton">
          <div class="card-priority-skeleton"></div>
          <div class="card-content">
            <div class="card-title-skeleton"></div>
            <div class="card-description-skeleton"></div>
          </div>
          <div class="card-footer">
            <div class="avatar-skeleton"></div>
            <div class="date-skeleton"></div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    loadingText: {
      type: String,
      default: 'Carregando...'
    }
  }
}
</script>

<style scoped>
.kanban-skeleton {
  display: flex;
  gap: 20px;
  padding: 16px;
  min-height: 80vh;
}

.column-skeleton {
  flex: 1;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 12px;
  min-width: 280px;
}

.column-header {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title-skeleton {
  height: 24px;
  width: 120px;
  background: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.header-count-skeleton {
  height: 24px;
  width: 30px;
  background: #e0e0e0;
  border-radius: 12px;
  animation: pulse 1.5s infinite;
}

.card-skeleton {
  background: #ffffff;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px;
  border-left: 4px solid #bdbdbd;
  animation: slideIn 0.5s ease-out;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.card-priority-skeleton {
  width: 60px;
  height: 20px;
  background: #eeeeee;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: pulse 1.5s infinite;
}

.card-content {
  margin: 8px 0;
}

.card-title-skeleton {
  height: 16px;
  width: 80%;
  background: #eeeeee;
  border-radius: 4px;
  margin-bottom: 8px;
  animation: pulse 1.5s infinite;
}

.card-description-skeleton {
  height: 12px;
  width: 90%;
  background: #eeeeee;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.avatar-skeleton {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #eeeeee;
  animation: pulse 1.5s infinite;
}

.date-skeleton {
  width: 80px;
  height: 16px;
  background: #eeeeee;
  border-radius: 4px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Suporte para tema escuro */
.theme--dark .column-skeleton {
  background: #1E1E1E;
}

.theme--dark .card-skeleton {
  background: #2D2D2D;
  border-left-color: #424242;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.theme--dark .header-title-skeleton,
.theme--dark .header-count-skeleton,
.theme--dark .card-priority-skeleton,
.theme--dark .card-title-skeleton,
.theme--dark .card-description-skeleton,
.theme--dark .avatar-skeleton,
.theme--dark .date-skeleton {
  background: #424242;
}

/* Novos estilos para o loading */
.loading-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.loading-indicator {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 20px;
}

.loading-text {
  font-size: 14px;
  color: #757575;
  font-weight: 500;
}

/* Suporte para tema escuro */
.theme--dark .loading-indicator {
  background: rgba(255, 255, 255, 0.05);
}

.theme--dark .loading-text {
  color: #bdbdbd;
}
</style> 