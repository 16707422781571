import router from '@/modules/service-desk/routes'
import config from '@/modules/service-desk/config'
import menu from '@/modules/service-desk/menu'
import url from '@/default/store/modules/url/url'

const system = url.state.system
let title = 'Service Desk'
let description = 'Módulo Gestão de Chamados'

if (system === 'sis-pocos') {
  title = 'Serviços'
  description = 'Gestão de O.S e Chamados'
}

export default {
  id: '93ce590a-82f4-478a-a10f-affed70c981e',
  name: 'service-desk',
  title,
  description,
  icon: 'wises-technology-module-service-desk.png',
  iconSystem: 'wise-network-system-service-desk.png',
  iconProfile: 'profile_service_desk.png',
  color: '#a59d19',
  menu,
  router,
  config
}
