import ServiceDeskDashboard from '@/modules/service-desk/pages/Dashboard/ServiceDeskDashboard'
import TicketsByNetworkTypeDashboard from '@/modules/service-desk/pages/Dashboard/TicketsByNetworkType'
import Ticket from '@/modules/service-desk/pages/Ticket/Ticket'
import Arquivos from '@/default/components/Arquivos/Arquivos'
import Chamados from '@/modules/service-desk/pages/Chamados/Chamados'
import Ocorrencias from '@/modules/service-desk/pages/Ocorrencias/Ocorrencias'
import ChamadosKanban from '@/modules/service-desk/pages/ChamadosKanban/ChamadosKanbanV2'
import ChamadoConfiguracoes from '@/modules/service-desk/pages/Configuracoes/ChamadoConfiguracoes'

import ChamadosPorOcorrencia from '@/modules/service-desk/pages/Reports/ChamadosPorOcorrencia'
import TicketsByNetworks from '@/modules/service-desk/pages/Reports/TicketsByNetworks'
import TicketsByCategoryStatistics from '@/modules/service-desk/pages/Reports/TicketsByCategoryStatistics'
import TicketsByNetworkStatistics from '@/modules/service-desk/pages/Reports/TicketsByNetworkStatistics'
import TicketsByNetworkStatisticsV2 from '@/modules/service-desk/pages/Reports/TicketsByNetworkStatisticsV2'
import TicketsList from '@/modules/service-desk/pages/Reports/TicketsList'

import Order from '@/modules/service-desk/pages/Order'
import BusinessOfProcess from '@/modules/business/pages/BusinessOfProcess'

import Diagnostic from '@/modules/service-desk/pages/TestManagements/Diagnostic/Diagnostic'
import Processs from '@/modules/service-desk/pages/Processs'
import Process from '@/modules/service-desk/pages/Process'

import OrdersCalendar from '@/modules/service-desk/pages/OrdersCalendar'
import OrdersDashboard from '@/modules/service-desk/pages/OrdersDashboard'
import {
  PERMISSION_ORDER_UPDATE
} from '@/default/constants/permissions'

export default [
  {
    path: 'chamados/:page',
    name: 'chamados',
    component: Chamados,
    meta: {
      id: '5dc282ab',
      auth: true,
      title: 'Chamados',
      icon: 'message',
      touch: true
    }
  },
  {
    path: 'chamado/:id',
    name: 'chamado',
    component: Ticket,
    meta: {
      id: '5dc282ab',
      auth: true,
      title: 'Chamado',
      icon: 'message'
    }
  },
  {
    path: 'ticket/:id',
    name: 'ticket',
    component: Ticket,
    meta: {
      id: '5dc282ab',
      auth: true,
      title: 'Chamado',
      icon: 'message'
    }
  },
  {
    path: 'configuracoes-do-chamado/:id',
    name: 'configuracoes-do-chamado',
    component: ChamadoConfiguracoes,
    meta: {
      id: '4f1be58b',
      auth: true,
      title: 'Configurações do Chamado',
      icon: 'settings'
    }
  },
  {
    path: 'chamados-kanban',
    name: 'chamados-kanban',
    component: ChamadosKanban,
    meta: {
      id: '5dc282ab',
      auth: true,
      title: 'Kanban Chamados',
      icon: 'view_week',
      touch: true
    }
  },
  {
    path: 'chamados-ocorrencias',
    name: 'chamados-ocorrencias',
    component: Ocorrencias,
    meta: {
      id: '4f1be58b',
      auth: true,
      title: 'Tipos de Ocorrências',
      icon: 'category',
      touch: true
    }
  },
  {
    path: 'files',
    name: 'files',
    component: Arquivos,
    meta: {
      id: 'svdk',
      auth: true,
      title: 'Arquivos',
      icon: 'file'
    }
  },
  {
    path: 'chamados-por-ocorrencia',
    name: 'chamados-por-ocorrencia',
    component: ChamadosPorOcorrencia,
    meta: {
      id: 'svdk',
      auth: true,
      title: 'Chamados Por Tipo de Ocorrência',
      icon: 'insert_chart_outlined'
    }
  },
  {
    path: 'tickets-by-networks',
    name: 'tickets-by-networks',
    component: TicketsByNetworks,
    meta: {
      id: 'svdk',
      auth: true,
      title: 'Chamados Por Grupo de Rede',
      icon: 'insert_chart_outlined'
    }
  },
  {
    path: 'tickets-by-category-statistics',
    name: 'tickets-by-category-statistics',
    component: TicketsByCategoryStatistics,
    meta: {
      id: '4dd2e87d',
      auth: true,
      title: 'Estatísticas de Chamados por Ocorrência',
      icon: 'insert_chart_outlined'
    }
  },
  {
    path: 'tickets-by-network-statistics',
    name: 'tickets-by-network-statistics',
    component: TicketsByNetworkStatistics,
    meta: {
      id: 'bef5093c',
      auth: true,
      title: 'Estatísticas de Chamados por Rede',
      icon: 'insert_chart_outlined'
    }
  },
  {
    path: 'tickets-by-network-statistics-v2',
    name: 'tickets-by-network-statistics-v2',
    component: TicketsByNetworkStatisticsV2,
    meta: {
      id: 'bef5093c',
      auth: true,
      title: 'Estatísticas de Chamados por Rede',
      icon: 'insert_chart_outlined'
    }
  },
  {
    path: 'tickets-list',
    name: 'tickets-list',
    component: TicketsList,
    meta: {
      id: '5ee5c88e',
      auth: true,
      title: 'Chamados Encerrados',
      icon: 'insert_chart_outlined'
    }
  },
  {
    path: 'order/:id',
    name: 'order',
    component: Order,
    meta: {
      id: '74cc44a0',
      auth: true,
      title: 'Detalhes da Ordem de Serviço',
      icon: 'phonelink_setup',
      config: {
        code: 5,
        name: 'order',
        title: 'Ordem de Serviço',
        icon: 'phonelink_setup',
        permission: {
          update: PERMISSION_ORDER_UPDATE
        }
      }
    }
  },
  {
    path: 'dashboard',
    name: 'service-desk-dashboard',
    component: ServiceDeskDashboard,
    meta: {
      id: '800034c4',
      auth: true,
      title: 'Dashboard Service Desk',
      icon: 'dashboard',
      moduleName: 'service-desk'
    }
  },
  {
    path: 'dashboard/tickets-by-network-type/:id',
    name: 'tickets-by-network-type-dashboard',
    component: TicketsByNetworkTypeDashboard,
    meta: {
      id: 'b87a2ece',
      auth: true,
      title: 'Dashboard Service Desk',
      icon: 'dashboard',
      moduleName: 'service-desk'
    }
  },
  {
    path: 'process-of-service-desk/:business',
    name: 'process-of-service-desk',
    component: BusinessOfProcess,
    meta: {
      id: '74cc44a0',
      auth: true,
      title: 'Ordens de Serviço',
      icon: 'phonelink_setup',
      touch: true
    }
  },
  {
    path: 'diagnostic/:id',
    name: 'diagnostic',
    component: Diagnostic,
    meta: {
      id: '74cc44a0',
      auth: true,
      title: 'Detalhes do Diagnóstico',
      icon: 'phonelink_setup'
    }
  },
  {
    path: 'process/:id',
    name: 'process',
    component: Processs,
    meta: {
      id: '74cc44a0',
      auth: true,
      title: 'Processo',
      icon: 'phonelink_setup'
    }
  },
  {
    path: 'process/execution/:id',
    name: 'process-execution',
    component: Process,
    meta: {
      id: '74cc44a0',
      auth: true,
      title: 'Processo',
      icon: 'phonelink_setup'
    }
  },
  {
    path: 'orders-calendar',
    name: 'orders-calendar',
    component: OrdersCalendar,
    meta: {
      id: '74cc44a0',
      auth: true,
      title: 'Calendário de Ordens de Serviço',
      icon: 'phonelink_setup'
    },
    children: [
      {
        path: 'orders-calendar/:id',
        name: 'orders-calendar-order',
        component: Order,
        meta: {
          id: '74cc44a0',
          auth: true,
          title: 'Detalhes da Ordem de Serviço',
          icon: 'phonelink_setup',
          config: {
            code: 5,
            name: 'order',
            title: 'Ordem de Serviço',
            icon: 'phonelink_setup',
            permission: {
              update: PERMISSION_ORDER_UPDATE
            }
          },
          scroll: true
        }
      }
    ]
  },
  {
    path: 'orders-dashboard',
    name: 'orders-dashboard',
    component: OrdersDashboard,
    meta: {
      id: '74cc44a0',
      auth: true,
      title: 'Dashboard Ordens de Serviço',
      icon: 'phonelink_setup'
    }
  }
]
