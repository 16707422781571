var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadings.load)?_c('div',[_c('TalkLoading',{attrs:{"mode":_vm.mode,"showDrawer":_vm.showContactInfo}})],1):_c('div',[_c('div',{staticStyle:{"padding":"0 !important","display":"flex","flex-direction":"row","flex-wrap":"nowrap","justify-content":"flex-start","align-items":"stretch","align-content":"stretch"}},[(_vm.talk && _vm.talk.id)?_c('div',{staticStyle:{"width":"100%"}},[_c('TalkToolbar',{attrs:{"mode":_vm.mode,"talkId":_vm.talkId},on:{"onClose":function($event){return _vm.closeAction()}}}),_c('div',{staticStyle:{"padding":"0 !important","display":"flex","flex-direction":"row","flex-wrap":"nowrap","justify-content":"flex-start","align-items":"stretch","align-content":"stretch"}},[_c('v-container',{staticClass:"talk__message-area",style:({
              'background-image': _vm.$wiDark ? 'url(\'static/background/background-whatsapp-dark.jpg?url=123\')' : 'url(\'static/background/background-whatsapp.jpg?url=123\')',
              height: _vm.pageHeight
            }),attrs:{"id":"container","fluid":""}},[_c('div',{staticStyle:{"padding":"0px 20px 20px 20px"}},[_c('div',{staticStyle:{"width":"100%","text-align":"center","padding-top":"20px"}},[_c('v-btn',{attrs:{"loading":_vm.loadings.paginate,"disabled":!_vm.paginate.next_page_url},on:{"click":function($event){return _vm.paginateMessages()}}},[_c('v-icon',[_vm._v("refresh")]),_c('span',{directives:[{name:"t",rawName:"v-t",value:('modules.talks.pages.talkView.talk.labels.loadMore'),expression:"'modules.talks.pages.talkView.talk.labels.loadMore'"}]})],1)],1),_vm._l((_vm.messagesOrdered),function(message,messageKey){return _c('TalkMessage',{key:(messageKey + "-" + (message.id)),attrs:{"talk":_vm.talk,"message":message},on:{"share":_vm.shareMessage}})}),(_vm.talk.contact.checked == 3)?_c('div',{staticClass:"elevation-10",staticStyle:{"margin":"20px 0 20px 0","width":"100%"}},[_c('div',[_c('v-alert',{staticStyle:{"padding":"5px","border-radius":"5px","color":"black"},attrs:{"value":true,"type":"warning"}},[_c('strong',[_vm._v("Atenção:")]),_c('br'),_c('span',[_vm._v("Este contato é inválido!")])])],1)]):_vm._e(),(_vm.talk.channel && _vm.talk.channel.status_id != 2)?_c('div',{staticClass:"elevation-10",staticStyle:{"margin":"20px 0 20px 0","width":"100%"}},[_c('div',[_c('v-alert',{staticStyle:{"padding":"5px","border-radius":"5px","cursor":"pointer"},attrs:{"value":true,"type":"error"},on:{"click":function($event){return _vm.$router.push({
                      name: 'talk-list',
                      params: { page: 'talk-channel' },
                      query: { where: ("id," + (_vm.talk.channel.id)) }
                    })}}},[_c('strong',[_vm._v("Atenção:")]),_c('br'),_c('span',[_vm._v("O canal de atendimento "),_c('strong',[_vm._v("\""+_vm._s(_vm.talk.channel.name)+"\"")]),_vm._v(" está desconectado.")])])],1)]):_vm._e()],2),_c('div',[_c('TalkFooter',{on:{"scroll":_vm.scrollToEnd,"refresh":function () { _vm.getTalk(_vm.talkId) }}})],1)])],1)],1):_vm._e(),(_vm.talk && _vm.talk.id)?_c('div',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('div',[(_vm.showContactInfo)?_c('div',{staticClass:"hidden-xs-only hidden-sm-only"},[_c('TalkContact',{attrs:{"contact":_vm.talk.contact,"refresh":function () { _vm.getTalk(_vm.talkId) },"mode":_vm.mode,"talkId":_vm.talkId},on:{"onClose":function($event){return _vm.closeAction()}}})],1):_vm._e()]):_c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"lazy":""},model:{value:(_vm.showContactInfo),callback:function ($$v) {_vm.showContactInfo=$$v},expression:"showContactInfo"}},[_c('TalkContact',{attrs:{"contact":_vm.talk.contact,"refresh":function () { _vm.getTalk(_vm.talkId) },"mode":_vm.mode,"talkId":_vm.talkId},on:{"onClose":function($event){return _vm.closeAction()}}})],1)],1)]):_c('div',{staticStyle:{"width":"100%","display":"flex","flex-direction":"column","justify-content":"center"}},[_c('div',{staticStyle:{"width":"100%","text-align":"center","margin":"auto","max-width":"400px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"static/background/whatsapp-not-found.png?url=temp1","alt":""}}),_c('br'),_c('span',{directives:[{name:"t",rawName:"v-t",value:('modules.talks.pages.talkView.talk.notFound'),expression:"'modules.talks.pages.talkView.talk.notFound'"}],staticStyle:{"font-size":"20px"}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }