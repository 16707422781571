import { render, staticRenderFns } from "./BusinessLayoutA4View.vue?vue&type=template&id=5efeba72&scoped=true&"
import script from "./BusinessLayoutA4View.vue?vue&type=script&lang=js&"
export * from "./BusinessLayoutA4View.vue?vue&type=script&lang=js&"
import style0 from "./BusinessLayoutA4View.vue?vue&type=style&index=0&id=5efeba72&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5efeba72",
  null
  
)

export default component.exports