<template>
  <v-dialog v-model="dialog" scrollable max-width="598">
    <template v-slot:activator="{ on }">
      <v-btn 
        outline :block="$vuetify.breakpoint.xsOnly"
        small v-on="on"
      >
        <v-icon>help</v-icon>
        <span
          class="ml-2"
          v-t="'components.appBar.suggestImprovement.label'"
        ></span>
      </v-btn>
    </template>
    <v-card class="support-dialog" :class="{ 'theme--dark': $wiDark }">
      <v-toolbar flat dense color="transparent">
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      
      <v-card-text class="text-center" style="max-height: 700px; overflow-y: auto;">
        <div class="header-wrapper">
          <div class="support-icon" :class="{ 'after-hours': !isDuringBusinessHours }">
            <span class="material-icons">{{ mainIcon }}</span>
          </div>
          
          <h2 class="support-title" :class="{ 'after-hours': !isDuringBusinessHours }">
            {{ mainTitle }}
          </h2>
        </div>

        <div class="support-schedule">
          <div class="schedule-item">
            <div>
              <strong>Horário de atendimento regular:</strong><br>
              Segunda a sexta, das 08h às 18h.
            </div>
          </div>

          <div class="schedule-item">
            <div>
              <strong>Plantão para emergências:</strong><br>
              Fora do horário regular, nossa equipe conta com uma escala de plantonistas para ajudar em situações críticas.
            </div>
          </div>
        </div>

        <h3 class="channels-title">Entre em contato, adoramos novas ideias e sugestões</h3>

        <div class="support-channels">
          <div class="channel-item whatsapp-card" @click="abrirWhatsApp">
            <div class="channel-icon whatsapp-pulse">
              <svg viewBox="0 0 24 24" width="24" height="24">
                <path fill="#25D366" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z"/>
              </svg>
            </div>
            <div>
              <strong>WhatsApp – Nosso principal canal de suporte</strong><br>
              Entre em contato diretamente com nossa equipe pelo WhatsApp.
            </div>
          </div>

          <div class="channel-item form-card" @click="$WiHelpDialog({
            title: 'Por favor, descreva abaixo sua solicitação.'
          })">
            <div class="channel-icon">
              <span class="material-icons">description</span>
            </div>
            <div>
              <strong>Envie sua dúvida ou sugestão de melhoria</strong><br>
              Adoramos receber novas ideias! Compartilhe suas sugestões ou tire suas dúvidas através do nosso formulário. Sua opinião é muito importante para continuarmos evoluindo.
            </div>
          </div>
        </div>

        <p class="support-footer">
          Estamos aqui para ajudar você! Nossa equipe se esforça para oferecer o melhor suporte possível.
        </p>
      </v-card-text>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :timeout="4000"
      color="success"
    >
      Obrigado por entrar em contato! Nossa equipe retornará em breve.
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
export default {
  name: 'HelpDialog',
  data() {
    return {
      dialog: false,
      snackbar: false,
      plataform: 'Wise Control'
    }
  },
  computed: {
    isDuringBusinessHours() {
      const now = new Date()
      const hour = now.getHours()
      const day = now.getDay()
      
      // Verifica se é dia útil (1-5 = Segunda a Sexta)
      const isWeekday = day >= 1 && day <= 5
      // Verifica se está dentro do horário (8h-18h)
      const isBusinessHour = hour >= 8 && hour < 18
      
      return isWeekday && isBusinessHour
    },
    mainIcon() {
      return !this.isDuringBusinessHours 
        ? 'nightlight_round' // Ícone de plantão noturno
        : 'headset_mic' // Ícone de atendimento ativo
    },
    mainTitle() {
      return this.isDuringBusinessHours
        ? 'Estamos disponíveis agora para atender você! Entre em contato sempre que precisar, queremos muito ajudar.'
        : 'Estamos em horário de plantão! Se você tiver uma emergência ou situação urgente, não hesite em nos contatar.'
    },
    user () {
      return this.$store.state.User
    },
    system () {
      return this.$store.state.system || []
    },
    themeColors() {
      return {
        background: this.$wiDark ? '#1E1E1E' : '#ffffff',
        scheduleBackground: this.$wiDark ? '#2D2D2D' : '#f8f9fa',
        channelBackground: this.$wiDark ? '#2D2D2D' : '#ffffff',
        borderColor: this.$wiDark ? '#424242' : '#e0e0e0',
        textColor: this.$wiDark ? '#E0E0E0' : '#2c3e50',
        secondaryText: this.$wiDark ? '#B0B0B0' : '#666',
        iconBackground: this.$wiDark ? '#363636' : '#f5f5f5',
        whatsappIconBg: this.$wiDark ? '#1A3329' : '#E7FFE7',
        formIconBg: this.$wiDark ? '#1A2733' : '#E3F2FD'
      }
    }
  },
  methods: {
    getNumeroWhatsApp() {
      switch(this.system.name) {
        case 'Wise Control':
          return '553798201057'
        case 'Connect View':
          return '553798201057'
        case 'Wise Message':
          return '553731129918'
        case 'SisPoços':
          return '553731129928'
        default:
          return '553798201057'
      }
    },
    formatarDataHora() {
      const agora = new Date()
      return agora.toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    },
    getMensagemWhatsApp() {
      const dataHora = this.formatarDataHora()
      
      return encodeURIComponent(
        `Olá! Preciso de suporte.\n\n` +
        `*Informações do contato:*\n` +
        `📝 Nome: ${this.user.name}\n` +
        `👤 Perfil: ${this.user.profile.name}\n` +
        `🏢 Empresa: ${this.user.account.name}\n` +
        `💻 Plataforma: ${this.system.name}\n` +
        `🕐 Data/Hora: ${dataHora}\n\n` +
        `Mensagem: `
      )
    },
    abrirWhatsApp() {
      const numero = this.getNumeroWhatsApp()
      const mensagem = this.getMensagemWhatsApp()
      window.open(`https://api.whatsapp.com/send?phone=${numero}&text=${mensagem}`, '_blank')
      this.snackbar = true
      this.dialog = false
    },
    abrirFormulario() {
      // Implementar redirecionamento para o formulário
      this.snackbar = true
      this.dialog = false
    },
    abrirFAQ() {
      // Implementar redirecionamento para o FAQ
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.support-dialog {
  border-radius: 12px;
  overflow: hidden;
}

.theme--dark {
  background: #1E1E1E;
}

.theme--dark .support-schedule {
  background: #2D2D2D;
}

.theme--dark .channels-title {
  color: #E0E0E0;
}

.theme--dark .channel-item {
  background: #2D2D2D;
  border-color: #424242;
}

.theme--dark .support-footer {
  color: #B0B0B0;
  background: #2D2D2D;
}

.theme--dark .channel-icon {
  background: #363636;
}

.theme--dark .whatsapp-card .channel-icon {
  background: #1A3329 !important;
}

.theme--dark .form-card .channel-icon {
  background: #1A2733;
}

.theme--dark .whatsapp-pulse {
  background: #1A3329 !important;
  box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.2);
}

.header-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  max-width: 500px;
  margin: 0 auto 16px auto;
  padding: 0 16px;
}

.support-icon {
  margin: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.support-icon .material-icons {
  font-size: 40px;
  color: #FFA000; /* Cor padrão laranja */
  background: #FFF3E0;
  padding: 12px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.support-icon:not(.after-hours) .material-icons {
  color: #4CAF50; /* Verde quando não for after-hours */
  background: #E8F5E9;
}

.support-title {
  font-size: 18px;
  color: #FFA000; /* Cor padrão laranja */
  margin: 0;
  line-height: 1.4;
  transition: all 0.3s ease;
  text-align: left;
}

.support-title:not(.after-hours) {
  color: #4CAF50; /* Verde quando não for after-hours */
}

.support-schedule {
  background: #f8f9fa;
  padding: 8px;
  border-radius: 8px;
  margin: 0 auto 16px auto;
  max-width: 450px;
  font-size: 13px;
}

.schedule-item {
  text-align: center;
  margin-bottom: 4px;
  padding: 4px;
}

.schedule-item:last-child {
  margin-bottom: 0;
}

.channels-title {
  font-size: 16px;
  color: #2c3e50;
  margin: 12px 0;
}

.support-channels {
  margin-bottom: 12px;
}

.channel-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  text-align: left;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  gap: 12px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  font-size: 14px;
}

.emoji {
  font-size: 24px;
}

.action-buttons {
  gap: 15px;
  margin-bottom: 30px;
}

.action-button {
  text-transform: none !important;
  height: 44px !important;
  font-weight: 500 !important;
  padding: 0 24px !important;
}

.whatsapp-button {
  background-color: #25D366 !important;
  color: white !important;
}

.form-button {
  background-color: #5C6BC0 !important;
  color: white !important;
}

.faq-button {
  background-color: #78909C !important;
  color: white !important;
}

.support-footer {
  color: #666;
  font-style: italic;
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.content-wrapper {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 16px;
}

.channel-item {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.channel-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.channel-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f5f5f5;
}

.whatsapp-card .channel-icon {
  min-width: 48px;
  height: 48px;
  background: #E7FFE7;
}

.whatsapp-card .channel-icon svg {
  width: 32px;
  height: 32px;
}

.form-card .channel-icon {
  background: #E3F2FD;
}

.form-card .channel-icon .material-icons {
  color: #1976D2;
  font-size: 20px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.7);
  }
  
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0);
  }
}

.whatsapp-pulse {
  animation: pulse 2s infinite;
  background: #dcffdc !important;
  box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.7);
}

@media (max-width: 500px) {
  .header-wrapper {
    flex-direction: column;
    text-align: center;
    gap: 8px;
  }

  .support-title {
    text-align: center;
  }
}

@media (min-height: 600px) {
  .support-icon .material-icons {
    font-size: 48px;
    padding: 16px;
  }

  .support-title {
    font-size: 20px;
  }

  .whatsapp-card .channel-icon {
    min-width: 56px;
    height: 56px;
  }

  .whatsapp-card .channel-icon svg {
    width: 36px;
    height: 36px;
  }
}
</style>
