var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.category)?_c('v-card',{staticClass:"selected-category",attrs:{"elevation":"10"}},[_c('div',{staticClass:"category-header",style:({ backgroundColor: _vm.category.color + '15' })},[_c('div',{staticClass:"header-content"},[_c('v-icon',{attrs:{"color":_vm.category.color,"size":"36"}},[_vm._v(_vm._s(_vm.category.icon))]),_c('div',{staticClass:"category-title"},[_c('h2',[_vm._v(_vm._s(_vm.category.name))]),(_vm.category.parents.length)?_c('div',{staticClass:"category-path"},_vm._l((_vm.orderedParents),function(parent){return _c('div',{key:parent.id,staticClass:"path-item"},[_c('v-icon',{staticClass:"path-icon",attrs:{"small":"","color":"rgba(0, 0, 0, 0.4)"}},[_vm._v("subdirectory_arrow_right")]),_c('span',{staticClass:"parent-name"},[_c('span',{staticClass:"level-badge"},[_vm._v(_vm._s(parent.level))]),_vm._v(" "+_vm._s(parent.name)+" ")])],1)}),0):_vm._e()])],1),_c('div',{staticClass:"action-buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-button edit-button",attrs:{"icon":""},on:{"click":_vm.handleEdit}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,2467708869)},[_c('span',[_vm._v("Editar categoria")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-button add-button",attrs:{"icon":""},on:{"click":_vm.handleAddChild}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,738092946)},[_c('span',[_vm._v("Adicionar subcategoria")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action-button delete-button",attrs:{"icon":""},on:{"click":_vm.handleDelete}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,2977155136)},[_c('span',[_vm._v("Excluir categoria")])])],1)]),_c('div',{staticClass:"category-content"},[_c('div',{staticClass:"info-grid"},[_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-label"},[_vm._v("ID")]),_c('div',{staticClass:"info-value"},[_vm._v(_vm._s(_vm.category.id))])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-label"},[_vm._v("Nível")]),_c('div',{staticClass:"info-value"},[_vm._v(_vm._s(_vm.category.level))])]),(_vm.category.children_count)?_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-label"},[_vm._v("Subcategorias diretas")]),_c('div',{staticClass:"info-value"},[_vm._v(_vm._s(_vm.category.children_count))])]):_vm._e(),(_vm.category.all_children_count)?_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"info-label"},[_vm._v("Total de subcategorias")]),_c('div',{staticClass:"info-value"},[_vm._v(_vm._s(_vm.category.all_children_count))])]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }