<template>
  <v-list-tile-title>
    <!-- Channel Label -->
    <v-tooltip bottom lazy>
      <template v-slot:activator="{ on }">
        <span 
          v-on="on" 
          small 
          tile 
          :style="getLabelStyle(channel.color)"
        >
          <span :style="{color: $WiColorContrast(channel.color)}">
            {{ channel.name | stringlength(getMaxCharLength) }}
          </span>
        </span>
      </template>
      <span>
        <span v-t="'modules.talks.pages.talkView.talks.labels.channel'"></span>: 
        <span>{{ channel.name }}</span>
      </span>
    </v-tooltip>

    <!-- Team Label -->
    <v-tooltip v-if="team" bottom lazy>
      <template v-slot:activator="{ on }">
        <span 
          v-on="on" 
          small 
          tile 
          :style="getLabelStyle(team.color)"
        >
          <span :style="{color: $WiColorContrast(team.color)}">
            {{ team.name | stringlength(getMaxCharLength) }}
          </span>
        </span>
      </template>
      <span>
        <span v-t="'modules.talks.pages.talkView.talks.labels.team'"></span>: 
        <span>{{ team.name }}</span>
      </span>
    </v-tooltip>

    <!-- Category Label -->
    <v-tooltip v-if="category" bottom lazy>
      <template v-slot:activator="{ on }">
        <span 
          v-on="on" 
          small 
          tile 
          :style="getLabelStyle(category.color)"
        >
          <span :style="{color: $WiColorContrast(category.color)}">
            {{ category.name | stringlength(getMaxCharLength) }}
          </span>
        </span>
      </template>
      <span>
        <span>Categoria:</span>
        <span>{{ category.name }}</span>
      </span>
    </v-tooltip>
  </v-list-tile-title>
</template>

<script>
export default {
  name: 'TalkListItemLabels',
  
  props: {
    channel: {
      type: Object,
      required: true
    },
    team: {
      type: Object,
      default: null
    },
    category: {
      type: Object,
      default: null
    }
  },

  computed: {
    getMaxCharLength() {
      // Se não tiver team e nem category, exibe 30 caracteres
      if (!this.team && !this.category) {
        return 30
      }
      
      // Se tiver apenas um dos dois (team OU category), exibe 20 caracteres
      if ((!this.team && this.category) || (this.team && !this.category)) {
        return 20
      }
      
      // Se tiver os três (channel, team E category), exibe 12 caracteres
      return 12
    }
  },

  methods: {
    getLabelStyle(color) {
      return {
        margin: '0 1px',
        borderRadius: '5px',
        padding: '2px 3px',
        fontSize: '10px',
        background: color
      }
    }
  }
}
</script> 