var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('view-detail',{attrs:{"index":"budget-template","title":"Detalhes do Modelo de Orçamento","apiUrl":"sales/budget","apiParams":"?with=events;finances;category:id,name,icon,color,form_id;category.form;items;items.item:id,name,type,item_unit_id;items.item.unit:id,code;business:id,type,form_reply_id,created_at,business_category_id;business.category.form;businesses:id,type,business_id,created_at","store":"budget-template"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var content = ref.content;
var refresh = ref.refresh;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-card',{staticClass:"elevation-17"},[_c('v-card-text',[_c('h4',[_vm._v("Nome: "+_vm._s(content.title || ' Modelo de orçamento #' + content.id))])])],1)],1),_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessInfo',{attrs:{"info":_vm.configuration,"data":content}})],1),(content.observation)?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessObservation',{attrs:{"observation":content.observation}})],1):_vm._e(),_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessItems',{attrs:{"business":content,"wiConfigId":"sale","onRefresh":refresh,"showAmounts":true}})],1),_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessFinance',{attrs:{"business":content,"wiConfig":"budget","refresh":refresh}})],1),(content.category.form && content.category.form.id)?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessForm',{attrs:{"refresh":refresh,"reply":content.reply,"businessId":content.id,"form":content.category.form,"replyEditor":content.reply_editor}})],1):_vm._e(),(
            content.business &&
            content.business.category &&
            content.business.category.form &&
            content.business.category.form.id
          )?_c('v-flex',{staticStyle:{"padding-bottom":"20px"},attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('BusinessForm',{attrs:{"refresh":refresh,"reply":content.business.reply,"businessId":content.business.id,"businessBusinessId":content.id,"form":content.business.category.form,"replyEditor":content.business.reply_editor}})],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }