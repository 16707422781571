var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{key:_vm.topic.uuid,staticClass:"topic-card elevation-10",class:{
    'topic-card--dark': _vm.$wiDark,
    'topic-card--unread': _vm.topic.unread_messages > 0,
    'primary--text': _vm.topic.unread_messages > 0
  },attrs:{"hover":""}},[_c('div',{staticClass:"topic-card__content"},[_c('div',{staticClass:"topic-card__media"},[(_vm.topic.talk_contact && _vm.topic.talk_contact.file && _vm.topic.talk_contact.file.url)?_c('v-avatar',{staticClass:"topic-card__avatar",attrs:{"size":"44"}},[_c('v-img',{attrs:{"src":_vm.topic.talk_contact.file.url}})],1):_c('div',{staticClass:"topic-card__icon"},[_c('v-icon',[_vm._v("topic")])],1)],1),_c('div',{staticClass:"topic-card__main",on:{"click":function($event){return _vm.$emit('click', _vm.topic)}}},[_c('div',{staticClass:"topic-card__header"},[_c('h3',{staticClass:"topic-card__title",class:{'white--text': _vm.$wiDark}},[_vm._v(" "+_vm._s(_vm.truncateText(_vm.topic.title || 'Tópico sem título', 50))+" ")])]),_c('div',{staticClass:"topic-card__info"},[(_vm.topic.talk_contact)?_c('span',{staticClass:"topic-card__contact"},[_vm._v(" "+_vm._s(_vm.truncateText(_vm.topic.talk_contact.name, 30))+" ")]):_vm._e(),(_vm.topic.description)?_c('p',{staticClass:"topic-card__description"},[_vm._v(" "+_vm._s(_vm.truncateText(_vm.topic.description, 80))+" ")]):_vm._e(),(_vm.topic.last_message)?_c('p',{staticClass:"topic-card__last-message"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("message")]),_c('span',[_vm._v(" "+_vm._s(_vm.truncateText(_vm.topic.last_message.text_html, 60))+" ")])],1):_vm._e()])]),_c('div',{staticClass:"topic-card__actions"},[_c('time',{staticClass:"topic-card__date"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.topic.last_message_at || _vm.topic.created_at))+" ")]),(_vm.topic.unread_messages > 0)?_c('div',{staticClass:"topic-card__unread"},[_c('span',{staticClass:"topic-card__unread-badge"},[_vm._v(" "+_vm._s(_vm.topic.unread_messages > 99 ? '99+' : _vm.topic.unread_messages)+" ")])]):_vm._e(),_c('div',{staticClass:"topic-card__buttons-area"},[_c('div',{staticClass:"topic-card__buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"topic-card__action-btn",attrs:{"icon":"","outline":"","small":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('cancel', _vm.topic)}}},on),[_c('v-icon',[_vm._v("close")])],1)]}}])},[_c('span',[_vm._v("Cancelar tópico")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"topic-card__action-btn",attrs:{"outline":"","small":"","icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('complete', _vm.topic)}}},on),[_c('v-icon',[_vm._v("done")])],1)]}}])},[_c('span',[_vm._v("Concluir tópico")])])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }