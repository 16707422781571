import { Filters } from '@/default/model/Filters'

export class PersonFilters extends Filters {
  constructor (title = 'Filtrar Pessoas') {
    super()

    this.title = title
    this.fields = {
      responsible_id: this.fieldWiListDialog({
        value: 'responsible_id',
        text: 'Colaborador Responsável',
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'collaborator',
        show: 'person.first_name',
        icon: 'person_outline'
      }),
      channel_id: this.fieldSelectApi ({
        value: 'channel_id',
        text: 'Canal de entrada',
        show: 'name',
        api: {
          url: 'register/channel',
          params: {
            order: 'name,asc'
          }
        },
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        clearable: true
      }),
      tags: this.fieldSelectApi({
        value: 'tags',
        text: 'Tags',
        size: ['xs12', 'sm12', 'md12', 'lg12'],
        api: {
          url: 'register/tag',
          params: {
            fields: 'id,name,color,icon',
          }
        },
        show: 'name',
        color: 'color',
        icon: 'icon',
        multiple: true
      }),
      date_field: this.fieldSelect ({
        value: 'date_field',
        text: 'Filtrar por data',
        placeholder: 'Selecione uma opção...',
        options: [
          { value: "entry_date", text: "Data de Entrada" },
          { value: "created_at", text: "Data de Cadastro" }
        ],
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        clearable: true
      }),
      date_start: this.fieldDate({
        value: 'date_start',
        text: 'Data Inicial',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        disabled: filters => !filters.date_field
      }),
      date_end: this.fieldDate({
        value: 'date_end',
        text: 'Data Final',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        disabled: filters => !filters.date_field
      }),
    }
  }
}
