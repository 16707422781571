import { BusinessAgency } from '@/modules/business/config/BusinessAgency'

class SalesAgency extends BusinessAgency {
  constructor() {
    super()
    this.id = 'a02e979e'
  }
}

export class BudgetAgency extends SalesAgency {
  constructor() {
    super()
    this.title.list = 'Órgãos'
  }
} 