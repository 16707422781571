<template>
  <div class="chat-topics" :class="{'chat-topics--dark': $wiDark, 'chat-topics__container--dialog': mode === 'dialog'}">
    <ChatTopicsLoading v-if="loadings.load" />
    
    <!-- Content -->
    <div v-else class="chat-topics__content">
      <ChatToolbar
        :chat="chat"
        :chatUuid="chatUuid"
        @onProfileClick="profileDialog = true"
        @onInfoClick="goToChatInfo"
        @onRefreshClick="getTopics(chatUuid)"
        @onCloseClick="closeAction"
      >
        <template #actions>
          <!-- ocultar botão no mobile com css -->
          <v-btn 
            @click="addTopic" 
            color="white" 
            class="chat-topics__new-btn hidden-xs-only hidden-sm-only"
            outline small
            v-prms="{id: '03fe44da'}"
          >
            <v-icon left>add</v-icon>
            Novo Tópico
          </v-btn>
        </template>
      </ChatToolbar>

      <div class="chat-topics__container">
        <div class="chat-topics__list" :class="{'chat-topics__list--dialog': mode === 'dialog'}">
          <!-- Topics List -->
          <template v-if="topicsOrdered.length">
            <transition-group name="topic-list" tag="div">
              <ChatTopicCard
                v-for="topic in topicsOrdered"
                :key="topic.uuid"
                :topic="topic"
                @click="openTopic"
                @cancel="cancelTopic"
                @complete="completeTopic"
              />
            </transition-group>

            <!-- Load More Button -->
            <div class="chat-topics__load-more">
              <v-btn
                @click="paginateTopics"
                :loading="loadings.paginate"
                :disabled="!paginate.next_page_url"
                outline
                color="primary"
              >
                <v-icon left>refresh</v-icon>
                Carregar mais tópicos
              </v-btn>
            </div>
          </template>

          <!-- Empty State -->
          <ChatTopicsEmpty 
            v-else-if="!topicsOrdered.length"
            @add-topic="addTopic"
          />
        </div>
      </div>
    </div>

    <ChatProfileDialog
      :chat="chat"
      :active="profileDialog"
      @onActive="profileDialog = $event"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ChatToolbar from './components/ChatToolbar'
import ChatProfileDialog from './ChatProfileDialog'
import ChatTopicsLoading from './components/ChatTopicsLoading.vue'
import ChatTopicCard from './components/ChatTopicCard.vue'
import ChatTopicsEmpty from './components/ChatTopicsEmpty.vue'

export default {
  name: 'ChatTopics',
  components: {
    ChatToolbar,
    ChatProfileDialog,
    ChatTopicsLoading,
    ChatTopicCard,
    ChatTopicsEmpty
  },
  data: () => ({
    profileDialog: false,
    chatDialog: false,
    currentTopicId: null
  }),
  computed: {
    ...mapState('ChatTopics', ['paginate', 'loadings', 'chat']),
    ...mapGetters('ChatTopics', ['topicsOrdered']),
    chatUuid() {
      return this.setChatUuid || this.$route.params.id
    }
  },
  methods: {
    ...mapActions('Chat', ['openChatDialog']),
    ...mapActions('ChatTopics', [
      'getTopics', 
      'paginateTopics', 
      'resetTopics',
      'subscribeOnTopics',
      'unsubscribeOnTopics'
    ]),
    formatDate(time) {
      if (time) {
        const date = this.$moment(time)
        const today = this.$moment().startOf('day')
        const days = Math.round(this.$moment?.duration(today - date).asDays())
        if (days > 1) {
          return this.$moment(time).format('DD/MM HH:mm')
        }
        return this.$moment(time).fromNow()
      } else if (time === null) {
        return '-'
      } else {
        return 'Agora'
      }
    },
    formatTime(time) {
      if (time) {
        return this.$moment(time).format('HH:mm')
      }
      return ''
    },
    goToChatInfo(uuid) {
      console.log('goToChatInfo', uuid)
      // Implementar navegação
    },
    closeAction() {
      if (this.mode === 'dialog') {
        this.currentTopicId = null
        this.chatDialog = false
        this.$emit('onClose')
      } else {
        this.$router.push({ name: 'chat-view' })
      }
    },
    openTopic(topic) {
      console.log('openTopic', topic)
      if (this.mode === 'dialog') {
        this.openChatDialog({
          chatUuid: this.chatUuid,
          topicUuid: topic.uuid
        })
      } else {
        const breackPoint = this.$vuetify.breakpoint.name
        let routeName = 'chat-view-topic-messages'
        if (breackPoint === 'xs' || breackPoint === 'sm') {
          routeName = 'chat-view-topic-messages-page'
        }
        this.$router.push({
          name: routeName,
          params: { id: this.chatUuid, topic_id: topic.uuid },
          query: { previousChatId: this.$route.params.id }
        })
      }
    },
    onCloseChatDialog() {
      this.chatDialog = false
      this.currentTopicId = null
    },
    addTopic() {
      this.$WiEditDialog({
        wiConfig: 'chat-topic',
        data: {
          chat_id: this.chat.id
        },
        redirectOnSubmit: false,
        onSubmit: () => {
          this.chatChange()
        }
      })
    },
    truncateText(text, length) {
      if (!text) return '';
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
    completeTopic(topic) {
      // Implementar lógica de conclusão
      console.log('Concluir tópico:', topic);
      this.$WiApiPut({
        uri: `chats/chat-topic/${topic.id}`,
        data: {
          status_id: 2
        },
        config: {
          msgLoad: {
            title: `Concluindo tópico...`,
            text: 'Não irá demorar mais que alguns segundos.'
          },
          msgSucess: {
            title: 'Solicitação enviada com sucesso.',
            text: 'O resultado da solicitação irá aparecer na timeline.'
          },
          msgError: {
            title: 'Erro ao processar solicitação',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja concluir o tópico?`,
            text: 'Esta ação não poderá ser desfeita.'
          }
        },
        callback: (response) => {
          console.log(response)
        }
      })
    },
    cancelTopic(topic) {
      // Implementar lógica de cancelamento
      console.log('Cancelar tópico:', topic);
      this.$WiApiPut({
        uri: `chats/chat-topic/${topic.id}`,
        data: {
          status_id: 3
        },
        config: {
          msgLoad: {
            title: `Cancelando tópico...`,
            text: 'Não irá demorar mais que alguns segundos.'
          },
          msgSucess: {
            title: 'Solicitação enviada com sucesso.',
            text: 'O resultado da solicitação irá aparecer na timeline.'
          },
          msgError: {
            title: 'Erro ao processar solicitação',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja cancelar o tópico?`,
            text: 'Esta ação não poderá ser desfeita.'
          }
        },
        callback: (response) => {
          console.log(response)
        }
      })
    },
    chatChange() {
      this.resetTopics()
      this.getTopics(this.chatUuid)
      this.unsubscribeOnTopics(this.$route.query.previousChatId)
      this.subscribeOnTopics(this.chatUuid)
    }
  },
  watch: {
    '$route': 'chatChange',
    'loadings.load'(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.$forceUpdate()
        })
      }
    }
  },
  mounted() {
    console.log('mounted', this.chatUuid)
    this.getTopics(this.chatUuid)
    this.subscribeOnTopics(this.chatUuid)
  },
  beforeDestroy() {
    this.unsubscribeOnTopics(this.chatUuid || this.chat?.uuid)
    this.resetTopics()
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: 'page'
    },
    setChatUuid: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>
/* Classes utilizadas */
.chat-topics {
  background: #F9FAFB;
  transition: background-color 0.3s ease;
}

.chat-topics--dark {
  background: #1C252E !important;
}

.chat-topics__container {
  height: calc(100vh - 107px);
  overflow: hidden;
  margin: 0;
  z-index: 1;
}

.chat-topics__container--dialog {
  height: calc(100vh - 207px);
}

.chat-topics__list {
  height: calc(100vh - 107px);
  padding: 20px 24px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.chat-topics__list--dialog {
  height: calc(100vh - 265px);
}

.chat-topics__load-more {
  text-align: center;
  padding: 32px 0;
}

/* Animações */
.topic-list-enter-active,
.topic-list-leave-active {
  transition: all 0.4s ease;
}

.topic-list-enter,
.topic-list-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

/* Responsivo */
@media (max-width: 960px) {
  .chat-topics__container {
    height: calc(100vh - 96px);
    padding: 0 16px;
  }
}

@media (max-width: 600px) {
  .chat-topics__container {
    padding: 0 12px;
  }
}
</style>