<template>
  <v-list-tile-title>
    <v-icon v-if="contact.type == 2" small>groups</v-icon>
    <strong v-if="contact.person">
      {{ contact.person.name | stringlength(25) }}
    </strong>
    <strong v-else>
      {{ contact.name | stringlength(25) }}
    </strong>
    
    <v-tooltip v-if="checked == 3" bottom lazy>
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          color="red"
          small
          style="font-size: 14px;"
        >do_not_disturb_on</v-icon>
      </template>
      <span>Contato inválido</span>
    </v-tooltip>
  </v-list-tile-title>
</template>

<script>
export default {
  name: 'TalkListItemHeader',
  
  props: {
    contact: {
      type: Object,
      required: true
    },
    checked: {
      type: Number,
      default: null
    }
  }
}
</script> 