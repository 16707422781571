<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="500px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <slot></slot>
      </div>
    </template>
    <v-card class="diagnostic-signal">
      <v-toolbar dense class="diagnostic-signal__toolbar">
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="host">
        <v-layout row wrap>
          <v-flex xs12 sm12 style="padding-right: 10px;">
            <v-card class="elevation-10">
              <v-card-text class="diagnostic-signal__signal diagnostic-signal__title" :class="{'diagnostic-offline__title': !host.online, 'diagnostic-signal__title--signal_is_ok': host.signal_is_ok}">
                <img v-if="!host.online" src="/static/icons/monitor/shutdown_96.png" width="100px">
                <img v-else-if="host.signal_is_ok" src="/static/icons/monitor/ok.gif" width="100px">
                <img v-else src="/static/icons/monitor/box_important.gif" width="100px">
                <div v-if="host.mac_address || host.serial" style="font-size: 20px;">
                  <span v-if="host.mac_address">MAC: {{ host.mac_address }}</span>
                  <br v-if="host.mac_address && host.serial">
                  <span v-if="host.serial">Serial: {{ host.serial }}</span>
                </div>
                <div v-else>
                  <span>{{ host.identification }}</span>
                </div>
                <div>
                  <strong v-if="host.online" style="color: green;">Online</strong>
                  <strong v-else style="color: red;">
                    <strong v-if="host.complement && host.complement.status" style="font-size: 18px; color: orange;">
                      <span v-if="host.complement.status == 1 || host.complement.status == 'undefined'" style="font-size: 18px; color: red;">
                        Offline
                      </span>
                      <span v-else-if="host.complement.status == 2 || host.complement.status == 'Link Loss' || host.complement.status == 'LOS'">
                        Link Loss
                      </span>
                      <span v-else-if="host.complement.status == 3 || host.complement.status == 'DyingGasp' || host.complement.status == 'Power-Off'">
                        DiyngGasp
                      </span>
                      <span v-else style="font-size: 18px; color: red;">
                        Offline
                      </span>
                    </strong>
                    <span v-else style="font-size: 18px; color: red;">
                      Offline
                    </span>
                  </strong>
                </div>
                <v-divider></v-divider>
                <div style="font-size: 38px;">
                  <strong v-if="host.online">Sinal: {{ host.signal || '- - -' }}</strong>
                  <div v-else>
                    <strong style="text-decoration: line-through;">Sinal: {{ host.signal || '- - -' }}</strong>
                    <p v-if="host.signal" style="font-size: 14px;">(Último sinal registrado)</p>
                  </div>
                </div>
                <v-divider></v-divider>
                <div v-if="host.checked_at">
                  <strong>Verificado em: </strong> {{ host.checked_at | moment('DD/MM/YY HH:mm') }}
                </div>
                <v-divider></v-divider>
                <div>
                  <strong>Referência: </strong> {{ host.signal_reference || '- - -' }}
                </div>
                <div v-if="host.online">
                  <strong>Aceitável?: </strong> {{ host.signal_is_ok ? 'Sim' : 'Não' }}
                </div>
                <v-divider></v-divider>
                <div
                  v-if="host.complement && checkComplement(host.complement)"
                  style="
                    margin: 10px;
                    border: 1px solid lightgray;
                    padding: 5px;
                    border-radius: 10px;
                  "
                >
                  <strong
                    v-if="!host.online"
                    style="
                      color: orange;
                      font-size: 12px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 5px 20px;
                      cursor: pointer;
                    "
                    @click="showDetailsDialog = true"
                  >
                    <span>
                      Atenção: Este equipamento está offline e, portanto, as informações abaixo se referem 
                    ao último momento em que o equipamento enviou dados. 
                    As informações podem estar desatualizadas.<br><span style="text-decoration: underline; display: inline;">Clique aqui para mais detalhes</span>.
                    </span>
                  </strong>
                  <div v-if="host.complement.txRate" style="text-decoration: line-through;">
                    <strong>TX Rate: </strong> {{ host.complement.txRate + ' Mbps' }}
                  </div>
                  <div v-if="host.complement.rxRate" style="text-decoration: line-through;">
                    <strong>RX Rate: </strong> {{ host.complement.rxRate + ' Mbps' }}
                  </div>
                  <div v-if="host.complement.downstream">
                    <strong>Downstream: </strong> {{ host.complement.downstream }}
                  </div>
                  <div v-if="host.complement.upstream">
                    <strong>Upstream: </strong> {{ host.complement.upstream }}
                  </div>
                  <div v-if="host.complement.download">
                    <strong>Download: </strong> {{ host.complement.download }}
                  </div>
                  <div v-if="host.complement.upload">
                    <strong>Upload: </strong> {{ host.complement.upload }}
                  </div>
                  <div v-if="host.complement.uptime">
                    <strong>Uptime: </strong> {{ host.complement.uptime }}
                  </div>
                  <div v-if="host.complement.slot">
                    <strong>Placa: </strong> {{ host.complement.slot }}
                  </div>
                  <div v-if="host.complement.port">
                    <strong>Porta: </strong> {{ host.complement.port }}
                  </div>
                  <div v-if="host.complement.index">
                    <strong>Index: </strong> {{ host.complement.index }}
                  </div>
                  <div v-if="host.complement.distance">
                    <strong>Distância: </strong> {{ host.complement.distance | number }} metros
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text v-else class="diagnostic-signal__not-found">
        <h2>Sinal não encontrado.☹️ </h2>
      </v-card-text>
      <v-toolbar v-if="refreshSignal" dense class="diagnostic-signal__toolbar">
        <v-btn outline block small @click="updateHostSignal()">
          <v-icon>update</v-icon>
          <span>Nova leitura de sinal</span>
        </v-btn>
      </v-toolbar>
      <v-dialog v-model="showDetailsDialog" max-width="400">
        <v-card style="border-radius: 10px;">
          <v-card-title class="headline">
            Informações Importantes
          </v-card-title>
          <v-card-text>
            <div class="mb-4">
              <p class="mb-2"><strong>Quando o equipamento está ONLINE:</strong></p>
              <ul>
                <li>Todos os dados exibidos são atualizados em tempo real</li>
                <li>As métricas de performance refletem o estado atual do equipamento</li>
              </ul>
            </div>

            <div class="mb-4">
              <p class="mb-2"><strong>Quando o equipamento está OFFLINE:</strong></p>
              <p>O sistema tentará mostrar o último estado conhecido do equipamento:</p>
              <ul>
                <li>Se existirem dados anteriores: serão exibidas as informações da última leitura bem-sucedida</li>
                <li>Se não existirem dados: significa que ainda não há histórico de leituras para este equipamento</li>
              </ul>
              
              <p class="mt-3">Neste caso, observe que:</p>
              <ul>
                <li>As taxas de transmissão (TX/RX) serão referentes à última leitura</li>
                <li>O uptime mostrado será o da última vez que o equipamento estava conectado</li>
                <li>Outras métricas de performance não representarão o estado atual</li>
              </ul>
            </div>

            <v-divider class="my-3"></v-divider>
            <p class="success--text"><strong>Dica:</strong> Você pode solicitar uma nova leitura a qualquer momento. Assim que o equipamento voltar a ficar online, os dados serão atualizados automaticamente e você voltará a ver as informações em tempo real.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="showDetailsDialog = false">
              Entendi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'HostSignalDialog',
    data: () => ({
      dialog: false,
      showDetailsDialog: false,
    }),
    methods: {
      updateHostSignal: function () {
        this.$WiApiPost({
          uri: `network/host/${this.host.id}/information`,
          data: {
            host_id: this.host.id
          },
          config: {
            msgLoad: {
              title: `Verificando...`,
              text: 'Pode demorar alguns segundos.'
            },
            msgSucess: {
              title: 'Tudo certo!',
              text: 'Informações atualizadas com sucesso!'
            },
            msgError: {
              title: 'Erro ao processar solicitação',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: {
              title: `Tem certeza que deseja realizar nova leitura?`,
              text: 'Esta ação não poderá ser desfeita.'
            }
          },
          callback: (response) => {
            this.$emit('refresh', response)
          }
        })
      },
      checkComplement: function (complement) {
        const keys = Object.keys(complement)
        if (
          keys.length > 0 && (
            keys.includes('txRate') ||
            keys.includes('rxRate') ||
            keys.includes('downstream') ||
            keys.includes('upstream') ||
            keys.includes('download') ||
            keys.includes('upload') ||
            keys.includes('uptime') ||
            keys.includes('slot') ||
            keys.includes('port') ||
            keys.includes('index') ||
            keys.includes('distance')
          )
        ) {
          return true
        }
        return false
      }
    },
    props: {
      host: {
        required: true,
        default: () => ({})
      },
      refreshSignal: {
        required: false,
        default: true
      },
      title: {
        required: false,
        default: 'Sinal do equipamento'
      }
    }
  }
</script>
<style scoped>
  .diagnostic-signal {
    width: 100%;
    text-align: center !important;
  }
  .diagnostic-signal__enable-button {
    cursor: pointer;
  }
  .diagnostic-signal__toolbar {
    z-index: 999;
  }

  .diagnostic-signal__signal {
    text-align: center;
    font-size: 18px;
    /* padding: 0; */
  }
  .diagnostic-signal__not-found {
    margin: 50px 0;
  }
  .diagnostic-signal__title {
    color: orange;
  }
  .diagnostic-signal__title--signal_is_ok {
    color: green;
  }
  .diagnostic-offline__title {
    color: red !important;
  }
</style>
