<template>
  <div class="chats-area" d-flex xs12 sm12 md4 lg3 xl2>
    <v-card tile>
      <div class="chats__toolbar-area">
        <v-toolbar flat dense tabs :color="primaryColor" class="white--text elevation-10 chats__toolbar">
          <v-toolbar-title>
            <v-icon color="white">forum</v-icon>
            Chat Interno
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip v-if="$WiCheckPermission('8f5150a9')" bottom lazy>
            <template v-slot:activator="{ on }">
              <v-btn class="chats__toolbar-button" v-on="on" icon small ripple @click="newChat()" >
                <v-icon class="white--text">person_search</v-icon>
              </v-btn>
            </template>
            <span>
              Pesquisar usuários
            </span>
          </v-tooltip>

          <v-menu
            v-if="$WiCheckPermission('550a585c')"
            v-model="optionsMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x style="z-index: 100;"
          >
            <template v-slot:activator="{ on: menuOn }">
              <v-tooltip v-if="$WiCheckPermission('8f5150a9')" bottom lazy>
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn class="chats__toolbar-button" v-on="{ ...tooltipOn, ...menuOn }" icon small ripple>
                    <v-icon class="white--text">more_vert</v-icon>
                  </v-btn>
                </template>
                <span>
                  Opções
                </span>
              </v-tooltip>
            </template>
            <v-card>
              <v-list>
                <v-list-tile avatar @click="createNewGroup()">
                  <v-list-tile-avatar>
                    <v-icon>group</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Criar novo grupo</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile disabled avatar>
                  <v-list-tile-avatar>
                    <v-icon>supervised_user_circle</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Criar grupo para uma equipe</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile disabled avatar>
                  <v-list-tile-avatar>
                    <v-icon>lock_open</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Criar grupo para um perfil</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-card>
          </v-menu>

          <v-tooltip bottom lazy>
            <template v-slot:activator="{ on }">
              <v-btn class="chats__toolbar-button" v-on="on" icon small ripple @click="getChats()" :loading="loadings.load">
                <v-icon class="white--text">refresh</v-icon>
              </v-btn>
            </template>
            <span>
              Atualizar lista de conversas
            </span>
          </v-tooltip>
          <v-tooltip v-if="drawer" bottom lazy>
            <template v-slot:activator="{ on }">
              <v-btn class="chats__toolbar-button" v-on="on" icon small ripple @click="TOUGGLE_DRAWER_CHATS_ACTIVE()">
                <v-icon class="white--text">close</v-icon>
              </v-btn>
            </template>
            <span v-t="'modules.talks.pages.talkView.talks.actions.close.tooltip'"></span>
          </v-tooltip>
        </v-toolbar>
      </div>
      <div class="chats__list">
        <v-list v-if="myChats.length > 0" two-line dense>
          <template v-for="(chat, indexChat) in myChats">
            <v-divider
              v-if="indexChat"
              :key="indexChat"
              inset
            ></v-divider>

            <v-list-tile
              :key="`chat-${indexChat}`"
              @click="chatAction(chat.chat)"
              v-ripple avatar class="chats__list-tile"
            >
              <v-list-tile-avatar>
                <v-avatar v-if="chat.chat.type_id == 1">
                  <img v-if="chat.person && chat.person.file" :src="chat.person.file.url">
                  <img v-else src="static/icons/user_icon_1.png" />
                </v-avatar>
                <v-avatar v-else-if="[2, 3].includes(chat.chat.type_id)">
                  <img v-if="chat.chat && chat.chat.file" :src="chat.chat.file.url">
                  <img v-else src="static/icons/group_icon_1.jpg" />
                </v-avatar>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <v-list-tile-title v-if="chat.chat.type_id == 1" style="font-size: 14px; font-weight: 500;">
                  <span v-if="chat.person && chat.person.name">
                    {{ chat.person.name }}
                  </span>
                  <span v-else-if="chat.chat && chat.chat.name">
                    {{ chat.chat.name }}
                  </span>
                  <span v-else>
                    - - -
                  </span>
                </v-list-tile-title>
                <v-list-tile-title v-else-if="[2, 3].includes(chat.chat.type_id)" style="font-size: 14px; font-weight: 500;">
                  <v-icon small>group</v-icon> {{ chat.chat.name }}
                </v-list-tile-title>
                <v-list-tile-sub-title v-if="chat.chat.type_id == 3">
                  Tópicos: 2
                </v-list-tile-sub-title>
                <v-list-tile-sub-title
                  v-else-if="chat.chat.last_message"
                  v-html="formatLastMessage(chat.chat.last_message)"
                ></v-list-tile-sub-title>
                <v-list-tile-sub-title v-else>
                  - - -
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text
                  style="font-size: 11px; max-width: 100px; text-align: right;"
                >
                  {{ getDateTimeFormated(chat.chat.last_message_at) }}
                </v-list-tile-action-text>
                <!-- <v-icon color="yellow darken-2">
                  star
                </v-icon> -->
                <strong
                  v-if="chat.unread_messages && parseInt(chat.unread_messages) && parseInt(chat.unread_messages) != 0 && chat.chat.uuid != $route.params.id"
                  v-text="chat.unread_messages"
                  class="chats-unseen-messages-count primary elevation-1"
                ></strong>
              </v-list-tile-action>
            </v-list-tile>
          </template>
        </v-list>
        <div v-else-if="!loadings.load && myChats.length == 0" style="width: 100%; text-align: center; padding: 10px;">
          <h2>
            Você ainda não possui<br>nenhuma conversa.
          </h2>
          <h3 class="mt-3">
            Pesquise por um usuário<br>para começar uma conversa.
          </h3>
        </div>
        <div v-if="loadings.load || myChats.length > 0" style="width: 100%; text-align: center; padding: 10px;">
          <v-btn @click="paginateChats()" flat block :loading="loadings.paginate || loadings.load">
            <v-icon>refresh</v-icon>
            <span v-t="'modules.talks.pages.talkView.talks.labels.loadMore'"></span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
  export default {
    name: 'ChatView',
    data: () => ({
      optionsMenu: false,
      currentChatId: null,
      chatDialog: false,
      topicDialog: false
    }),
    computed: {
      ...mapState('Chats', ['chats', 'loadings']),
      ...mapGetters('Chats', ['myChats']),
      user () {
        return this.$store.state.user
      },
      primaryColor () {
        return !this.$wiDark ? 'primary' : 'dark'
      }
    },
    methods: {
      ...mapActions('Chat', ['openChatDialog']),
      ...mapActions('ChatTopics', ['openTopicsDialog']),
      ...mapActions('Chats', ['getChats', 'paginateChats']),
      ...mapMutations('Pages/Main', ['TOUGGLE_DRAWER_CHATS_ACTIVE']),
      newChat: function () {
        this.$WiListDialog({
          wiConfig: 'collaborator',
          mode: 'select',
          callback: (collaborator) => {
            this.$WiApiPost({
              uri: 'chats/make-chat',
              data: { person_id: collaborator.id },
              callback: ({ sucess }) => {
                if (sucess) {
                  this.getChats()
                  this.$router.push({
                    name: 'chat-view-messages',
                    params: { id: sucess.uuid }
                  })
                }
              },
              config: {
                msgLoad: {
                  title: 'Criando chat...',
                  text: 'Aguarde enquanto o chat é criado.'
                },
                msgSucess: {
                  title: 'Chat criado com sucesso',
                  text: 'O chat foi criado com sucesso.'
                },
                msgError: {
                  title: 'Erro ao criar chat',
                  text: 'Tente novamente ou contacte o suporte.'
                }
              }
            })
          },
          params: { order: 'created_at,desc'},
          filter: { order: 'name,asc', where: 'collaborator,1' }
        })
      },
      getDateTimeFormated: function (time) {
        if (time) {
          const date = this.$moment(time)
          const today = this.$moment().startOf('day')
          const days = Math.round(this.$moment?.duration(today - date).asDays())
          if (days > 1) {
            return this.$moment(time).format('DD/MM HH:mm')
          }
          return this.$moment(time).fromNow()
        } else if (time === null) {
          return '-'
        } else {
          return 'Agora'
        }
      },
      goTo: function (chat) {
        if (chat.type_id === 3) {
          this.goToChatTopics(chat)
        } else {
          this.goToChat(chat)
        }
      },
      goToChat: function (chat) {
        const breackPoint = this.$vuetify.breakpoint.name
        let routeName = 'chat-view-messages'
        if (breackPoint === 'xs' || breackPoint === 'sm') {
          routeName = 'chat-view-messages-page'
        }
        this.$router.push({
          name: routeName,
          params: { id: chat.uuid },
          query: { previousChatId: this.$route.params.id }
        })
      },
      goToChatTopics: function (chat) {
        const breackPoint = this.$vuetify.breakpoint.name
        let routeName = 'chat-view-topics'
        if (breackPoint === 'xs' || breackPoint === 'sm') {
          routeName = 'chat-view-topics-page'
        }
        this.$router.push({
          name: routeName,
          params: { id: chat.uuid },
          query: { previousChatId: this.$route.params.id }
        })
      },
      chatAction: function (chat) {
        if (
          this.$route.name === 'chat-view' ||
          this.$route.name === 'chat-view-messages' ||
          this.$route.name === 'chat-view-messages-page' ||
          this.$route.name === 'chat-view-topic-messages' ||
          this.$route.name === 'chat-view-topics' ||
          this.$route.name === 'chat-view-topics-page' ||
          this.$route.name === 'chat-view-topic-messages'
        ) {
          this.goTo(chat)
        } else {
          this.openChatInDialog(chat)
        }
      },
      openChatInDialog: function (chat) {
        if (chat.type_id === 3) {
          this.openTopicsDialog(chat.uuid)
        } else {
          this.openChatDialog({
            chatUuid: chat.uuid,
            topicUuid: null
          })
        }
      },
      createNewGroup: function () {
        this.$WiEditDialog({
          wiConfig: 'chat-group',
          onSubmit: ({ data }) => {
            this.goToChat(data.uuid)
          },
          redirectOnSubmit: false
        })
      },
      formatLastMessage: function (message) {
        return message.text_html
          .replaceAll('<br>', ' ')
          .replaceAll('<br/>', ' ')
          .replaceAll('<br />', ' ')
          .replaceAll('<p>', ' ')
          .replaceAll('</p>', ' ')
      }
    },
    props: {
      drawer: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>
  .chats-area {
    width: 330px;
    min-width: 330px;
  }
  .chats__list {
    height: calc(100vh - 106px) !important;
    overflow-y: auto;
    padding: 20px 0;
    top: -10px;
  }
  .chats__toolbar {
    border-radius: 10px !important;
  }
  .chats__toolbar >>> .v-toolbar__content {
    padding: 0 14px !important;
  }
  .chats__toolbar-area {
    background: transparent;
    padding: 10px 10px 0px 10px;
  }
  .chats-unseen-messages-count {
    padding: 0 5px;
    color: white;
    border-radius: 20px;
    margin-right: 5px;
  }
  .chats__list-tile {
    cursor: pointer;
  }
  .chats__toolbar-button {
    margin: 6px 3px !important;
  }
  .chats__list-items {
    padding: 0 !important;
    overflow-y: auto;
  }
  /* media queries para mobile */
  @media (max-width: 959px) {
    .chats-area {
      width: 100%;
    }
  }
</style>
