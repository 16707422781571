export default [
  // Primeira linha - Busca inicial
  [
    {
      key: 'header1',
      label: 'Dados iniciais',
      icon: 'person',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'person.cpf_cnpj',
      label: 'CPF',
      icon: 'person',
      size: ['xs12', 'sm4', 'md4', 'lg4']
    },
    {
      key: 'category.name',
      label: 'Produto',
      icon: 'shopping_cart',
      size: ['xs12', 'sm6', 'md6', 'lg6']
    }
  ],

  // Segunda linha - Campos de simulação
  [
    {
      key: 'header2',
      label: 'Dados de simulação',
      icon: 'attach_money',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    // {
    //   key: 'exclusiveMarginValue',
    //   label: 'Valor margem exclusiva cartão',
    //   icon: 'attach_money',
    //   size: ['xs12', 'sm3', 'md3', 'lg3'],
    //   prefix: 'R$'
    // },
    {
      key: 'integration_code',
      label: 'Matrícula',
      icon: 'badge',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'person.birthday',
      label: 'Data Nascimento',
      icon: 'cake',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      type: 'date'
    },
    {
      key: 'agreement.name',
      label: 'Convênio',
      icon: 'business',
      size: ['xs12', 'sm12', 'md2', 'lg4']
    },
    {
      key: 'agreement.name',
      label: 'Órgão',
      icon: 'account_balance',
      size: ['xs12', 'sm12', 'md2', 'lg4']
    }
  ],

  // Terceira linha - Resultados da simulação
  [
    {
      key: 'header3',
      label: 'Resultados da simulação',
      icon: 'attach_money',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'total_liquid',
      label: 'Valor Disponível',
      icon: 'attach_money',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      prefix: 'R$',
      type: 'money'
    },
    {
      key: 'amount_installment',
      label: 'Valor da parcela',
      icon: 'attach_money',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      prefix: 'R$',
      type: 'money'
    },
    {
      key: 'quantity_installment',
      label: 'Quantidade de parcelas',
      icon: 'attach_money',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      prefix: 'R$'
    },
    {
      key: 'sum_amount_unit',
      label: 'Margem Total',
      icon: 'attach_money',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      prefix: 'R$',
      type: 'money'
    },
    // {
    //   key: 'withdrawalLimitValue',
    //   label: 'Valor Limite Saque',
    //   icon: 'local_atm',
    //   size: ['xs12', 'sm3', 'md3', 'lg3'],
    //   prefix: 'R$'
    // },
    // {
    //   key: 'maxWithdrawalTerm',
    //   label: 'Prazo Máximo Saque',
    //   icon: 'schedule',
    //   size: ['xs12', 'sm2', 'md2', 'lg2'],
    //   suffix: 'meses'
    // }
  ],

  // Quarta linha - Dados do canal e tipo de operação
  // [
  //   {
  //     key: 'person.channel.name',
  //     label: 'Canal de Vendas',
  //     icon: 'store',
  //     size: ['xs12', 'sm7', 'md7', 'lg7']
  //   },
  //   {
  //     key: 'operationType',
  //     label: 'Tipo de Operação',
  //     icon: 'category',
  //     size: ['xs12', 'sm5', 'md5', 'lg5']
  //   }
  // ],

  // Dados Cadastrais - Primeira linha
  [
    {
      key: 'header4',
      label: 'Dados do cliente',
      icon: 'person',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'person.first_name',
      label: 'Nome',
      icon: 'person',
      size: ['xs12', 'sm3', 'md3', 'lg3']
    },
    {
      key: 'person.nationality',
      label: 'Nacionalidade',
      icon: 'public',
      size: ['xs12', 'sm3', 'md3', 'lg3']
    },
    {
      key: 'person.naturalness',
      label: 'Naturalidade',
      icon: 'location_city',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'person.sex',
      label: 'Sexo',
      icon: 'wc',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'person.marital_status',
      label: 'Estado Civil',
      icon: 'family_restroom',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    }
  ],

  // Dados Cadastrais - Segunda linha
  [
    {
      key: 'person.document_type',
      label: 'Tipo Doc',
      icon: 'description',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      default: 'RG'
    },
    {
      key: 'person.rg',
      label: 'Nº Documento',
      icon: 'badge',
      size: ['xs12', 'sm3', 'md3', 'lg3']
    },
    {
      key: 'person.rg_issuing_agency',
      label: 'Emissor',
      icon: 'domain',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'person.rg_state.code',
      label: 'UF',
      icon: 'place',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'person.rg_issue_date',
      label: 'Data Emissão',
      icon: 'date_range',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      type: 'date'
    }
  ],

  // Dados Cadastrais - Terceira linha
  [
    {
      key: 'person.mothers_name',
      label: 'Nome da mãe',
      icon: 'person',
      size: ['xs12', 'sm3', 'md2', 'lg2']
    },
    {
      key: 'talk_contact.number',
      label: 'Celular (WhatsApp)',
      icon: 'phone',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      type: 'phone'
    },
    {
      key: 'person.email',
      label: 'Email',
      icon: 'email',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'person.income_gross',
      label: 'Renda',
      icon: 'payments',
      size: ['xs12', 'sm2', 'md2', 'lg2'],
      prefix: 'R$',
      type: 'money'
    },
    {
      key: 'invoice',
      label: 'Id Averbação',
      icon: 'tag',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'integration',
      label: 'Cod Correspondente',
      icon: 'numbers',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    }
  ],

  // Dados Cadastrais - Quarta linha
  [
    {
      key: 'person.salary_liquid',
      label: 'Salário Líquido',
      icon: 'payments',
      size: ['xs12', 'sm3', 'md3', 'lg3'],
      prefix: 'R$',
      type: 'money'
    },
    {
      key: 'person.social_name',
      label: 'Nome Social',
      icon: 'person',
      size: ['xs12', 'sm9', 'md9', 'lg9']
    }
  ],

  // Dados Endereço
  [
    {
      key: 'header5',
      label: 'Endereço do cliente',
      icon: 'route',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'address.type_name',
      label: 'Tipo de residência',
      icon: 'route',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'address.zip_code',
      label: 'CEP',
      icon: 'route',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'address.address',
      label: 'Logradouro',
      icon: 'route',
      size: ['xs12', 'sm12', 'md4', 'lg4']
    },
    {
      key: 'address.number',
      label: 'Número',
      icon: 'pin',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    },
    {
      key: 'address.complement',
      label: 'Complemento',
      icon: 'apartment',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }
  ],

  // Dados Endereço - Segunda linha
  [
    {
      key: 'address.district',
      label: 'Bairro',
      icon: 'location_city',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    },
    {
      key: 'address.city.name',
      label: 'Cidade',
      icon: 'location_city',
      size: ['xs12', 'sm12', 'md3', 'lg3']
    },
    {
      key: 'address.city.state.code',
      label: 'UF',
      icon: 'place',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }
  ],

  // Dados de Liberação Financeira - Primeira linha
  [
    {
      key: 'header6',
      label: 'Dados da conta do cliente',
      icon: 'account_balance',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'finance_account.type.name',
      label: 'Tipo Conta',
      icon: 'account_balance',
      size: ['xs12', 'sm2', 'md2', 'lg2']
    },
    {
      key: 'finance_account.bank.name_full',
      label: 'Banco',
      icon: 'account_balance',
      size: ['xs12', 'sm4', 'md3', 'lg3']
    },
    {
      key: 'finance_account.agency',
      label: 'Agência',
      icon: 'store',
      size: ['xs12', 'sm3', 'md2', 'lg2']
    },
    {
      key: 'finance_account.account',
      label: 'Conta',
      icon: 'credit_card',
      size: ['xs12', 'sm3', 'md3', 'lg3']
    },
    {
      key: 'finance_account.operation',
      label: 'Dígito',
      icon: 'pin',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }
  ],

  // Documentos
  [
    {
      key: 'header7',
      label: 'Arquivos e documentos anexados',
      icon: 'description',
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'header'
    },
    {
      key: 'documents',
      label: 'Documentos',
      icon: 'description',
      type: 'files',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }
  ]
]
